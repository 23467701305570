<template>
  <div 
    style="display: contents"
    v-if="!isInnerLockedByInvoice(resource, feature)">
    <slot />
  </div>

  <div 
    class="ui-inner-feature" 
    v-else>
    <div class="ui-inner-feature__body">
      <div class="ui-inner-feature__icon">
        <ui-icon glyph="lock" />
      </div>

      <div class="ui-inner-feature__label">{{ $t(`sayl-invoicing.tier_component_locked_title`) }} </div>

      <!-- <actions-button 
        :appearance="$pepper.Appearance.LINK"
        class="ui-inner-feature__link"
        :size="$pepper.Size.S"
        @click="onLearnMore"
      >{{ $t('sayl-invoice.learn_more') }}</actions-button> -->
<!-- 
      <actions-button 
        :appearance="$pepper.Appearance.LINK"
        class="ui-inner-feature__link"
        :size="$pepper.Size.S"
        @click="onContactUs"
      >{{ $t('sayl-invoice.contact_us') }}</actions-button> -->

      <actions-button 
        :appearance="$pepper.Appearance.LINK"
        class="ui-inner-feature__link"
        :size="$pepper.Size.S"
        @click="onLearnMore"
      >{{ $t('sayl-invoice.upgrade') }}</actions-button> 
    </div>
  </div>
</template>

<script>
import { MixinInvoicing } from '@sayl/admin-common'

export default {
  name: 'UiInnerFeature',

  mixins: [ MixinInvoicing ],

  props: {
    resource: {
      required: true,
      type: String,
    },

    feature: {
      default: null,
      type: String
    },
  },
}
</script>
