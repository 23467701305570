<template>
  <!-- Start and End dates -->
  <ui-details 
    class="view-loyalty-config__details flow" 
    open>
    <template #summary>{{ $t('sayl-ecommerce.loyalty_config_active_dates_title') }}</template>
    <!-- <summary class="ui-card__summary layout-modal__summary">{{ $t('sayl-ecommerce.loyalty_config_active_dates_title') }}</summary> -->
    
    <div class="view-loyalty-config__fields view-loyalty-modal__fields flow">
      <div class="group">
        <!-- Start::Date -->
        <forms-input
          :read-only="readOnly"
          type="date"
          v-model="edition.from"
        >{{ $t('sayl-ecommerce.loyalty_config_start_date_label') }}</forms-input>

        <!-- Start::Time -->
        <forms-input
          :read-only="readOnly"
          type="time"
          v-model="edition.fromTime"
          >{{ $t('sayl-ecommerce.loyalty_config_start_time_label') }}</forms-input>
      </div>

      <div class="group">
        <!-- End? -->
        <forms-checkbox
          :appearance="$pepper.Appearance.TOGGLE"
          :hint="$t('sayl-ecommerce.loyalty_config_has_end_date_hint')"
          :read-only="readOnly"
          :size="$pepper.Size.S"
          v-model="hasEndDate"
          @change="onEndDateChange"
        >{{ $t('sayl-ecommerce.loyalty_config_has_end_date_label') }}</forms-checkbox>
      </div>

      <div 
        class="group"
        v-if="hasEndDate">
        <!-- End::Date -->
        <forms-input
          :read-only="readOnly"
          :required="hasEndDate"
          type="date"
          v-model="edition.to"
          >{{ $t('sayl-ecommerce.loyalty_config_end_date_label') }}</forms-input>
        
        <!-- End::Time -->
        <forms-input
          :read-only="readOnly"
          :required="hasEndDate"
          type="time"
          v-model="edition.toTime"
          >{{ $t('sayl-ecommerce.loyalty_config_end_time_label') }}</forms-input>
      </div>
    </div>
  </ui-details>
</template>

<script>
import { mapState } from 'vuex'

import { MixinError } from '@sayl/admin-common'

export default { 
  name: 'LoyaltyProgramConfigDates',

  mixins: [ MixinError ],

  props: {
    errors: {
      type: Object,
    },

    readOnly: {
      required: false,
      type: Boolean
    },
  },

  data() {
    return {
      hasEndDate: false,
    }
  },

  computed: {
    ...mapState({
      edition: state => state.loyalty.loyalty.edition,
      origin: state => state.loyalty.loyalty.origin,
    }),

   
  },

  methods: {
    onEndDateChange() {
      this.edition.to = !this.hasEndDate ? null : this.origin.to;
      this.edition.toTime = !this.hasEndDate ? null : this.origin.toTime;
    },
  },

  mounted() {
    if(!this.$basil.isNil(this.$route.params.program)) {
      this.hasEndDate = !this.$basil.isNil(this.$basil.get(this.edition, 'to', null))
    } else {
      this.hasEndDate = false
    }
  }
}
</script>
