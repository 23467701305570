export default [
  {
    "amount": null,
    "description": "Purchase for more than 100$ and get a permanent reward!",
    "key": "650adb35b5ce73698703b9bd",
    "name": "Bronze",
    "rewards": [
      {
        "description": "Get an automatic 2% discount on your order!",
        "name": "2% discount",
        "type": "perk",
      }
    ]
  },
  {
    "amount": null,
    "description": "Purchase for more than 250$ and get permanent reward!",
    "key": "650adbf1b64477b3630e9735",
    "name": "Silver",
    "rewards": [
      {
        "description": "Get an automatic 5% reward on your basket in our shops!",
        "name": "5% discount",
        "type": "perk",
      }
    ],
  },
  {
    "amount": null,
    "description": "Purchase for more than 1000$ and get some awesome rewards!",
    "key": "650adc1ab64477b3630e9736",
    "name": "Gold",
    "rewards": [
      {
        "description": "10% discount",
        "name": "Get an automatic permanent 10% discount on your orders in our shops!",
        "type": "perk",
      },
      {
        "description": "A wallet voucher worth 100€",
        "name": "Gold card",
        "type": "voucher",
      }
    ],
  },
  {
    "amount": null,
    "description": "Spend more than 5000$ in our shop and be one of our exclusive VIP customer!",
    "key": "650aef54c094949343043ae4",
    "name": "Platinum",
    "rewards": [
      {
        "description": "Get an automatic and permanent 20% on any purchase in our shops!",
        "name": "20% discount",
        "type": "perk",
      }
    ],
  }
]