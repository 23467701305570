<template>
  <layout-modal 
    v-bind="attrs"
    v-on="listeners">

    <div class="flow">
      <div class="modal-url-builder__article modal-url-builder__group group">
        <!-- Storefront -->
        <forms-select 
          class="modal-url-builder__storefront"
          :options="embedsList"
          :placeholder="$t('sayl-settings.askemma_url_builder_embeds')"
          v-model="embed"
        >{{ $t('sayl-settings.askemma_url_builder_embeds') }}</forms-select>

        <!-- Services -->
        <forms-toggle
          class="modal-url-builder__services"
          :values="servicesList"
          v-model="service"
          v-if="path === null"
        >{{ $t('sayl-settings.askemma_url_builder_services') }}</forms-toggle>
      </div>

      <div class="modal-url-builder__article group">
        <!-- Lang -->
        <forms-select 
          :options="langs"
          :placeholder="$t('sayl-settings.askemma_url_builder_languages')"
          v-model="lang"
        >{{ $t('sayl-settings.askemma_url_builder_languages') }}</forms-select>
          
        <!-- Table -->
        <forms-select 
          :disabled="!isEatin"
          :options="locationsList"
          :placeholder="$t('sayl-settings.askemma_url_builder_locations')"
          v-model="location"
        >{{ $t('sayl-settings.askemma_url_builder_locations') }}</forms-select>
      </div>

      <div class="modal-url-builder__article url-builder__utms">
        <forms-iterable 
          :options="utmOptions"
          :i18n="{ filled: $t('sayl.iterable_hint_add') }"
          v-model="utms"
        >{{ $t('sayl-settings.askemma_url_builder_utm') }}</forms-iterable>
      </div>

      <!-- URL -->
      <div class="modal-url-builder__article group modal-url-builder__outputs">
        <forms-checkbox
          :appearance="$pepper.Appearance.TOGGLE"
          :hint="$t('sayl-settings.askemma_url_builder_no_cache_hint')"
          :size="$pepper.Size.S"
          v-model="noCache"
        >{{ $t('sayl-settings.askemma_url_builder_no_cache') }}</forms-checkbox>
      </div>

      <!-- URL -->
      <div class="modal-url-builder__article modal-url-builder__outputs group">
        <forms-input 
          class="modal-url-builder__output"
          id="field" 
          read-only 
          :value="url"
        />

        <div class="modal-url-builder__actions">
          <actions-button 
            :appearance="$pepper.Appearance.SUBTLE"
            class="modal-url-builder__action"
            @click="onCopy"
            icon-post="copy"
            :title="$t('sayl-settings.askemma_url_builder_copy')"
          />
          
          <actions-button 
            :appearance="$pepper.Appearance.SUBTLE"
            class="modal-url-builder__action"
            :href="url"
            icon-post="open"
            target="_blank"
            :title="$t('sayl-settings.askemma_url_builder_go_to')"
          />

          <actions-button 
            :appearance="$pepper.Appearance.SUBTLE"
            class="modal-url-builder__action"
            @click="onReset"
            iconPre="redo"
            :title="$t('sayl-settings.askemma_url_builder_reset')"
          />
        </div>
      </div>
    </div>

    <!-- Footer -->
    <template v-slot:footer>
      <actions-button
        :appearance="$pepper.Appearance.DEFAULT"
        @click="$emit('close')"
      >{{ $t('sayl.close') }}</actions-button>
    </template>
  </layout-modal>
</template>

<script>
import {mapGetters,mapState} from 'vuex'
import { MixinServices } from '@sayl/admin-common'

export default {
  name: 'ModalURLBuilder',

  mixins: [
    MixinServices
  ],

  props: {
    path: {
      type: String,
      default: null,
    },

    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return { 
      embed: null,
      service: null,
      lang: null,
      location: null,
      utms: [],
      noCache: false
    }
  },

  computed: {
     ...mapState({ 
      host: state => state['host'].edition,
      embeds: state => state.settings.askemmaSettings.embeds.all,
      shops: state => state.settings.askemmaSettings.shops.all,
      locations: state => state.settings.askemmaSettings.locations.all,
    }),

    ...mapGetters({
      config: 'sayl/config',
    }),

    askemmaHref(){
      return this.$basil.get(this.config, 'askemma.url', '')
    },

    attrs() {
      return {
        classes: {
          'modal-url-builder': true,
          '-no-mt': true,
          '-body-p0': true
        },
        closable: true,
        title: this.$t('sayl.modal_url_builder_title'),
        description: this.$t('sayl.modal_url_builder_description'),
        visible: this.visible
      }
    },

    isEatin() {
      return this.$basil.get(this.service, 'value') === 'eatin'
    },

    embedsList() {
      let ret = this.embeds || [];
      return ret.map(e => {
        return Object.assign(e, { label: e.name, value: e.id })
      }).sort((a, b) => {
        if (a.label < b.label) {
            return -1;
        } else if (a.label > b.label) {
            return 1;
        } else {
            return 0;
        }
      })
    },

    locationsList() {
      let ret = []

      this.locations && this.locations.forEach((e, i) => {
        let shop = this.shops.find(s => s.id === e.shopId)
        let shopName = this.$basil.get(shop, 'title.all', null)
        let f = ret.find(r => r.value === this.$basil.get(shop, 'id'))

        let name = this.$basil.get(e, 'name')
        let label = !this.$basil.isNil(name) && !this.$basil.isEmpty(name.all) ? name.all : 'unknown table'

        if(f == null) {
          ret.push({
            label: shopName,
            group: true,
            options: [Object.assign(e, { label, value: e.id })],
            value: this.$basil.get(shop, 'id')
          })
        } else {
          f.options.push(Object.assign(e, { label, value: e.id }))
        }
      })

      ret.forEach(f => {
        f.options = f.options.sort((a, b) => {
          if (a.label < b.label) {
              return -1
          } else if (a.label > b.label) {
              return 1
          } else {
              return 0
          }
        })
      })

      return ret.sort((a, b) => {
        if (a.label < b.label) {
            return -1;
        } else if (a.label > b.label) {
            return 1;
        } else {
            return 0;
        }
      })
    },

    langs() {
      return this.host.languages.map(l => {
        return {
          label: this.$t('sayl.lang_' + l),
          value: l
        }
      });
    },

    listeners() {
      return {
        close: () => this.$emit('close')
      }
    },

    servicesList(){
      return this.services
        .map(s => {
          let icon = s;
          icon = icon === 'base' ? 'pickup' : icon;

          return {
            icon,
            title: this.$t('sayl.service_' + s),
            value: s === 'base' ? 'pickup' : s
          }
        })
    },

    url() {
      let ret = this.askemmaHref
      let path = []
      let query = []

      if (!this.$basil.isNil(this.embed)){
        path.push(this.embed)

        if(!this.$basil.isNil(this.path)) {
          path.push(this.path)
        } else if(!this.$basil.isNil(this.service)) {
          path.push(this.service.value)
        }

        if(!this.$basil.isNil(this.lang)) {
          query.push(`lang=${this.lang}`)
        }

        if(!this.$basil.isNil(this.location) && this.isEatin) {
          query.push(`table=${this.location}`)
        }

        if(this.noCache === true) {
          query.push('nocache=1')
        }

        if(!this.$basil.isNil(this.utms) && this.$basil.get(this.utms, 'length', 0) > 0) {
          this.utms.forEach(u => query.push(`${u.field}=${u.value}`))
        }
      }
      
      ret = `${ret}${path.join('/')}`
      if (query.length > 0) {
        ret = `${ret}?${query.join('&')}`
      }

      return ret
    },

    utmOptions() {
      return [
        {
          type: Array,
          placeholder: this.$t('sayl.select_placeholder'),
          options: this.utmTags,
          property: 'field',
          required: true,
        },

        {
          type: String,
          placeholder: this.$t('sayl.placeholder'),
          property: 'value',
          required: true,
        }
      ]
    },

    utmTags() {
      return [
        {
          label: 'utm_campaign',
          value: 'utm_campaign',
        },
        {
          label: 'utm_source',
          value: 'utm_source',
        },
        {
          label: 'utm_medium',
          value: 'utm_medium',
        },
        {
          label: 'utm_term',
          value: 'utm_term',
        },
        {
          label: 'utm_content',
          value: 'utm_content',
        },
      ].map(u => {
        u.disabled = !this.$basil.isNil(this.utms.find(utm => utm.field === u.value))
        return u
      })
    },
  },

  watch: {
    visible: {
      immediate: true,
      handler(nv, ov) {
        if(nv && !ov) {
          this.reset()
        }
      }
    }
  },

  methods: {
    onReset() {
      this.embed = null
      this.service = null
      this.lang = null
      this.location = null
      this.utms = []
    },

    onCopy() {
      let copyText = document.getElementById("field")
      copyText.disabled = false
      copyText.select()
      copyText.disabled = true
      document.execCommand("copy")
    },

    reset() {
      this.loading = true
      let promises = []

      promises.push(this.$settings.askemmaSettings.findEmbeds.bind(this.$settings.askemmaSettings, { args: { visibility: 2 }}))
      promises.push(this.$settings.askemmaSettings.findShops.bind(this.$settings.askemmaSettings, { args: { visibility: 2 }}))
      promises.push(this.$settings.askemmaSettings.findLocations.bind(this.$settings.askemmaSettings, { args: { visibility: 2 }}))

      this.noCache = this.path !== null
      this.$basil.sequence(promises)
        .catch((e) => this.handleErrors(e, 'askemma-settings'))
        .finally(() => this.loading = false)
    }
  }
}
</script>