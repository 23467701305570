<template>
  <div 
    :class="classes"
    id="sayl">
    <!-- Loading -->
    <transition name="view-loading">
      <view-loading v-if="loading && ready" />
    </transition>

    <!-- Application -->
    <template v-if="!loading && ready">
      <!-- Navigations::Primary -->
      <navigations-primary />

      <!-- Ginger::Router-View -->
      <ginger-router-view />

      <!-- Navigations::Footer -->
      <navigations-footer />
    </template>

    <modal-url-builder
      v-bind="urlBuilderAttrs"
      :visible="showUrlBuilder"
      @close="onCloseModalUrlBuilder"
      v-if="showUrlBuilder"
    />

    <!-- Error -->
    <template v-if="!ready && isErrored">
      <router-view />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { READY } from '@spices/ginger'

import { APPLICATION_READY } from '@/data'
import { isProduction } from '@/env'
import ViewLoading from '@/views/ui/loading'

import ModalUrlBuilder from '@/views/ui/components/modals/url-builder'
import NavigationsPrimary from '@/views/ui/components/navigations/primary/primary'
import NavigationsFooter from '@/views/ui/components/navigations/footer'

export default {
  name: 'SaylAdmin',

  components: {
    ModalUrlBuilder,
    NavigationsFooter,
    NavigationsPrimary,
    ViewLoading,
  },

  data() {
    return {
      urlBuilderAttrs: {},

      initRoute: null,
      isReady: false,

      showUpgrade: false,
      showUrlBuilder: false,
    }
  },

  computed: {
    ...mapState({
      errors: state => state.ginger.errors,
      state: state => state.sayl.state,
    }),

    loading() {
      return this.$ginger.loading
    },

    ready() {
      return this.state >= APPLICATION_READY
    },

    isErrored() {
      return this.errors && this.errors.length > 0
    },

    classes() {
      return {
        '-is-loading': this.loading === true
      }
    }
  },

  watch: {
    isErrored: {
      handler: function(value) {
        if (!isProduction) {
          this.errors.forEach(e => console.error(e))
        }
        
        if(value && this.$basil.get(this.errors, '[0].response.status', 401)) {
          this.$router.replace({ name: '401' }).catch(() => {})
        } else if (value) {
          this.$router.replace({name: 'server-error'}).catch(() => {})
        }
      }
    }
  },

  methods: {
    onCloseModalUrlBuilder() {
      this.showUrlBuilder = false
    }
  },

  mounted() {
    this.initRoute = this.$router.currentRoute

    this.$bus.$on(READY, () => {
      this.isReady = true
 
      let saylRedirect = sessionStorage.getItem('sayl-redirect')
      if(!this.$basil.isNil(saylRedirect)) {
        saylRedirect = JSON.parse(saylRedirect)
        this.$router.replace(saylRedirect)
        sessionStorage.removeItem('sayl-redirect')
      } else {
        this.$router.replace(this.initRoute.path).catch(() => {})
      }
    })

    this.$bus.$on('url-builder', (attrs) => {
      this.showUrlBuilder = true
      this.urlBuilderAttrs = attrs
    })

    this.$bus.$on('feature-locked', () => this.showUpgrade = true)
  },

  beforeDestroy() {
    this.$bus.$off('url-builder')
    this.$bus.$off('feature-locked')
  }
}
</script>
