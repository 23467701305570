<template>
  <layout-modal 
    v-bind="attrs"
    v-on="listeners">

    <div class="flow" v-if="!loading">
      <div class="group">
        <forms-select
          :errors="getErrors('type')"
          :options="types"
          :placeholder="$t('sayl.select_placeholder')"
          required
          @change="onRemoveError('type')"
          v-model="edition.type"
        >{{ $t('sayl-loyalty.referral_rule_type_label') }}</forms-select>

        <forms-select
          :errors="getErrors('operator')"
          :options="operators"
          :placeholder="$t('sayl.select_placeholder')"
          required
          @change="onRemoveError('operator')"
          v-model="edition.operator"
        >{{ $t('sayl-loyalty.referral_rule_operator_label') }}</forms-select>
      </div>

      <div class="group">
        <forms-input
          :errors="getErrors('value')"
          :placeholder="$t('sayl.placeholder')"
          required
          :step="1"
          :min="1"
          type="number"
          @input="onRemoveError('value')"
          v-model.number="edition.value"
        >{{ $t('sayl-loyalty.referral_rule_value_label') }}</forms-input>
      </div>
    </div>

    <template v-slot:footer>
      <actions-button
        :appearance="$pepper.Appearance.SUBTLE"
        @click="onDiscard"
      >{{ backLabel }}</actions-button>

      <actions-button
        appearance="primary"
        :disabled="loading"
        :loading="loading"
        @click="onConfirm"
      >{{ $t('sayl.confirm') }}</actions-button>
    </template>
  </layout-modal>
</template>

<script>
import { mapState } from 'vuex'

import { 
  MixinEditionDiscard,
  MixinError,
} from '@sayl/admin-common'

export default {
  name: 'ModalReferralRule',

  mixins: [
    MixinEditionDiscard,
    MixinError,
  ],

  props: {
    value: {
      type: Object,
      default: null,
    },

    visible: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      edition: null,
      errors: {},
      loading: true,
    }
  },

  computed: {
    ...mapState({
      referral: state => state.loyalty.referral.edition,
    }),

    attrs() {
      return {
        title: this.isNew ? this.$t('sayl-loyalty.referral_create_rule') : this.$t('sayl-loyalty.referral_edit_rule'),
        visible: this.visible,
      }
    },

    backLabel() {
      return this.pristine ? this.$t('sayl.close') : this.$t('sayl.discard')
    },

    isNew() {
      return this.$basil.isNil(this.value)
    },

    listeners() {
      return {
        close: this.onDiscard
      }
    },
    
    operators() {
      return [
        {
          label: this.$t('sayl.operator_lt'),
          value: 'lt',
        },
        {
          label: this.$t('sayl.operator_lte'),
          value: 'lte',
        },
        {
          label: this.$t('sayl.operator_eq'),
          value: 'eq',
        },
        {
          label: this.$t('sayl.operator_gt'),
          value: 'gt',
        },
        {
          label: this.$t('sayl.operator_gte'),
          value: 'gte',
        },
      ]
    },

    pristine() {
      return JSON.stringify(this.value) === JSON.stringify(this.edition)
    },

    types() {
      return [
        {
          label: this.$t('sayl-loyalty.referral_rule_type_purchased_amount'),
          value: 'purchased_amount'
        },
        {
          label: this.$t('sayl-loyalty.referral_rule_type_referral_purchased_amount'),
          value: 'referral_purchased_amount'
        },
        {
          label: this.$t('sayl-loyalty.referral_rule_type_referral_purchased_count'),
          value: 'referral_purchased_count'
        },
      ]
    },
  },

  watch: {
    visible: {
      immediate: true,
      handler() {
        if(this.visible) {
          this.reset()
        }
      }
    }
  },

  methods: {
    back() {
      this.$emit('close')
    },

    onConfirm() {
      if(this.validate()) {
        this.$emit('confirm', this.edition)
      } 
    },

    reset() {
      this.loading = true
      this.errors = {}
      this.edition = this.value.copy()
      this.loading = false
    },

    validate() {
      this.errors = {}

      if(this.$basil.isNil(this.edition.type) || this.$basil.isEmpty(this.edition.type)) {
        this.errors['type'] = ['field_is_required']
      }

      if(this.$basil.isNil(this.edition.operator) || this.$basil.isEmpty(this.edition.operator)) {
        this.errors['operator'] = ['field_is_required']
      }

      if(this.$basil.isNil(this.edition.value) || this.edition.value < 1) {
        this.errors['value'] = ['field_must_be_greater_than_0']
      }

      return Object.keys(this.errors).length === 0
    }
  }
}
</script>
