export default [
  {
    key: '1',
    title: '5Eur discount',
    points: 50,
    type: 'discount',
    validityPeriod: 365,
    description: null,
  },
  {
    key: '2',
    title: 'Free advantage',
    points: 100,
    type: 'perks',
    validityPeriod: 365,
    description: null,
  },
  {
    key: '3',
    title: '10Eur wallet',
    points: 200,
    type: 'wallet',
    validityPeriod: 365,
    description: null,
  },
]
