import NotFound from '@/views/ui/errors/notfound'
import NotAuthenticated from '@/views/ui/errors/not-authenticated'
import ServerError from '@/views/ui/errors/server'
import NotAllowed from '@/views/ui/errors/notallowed'

const routes = [
  {
    path: '*',
    name: '403',
    component: NotAllowed,
    meta: {
      bodyClass: 'notfound'
    }
  },

  {
    path: '*',
    name: '401',
    component: NotAuthenticated,
    meta: {
      bodyClass: 'notfound'
    }
  },

  {
    path: '*',
    name: 'notfound',
    component: NotFound,
    meta: {
      bodyClass: 'notfound'
    }
  },

  {
    path: '*',
    name: 'server-error',
    component: ServerError,
    meta: {
      bodyClass: 'notfound'
    }
  }
]

export default routes
