const base = [
  {
    clearable: true,
    component: 'forms-input',
    fqn: 'history-style-bg',
    label: 'bg',
    property: '--slp-history-bg',
    section: 'history',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'history-content-empty-title',
    label: 'empty_title',
    property: 'empty-title',
    section: 'history',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'history-content-entry-empty-title',
    label: 'empty_title',
    property: 'entry-empty-title',
    section: 'history',
    type: 'content',
  },
]

const title = [
  {
    component: 'forms-input',
    fqn: 'history-content-title',
    label: 'title',
    property: 'title',
    section: 'history',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'history-content-entry-title',
    label: 'title',
    property: 'entry-title',
    section: 'history',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'history-style-title-fc',
    label: 'fc',
    property: '--slp-history-title-fc',
    section: 'history',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-select',
    fqn: 'history-style-title-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-history-title-fs',
    section: 'history',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component: 'forms-select',
    fqn: 'history-style-title-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-history-title-fw',
    section: 'history',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const description = [
  {
    component: 'forms-textarea',
    fqn: 'history-content-descr',
    label: 'description',
    property: 'description',
    section: 'history',
    type: 'content',
  },
  {
    component: 'forms-textarea',
    fqn: 'history-content-entry-descr',
    label: 'description',
    property: 'entry-description',
    section: 'history',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'history-style-descr-fc',
    label: 'fc',
    property: '--slp-history-descr-fc',
    section: 'history',
    subtype: 'color',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'history-style-descr-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-history-descr-fs',
    section: 'history',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'history-style-descr-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-history-descr-fw',
    section: 'history',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const meta = [
  {
    component: 'forms-merge-tags',
    fqn: 'history-content-meta',
    label: 'meta',
    logged: true,
    property: 'meta',
    section: 'history',
    subtype: 'merge-tags',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'history-style-meta-fc',
    label: 'fc',
    logged: true,
    property: '--slp-history-meta-fc',
    section: 'history',
    subtype: 'color',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'history-style-meta-fs',
    label: 'fs',
    logged: true,
    options: 'fontSizes',
    property: '--slp-history-meta-fs',
    section: 'history',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'history-style-meta-fw',
    label: 'fw',
    logged: true,
    options: 'fontWeights',
    property: '--slp-history-meta-fw',
    section: 'history',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const actions = [
  {
    component: 'forms-input',
    fqn: 'history-content-btn-more',
    label: 'btn_more',
    logged: true,
    property: 'btn-more',
    section: 'history',
    type: 'content',
  },
]

const itemBase = [
  {
    component: 'forms-input',
    fqn: 'history-style-item-bg',
    label: 'bg',
    property: '--slp-history-item-bg',
    section: 'history',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'history-style-item-bc',
    label: 'bc',
    property: '--slp-history-item-bc',
    section: 'history',
    subtype: 'color',
    type: 'style',
  },

  // Labels
  {
    component: 'forms-input',
    fqn: 'history-style-item-fc',
    label: 'fc',
    property: '--slp-history-item-fc',
    section: 'history',
    subtype: 'color',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'history-style-item-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-history-item-fs',
    section: 'history',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component: 'forms-select',
    fqn: 'history-style-item-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-history-item-fw',
    section: 'history',
    subtype: 'fontWeight',
    type: 'style',
  },

  // Date
  {
    component: 'forms-input',
    fqn: 'history-style-item-date-fc',
    label: 'fc',
    property: '--slp-history-item-date-fc',
    section: 'history',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-select',
    fqn: 'history-style-item-date-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-history-item-date-fw',
    section: 'history',
    subtype: 'fontWeight',
    type: 'style',
  },
]

export default [
  ...base,
  ...title, 
  ...description,
  ...meta, 
  ...actions,
  ...itemBase,
]
