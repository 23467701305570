<template>
  <layout-modal-steps
    v-model="current"
    v-bind="attrs"
    v-on="listeners"
  />
</template>

<script>
import Step1 from './step1'
import Step2 from './step2'
import Step3 from './step3'

import { MixinEditionDiscard } from '@sayl/admin-common'

export default {
  name: 'ModalReferralReward',

  mixins: [ MixinEditionDiscard ],

  props: {
    value: {
      type: Object,
      required: true
    },

    visible: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      current: 0,
      loading: true,
      edition: null,
    }
  },

  computed: {
    attrs(){
      return {
        classes: { 
          'view-challenge__reward': true,
          'modal-reward': true 
        },
        loading: this.loading,
        views: this.views,
        visible: this.visible,
        value: this.edition,
        resetOnClose: false
      }
    },

    isNew() {
      return this.$basil.isNil(this.value)
    },

    listeners() {
      return Object.assign(this.$listeners, { 
        close: () => this.onDiscard(),
      })
    },

    pristine() {
      return JSON.stringify(this.value) === JSON.stringify(this.edition)
    },

    views() {
      return !this.loading ? [
        { view: Step1, },
        { view: Step2, },
        this.edition.type === 'nft' ? { view: Step3 } : null
      ].filter((s) => !this.$basil.isNil(s)) : []
    }
  }, 

  watch: {
    visible() {
      this.current = 0
    }
  },

  methods: {
    back() {
      this.$emit('close')
    },
  },

  mounted() {
    this.current = 0
    this.edition = this.value.copy()
    this.loading = false
  }
};
</script>
