import { SaylController } from '@spices/basil-sayl'

/**
 * Loyalty Card controller
 * for @sayl
 * @class
 */
export default class LoyaltyCardController extends SaylController {
  /**
   * @constructor
   * @param {Object} options
   * @param {Object} options.curry - Curry Controller 
   * @param {Object} options.store
   */
  constructor({ curry, fqn = 'loyalty.card', module = 'loyalty', store }) {
    super({ curry, fqn, module, store })
  }

  ////////////////////////////////////////
  export({ args = {} }) {
    return new Promise((resolve, reject) => {
      this._curry.export(args)
        .then((data) => resolve(data))
        .catch((e) => reject(e))
    })
  }

  getKpis() {
    return new Promise((resolve, reject) => {
      this._curry.kpis()
        .then((value) => {
          this.store.commit(`${this.module}/kpis`, { name: this.fqn + '.kpis', value });
          resolve(value);
        })
        .catch((e) => reject(e))
    })
  }

  users({ args = {} }) {
    return new Promise((resolve, reject) => {
      this.store.commit(`${this.module}/loading`, { name: this.fqn + '.users', value: true })
      let payload = Object.assign(args, {})

      this._curry.find(payload)
        .then(({ collection, meta }) => {
          this.store.commit(`${this.module}/collection`, { name: this.fqn + '.users', values: collection, meta });
          resolve(collection)
        })
        .catch((e) => reject(e))
        .finally(() => this.store.commit(`${this.module}/loading`, { name: this.fqn + '.users', value: false }))
    })
  }
}
