<template>
  <layout-level1 v-bind="attrs">
    <div class="layout-level1__container">
      <div 
        class="view-upgrade__body" 
        v-if="hasTiers">
        <table class="view-upgrade__table">
          <thead class="view-upgrade__header">
            <!-- Tier -->
            <tr>
              <th class="view-upgrade__heading view-upgrade__sticky">{{ $t('sayl-invoice.col_features') }}</th>

              <th 
                :class="{ 
                  'view-upgrade__heading -first': true, 
                  '-is-active': tier.id !== t.id && t.priority === 2
                }"
                v-for="t in tiers">
                {{ t.name }}

                <data-tag 
                  :appearance="$pepper.Appearance.PRIMARY"
                  class="view-upgrade__current" 
                  :size="$pepper.Size.S"
                  v-if="tier.id === t.id"
                >{{ $t('sayl-invoice.tag_current_plan') }}</data-tag>

                <data-tag 
                  :appearance="$pepper.Appearance.PRIMARY"
                  class="view-upgrade__recommended"
                  :size="$pepper.Size.S"
                  v-if="tier.id !== t.id && t.priority === 2"
                >{{ $t('sayl-invoice.tag_recommended_plan') }}</data-tag>
              </th>
            </tr>

            <!-- Price -->
            <tr>
              <th class="view-upgrade__sticky"></th>

              <th 
                :class="{ 'view-upgrade__heading': true, '-is-active': tier.id !== t.id && t.priority === 2 }"
                v-for="t in tiers">
                <div class="view-upgrade__pricing">
                  <div 
                    class="view-upgrade__price" 
                    v-html="formatBasePrice(t)"
                  ></div>

                  <actions-button
                    class="view-upgrade__pricing-action"
                    :appearance="$pepper.Appearance.DEFAULT"
                    icon-post="info"
                    :title="$t('sayl-invoice.view_price_grid_action')"
                    :size="$pepper.Size.S"
                    v-if="!t.name.includes('free')"
                    @click.stop.prevent="onShowPricesForTier(t)"
                  />
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            <!-- Functionalities -->
            <tr v-for="(f, j) in functionalities">
              <td class="view-upgrade__label  view-upgrade__sticky">{{ f.label }}</td>

              <td 
                :class="{ 'view-upgrade__cell': true, '-is-active': tier.id !== t.id && t.priority === 2 }"
                v-for="(t, i) in tiers">
                <div class="view-upgrade__functionality">
                  <div :class="{ 
                    'view-upgrade__enable': true,
                    '-is-disabled': isFunctionliatiesExcluded(f, t) 
                  }">
                    <ui-icon 
                      class="view-upgrade__status"
                      :glyph="isFunctionliatiesExcluded(f, t) ? 'cross' : 'check'" 
                    />
                  </div>
                </div>
              </td>
            </tr>

            <!-- Event retention in days -->
            <tr>
              <td class="view-upgrade__sticky -events">{{ $t('sayl-invoice.events_retention') }}</td>

              <td 
                :class="{ 'view-upgrade__cell -events': true, '-is-active': tier.id !== t.id && t.priority === 2 }"
                v-for="t in tiers">
                {{ $t('sayl-invoice.events_retention_days', { value: $basil.get(t, 'eventsRetention', 0)}) }}
              </td>
            </tr>

            <!-- Actions -->
            <tr>
              <td class="view-upgrade__sticky"></td>
              <td 
                :class="{ 'view-upgrade__cell -bottom': true, '-is-active': tier.id !== t.id && t.priority === 2 }"
                v-for="t in tiers">
                <div class="-action">
                  <actions-button 
                    :size="$pepper.Size.S"
                    @click="onContactUs"
                    v-if="t.id !== tier.id"
                  >{{ $t('sayl-invoice.contact_us_action') }}</actions-button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

        <data-empty
          icon="file-invoice"
          :description="$t('sayl-invoice.no_tiers_defined_description')"
          :title="$t('sayl-invoice.no_tiers_defined')"
          v-if="!hasTiers"
        />
    </div>

    <modal-upgrade-prices
      :tier="selectedTier"
      :visible="showPrices"
      @close="showPrices = false"
      v-if="showPrices"
    />
  </layout-level1>
</template>

<script>
import ModalUpgradePrices from './modal-prices.vue'

import { MixinInvoicing } from '@sayl/admin-common'

export default {
  name: 'ViewUpgrade',

  components: {
    ModalUpgradePrices,
  },

  mixins: [ MixinInvoicing ],

  data() {
    return {
      selectedTier: null,
      showPrices: false,
    }
  },

  computed: {
    attrs() {
      return {
        breadcrumb: this.breadcrumb,
        class: { 'view-upgrade': true },
        icon: 'file-invoice',
        title: this.title,
        style: `--column-count: ${this.tiers.length}`
      }
    },

    breadcrumb() {
      return [
        { label: this.title, icon: 'file-invoice', }
      ]
    },

    currency() {
      return this.$basil.get(window.$dl, 'invoice.currency')
    },

    features() {
      let ret = this.$basil.get(window, '$dl.invoice.features', [])
      return ret.filter(r => this.$basil.get(r, 'pricing.type') != 'delegated')
    },

    functionalities() {
      let erFull = []
      let tiers = this.$basil.get(window.$dl, 'invoice.tiers', [])

      tiers.forEach(t => {
        t.excludedResources.forEach((e) => {
          // If there is features, split it to get atomic functionalities
          if(!this.$basil.isNil(e.features) && !this.$basil.isEmpty(e.features)) {
            e.features.forEach(ef => {
              let label = this.$t(`sayl-invoice.feature_${e.name.split('.').join('_')}`)
              let ret = Object.assign({ label }, e)
              ret.label += `_${ef.name}`
              let f = erFull.find(c => c.label === ret.label)
              if(this.$basil.isNil(f)) {
                ret.label = this.$t(ret.label)
                erFull.push(ret)
              }
            })
          } 
          // If there is no features
          else {
            let label = this.$t(`sayl-invoice.feature_${e.name.split('.').join('_')}`)
            let ret = Object.assign({ label }, e)
            let f = erFull.find(c => c.name === e.name)
            if(this.$basil.isNil(f)) {
              erFull.push(ret)
            }
          }
        })
      })

      return erFull
    },

    hasTiers() {
      let ret = this.$basil.get(window.$dl, 'invoice.tiers')
      return !this.$basil.isNil(ret) && !this.$basil.isEmpty(ret)
    },

    tier() {
      return this.$basil.get(window.$dl, 'invoice.tier')
    },

    tiers() {
      let tiers = this.$basil.get(window.$dl, 'invoice.tiers', [])
      let nextT = this.$basil.get(this.tier, 'nextTier', null)

      if(this.$basil.isNil(nextT)) {
        return [this.tier]
      }

      let ret = tiers.filter(t => t.priority > this.tier.priority)
      ret.unshift(this.tier)
      return ret
    },

    title() {
      return this.$t('sayl-invoice.upgrade_title')
    },
  },

  methods: {
    formatBasePrice(t) {
      let ret = this.tieredPrice(t)
      ret = `<span class="-l -price ${t.id === this.tier.id ? '-is-active' : ''}">${ret}</span>`
      return this.$t('sayl-invoice.start_price', { price: ret })
    },

    isFunctionliatiesExcluded(func, tier) {
      let er = this.$basil.get(tier, 'excludedResources', [])
      let ret = er.find(e => e.name === func.name)

      return !this.$basil.isNil(ret)
    },

    onShowPricesForTier(tier) {
      this.selectedTier = tier
      this.showPrices = true
    },

    tieredPrice(tier) {
      let ret = 0
      let feats = this.features.filter(f => f.pricing.type === 'tiered')
      
      feats.forEach(f => {
        let t = f.pricing.tiers.reduce((acc, cur) => {
          if(acc == null || acc.max > cur.max) {
            return cur
          }
          return acc
        }, null)

        if(!this.$basil.isNil(t)) {
          let price = t.prices.find(p => p.tier === tier.name)
          let curPrice = this.$basil.get(price, `prices.${this.currency}`)
          ret += curPrice
        }
      })

      ret = Number.isNaN(ret) ? 0 : ret
      return this.$basil.i18n.currency(ret, { currency: this.$basil.i18n.Currencies.getByAlpha(this.currency) })
    }
  }
}
</script>