// SAYL customer
const LIST = [
  // IDENTITY
  {
    description: 'sayl-customer.identify_description',
    fqn: 'sayl-customer-identity',
    icon: 'face-id',
    icons: ['flag', 'git-compare', 'bolt'],
    label: 'sayl-customer.identity',
    subs: [],
    children: []
  },
  {
    description: 'sayl-audiences.segments_description',
    fqn: 'sayl-customer-segments', 
    icon: 'face-id',
    href: { name: 'sayl-audiences_segments' },
    label: 'sayl-audiences.segment',
    subs: ['sayl-audiences_segments-item-editor', 'sayl-audiences_segments-item-overview', 'sayl-audiences_segments-item-id'],
  },
  {  
    description: 'sayl-audiences.events_description',
    fqn: 'sayl-customer-behaviors',
    href: { name: 'sayl-audiences_events' },
    icon: 'focus',
    label: 'sayl-audiences.events',
    subs: ['sayl-audiences_events-received-item', 'sayl-audiences_events-library-item'],
  },
  {
    description: 'sayl-audiences.utm_description',
    fqn: 'sayl-customer-campaigns',
    icon: 'face-id',
    href: { name: 'sayl-audiences_utm' },
    label: 'sayl-audiences.utm',
  },

  // Activate
  {
    description: 'sayl-customer.activate_description',
    fqn: 'sayl-customer-activate',
    icon: 'rocket',
    icons: ['bag', 'building-store', 'delivery'],
    label: 'sayl-customer.activate',
    href: { name: 'sayl-audiences_interactions' },
    subs: ['sayl-audiences_interactions-item'],
  },

  // Booking
  // {
  //   fqn: 'sayl-customer-bookings',
  //   href: { name: 'sayl-booking_home' },
  //   icon: 'bookmark',
  //   label: 'sayl-booking.bookings',
  //   subs: ['sayl-booking_entry']
  // },

  // Feedbacks
  {
    fqn: 'sayl-customer-feedbacks',
    href: { name: 'sayl-feedbacks_home' },
    icon: 'star',
    description: 'sayl-feedback.feedback_description',
    label: 'sayl-feedback.title',
    subs: [
      'sayl-feedbacks_library',
      'sayl-feedbacks_item',
      'sayl-feedbacks_entries',
      'sayl-feedbacks_entries-item',
    ]
  },
]

export default LIST
