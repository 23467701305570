<template>
  <div :class="classes">
    <div 
      class="data-progress__inner" 
      v-if="limit != null && layout === 'circle'">
    </div>

    <div class="data-progress__number">
      <slot>{{ current }}</slot>
    </div>

    <div 
      class="data-progress__fraction" 
      v-if="layout === 'fraction'">
      <span class="data-progress__limit">/ {{ limit }}</span>

      <div class="data-progress__bar">
        <div class="left" :style="`width: ${currentPercent > 100 ? 100 : currentPercent}%; background-color: ${hue}`"></div>
        <div class="right"></div>
      </div>
    </div>

    <div 
      class="data-progress__circle"
      v-if="limit != null && layout === 'circle'">

      <div class="data-progress__bar left">
        <div 
          class="data-progress__progress"
          :style="`transform: rotate(${rightCurrentRotation}deg); background-color: ${hue}`"
        ></div>
      </div>
      <div class="data-progress__bar right">
        <div 
          class="data-progress__progress" 
          :style="`transform: rotate(${leftCurrentRotation}deg); background-color: ${hue}`"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataProgress',
  
  props: {
    current: {
      type: Number,
    },

    layout: {
      type: String,
      default: 'circle',
      validator: (value) => ['circle', 'fraction'].includes(value)
    },

    limit: {
      type: Number
    }
  },

  computed: {
    classes() {
      return {
        'data-progress': true,
        '-circle': this.layout === 'circle',
        '-fraction': this.layout === 'fraction'
      }
    },

    hue() {
      let ret = '#458E79'

      let cmp = this.limit - this.current;

      ret = cmp <= (this.limit / 4) ? '#DB9E14' : ret
      ret = cmp <= 0 ? '#CC4B23' : ret;

      return ret
    },

    currentPercent() {
      let ret = (100 / this.limit) * this.current
      return ret < 0 ? 0 : ret
    },

    leftCurrentRotation() {
      let val = this.currentPercent - 50
      let ret = (360 / 100) * (val <= 0 ? 0 : val)

      return ret > 180 ? 180 : ret
    },

    rightCurrentRotation() {
      let sub = this.currentPercent - 50
      let val = this.currentPercent - (sub < 0 ? 0 : sub)
      let ret = (360 / 100) * (val <= 0 ? 0 : val)
      return ret > 180 ? 180 : ret
    },
  },
}
</script>
