<template>
  <layout-modal
    v-bind="attrs"
    v-on="listeners">
    <!-- Loading -->
    <div 
      class="layout-modal__loader"
      v-if="loading">
      <ui-loader />
    </div>

    <div 
      class="flow" 
      v-if="!loading">
      <div class="modal-url-builder__article modal-url-builder__group group">
        <forms-input
          data-lpignore="true"  
          :errors="getErrors('name')"
          :placeholder="$t('sayl.placeholder')"
          required
          :read-only="readOnly || !isEmpty"
          type="text"
          @input="onNameChange"
          v-model="edition.name"
        >{{ $t('sayl-configuration.embed_name')}}</forms-input>
      </div>

      <div 
        class="modal-url-builder__article modal-url-builder__outputs group" 
        v-if="!loading && !isEmpty">
        <forms-input 
          class="modal-url-builder__output"
          id="field" 
          read-only 
          :value="url"
        />
  
        <div class="modal-url-builder__actions">
          <actions-button 
            :appearance="$pepper.Appearance.SUBTLE"
            class="modal-url-builder__action"
            icon-post="copy"
            :title="$t('sayl-settings.askemma_url_builder_copy')"
            @click="onCopy"
          />
          
          <actions-button 
            :appearance="$pepper.Appearance.SUBTLE"
            class="modal-url-builder__action"
            :href="url"
            icon-post="open"
            target="_blank"
            :title="$t('sayl-settings.askemma_url_builder_go_to')"
          />
        </div>
      </div>
    </div>

    <template v-slot:footer>
      <actions-button 
        :appearance="$pepper.Appearance.SUBTLE"
        :disabled="loading"
        @click="onDiscard"
        >{{ $t('sayl.close') }}</actions-button>

      <actions-button 
        :appearance="$pepper.Appearance.PRIMARY" 
        :disabled="loading"
        @click="onConfirm"
        v-if="!loading && isEmpty"
        >{{ $t('sayl.confirm') }}</actions-button>
    </template>
  </layout-modal>
</template>

<script>
import { 
  mapGetters, 
  mapState 
} from 'vuex'

import { 
  MixinACL, 
  MixinEditionDiscard,
  MixinEditionSave,
  MixinError,
  MixinTranslation,
} from '@sayl/admin-common'

export default {
  name: 'ModalViewLoyaltyPage',

  mixins: [
    MixinACL,
    MixinEditionDiscard,
    MixinEditionSave,
    MixinError,
    MixinTranslation,
  ],

  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },

    visible: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      loading: true,
      errors: {},
    } 
  },

  computed: {
    ...mapState({
      edition: state => state.configuration.userPortal.edition,
      embeds: state => state.configuration.userPortal.all,
      program: state => state.loyalty.loyalty.edition,
    }),

    ...mapGetters({
      config: 'sayl/config',
      pristine: 'configuration/userPortal.pristine'
    }),

    attrs() {
      return {
        classes: { 
          'modal-url-builder': true,
          '-body-p0': true
        },
        article: true,
        description: this.description,
        visible: this.visible,
        closable: false,
        title: this.title
      }
    },

    isEmpty() {
      return !this.loading && this.$basil.isNil(this.$basil.get(this.edition, 'id'))
    },

    listeners() {
      return {
        back: this.onDiscard,
        discard: this.onDiscard,
        close: this.onDiscard,
        save: this.onConfirm,
      }
    },
    
    title() {
      return !this.loading ? 
      this.$t('sayl-loyalty.visit-loyalty-page_title') : 
      this.$t('sayl.loading')
    },
    
    url() {
      let href = this.$basil.get(this.config, 'userPortal.url', 'https://apps.sayl.cloud/user/')
      let url = `${href}${this.$basil.get(this.edition, 'id')}/loyalty`

      return url
    },
  },

  watch: {
    visible(newVal, oldVal) {
      if(newVal === true && (oldVal === false || oldVal == null)) {
        this.reset()
      }
    }
  },

  methods: {
    back() {
      this.$emit('close')
    },

    onConfirm() {
      this.loading = true
      this.onSave({})
        .then(() => this.reset())
        .catch((e) => $console.error(e))
        .finally(() => this.loading = false)
    },

    onCopy() {
      let copyText = document.getElementById("field")
      copyText.disabled = false
      copyText.select()
      copyText.disabled = true
      document.execCommand("copy")
    },

    onNameChange() {
      this.onRemoveError('name')

      if(!this.$basil.isNil(this.edition.name) && !this.$basil.isEmpty(this.edition.name)) {
        this.edition.reference = this.$basil.slugify(this.edition.name)
        return
      }

      this.edition.reference = null
    },

    reset() {
      this.loading = true
      this.$configuration.userPortal.all({ args: { product: 'resto', type: 'user_portal' }})
        .then(() => {
          if(this.embeds && this.embeds.length > 0) {
            return this.$configuration.userPortal.findById({ id: this.$basil.get(this.embeds, '[0].id', null) })
          }
          return this.$configuration.userPortal.create({ args: { active: true, product: 'resto', embed_type: 'user_portal' }})
        })
        .catch((e) => this.handleErrors(e))
        .finally(() => this.loading = false)
    },

    save() {
      this.errors = {};
      return this.$configuration.userPortal.save({ item: this.edition, isNew: true })
    }
  },
}
</script>