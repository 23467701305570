<template>
  <ui-card 
    :appearance="$pepper.Appearance.SUBTLE" 
    article>
    <template #header>
      <h3 class="ui-card__title">{{ $t('sayl.tier_perks_title') }}</h3>
    </template>

    <article class="ui-card__article" :key="key">
      <forms-list
        :actions="actions"
        :events="!readOnly ? events : null"
        :read-only="readOnly"
        :loading="loading"
        :select="select"
        :sortable="rewards && rewards.length > 0"
        @sort="onSort"
        v-model="rewards">
        <template #entry="{entry}">
          <div class="row group">
            {{ entry.name.all }}
            <data-tag :size="$pepper.Size.S">{{ $t(`sayl.tier_reward_type_${entry.type}`) }}</data-tag>
          </div>
        </template>
      </forms-list>
    </article>

    <modal-perk
      :value="selectedReward"
      :visible="showPerk"
      @close="onClose"
      v-if="showPerk"
    />

    <modal-voucher
      :value="selectedReward"
      :visible="showVoucher"
      @close="onClose"
      v-if="showVoucher"
    />
  </ui-card>
</template>

<script>
import { mapState } from 'vuex'

import { MixinList } from '@sayl/admin-common'

import ModalPerk from './perks/index.vue'
import ModalVoucher from './vouchers/index.vue'

export default {
  name: 'TierPerksList',

  components: {
    ModalPerk,
    ModalVoucher,
  },

  mixins: [ MixinList ],

  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },

    tier: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      loading: true,
      selectedReward: null,
      showPerk: false,
      showVoucher: false,
      key: 1,
    }
  },

  computed: {
    ...mapState({
      edition: state => state.tier.tier.edition,
      rewards: state => state.tier.reward.all,
    }),

    actions() {
      let ret = []

      if(!this.readOnly) {
        ret.push(
          {
            label: this.$t('sayl.tier_reward_create_voucher'),
            iconPost: 'plus',
            action: this.onAddVoucher,
            mode: this.$pepper.Action.GLOBAL,
          },
          {
            label: this.$t('sayl.tier_reward_create_perk'),
            iconPost: 'plus',
            action: this.onAddPerk,
            mode: this.$pepper.Action.GLOBAL,
          },
          {
            label: this.$t('sayl.delete'),
            action: this.onRemove,
            mode: this.$pepper.Action.SINGLE + this.$pepper.Action.SOME + this.$pepper.Action.PAGE + this.$pepper.Action.PAGES
          } 
        )
      }

      return ret
    },

    events() {
      return {
        click: (row) => {
          this.selectedReward = row.copy ? row.copy() : JSON.parse(JSON.stringify(row))
          if(row.type === 'perk') {
            this.showPerk = true
            return
          }

          if(row.type === 'voucher') {
            this.showVoucher = true
          }
        }
      }
    },

    select() {
      return !this.readOnly ? this.$pepper.Selection.PAGE : null
    }
  },

  methods: {
    onAddPerk() {
      this.selectedReward = {}
      this.showPerk = true
    },

    onAddVoucher() {
      this.selectedReward = {}
      this.showVoucher = true
    },

    onClose() {
      this.showPerk = false
      this.showVoucher = false
      this.selectedReward = null
      this.reset()
    },

    onSort(values) {
      this.loading = true
      let proms = []

      values.forEach((v, i) => {
        v.position = i + 1
        proms.push(window.$dl.tierReward.save.bind(window.$dl.tierReward, { args: { tierId: this.$route.params.tier }, item: v, isNew: false }))
      })

      this.$basil.sequence(proms)
        .then(() => this.reset())
        .catch((e) => $console.error(e))
    },

    remove({id}) {
      return window.$dl.tierReward.remove({ id, args: { tierId: this.$route.params.tier }})
    },

    reset() {
      this.loading = true
      window.$dl.tierReward.all({ args: { tierId: this.$route.params.tier }})
        .catch((e) => $console.error(e))
        .finally(() => {
          this.loading = false
          this.key++
        })
    }
  },

  mounted() {
    this.reset()
  }
}
</script>