<template>
  <layout-base>
    <div class="not-authenticated">
      <div class="not-authenticated__inner">
        <header class="not-authenticated__header">
          <img 
            :src="$getImageSrc('/statics/images/logos/sayl/glyph-white.svg')" 
            alt="Sayl Logo"
          />
        </header>

        <section class="not-authenticated__body">
          <h1 class="not-authenticated__title">Loading login page</h1>
          <h2 class="not-authenticated__subtitle">Redirecting</h2>
          <div class="not-authenticated__loader">
            <ui-loader appearance="dots" />
          </div>
        </section>

        <!-- <div class="footer not-authenticated__footer">
          <div class="not-authenticated__description">You can return to our <router-link to="/">front page</router-link>, or <a href="mailto:info@infinity-mobile.io">drop us a line</a> if you can't find what you're looking for.</div>
        </div> -->
      </div>
    </div>
  </layout-base>
</template>

<script>
import LayoutBase from '../layouts/base'

export default {  
  name: 'NotAllowed',
  
  components: {
    LayoutBase
  },
}
</script>
