const LIST = [
  // Overview
  {
    description: 'sayl.dashboard_description',
    fqn: 'sayl-overview',
    href: { name: 'sayl-homepage-sayl_index' },
    icon: 'dashboard',
    label: 'sayl.dashboard',
    subs: [],
  },

  // Customers
  {
    description: 'sayl.customers_description',
    fqn: 'sayl-customer',
    icon: 'user',
    label: 'sayl.customers_title',
    subs: [],
  },
  {
    description: 'sayl.customer_description',
    fqn: 'sayl-customer-home',
    href: { name: 'sayl-customer_home' },
    icon: 'user',
    label: 'sayl.customer',
    subs: [
      'sayl-customer_entry',
      'sayl-customer_entry-overview',
      'sayl-customer_entry-fields',
      'sayl-customer_entry-details',
      'sayl-customer_entry-commerce',
      'sayl-customer_entry-referral',
      'sayl-customer_entry-web3',
      'sayl-customer_entry-edition',
    ],
  },
  {
    description: 'sayl-customer.companies_description',
    fqn: 'sayl-customer-companies',
    href: { name: 'sayl-companies_home' },
    icon: 'users',
    label: 'sayl-customer.companies_title',
    subs: [
      'sayl-companies_home',
      'sayl-companies_library',
      'sayl-companies_codes',
      'sayl-companies_item',
      'sayl-companies_item-details',
      'sayl-companies_item-orders',
      'sayl-companies_item-order',
      'sayl-companies_item-customers',
      'sayl-companies_item-codes',
    ],
  },

  // Loyalty
  {
    fqn: 'sayl-customer-loyalty',
    icon: 'award',
    icons: ['percent', 'award', 'users'],
    label: 'sayl-loyalty.loyalty',
    description: 'sayl-loyalty.loyalty_description',
    subs: [
      'sayl-customer-loyalty_cards',
      'sayl-customer-loyalty_program',
      'sayl-customer-loyalty_program-passes',
      'sayl-customer-loyalty_program-program',
      'sayl-customer-loyalty_program-resto',
      'sayl-customer-loyalty_program-ssm',
      'sayl-customer-loyalty_program-page',
      'sayl-customer-loyalty_program-tiers',
      'sayl-customer-loyalty_program-tier',
      'sayl-customer-loyalty_referral-edition',
      'sayl-customer-loyalty_referral',
    ],
  },
  {
    fqn: 'sayl-customer-loyalty-overview',
    href: { name: 'sayl-customer-loyalty_overview' },
    icon: 'award',
    label: 'sayl-loyalty.loyalty_overview',
    description: 'sayl-loyalty.loyalty_overview_description',
    subs: [],
  },
  {
    fqn: 'sayl-customer-loyalty-program',
    href: { name: 'sayl-customer-loyalty_program-config' },
    icon: 'award',
    label: 'sayl-loyalty.loyalty_program',
    description: 'sayl-loyalty.loyalty_program_description',
    subs: [
      'sayl-customer-loyalty_program',
      'sayl-customer-loyalty_program-passes',
      'sayl-customer-loyalty_program-program',
      'sayl-customer-loyalty_program-resto',
      'sayl-customer-loyalty_program-ssm',
      'sayl-customer-loyalty_program-page',
      'sayl-customer-loyalty_program-tiers',
      'sayl-customer-loyalty_program-tier',
    ],
  },
  {
    fqn: 'sayl-customer-loyalty-cards',
    href: { name: 'sayl-customer-loyalty_cards' },
    icon: 'award',
    label: 'sayl-loyalty.loyalty_cards',
    description: 'sayl-loyalty.loyalty_cards_description',
    subs: [],
  },
  {
    fqn: 'sayl-customer-loyalty-referral',
    href: { name: 'sayl-customer-loyalty_referral' },
    icon: 'award',
    label: 'sayl-loyalty.loyalty_referral',
    description: 'sayl-loyalty.loyalty_referral_description',
    subs: [
      'sayl-customer-loyalty_referral-edition',
    ],
  },

  // {
  //   fqn: 'sayl-tiers',
  //   href: { name: 'sayl-tiers' },
  //   icon: 'crown',
  //   label: 'sayl.tiers_title',
  //   subs: ['sayl-tier'],
  // },

  // Challenges
  {
    fqn: 'sayl-customer-challenges',
    icon: 'flag',
    icons: ['cog', 'flag', 'bolt'],
    label: 'sayl-audiences.challenges',
    description: 'sayl-audiences.challenges_descriptions',
    subs: [],
  },
  {
    fqn: 'sayl-customer-challenges-home',
    description: 'sayl-audiences.challenges_description',
    href: { name: 'sayl-audiences_challenges-home' },
    icon: 'flag',
    label: 'sayl-audiences.challenges_title',
    subs: [
      'sayl-audiences_challenges-library',
      'sayl-audiences_challenges-overview',
      'sayl-audiences_challenge-entry',
      'sayl-audiences_challenge-entry-global',
      'sayl-audiences_challenge-entry-overview'
    ],
  },
  {
    fqn: 'sayl-customer-challenges-settings',
    description: 'sayl-audiences.challenges_settings_description',
    href: { name: 'sayl-audiences_challenges-settings' },
    icon: 'cog',
    label: 'sayl-audiences.challenges_settings_title',
    subs: [
      'sayl-audiences_challenges-settings-home',
      'sayl-audiences_challenges-settings-entry',
    ],
  },

  // Vouchers
  {
    description: 'sayl.vouchers_description',
    fqn: 'sayl-vouchers',
    icons: ['percent', 'credit-card', 'star'],
    icon: 'credit-card',
    label: 'sayl.vouchers',
    subs: [
      'sayl-ecommerce_voucher',
      'sayl-ecommerce_voucher-template',
    ],
  },
  {
    description: 'sayl-ecommerce.vouchers_overview_description',
    fqn: 'sayl-vouchers-analytics',
    href: { name: 'sayl-ecommerce_vouchers-overview' },
    icon: 'credit-card',
    label: 'sayl-ecommerce.vouchers_overview',
    subs: ['sayl-ecommerce_voucher-overview'],
  },
  {
    description: 'sayl-ecommerce.vouchers_description',
    fqn: 'sayl-vouchers-library',
    href: { name: 'sayl-ecommerce_vouchers-library' },
    icon: 'credit-card',
    label: 'sayl-ecommerce.vouchers',
    subs: ['sayl-ecommerce_voucher'],
  },
  {
    description: 'sayl-ecommerce.vouchers_templates_description',
    fqn: 'sayl-vouchers-templates',
    href: { name: 'sayl-ecommerce_vouchers-templates' },
    icon: 'credit-card',
    label: 'sayl-ecommerce.vouchers_templates',
    subs: ['sayl-ecommerce_voucher-template'],
  },
  {
    description: 'sayl-ecommerce.voucher_print_settings_title_description',
    fqn: 'sayl-vouchers-gift-cards-pdf-settings',
    href: { name: 'sayl-ecommerce_vouchers-library-print-settings' },
    icon: 'credit-card',
    label: 'sayl-ecommerce.voucher_print_settings_title',
    subs: ['sayl-ecommerce_vouchers-library-print-settings'],
  },

  // Integration
  {
    description: 'sayl-integration.description',
    fqn: 'sayl-integrations',
    icon: 'layout-grid-add',
    icons: ['ghost', 'workflow', 'bullhorn'],
    label: 'sayl-integration.title', 
    href: { name: 'sayl-integration_home' },
    subs: [
      'sayl-integration_config', 
      'sayl-integration_presentation', 
    ],
  },

  // Configuration
  {
    description: 'sayl.configure_description',
    fqn: 'sayl-configure',
    icon: 'adjustments',
    icons: ['browser', 'adjustments', 'cog'],
    label: 'sayl.configure_title',
    subs: ['sayl-configuration_home',],
  },
  {
    description: 'sayl.cms_description',
    fqn: 'sayl-cms',
    href: { name: 'sayl-configuration_blocks' },
    icon: 'browser',
    label: 'sayl-cms.title',
    subs: ['sayl-configuration_block'],
  },
  {
    description: 'sayl-settings.users_description',
    fqn: 'sayl-configure-users',
    icon: 'users',
    href: { name: 'sayl-configuration_users' },
    label: 'sayl-settings.users',
    subs: ['sayl-configuration_user'],
  },
  {
    description: 'sayl-settings.customer_description',
    fqn: 'sayl-configure-project',
    icon: 'user',
    href: { name: 'sayl-configuration_project' },
    label: 'sayl-settings.customer',
    subs: [],
  },
  {
    description: 'sayl-settings.tags_description',
    fqn: 'sayl-configure-tags',
    icon: 'pin',
    href: { name: 'sayl-configuration_tags' },
    label: 'sayl-settings.tags',
    subs: ['sayl-configuration_tag'],
  },
  {
    description: 'sayl.vat_description',
    fqn: 'sayl-configure-vats',
    href: { name: 'sayl-configuration_vats' },
    icon: 'percent',
    label: 'sayl.vat_title',
    subs: ['sayl-configuration_vat'],
  },
  {
    description: 'sayl-configuration.webhooks_description',
    fqn: 'sayl-configure-webhooks',
    icon: 'git-compare',
    href: { name: 'sayl-configuration_webhooks' },
    label: 'sayl-configuration.webhooks_title',
    subs: ['sayl-configuration_webhook'],
  },
  {
    description: 'sayl-configuration.public_api_tokens_description',
    fqn: 'sayl-configure-public-api-tokens',
    icon: 'globe',
    href: { name: 'sayl-configuration_public-api-tokens' },
    label: 'sayl-configuration.public_api_tokens',
    subs: ['sayl-configuration_public-api-token'],
  },
  {
    description: 'sayl-configuration.email_templates_description',
    fqn: 'sayl-configure-emails',
    icon: 'envelope',
    href: { name: 'sayl-configuration_emails-home' },
    label: 'sayl-configuration.email_templates_title',
    subs: [
      'sayl-configuration_emails-library',
      'sayl-configuration_emails-fallbacks',
      'sayl-configuration_emails-bindings',
      'sayl-configuration_emails-binding',
      'sayl-configuration_email-entry',
    ],
  },
  {
    description: 'sayl-configuration.notification_settings_description',
    fqn: 'sayl-configure-notification-settings',
    icon: 'cog',
    href: { name: 'sayl-configuration_notification-settings-home' },
    label: 'sayl-configuration.notification_settings_title',
    subs: [],
  },

  // Customer portal
  {
    description: 'sayl-configuration.user_portal_description',
    fqn: 'sayl-configure-user-portal',
    icons: ['window', 'browser', 'layout-grid'],
    icon: 'browser',
    label: 'sayl-configuration.user_portal_title',
    subs: [],
  },
  {
    description: 'sayl-configuration.portal_description',
    fqn: 'sayl-configure-portal',
    icon: 'user',
    href: { name: 'sayl-configuration_user-portal-home' },
    label: 'sayl-configuration.portal_title',
    subs: [],
  },
  {
    description: 'sayl-widget.widget_description',
    icon: 'browser',
    fqn: 'sayl-widget',
    href: { name: 'sayl-widget-home' },
    label: 'sayl-widget.widget_title',
    subs: [],
  },
]

export default LIST
