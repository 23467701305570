<template>
  <!-- Loading -->
  <div 
    class="view-loyalty__loader"
    v-if="loading">
    <ui-loader />
  </div>

  <div 
    class="layout-level2__container container flow" 
    v-else>
    <ssm-redemption 
      :errors="errors"
      :read-only="readOnly"
    />
  </div>
</template>

<script>
import { mapState, } from 'vuex'

import { MixinImage } from '@sayl/admin-common'

import SsmRedemption from './redemption'

export default {
  name: 'LoyaltyRestoConfig',

  components: {
    SsmRedemption
  },

  mixins: [ MixinImage ],

  props: {
    errors: {
      type: Object,
    },

    readOnly: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      loading: true
    }
  },

  computed: {
    ...mapState({
      edition: state => state.loyalty.loyalty.edition,
      origin: state => state.loyalty.loyalty.origin,
    }),
  },

  methods: {
    reset() {
      this.loading = true;
      this.$configuration.block.all({ args: { limit: 9999, pageSize: 9999 }})
        .then(() => this.$image.all({ data: { entityType: 'loyalty_program', entityId: this.$route.params.program, fieldName: 'banner', module: 'loyalty' }}))
        .catch((e) => $console.error(e))
        .finally(() => this.loading = false)
    }
  },

  mounted() {
    this.reset()
  }
}
</script>
