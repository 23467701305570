<template>
  <nav class="n-breadcrumb">
    <template v-for="(c, i) in children">
      <button 
        :key="$basil.uniqId('breadcrumb')"
        v-bind="getAttrsForItem(c)"
        v-on="getListenersForItem(c)">

        <!-- Image -->
        <img 
          :alt="c.label" 
          class="n-breadcrumb__image"
          :src="$getImageSrc(c.image)" 
          v-if="c.image"
        />

        <!-- Icon -->
        <ui-icon 
          class="n-breadcrumb__icon" 
          :glyph="c.icon"
          v-if="c.icon"
        />

        <!-- Label -->
        <span 
          class="n-breadcrumb__label"
          v-if="c.label"
        >{{ c.label }}</span>
      </button>

      <span 
        :key="$basil.uniqId('breadcrumb')"
        class="n-breadcrumb__separator"
        v-if="i != nChildren"
      ></span>
    </template>

    <div class="n-breadcrumb__actions">
      <actions-button
        :appearance="$pepper.Appearance.SUBTLE"
        class="n-breadcrumb__action"
        :class="{ '-is-active': $route.name === 'translation-extractor' }"
        icon-pre="l8n"
        :href="{ name: 'translation-extractor' }"
        :size="$pepper.Size.S"
        :title="$t('sayl.go_to_missing_translation')"
        v-if="config.env === 'development'"
      >{{ $t('sayl.go_to_missing_translation') }}</actions-button>

      <actions-button
        :appearance="$pepper.Appearance.SUBTLE"
        class="n-breadcrumb__action n-breadcrumb__url-builder"
        icon-pre="rocket"
        :size="$pepper.Size.S"
        :title="$t('sayl.resto_url_builder')"
        @click="$bus.$emit('url-builder')"
        v-if="hasRestoUrl"
      >{{ $t('sayl.resto_url_builder') }}</actions-button>
      
      <actions-button
        :appearance="$pepper.Appearance.SUBTLE"
        class="n-breadcrumb__action"
        :class="{ '-is-active': $route.name === 'sayl-profile_user' }"
        icon-pre="user"
        :href="{ name: 'sayl-profile_index' }"
        :size="$pepper.Size.S"
        :title="$t('sayl-profile.profile')"
      >{{ $t('sayl-profile.profile') }}</actions-button>

      <actions-button
        :appearance="$pepper.Appearance.SUBTLE"
        class="n-breadcrumb__action"
        :class="{ '-is-active': $route.name === 'sayl-profile_invoices' }"
        icon-pre="file-invoice"
        :href="{ name: 'sayl-profile_invoices' }"
        :size="$pepper.Size.S"
        :title="$t('sayl-profile.your_plan', { name: planName })"
        v-if="hasInvoice">
        {{ $t('sayl-invoice.your_plan', { name: planName }) }}

        <actions-button
          :appearance="$pepper.Appearance.PRIMARY"
          class="n-breadcrumb__action -upgrade"
          :size="$pepper.Size.S"
          :title="$t('sayl-invoice.upgrade_action')"
          @click.stop.prevent="onUpgrade"
          v-if="hasUpgrade"
        >{{ $t('sayl-invoice.upgrade_action') }}</actions-button>
      </actions-button>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NavBreadcrumb',

  props: {
    children: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapGetters({
      config: 'sayl/config',
    }),

    hasInvoice() {
      return !this.$basil.isNil(this.invoice) && this.invoice.isActive
    },
    
    invoice() {
      return this.$basil.get(window, '$dl.invoice')
    },

    hasRestoUrl(){
      if(window.$dl) {
        return !window.$dl.elements.isHidden('sayl_resto_url')
      }
      return true
    },

    hasUpgrade() {
      return this.$basil.get(this.invoice, 'tier.nextTier') != null
    },
    
    nChildren() {
      return this.children.length - 1
    },

    planName() {
      return this.$basil.get(this.invoice, 'planName')
    }
  },

  methods: {
    getAttrsForItem(item){
      let href = this.$basil.get(item, 'href')
      let type = this.getTypeForItem(item)

      let ret = {
        class: 'n-breadcrumb__item',
        is: type,
      }

      if (type === 'router-link'){
        ret.to = href
      }

      return Object.assign({}, ret, item)
    },  

    getListenersForItem(item){
      let ret = {}
      let type = this.getTypeForItem(item)

      if(type === 'button'){ 
        ret = item.listeners || {}
      }

      return ret
    },

    getTypeForItem(item){
      let href = this.$basil.get(item, 'href')
      return this.$basil.isNil(href) === false ? 'router-link' : 'button'
    },

    onUpgrade() {
      this.$router.push({ name: 'sayl-upgrade' })
    }
  }
}
</script>
