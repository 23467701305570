export default [
  {
    type: 'physical',
    token: 'ABCD1234',
    status: 'active',
    key: '1234567890',
  },
  {
    type: 'digital',
    token: 'DCBA4321',
    status: 'active',
    key: '0987654321',
  }
]
