<template>
  <section class="view-loyalty-kpis">
    <article 
      v-for="(kpi, i) in dataset" 
      :key="i"
      class="view-loyalty-kpis__item">

      <!-- Overline -->
      <div class="view-loyalty-kpis__overline">{{ kpi.title }}</div>

      <!-- Figure -->
      <div class="view-loyalty-kpis__figure">
        <span 
          class="view-loyalty-kpis__icon" 
          v-if="kpi.icon"
        ><ui-icon 
          :glyph="kpi.icon.glyph" 
          :class="kpi.icon.class"
        /></span>

        <span class="view-loyalty-kpis__value">{{ kpi.value }} </span>
        <span class="view-loyalty-kpis__legend">{{ kpi.unity }}</span>
      </div>

      <!-- Meta -->
      <div class="view-loyalty-kpis__metas">
        <span 
          :key="j"
          class="view-loyalty-kpis__meta"
          v-for="(m, j) in kpi.meta" 
        >{{ m.label }}</span>
      </div>
    </article>
  </section>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'LoyaltyProgramKPIs',

  props: {
    range: {
      type: Object,
    }
  },

  data() {
    return {
      key: 1,
    }
  },

  computed: {
    ...mapState({
      state: state => state.loyalty.loyalty,
    }),

    collection() {
      return this.key && this.state.collection
    },

    program() {
      return this.key && this.$basil.get(this.collection, '[0]')
    },

    kpis() {
      return this.key && this.state.kpis.value
    },

    loading() {
      return this.key && this.state.kpis.loading && !this.$basil.isNil(this.range)
    },

    dataset() {
      let ret = [];
      let program = this.$basil.get(this.collection, '0', null);

      if(!this.loading && !this.$basil.isNil(program)) {
        let customers = this.$basil.get(this.kpis, 'customers', 0);
        let customersTotal = this.$basil.get(this.kpis, 'customers_total', 0);
        let percent = customers > 0 && customersTotal > 0 ? customers/customersTotal : 0;

        // enroled
        ret.push({
          title: this.$t('sayl-ecommerce.loyalty_kpis_enrolled_label'),
          value: customers,
          unity: 'customers',
          meta: [
            { label: this.$t('sayl-ecommerce.loyalty_kpis_enrolled_percentage', { percent: this.$basil.i18n.percent(percent) }) },
          ]
        });

        let redemptionCount = this.$basil.get(this.kpis, 'redemption_aggregate[0].count', 0);
        let redemptionTotal = this.$basil.get(this.kpis, 'redemption_aggregate[0].total', 0);
        let redemptionTotalAmount = redemptionTotal * this.$basil.get(this.program, 'rules.pointsToMoney', 1)

        // Redemption DONE
        ret.push({
          title: this.$t('sayl-ecommerce.loyalty_kpis_redemption_done'),
          value: this.$basil.i18n.number(redemptionCount),
          unity: this.$t('sayl-ecommerce.loyalty_kpis_redemptions_done_unity'),
          meta: [
            {
              label: this.$t('sayl-ecommerce.loyalty_kpis_redemption_done_amount', { value: this.$basil.i18n.currency(Math.abs(redemptionTotalAmount)) })
            }
          ]
        })

        // trending
        let trendPercent = this.$basil.get(this.kpis, 'trended.percent', 0)
        let trendAmount = this.$basil.get(this.kpis, 'trended.amount', 0)
        let trendAmountConverted = trendAmount  * this.$basil.get(this.program, 'rules.pointsToMoney', 1)

        ret.push({
          title: this.$t('sayl-ecommerce.loyalty_kpis_redemption_trend_rate'),
          icon: {
            glyph: trendPercent > 0 ? 'arrow-up' : trendPercent === 0 ? null : 'arrow-down',
            class: trendPercent > 0 ? 'up' : trendPercent === 0 ? 'equal' : 'down',
          },
          value: this.$basil.i18n.percent(trendPercent / 100),
          unity: this.$t('sayl-ecommerce.loyalty_kpis_redemption_trend_rate_unity'),
          meta: [
            {
              label: this.$t('sayl-ecommerce.loyalty_kpis_redemption_trend_rate_growth', {
                value: this.$basil.i18n.currency(Math.abs(trendAmountConverted)),
                growth: (trendAmount > 0 ? this.$t('sayl-ecommerce.loyalty_kpis_redemption_trend_rate_more') : (trendAmount < 0 ? this.$t('sayl-ecommerce.loyalty_kpis_redemption_trend_rate_less') : ''))
              })
            }
          ]
        })
      }

      return ret
    }
  },
}
</script>
