<template>
  <article 
    :class="classes" 
    @click="$emit('select', value)">
    <div 
      class="perk-card__selected" 
      v-if="isSelected">
      <ui-icon glyph="check" />
    </div>

    <div 
      class="perk-card__figure"
      v-if="banner">
      <img 
        class="perk-card__image"
        :src="banner" 
        :alt="`Perk image ${label}`"
      />
    </div>

    <div class="perk-card__body">
      <h4 class="perk-card__title" v-html="label"></h4>
      <p class="perk-card__description" v-html="description"></p>
    </div>
  </article>
</template>

<script>
export default {
  name: 'TriggerPerkCard',

  props: {
    hasBorder: {
      type: Boolean,
      default: true
    },

    hasSelection: {
      type: Boolean,
      default: true,
    },

    isSelected: {
      type: Boolean, 
      default: false,
    },

    value: {
      type: Object,
      required: true
    }
  },

  computed: {
    classes() {
      return {
        'perk-card': true,

        '-full': this.fillImage,
        '-has-selection': this.hasSelection,
        '-no-border': !this.hasBorder,
        '-is-selected': this.hasSelection && this.isSelected
      }
    },

    banner() {
      return this.$basil.get(this.value, 'banner', null)
    },
    
    description() {
      let ret = this.$basil.get(this.value, `description.${this.$basil.i18n.locale.lang}`, null)
      return this.$basil.isNil(ret) ? this.$basil.get(this.value, 'description.all') : ret
    },

    label() {
      let ret = this.$basil.get(this.value, `name.${this.$basil.i18n.locale.lang}`, null)
      return this.$basil.isNil(ret) ? this.$basil.get(this.value, 'name.all') : ret
    },
  }
}
</script>
