/**
 * @readonly
 * @property {Object} Types 
 * Types definitions 
 */
const Types = {}
const prefix = 'sayl.type_'

//////////////////////////////////////
// ADDRESS
//////////////////////////////////////
/**
 * @property {Object} ADDRESS
 */
const ADDRESS = {
  label: `${prefix}address`,
  value: 'address'
}
Types.ADDRESS = ADDRESS

//////////////////////////////////////
// BOOLEAN
//////////////////////////////////////
/**
 * @property {Object} BOOLEAN
 */
const BOOLEAN = {
  label: `${prefix}boolean`,
  value: 'boolean'
}
Types.BOOLEAN = BOOLEAN

//////////////////////////////////////
// COUNTRY
//////////////////////////////////////
/**
 * @property {Object} COUNTRY
 */
const COUNTRY = {
  label: `${prefix}country`,
  value: 'country'
}
Types.COUNTRY = COUNTRY

//////////////////////////////////////
// DATE
//////////////////////////////////////
/**
 * @property {Object} DATE
 */
const DATE = {
  label: `${prefix}date`,
  value: 'date'
}
Types.DATE = DATE

//////////////////////////////////////
// DATETIME
//////////////////////////////////////
/**
 * @property {Object} DATETIME
 */
const DATETIME = {
  label: `${prefix}datetime`,
  value: 'datetime'
}
Types.DATETIME = DATETIME

//////////////////////////////////////
// EMAIL
//////////////////////////////////////
/**
 * @property {Object} EMAIL
 */
const EMAIL = {
  label: `${prefix}email`,
  value: 'email'
}
Types.EMAIL = EMAIL

//////////////////////////////////////
// ENUM
//////////////////////////////////////
/**
 * @property {Object} ENUM
 */
const ENUM = {
  label: `${prefix}enum`,
  value: 'enum'
}
Types.ENUM = ENUM

//////////////////////////////////////
// INTEGER
//////////////////////////////////////
/**
 * @property {Object} INTEGER
 */
const INTEGER = {
  label: `${prefix}integer`,
  value: 'integer'
}
Types.INTEGER = INTEGER

//////////////////////////////////////
// NUMBER
//////////////////////////////////////
/**
 * @property {Object} MEDIA
 */
const MEDIA = {
  label: `${prefix}media`,
  value: 'media'
}
Types.MEDIA = MEDIA

//////////////////////////////////////
// NUMBER
//////////////////////////////////////
/**
 * @property {Object} NUMBER
 */
const NUMBER = {
  label: `${prefix}number`,
  value: 'number'
}
Types.NUMBER = NUMBER

//////////////////////////////////////
// PHONE
//////////////////////////////////////
/**
 * @property {Object} PHONE
 */
const PHONE = {
  label: `${prefix}phone`,
  value: 'phone'
}
Types.PHONE = PHONE

//////////////////////////////////////
// PRICE
//////////////////////////////////////
/**
 * @property {Object} PRICE
 */
const PRICE = {
  label: `${prefix}price`,
  value: 'price'
}
Types.PRICE = PRICE

//////////////////////////////////////
// STRING
//////////////////////////////////////
/**
 * @property {Object} STRING
 */
const STRING = {
  label: `${prefix}string`,
  value: 'string'
}
Types.STRING = STRING

//////////////////////////////////////
// TEXT
//////////////////////////////////////
/**
 * @property {Object} TEXT
 */
const TEXT = {
  label: `${prefix}text`,
  value: 'text'
}
Types.TEXT = TEXT


//////////////////////////////////////
// TIMESTAMP
//////////////////////////////////////
/**
 * @property {Object} TIMESTAMP
 */
const TIMESTAMP = {
  label: `${prefix}timestamp`,
  value: 'timestamp'
}
Types.TIMESTAMP = TIMESTAMP

//////////////////////////////////////
// URL
//////////////////////////////////////
/**
 * @property {Object} TURL
 */
const TURL = {
  label: `${prefix}url`,
  value: 'url'
}
Types.URL = TURL

/**
 * @readonly
 * @property {Object} ALL 
 * Types ALL
 */
Types.ALL = [
  ADDRESS,
  BOOLEAN,
  COUNTRY,
  DATE,
  DATETIME,
  EMAIL,
  ENUM,
  INTEGER,
  NUMBER,
  PHONE,
  PRICE,
  STRING,
  TEXT,
  TURL,
]

Types.NFT_ATTRIBUTES = [
  STRING,
  TIMESTAMP,
  NUMBER,
]

export default Types
