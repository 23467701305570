<template>
  <!-- Excluded shops -->
  <ui-details 
    class="view-loyalty-config__details flow"
    open
    v-if="show">
    <template #summary>{{ $t('sayl-ecommerce.loyalty_config_excluded_shops_title') }}</template>
    <!-- <summary class="ui-card__summary layout-modal__summary">{{ $t('sayl-ecommerce.loyalty_config_excluded_shops_title') }}</summary> -->

    <div 
      class="view-loyalty-config__fields view-loyalty-modal__fields flow"
      v-if="showExcludedShops">
      <forms-list
        :actions="actions"
        :add="!readOnly"
        :provider="provider"
        :read-only="readOnly"
        :select="select"
        :size="$pepper.Size.S"
        v-model="list"
        @add="onAdd"
      />  
    </div>
  </ui-details>
</template>

<script>
import { mapState } from 'vuex'

import { MixinError } from '@sayl/admin-common'

export default { 
  name: 'LoyaltyProgramConfigExludedShops',

  mixins: [ MixinError ],

  props: {
    errors: {
      type: Object,
    },

    readOnly: {
      required: false,
      type: Boolean
    },
  },

  computed: {
    ...mapState({
      edition: state => state.loyalty.loyalty.edition,
      origin: state => state.loyalty.loyalty.origin,
      shops: state => state.loyalty.loyalty.shops.all,
    }),

    actions() {
      let ret = [];

      if(!this.readOnly) {
        ret.push({ 
          action: this.onRemove,
          label: this.$t('sayl.delete'),
          mode: this.$pepper.Action.SINGLE + this.$pepper.Action.SOME + this.$pepper.Action.PAGE
        })
      }
      return ret
    },

    list() {
      return this.$basil.get(this.edition, 'excludedShopIds', [])
        .map((id) => {
          let ret = this.shops.find(s => s.id === id)
          return !this.$basil.isNil(ret) ? {
            label: this.$basil.get(ret, 'title.all', null),
            value: this.$basil.get(ret, 'id', null),
          } : null
        }).filter(s => !this.$basil.isNil(s))
    },

    select() {
      return this.actions.length > 0 ? 
        this.$pepper.Selection.PAGE : 
        null
    },

    show() {
      return this.showExcludedShops
    },

    showExcludedShops() {
      return !window.$dl.fields.isPropHidden('loyalty_program', 'excluded_shop_ids')
    },
  },

  methods: {
    provider(term) {
      let ret = this.shops || [];

      return ret
        .map((shop) => {
          return {
            label: this.$basil.get(shop, 'title.all', 'Undefined name'),
            value: this.$basil.get(shop, 'id'),
            id: this.$basil.get(shop, 'id'),
            fqn: this.$basil.get(shop, 'id'),
          }
        }).filter((shop) => {
          let ret = !this.edition.excludedShopIds.includes(shop.id)

          if(this.$basil.isNil(term)) {
            ret = JSON.stringify(shop.title).includes(term)
          }

          return ret
        })
    },


    onAdd(entry, newValue) {
      this.edition.excludedShopIds.push(entry.id)
    },

    onRemove(rows) {
      rows = this.$basil.isArray(rows) ? rows : [rows]
      rows.forEach(r => this.edition.excludedShopIds.splice(this.edition.excludedShopIds.indexOf(r.value), 1))
    },
  },
}
</script>
