import { cp, oauth } from './'
import { config } from '@/env'

export default {
  /**
   * Load the bootstrap info from the cp server
   * 
   * @returns {Promise}
   */
  bootstrap() {
    let url = [config.transports.cp.api, 'bootstrap'];
    let args = ['convert=1'];

    if (config.transports.preview) {
      args.push('preview=1');
    }

    if (config.transports.next) {
      args.push('next=1')
    }

    args = args.join('&');
    url = `${url.join('')}?${args}`;

    return cp.get(url)
      .then(response => {
        return new Promise((resolve) => {
          let data = response.data;

          cp.defaults.baseURL = config.transports.cp.api;
          cp.defaults.headers.common['X-CSRF-TOKEN'] = data.csrf_token;
          oauth.defaults.headers.common['X-CSRF-TOKEN'] = data.csrf_token;

          resolve(response);
        })
      })
  },

  /**
   * Fetch the server to keep-alive the user session
   */
  keepAlive() {
    cp.get('keepalive')
      .then((response) => {
        cp.defaults.headers.common['X-CSRF-TOKEN'] = response.data.csrf_token;
        oauth.defaults.headers.common['X-CSRF-TOKEN'] = response.data.csrf_token;
      }).catch((error) => {
        console.error('bootstrap.api.keepalive errored with message:', error.message)
      });
  },
}
