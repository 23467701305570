<template>
  <layout-modal-steps
    v-model="current"
    v-bind="attrs"
    v-on="listeners" />
</template>

<script>
import Step1 from './step1'
import StepReview from './review'

export default {
  name: 'ModalExportIndex',

  props: {
    visible: {
      default: false,
      type: Boolean,
    },

    filename: {
      type: String
    },

    additionnalSteps: {
      default: () => { return [] },
      type: Array,
    }
  },

  data(){
    return {
      current: 0,
    }
  },

  computed: {
    attrs(){
      return {
        closable: true,
        views: this.views,
        visible: this.visible,
        title: this.$t('sayl.export_generic_title'),
        description: this.$t('sayl.export_generic_description'),
        filename: this.filename
      }
    },

    listeners() {
      return Object.assign({ 
        close: () => { 
          this.$emit('close'); 
        },
        confirm: (args) => {
          this.$emit('confirm', args)
        }
      }, this.$listeners);
    },

    views(){
      let ret = [{view: Step1}]

      this.additionnalSteps.forEach(s => ret.push({ view: s }))

      ret.push({ view: StepReview })

      return ret
    }
  },

  watch: {
    visible: {
      immediate: true,
      handler() {
        if(this.visible) {
          this.current = 0
        }
      }
    }
  },
}
</script>
