<template>
  <canvas ref="canvas"></canvas>
</template>

<script>
import Chart from 'chart.js'

export default {
  name: 'ChartsDoughtnut',
  
  props: {
    datasets: {
      type: Array
    },

    legend: {
      type: Boolean,
      default: true
    },

    labels: {
      type: Array
    },

    tooltips: {
      type: Boolean,
      default: true
    }
  },

  data(){
    return {
      chart: null
    }
  },

  computed: {
    options(){
      return {
        cutoutPercentage: 90,

        legend: {
          display: this.legend
        },

        maintainAspectRatio: false,
        responsive: true,

        tooltips: {
          enabled: this.tooltips
        }
      }
    },

    value(){
      let ret = {
        labels: this.labels,
        datasets: this.datasets
      }

      ret.datasets.forEach(d => {
        d.borderWidth = 0
        d.width = '1px'
      })

      return ret
    }
  },

  mounted(){
    let canvas = this.$refs.canvas;
    let ctx = canvas.getContext('2d');
    this.chart = new Chart(ctx, {
      type: 'doughnut',
      data: this.value,
      options: this.options
    }) 
  }
}
</script>