import * as cps from '@/views/ui/components'
import * as lts from '@/views/ui/layouts'
import * as vws from '@/views/index.js'

import { APPLICATION_READY } from '@/data'

/**
 * Register the sayl components
 * 
 * @param {Object} options
 * @param {GingerCapabilities} options.capabilities
 * @returns {Promise.resolve}
 */
export default function ({ capabilities }) {
  $console.group('sayl-components')

  let Vue = capabilities.vue

  // Components
  Object.keys(cps).forEach(name => { 
    Vue.component(name, cps[name])
  })

  // Layouts
  Object.keys(lts).forEach(name => {
    Vue.component(name, lts[name])
  })

  // Generic View elements
  Object.keys(vws).forEach(name => {
    Vue.component(name, vws[name])
  })

  capabilities.store.commit('sayl/setState', APPLICATION_READY)

  $console.groupEnd('sayl-components')
  return Promise.resolve()
}
