export default {
  namespaced: true,

  mutations: {
    setEdition: (state, edition) => state.edition = edition,
    setOrigin: (state, origin) => state.origin = origin,
    setChildren: (state, children) => state.children = children,
  },

  state: {
    edition: null,
    origin: null,
    children: null,
  },
}
