<template>
  <nav 
    :class="classes">

    <section 
      class="n-header__row"
      v-if="breadcrumb">
      <nav-breadcrumb 
        :children="breadcrumb"
        class="n-header__breadcrumb"
      />
    </section>

    <!-- Main -->
    <section class="n-header__row n-header__main">

      <!-- Main::Content -->
      <article class="n-header__content">

        <!-- Figure -->
        <div 
          v-if="icon || image" 
          class="n-header__figure">
          <!-- Icon -->
          <ui-icon 
            v-if="icon"
            class="n-header__icon"
            :glyph="icon" 
          />

          <!-- Image -->
          <img 
            v-if="image"
            class="n-header__image"
            :src="$getImageSrc(image)" 
            :alt="title" />
        </div>

        <!-- Status -->
        <data-state 
          v-if="state"
          class="n-header__state"
          :state="currentState"
        />

        <!-- Block -->
        <div class="n-header__block">
          <!-- Title -->
          <h1 
            v-if="title"
            class="n-header__title">{{ title }}</h1>

          <!-- Description -->
          <p 
            v-if="description"
            class="n-header__description">{{ description }}</p>

          <!-- Meta -->
          <div 
            v-if="hasMeta" 
            class="n-header__meta">
            <slot name="meta">
              <component 
                v-for="(m, i) in meta" 
                :key="i"
                :is="getMetaType(m)"
                v-bind="getMetaAttrs(m)">
                <!-- Icon -->
                <ui-icon 
                  v-if="m.icon"
                  class="n-header__meta-icon" 
                  :glyph="m.icon"
                />

                <!-- Label -->
                <span class="n-header__meta-label">{{ m.label }}</span>
              </component>
            </slot>    
          </div>
        </div>
      </article>

      <!-- Main::Actions -->
      <article 
        class="n-header__actions"
        v-if="hasActions">
        <!-- Secondary -->
        <actions-button
          class="n-header__secondary"
          @click="secondary.click"
          v-bind="secondary"
          v-if="hasSecondaryAction"
        >{{ secondary.label }}</actions-button>

        <!-- Primary -->
        <actions-button
          :appearance="$pepper.Appearance.PRIMARY"
          class="n-header__primary"
          @click="primary.click"
          v-bind="primary"
          v-if="hasPrimaryAction"
        >{{ primary.label }}</actions-button>

        <!-- Others -->
        <actions-button
          :appearance="$pepper.Appearance.SUBTLE"
          class="n-header__tertiary"
          @click="showTertiary = !showTertiary"
          icon-pre="dot-dot-dot"
          ref="tertiary"
          v-if="hasTertiaryAction"
        />
      </article>
    </section>

    <!-- Navigation::Tabs -->
    <div 
      class="n-header__row n-header__tabs"
      v-if="tabs && tabs.children">
      <navigations-tabs
        :appearance="$pepper.Appearance.DEFAULT"
        v-bind="tabs">
        <navigations-tabs-item
          v-for="(t, i) in tabs.children"
          :class="t.classes || {}"
          :key="i"
          v-bind="t"
          v-on="$basil.get(t, 'events', {})"
        >{{ t.label }}</navigations-tabs-item>
      </navigations-tabs>
    </div>

    <!-- Navigation::SubTabs -->
    <div 
      v-if="tabs && tabs.subtabs && tabs.subtabs.children"
      class="n-header__row n-header__tabs">
      <navigations-tabs
        :appearance="$pepper.Appearance.DEFAULT"
        v-bind="tabs.subtabs">
        <navigations-tabs-item
          v-for="(t, i) in tabs.subtabs.children"
          :key="i"
          v-bind="t"
          v-on="$basil.get(t, 'events', {})"
        >{{ t.label }}</navigations-tabs-item>
      </navigations-tabs>
    </div>

    <!-- Filters -->
    <div 
      class="n-header__row n-header__filters" 
      v-if="hasFilters">
      <div class="n-header__filters-intro">
        <ui-icon glyph="adjustments-horizontal" />
      </div>

      <slot name="filters"></slot>
    </div>

    <!-- Extra -->
    <section 
      v-if="hasExtra"
      class="n-header__row">
      <slot name="extra"></slot>
    </section>

    <!-- Tertiary actions -->
    <popins-list-dropdown
      v-if="hasTertiaryAction"
      class="popin-n-header__tertiary"
      :target="$refs.tertiary"
      position="bottom-center"
      :visible="showTertiary"
      :layout="['label']"
      :options="tertiary"
    />
  </nav>
</template>

<script>
export default {
  name: 'NavigationHeader',

  props: {
    /**
     * @property {Array} breadcrumb
     * @property {Boolean} [breadcrumb[].active=false] Whether or not the item is active
     * @property {String} [breadcrumb[].icon] The icon to display
     * @property {String} [breadcrumb[].label] The label to display
     * @property {String} [breadcrumb[].href] The link (router-link :to)
     */
    breadcrumb: {
      type:Array
    },

    /**
     * @property {String} description The description of the entity.
     */
    description: {
      type: String
    },

    /**
     * @property {Boolean} errored Whether or not the view is in error.
     */
    errored: {
      type: Boolean,
      default: false
    },

    /**
     * @property {String} icon The icon to display
     */
    icon: {
      type: String
    },

    /**
     * @property {String} image The image to display
     */
    image: {
      type: String
    },

    /**
     * @property {Boolean} [loading=false] Whether or not it is loading
     */
    loading: {
      type: Boolean,
      default: false
    },

    /**
     * @property {Array} meta Will display a set of meta info
     * @property {String} meta[].icon The icon
     * @property {String} meta[].href The link (router-link)
     * @property {String} meta[].label The label
     */
    meta: {
      type: Array,
      default: () => []
    },

    /**
     * @property {Object} primary Display a primary button
     * @property {Function} primary.click The click handler
     * @property {String} primary.label The label for the button
     * @property {Any} primary.[...] Any other param will be binded to the actions-button
     */
    primary: {
      type:Object
    },
    
    /**
     * @property {Object} secondary Display a secondary button
     * @property {Function} secondary.click The click handler
     * @property {String} secondary.label The label for the button
     * @property {Any} secondary.[...] Any other param will be binded to the actions-button
     */
    secondary: {
      type:Object
    },

    /**
     * @property {Boolean} [state=true] Whether or not to display the state of the view
     */
    state: {
      type: Boolean,
      default: true
    },

    /**
     * @property {Object} tabs Display a navigations-tabs
     * @property {String} tabs.appearance
     * @property {Array} tabs.children
     * @property {Boolean} tabs.disabled
     */
    tabs: {
      type: Object
    },

    /**
     * @property {Array} tertiary Display a tertiary button (dot dot dot)
     * @property {Function} tertiary[].click The click handler
     * @property {String} tertiary[].label The label for the button
     * @property {Any} tertiary[].[...] Any other param will be binded to the actions-button
     */
    tertiary: {
      type: Array
    },

    /**
     * @property {String} title The title of the entity
     */
    title: {
      type: String
    },

    /**
     * @property {Boolean} [warned=false] Whether or not a warning is raised for the current view
     */
    warned: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      showTertiary: false
    }
  },

  computed: {
    classes() {
      return {
        'n-header': true,
        '-no-description': !this.description,
        '-no-figure': !this.icon && !this.image,
        '-no-meta': !this.hasMeta
      }
    },

    currentState() {
      let ret = 'success'
      ret = this.warned === true ? 'warning' : ret
      ret = this.errored === true ? 'errored': ret
      ret = this.loading === true ? 'loading': ret

      return ret
    },

    hasPrimaryAction() {
      return this.$basil.isNil(this.primary) === false
    },

    hasSecondaryAction() {
      return this.$basil.isNil(this.secondary) === false
    },

    hasTertiaryAction() {
      return this.$basil.isNil(this.tertiary) === false && this.tertiary.length > 0
    },

    hasActions() {
      return this.hasPrimaryAction || this.hasSecondaryAction || this.hasTertiaryAction
    },

    hasExtra() {
      return !!this.$slots.extra
    },

    hasFilters() {
      return !!this.$slots.filters
    },

    hasMeta() {
      return !!this.$slots.meta || (this.meta && this.meta.length);
    },

    slots() {
      return this.$slots
    },

  },

  methods: {
    getMetaAttrs(item) {
      if(item.component) {
        let classes = Object.assign({ 'n-header__meta-item': true }, item.classes ? item.classes : {})
        return { 
          class: classes,
          ...item
        }
      }
      let ret = {
        class: 'n-header__meta-item'
      }

      if (item.href) {
        ret.href = item.href
      }

      if (item.title) {
        ret.title = item.title
      }

      return ret
    },

    getMetaType(item) {
      if(item.component) {
        return item.component
      }
      return item.href ? 'a' : 'article'
    },
  }
}
</script>
