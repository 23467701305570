const base = [
  {
    component: 'forms-input',
    fqn: 'general-style-input-bg',
    label: 'bg',
    property: '--slp-forms-input-bg',
    section: 'general',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'general-style-input-bc',
    label: 'bc',
    property: '--slp-forms-input-bc',
    section: 'general',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'general-style-input-bc-hover',
    label: 'bc_hover',
    property: '--slp-forms-input-bc-hover',
    section: 'general',
    subtype: 'color',
    type: 'style',
  },
]

const label = [
  {
    component: 'forms-input',
    fqn: 'general-style-input-fc',
    label: 'fc',
    property: '--slp-forms-input-fc',
    section: 'general',
    subtype: 'color',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'general-style-input-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-forms-input-fs',
    section: 'general',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'general-style-input-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-forms-input-fw',
    section: 'header',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const icons = [
  {
    component: 'forms-input',
    fqn: 'general-style-input-icon-bg',
    label: 'bg',
    property: '--slp-forms-input-icon-bg',
    section: 'general',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'general-style-input-icon-fc',
    label: 'fc',
    property: '--slp-forms-input-icon-fc',
    section: 'general',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'general-style-input-icon-fc-hover',
    label: 'fc_hover',
    property: '--slp-forms-input-icon-fc-hover',
    section: 'general',
    subtype: 'color',
    type: 'style',
  },
]

export default [
  ...base,
  ...label,
  ...icons,
]
