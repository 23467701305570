<template>
  <!-- Container -->
  <section class="view-loyalty-referral flow">
    <!-- Header -->
    <header 
      class="layout-level1__header flow" 
      v-if="!loading && hasReferrals">
      <div>
        <h3 class="layout-level1__title">{{ $t('sayl-loyalty.referral_title') }}</h3> 
        <p class="layout-level1__description">{{ $t('sayl-loyalty.referral_description') }}</p>
      </div>
    </header>

    <!-- Loading -->
    <div 
      class="view-loyalty-referral__loader"
      v-if="loading">
      <ui-loader />
    </div>

    <!-- Body -->
    <div 
      class="flow" 
      v-if="!loading && hasReferrals && !$basil.isNil(range)">
      <!-- KPIS -->
      <referral-kpis :range="{ from: from, to: to }" /> 

      <!-- Chart -->
      <referral-chart 
        :loading="loading"
        :range="{ from: from, to: to }" 
      />

      <!-- History -->
      <ui-card 
        :appearance="$pepper.Appearance.SUBTLE"
        article
        no-padding>
        <referral-history :args="args" /> 
      </ui-card>
    </div>

    <!-- Empty -->
    <data-empty
      :class="emptyClass"
      :description="emptyDescription"
      :figure="figure"
      :primary-action="createActionLabel"
      :title="$t('sayl-loyalty.no_referral_title')"
      @click="createAction"
      v-if="!hasReferrals && !loading"
    />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { 
  MixinACL,
  MixinCalendar,
  MixinImpersonation,
} from '@sayl/admin-common'

import ReferralChart from './chart'
import ReferralHistory from './history'
import ReferralKpis from './kpis'

export default {
  name: 'ViewReferralOverview',

  components: {
    ReferralChart,
    ReferralHistory,
    ReferralKpis,
  },

  mixins: [ 
    MixinACL,
    MixinCalendar,
    MixinImpersonation,
  ],

  props: {
    readOnly: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return { 
      loading: true,
      from: null,
      to: null,
      key: 1,

      debounce: null,
    }
  },

  computed: {
    ...mapState({
      edition: state => state.loyalty.referral.edition,
      programs: state => state.loyalty.loyalty.collection,
      module: state => state.ginger.module,
      state: state => state.loyalty.referral,
    }),

    actions() {
      return [
        this.hasReferrals ? {
          label: !this.readOnly ? 
            this.$t('sayl-loyalty.referral_edit_config') : 
            this.$t('sayl-loyalty.referral_view_config'),
          events: {
            click: () => this.$bus.$emit('edit-referral')
          },
        } : null
      ].filter((s) => !this.$basil.isNil(s))
    },

    args() {
      return {
        id: this.$basil.get(this.collection, '[0].id'), 
        from: parseInt((!this.$basil.isNil(this.from) ? this.from : this.$basil.get(this.defaultRange, 'from')) / 1000, 10),
        to: parseInt((!this.$basil.isNil(this.to) ? this.to : this.$basil.get(this.defaultRange, 'to')) / 1000, 10),
        timezoneOffset: new Date().getTimezoneOffset(),
      };
    },

    collection() {
      return this.key && (this.state.all || [])
    },

    createActionLabel() {
      return  this.canCreate && this.isMaster && !this.isImpersonating ?
        this.$t('sayl-loyalty.no_referral_action') : null
    },

    defaultRange() {
      return window.$dl.ranges.THIS_MONTH.value
    },

    emptyClass() {
      return {
        'view-loyalty-referral__empty': true,
        '-read-only': this.isReadOnly,
      }
    },

    emptyDescription() {
      return !this.isReadOnly ? 
        this.$t('sayl-loyalty.no_referral_description') : 
        this.$t('sayl-loyalty.no_referral_description_read_only')
    },

    filters() {
      let ret = [
        this.hasReferrals ? {
          type: Date,
          values: this.date,
          ranges: this.ranges,
          label: this.range,
          minDate: this.minDate,
          maxDate: this.maxDate,
          events: {
            input: (date) => {
              this.tmpDate = date
            },

            apply: () => {
              this.onDateApply()
              this.onFilters()
            }
          }
        } : null,
      ].filter(s => !this.$basil.isNil(s))

      return this.key && ret
    },  

    figure() {
      return this.$getImageSrc('/statics/images/figures/referral-empty.svg')
    },

    hasReferrals() {
      return !this.$basil.isNil(this.collection) && this.collection.length > 0
    },

    isReadOnly() {
      return !this.canEdit || !this.isMaster || this.isImpersonating
    },

    maxDate() {
      return new Date(window.$dl.ranges.THIS_YEAR.value.to)
    },

    ranges() {
      return [
        window.$dl.ranges.TODAY,
        window.$dl.ranges.YESTERDAY,
        window.$dl.ranges.THIS_WEEK,
        window.$dl.ranges.LAST_WEEK,
        window.$dl.ranges.THIS_MONTH,
        window.$dl.ranges.LAST_MONTH,
        window.$dl.ranges.LAST_YEAR,
      ].map(r => {
        let ret = {
          label: this.$t(r.label),
          start: new Date(r.value.from),
          end: new Date(r.value.to)
        }
        
        let start = this.$basil.get(this.date, 'start', null)
        let end = this.$basil.get(this.date, 'end', null)

        start = !this.$basil.isNil(start) ? start.getTime() : null
        end = !this.$basil.isNil(end) ? end.getTime() : null

        ret.active = start === ret.start && end === ret.end
        return ret
      })
    }
  },

  methods: {
    createAction() {
      return this.canCreate && this.isMaster && !this.isImpersonating ?
        this.onCreate() : () => {}
    },

    onCreate() {
      this.$router.push({ name: 'sayl-customer-loyalty_referral-edition' })
    },

    onFilters() {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => this.reset(), 300)
    },

    reset() {
      this.loading = true
      
      if(this.$basil.isNil(this.range)) {
        this.range = this.defaultRange
      }

      this.$emit('init', { actions: this.actions, filters: this.filters, reset: this.reset })

      window.$dl.referral.getKpis(this.args)
        .catch((e) => $console.error(e))
        .finally(() => this.loading = false)
    },
  },

  mounted() {
    this.reset()
  }
}
</script>
