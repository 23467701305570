<template>
  <!-- Loading -->
  <div 
    class="view-loyalty__loader"
    v-if="loading">
    <ui-loader />
  </div>

  <div 
    class="layout-level2__container container view-loyalty-config__cols" 
    v-else>
    <div 
      class="col"
      :key="`template_${i}`"
      v-for="(t, i) in template">
      <component 
        :is="tt.component"
        :key="`comp_${j}`"
        v-bind="tt.attrs"
        v-for="(tt, j) in t"
      />
    </div>
  </div>
</template>


<script>
import { mapState, } from 'vuex'

import { MixinImage } from '@sayl/admin-common'

import LoyaltyRestoCheckout from './checkout'
import LoyaltyRestoConfirmation from './confirmation'
import LoyaltyRestoExplanations from './explanations'

export default {
  name: 'LoyaltyRestoConfig',

  components: {
    LoyaltyRestoCheckout,
    LoyaltyRestoConfirmation,
    LoyaltyRestoExplanations,
  },

  mixins: [ MixinImage ],

  props: {
    errors: {
      type: Object,
    },

    readOnly: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      loading: true
    }
  },

  computed: {
    ...mapState({
      edition: state => state.loyalty.loyalty.edition,
      origin: state => state.loyalty.loyalty.origin,
    }),

    template() {
      return [
        [
          this.$basil.get(this.edition, 'rewardEatin', true) === true ? {
            component: 'loyalty-resto-explanations',
            attrs: {
              errors: this.errors,
              readOnly: this.readOnly,
            }
          } : null
        ].filter(s => !this.$basil.isNil(s)),
        [
          this.$basil.get(this.edition, 'rewardEatin', true) === true ? {
            component: 'loyalty-resto-confirmation',
            attrs: {
              errors: this.errors,
              readOnly: this.readOnly,
            }
          } : null,
          {
            component: 'loyalty-resto-checkout',
            attrs: {
              errors: this.errors,
              readOnly: this.readOnly,
            }
          }
        ].filter(s => !this.$basil.isNil(s))
      ].filter(s => s.length > 0)
    }
  },

  methods: {
    reset() {
      this.loading = true;
      this.$configuration.block.all({ args: { limit: 9999, pageSize: 9999 }})
        .then(() => this.$image.all({ data: { entityType: 'loyalty_program', entityId: this.$route.params.program, fieldName: 'banner', module: 'loyalty' }}))
        .catch((e) => $console.error(e))
        .finally(() => this.loading = false)
    }
  },

  mounted() {
    this.reset();
  }
}
</script>
