<template>
  <div class="data-timeline">
    <div 
      class="data-timeline__loader"
      v-if="loading">
      <ui-loader/>
    </div>

    <!-- Rows -->
    <div 
      class="data-timeline__rows"
      :class="{ '-is-loading': loading }"
      v-if="!isEmpty">
      <component
        :is="!$basil.isNil(row.href) ? 'router-link' : 'div'"
        :target="row.blank ? '_blank' : null"
        :to="row.href"
        class="data-timeline__row"
        :class="{ '-is-link': !$basil.isNil(row.href) }"
        :key="$basil.uniqId(i)"
        v-for="(row, i) in rows">

        <component
          :is="row.component"
          v-bind="row"
          v-if="!$basil.isNil(row.component)"
        />

        <!-- Icon  -->
        <div 
          class="data-timeline__icon"
          :class="$basil.get(row, 'icon.hue')"
          v-if="$basil.isNil(row.component) && !$basil.isNil(row.icon)">
          <ui-icon :glyph="$basil.get(row, 'icon.glyph', $basil.get(row, 'icon', null))" />
        </div>

        <!-- Content -->
        <div class="data-timeline__content" v-if="$basil.isNil(row.component)">
          <div 
            class="data-timeline__value"
            v-if="!$basil.isNil(row.labels)">
            <component
              v-for="(block, j) in row.labels"
              :key="$basil.uniqId(j)"
              :is="block.type"
              v-bind="block.bind"
              v-html="block.value"
            ></component>
          </div>

          <div 
            class="data-timeline__value"
            v-if="!$basil.isNil(row.label)">
            {{ row.label }}
          </div>

          <div 
            class="data-timeline__metas"
            v-if="row.meta">
            <div 
              class="data-timeline__meta"
              :class="{ '-meta-route': !$basil.isNil(meta.href) }"
              :key="$basil.uniqId(k)"
              v-for="(meta, k) in row.meta"
              @click="!$basil.isNil(meta.href) ? onMetaClick(meta.href) : () => {}"
            ><ui-icon class="data-timeline__meta-icon" :glyph="meta.icon" v-if="meta.icon"/>{{ meta.label }}</div>
          </div>
        </div>

        <data-badge
          class="data-timeline__badge"
          :class="$basil.get(row, 'badge.hue', {})"
          :appearance="$pepper.Appearance.DEFAULT"
          :size="$pepper.Size.S"
          :value="$basil.get(row, 'badge.value', $basil.get(row, 'badge', null))"
          v-if="$basil.isNil(row.component) && row.badge"
        />
      </component>
    </div>

    <slot name="empty">
    <!-- Empty -->
      <data-empty
        :title="iEmptyTitle"
        :description="iEmptyDescription"
        v-if="isEmpty"
      />
    </slot>

    <footer 
      class="data-timeline__footer"
      v-if="hasFooter || hasPagination">
      <slot name="footer">
        <!-- Pagination -->
        <data-pagination
          class="data-timeline__pagination"
          :layout="['prev', 'pager', 'next']"
          :current-page="pagination.currentPage"
          :page-size="pagination.pageSize"
          :total="pagination.total"
          :size="$pepper.Size.S"
          @change="onPage"
          v-if="!isEmpty && !$basil.isNil(pagination)"
        />
      </slot>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'UiTimeline',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    pagination: {
      type: Object,
    },

    rows: {
      type: Array,
      required: true,
    },

    emptyTitle: {
      type: String,
      default: null
    },

    emptyDescription: {
      type: String,
      default: null
    },
  },

  computed: {
    isEmpty() {
      return this.rows.length === 0
    },

    hasFooter() {
      return !!this.$slots.footer
    },

    hasPagination() {
      return !this.$basil.isNil(this.pagination)
    },

    iEmptyDescription() {
      return this.$basil.isNil(this.emptyDescription) ? this.$t('sayl-ecommerce.loyalty_history_empty_description') : this.emptyDescription
    },

    iEmptyTitle() {
      return this.$basil.isNil(this.emptyTitle) ? this.$t('sayl-ecommerce.loyalty_history_empty_title') : this.emptyTitle
    }
  },

  methods: {
    onPage(page) {
      this.$emit('page', page)
    },

    onMetaClick(route) {
      let routeF = this.$router.resolve(route)
      window.open(routeF.href, '_blank')
    }
  }
}
</script>
