<template>
  <layout-level2-sided
    v-bind="attrs"
    v-on="listeners">

    <div 
      class="container flow" 
      v-if="!loading"> 
      <div v-if="isSearchable">
        <forms-input
          type="search"
          :placeholder="$t('sayl.search_placeholder')"
          v-model="searchQuery"
        />
      </div>

      <div>
        <slot 
          name="list" 
          :search="searchQuery"
        ></slot>
      </div>

      <article 
        class="ui-card__article" 
        v-if="!hasListSlot"> 
        <data-empty
          :title="$t('sayl.modal_choices_empty_title')"
          v-if="resultQuery.length === 0"
        />

        <ul 
          class="modal-choices__items"
          v-if="resultQuery.length > 0">
          <li
            :class="itemClasses(v)"
            :key="`${v.value}_${i}`"
            v-for="(v, i) in resultQuery"
            @click="() => itemSelector(v)">
            <div 
              class="modal-choices__itemchecked"
              v-if="isChecked(v.value) && appearance === $pepper.Appearance.CARD">
              <ui-icon
                class="modal-choices__itemicon"
                glyph="check" 
              />
            </div>

            <forms-radio
              class="modal-choices__selector"
              icon-post-interactive 
              :key="`${v.value}_${key}`"
              :modelValue="isSelected"
              :size="$pepper.Size.S"
              :value="v.value"
              v-if="!isMultiple && appearance === $pepper.Appearance.DEFAULT"
            />

            <forms-checkbox
              class="modal-choices__selector"
              :checked="isChecked(v.value)"
              icon-post-interactive 
              :size="$pepper.Size.S"
              :value="v.value"
              v-if="isMultiple && appearance === $pepper.Appearance.DEFAULT"
            />

            <div class="modal-choices__inner">
              <slot :entry="v">
                <p
                  class="modal-choices__innerlabel -no-slot"
                  v-html="$basil.get(v, 'label', 'No label')"
                ></p>
              </slot>
            </div>
          </li>
        </ul>
      </article> 
    </div>
  </layout-level2-sided>
</template>

<script>
import { 
  MixinACL, 
  MixinEditionDiscard,
  MixinError, 
} from '@sayl/admin-common'
import { Appearance } from '@pepper/utils'

export default{
  name:'ModalChoices',

  mixins:[
    MixinACL, 
    MixinEditionDiscard,
    MixinError, 
  ],

  props: {    
    appearance: {
      default: Appearance.DEFAULT,
      type: String,
      validator: (value) => [Appearance.DEFAULT, Appearance.CARD].includes(value),
    },

    breadcrumb: {
      type: Array,
    },

    classes: {
      type: Object,
    },

    errors: {
      type: Object,
    },

    icon: {
      type: String
    },

    isMultiple: {
      type: Boolean,
      default: false
    },

    isSearchable: {
      type: Boolean,
      default: true
    },

    items: {
      type: Array | Object,
      required: true
    },

    loading:{
      type:Boolean,
      default:false,
    },

    title: {
      type: String,
      required: true
    },

    value: {
      type: String | Array,
      required: true,
    },

    visible:{
      type:Boolean,
      required:true,
    },
  },
  
  data() {
    return {
      key: 1,
      isSelected:null,
      searchQuery:'',
    }  
  },

  computed:{
    attrs() {
      return {
        classes: {
          '-body-p0': true,
          'modal-choices': true,
          '-card': this.appearance === this.$pepper.Appearance.CARD,
          ...this.classes
        },
        breadcrumb: this.breadcrumb,
        closable: true,
        icon: this.icon,
        title: this.title,
        // pristine: this.pristine,
        visible: this.visible,
        loading: this.loading,

        primary: this.isMultiple ? {
          label: this.$t('sayl.confirm'),
          click: this.onConfirm
        } : null,
      }
    },
    
    hasListSlot() {
      return !!this.$slots.list
    },

    listeners() {
      return {
        back: this.onDiscard,
        close: this.onDiscard,
        discard: this.onDiscard,
        save: this.onConfirm,
      }
    },

    pristine() {
      return JSON.stringify(this.isSelected) === JSON.stringify(this.value)
    },

    resultQuery() {
      if(!this.$basil.isNil(this.searchQuery) && !this.$basil.isEmpty(this.searchQuery) && !this.loading && this.$basil.isArray(this.items)) {
        return this.items.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(v => JSON.stringify(item).toLowerCase().includes(v))
        });
      } else {
        return this.items
      }
    },
  },
  
  watch: {
    visible: {
      immediate: true,
      handler() {
        this.reset()
      }
    }
  },

  methods:{
    back() {
      this.$emit('close')
    },

    isChecked(id) {
      return this.isMultiple ? 
        this.isSelected.includes(id) : 
        this.isSelected === id
    },

    itemClasses(v) {
      return {
        'modal-choices__item': true,
        '-is-selected': this.isChecked(v.value)
      }
    },

    onConfirm() {
      this.$emit('change', this.isSelected)
      this.back()
    },

    itemSelector(value){
      if(this.isMultiple) {
        let index = this.items.indexOf(value)

        this.isSelected.includes(value.value) ? 
          this.isSelected.splice(this.isSelected.indexOf(value.value),1):
          this.isSelected.splice(index, 0, value.value) 
      } else {
        this.isSelected = value.value
        this.onConfirm()
      }
      this.key++
    },

    reset() {
      this.isSelected = JSON.parse(JSON.stringify(this.value))
      this.key++
    }
  },
}
</script>
