<template>
  <div 
    v-if="!hasBlockingErrors" 
    style="display: contents">
    <slot></slot>
  </div>

  <div 
    class="ui-errors" 
    v-else>
    <div class="notfound__inner">
      <div class="ui-errors__body">
        <h3 class="ui-errors__title">{{ $t(`sayl.error_${status}_title`, { entity }) }}</h3>
        <div class="ui-errors__subtitle">{{ $t(`sayl.error_${status}_description`) }}</div>

        <div class="ui-errors__actions">
          <actions-button
            :appearance="$pepper.Appearance.PRIMARY"
            class="ui-errors__action"
            @click="$emit('back')"
          >{{ $t('sayl.error_back_action') }}</actions-button>
        </div>
      </div>

      <div class="footer notfound__footer">
        <div 
          class="notfound__description"
          v-html="$t('sayl.error_page_not_found_description')"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'UIError',
  
  props: {
    errors: {
      type: Object,
        default:() => { 
        return {} 
      }
    }
  },

  computed: {
    entity() {
      return this.$t(`sayl.entity_${this.$basil.get(this.errors,'entity')}`)
    },

    hasBlockingErrors() {
      let status = this.$basil.get(this.errors, 'status', 200)
      return [403, 404, 500].includes(status)
    },

    status() {
      return this.$basil.get(this.errors,'status', 200)
    }
  },
}
</script>
