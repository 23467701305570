<template>
  <section :class="classes">
    <!-- Intro -->
    <div class="n-primary-module-pane__intro">
      <!-- Icons -->
      <div class="n-primary-module-pane__icons">
        <ui-icon
          class="n-primary-module-pane__intro-icon n-primary-module-pane__icon1"
          :key="j"
          :glyph="i"
          v-for="(i, j) in icons"
        />
      </div>

      <!-- Title -->
      <div class="n-primary-module-pane__name">{{ title }}</div>

      <!-- Description -->
      <div class="n-primary-module-pane__description">{{ description }}</div>
      
      <!-- Company Name -->
      <data-tag 
        class="n-primary-module-pane__customer"
        :size="$pepper.Size.S"
        v-if="isIdentity"
      >{{ currentCustomerName }}</data-tag>
    </div>

    <!-- Impersonation -->
    <primary-impersonation v-if="isIdentity" />

    <!-- Links -->
    <ul class="n-primary-module-pane__links">
      <li
        class="n-primary-module-pane__item"
        :key="i" 
        v-for="(e, i) in children">
        <router-link
          :active="isActive(e)"
          :to="e.href"
          class="n-primary-module-pane__link"
          :class="{ 'router-link-active': isActive(e) }"
          @click.native.stop.prevent="onRouterClick(e)">
          <span class="n-primary-module-pane__group">
            <!-- Label -->
            <div class="n-primary-module-pane__label">{{ $t(e.label) }}</div>

            <!-- Restricted -->
            <div 
              class="n-primary-module-pane__restricted"
              v-if="e.locked">
              <ui-icon 
                class="n-primary-module-pane__lock"
                glyph="lock" 
              />
            </div>

            <!-- Icon -->
            <ui-icon 
              class="n-primary-module-pane__icon" 
              glyph="arrow-right" 
              v-else
            />
          </span>

          <span class="n-primary-module-pane__description">{{ $t(e.description) }}</span>
        </router-link>
      </li>
      
      <!-- Logout -->
      <li
        v-if="isIdentity" 
        class="n-primary-module-pane__item">
        <button 
          class="n-primary-module-pane__link -danger"
          @click="logout">

          <span class="n-primary-module-pane__group">
            <!-- Label -->
            <div class="n-primary-module-pane__label">{{ $t('sayl.logout') }}</div>
            
            <!-- Icon -->
            <ui-icon 
              class="n-primary-module-pane__icon" 
              glyph="arrow-right" />
          </span>

          <span class="n-primary-module-pane__description">{{ $t('sayl.logout_description') }}</span>
        </button>
      </li>

      <!-- SSM -->
      <li  
        v-if="isIdentity && ssmURL"
        class="n-primary-module-pane__item">
        <a 
          class="n-primary-module-pane__link"
          :href="ssmURL"
          target="_blank">

          <span class="n-primary-module-pane__group">
            <!-- Label -->
            <div class="n-primary-module-pane__label">{{ $t('sayl.ssm_title') }}</div>
            
            <!-- Icon -->
            <ui-icon 
              class="n-primary-module-pane__icon" 
              glyph="open" />
          </span>

          <span class="n-primary-module-pane__description">{{ $t('sayl.ssm_description') }}</span>
        </a>
      </li>
    </ul>

    <!-- Locked feature block -->
    <button 
      v-if="entry.locked === true"
      class="n-primary-module-pane__featurelock"
      @click="$emit('locked', entry)">
      <div class="n-primary-module-pane__lock-glyph">
        <ui-icon 
          class="n-primary-module-pane__lock-icon"
          glyph="lock" />
      </div>

      <p class="n-primary-module-pane__lock-label">{{ $t(`sayl-feature.${productName}_component_locked_title`)}}</p>
      <span 
        class="n-primary-module-pane__lock-cta"
        @click="onModalFeature">{{$t('sayl-feature.learn_more')}}</span>
    </button>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { MixinImpersonation } from '@sayl/admin-common'

import PrimaryImpersonation from './primary-impersonation'

export default {
  name: 'PrimaryNavigationModulePane',

  components: {
    PrimaryImpersonation
  },

  mixins: [
    MixinImpersonation
  ],

  props: {
    entry: {
      type: Object
    }
  },

  computed: {
    ...mapState({
      bootstrap: state => state.sayl.bootstrap
    }),

    children(){
      return this.isIdentity ? [] : this.$basil.get(this.entry, 'children', [])
    },

    classes(){
      let fqn = this.$basil.get(this.entry, 'parent.fqn')
      return {
        'n-primary-module-pane': true,
        '-is-sayl': fqn === 'sayl',
        '-is-customer': fqn === 'sayl-customer',
        '-is-resto': fqn === 'sayl-resto',
        '-is-finance': fqn === 'sayl-finance',
      }
    },

    description(){
      return this.$t(this.entry.description)
    },

    hasChildren(){
      return this.children && this.children.length > 0
    },

    icons(){
      return this.$basil.get(this.entry, 'icons', [this.$basil.get(this.entry, 'icon')])
    },
    
    isIdentity(){
      return this.$basil.get(this.entry, 'fqn') === 'sayl-identity'
    },

    productName() {
      let ret = this.$basil.get(this.entry, 'parent.fqn')

      return !this.$basil.isNil(ret) ? ret.split('-').join('_') : ''
    },

    ssmURL(){
      return this.$basil.get(this.bootstrap, 'ssm.url', null)
    },

    title(){
      return this.$t(this.entry.title) || this.$t(this.entry.label)
    },
  },

  methods: {
    onModalFeature() {
      this.$bus.$emit('feature-locked', { product: this.$basil.get(this.entry, 'parent.fqn'), feature: this.$basil.get(this.entry, 'fqn') })
    },

    onRouterClick(e) {
      this.$store.commit('sayl/setRoute', { current:e, parent: this.$basil.get(this.entry, 'parent') })
      this.$emit('selection', this.entry, e)
    },

    isActive(e) {
      let name = this.$basil.get(e, 'href.name')
      return this.$route.name === name || (e.subs && e.subs.includes(this.$route.name))
    },

    logout() {
      $dl.logout();
    },
  }
}
</script>
