<template>
  <!-- Eatin Rewards -->
  <ui-details 
    article
    class="view-loyalty-config__details flow" 
    open>
    <template #summary>{{ $t('sayl-ecommerce.loyalty_config_eatin_rewards_title') }}</template>
    
    <!-- Rules::Reward Eatin? -->
    <article class="ui-details__article view-loyalty-config__fields view-loyalty-modal__fields flow -first">
      <forms-checkbox
        :appearance="$pepper.Appearance.TOGGLE"
        :hint="$t('sayl-ecommerce.loyalty_config_has_reward_eatin_account_hint')"
        :read-only="readOnly"
        :size="$pepper.Size.S"
        v-model="edition.rewardEatin"
        @change="onRewardEatinChange"
        v-if="showRewardEatin"
      >{{ $t('sayl-ecommerce.loyalty_config_has_reward_eatin_account_label') }}</forms-checkbox>
    </article>

    <article 
      class="ui-details__article view-loyalty-config__fields view-loyalty-modal__fields flow"
      v-if="show">
      <!-- class="view-loyalty-config__fields view-loyalty-modal__fields flow" -->
      <!-- Eatin Reward::Allow Digital Cards -->
      <forms-checkbox
        :appearance="$pepper.Appearance.TOGGLE"
        :hint="$t('sayl-ecommerce.loyalty_config_allow_digital_cards_hint')"
        :read-only="readOnly"
        :size="$pepper.Size.S"
        v-model="edition.allowDigitalCards"
        v-if="showDigitalCard"
      >{{ $t('sayl-ecommerce.loyalty_config_allow_digital_cards_label') }}</forms-checkbox>

      <!-- Eatin Reward::Allow Physical Cards -->
      <forms-checkbox
        :appearance="$pepper.Appearance.TOGGLE"
        :hint="$t('sayl-ecommerce.loyalty_config_allow_physical_cards_hint')"
        :read-only="readOnly"
        :size="$pepper.Size.S"
        v-model="edition.allowPhysicalCards"
        v-if="showPhysicalCard"
      >{{ $t('sayl-ecommerce.loyalty_config_allow_physical_cards_label') }}</forms-checkbox>
  </article>
  </ui-details>
</template>

<script>
import { mapState } from 'vuex'
import { MixinError } from '@sayl/admin-common'

export default { 
  name: 'LoyaltyProgramConfigPoints',

  mixins: [
    MixinError,
  ],

  props: {
    errors: {
      type: Object,
    },

    readOnly: {
      required: false,
      type: Boolean
    },
  },

  computed: {
    ...mapState({
      edition: state => state.loyalty.loyalty.edition,
      origin: state => state.loyalty.loyalty.origin,
    }),

    show() {
      return this.edition.rewardEatin && 
        (this.showDigitalCard || this.showPhysicalCard)
    },

    showDigitalCard() {
      return !window.$dl.fields.isPropHidden('loyalty_program', 'allow_digital_cards')
    },

    showPhysicalCard() {
      return !window.$dl.fields.isPropHidden('loyalty_program', 'allow_physical_cards')
    },

    showRewardEatin() {
      return !window.$dl.fields.isPropHidden('loyalty_program', 'reward_eatin')
    },
  },

  methods: {
    onRewardEatinChange() {
      if(this.showRewardEatin) {
        this.edition.allowDigitalCards = this.edition.rewardEatin ? this.origin.allowDigitalCards || false : false
        this.edition.allowPhysicalCards = this.edition.rewardEatin ? this.origin.allowPhysicalCards || false : false
      }
    },
  }
}
</script>
