<template>
  <article class="ui-card__article flow">
    <forms-select
      :errors="getErrors('segment_id')"
      :options="segmentsList"
      :placeholder="$t('sayl.select_placeholder')"
      :read-only="readOnly"
      required
      @change="onRemoveError('segment_id')"
      v-model="edition.segmentId"
    >{{ $t('sayl.tier_segment_label') }}</forms-select>

    <!-- <forms-input
      :errors="getErrors('options.segment_evaluation_frequency')"
      required
      :hint="$t('sayl.tier_segment_cron_hint')"
      v-model="edition.options.segment_evaluation_frequency"
    >{{ $t('sayl.tier_segment_cron_label') }}</forms-input> -->
  </article>
</template>

<script>
import { mapState } from 'vuex'

import { MixinError } from '@sayl/admin-common'

export default {
  name: 'TierEditionTypeSegment',

  mixins: [
    MixinError,
  ],

  props: {
    errors: {},

    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState({
      edition: state => state.tier.tier.edition,
      segments: state => state.tier.tier.segments.all,
    }),

    segment() {
      let ret = null

      if(this.edition.segmentId) {
        ret = this.segments.find(s => s.id === this.edition.segmentId)
      }

      return ret
    },

    segmentsList() {
      let ret = this.segments || []

      return ret.map((r) => {
        return {
          label: this.$basil.get(r, 'name'),
          value: this.$basil.get(r, 'id')
        }
      })
    },
  },

  methods: {
    reset() {
      window.$dl.tier.getSegments()
        .catch((e) => $console.error(e))
    },
  },

  mounted() {
    this.reset()
  }
}
</script>