<template>
  <view-edition-tier 
    v-bind="attrs"
    v-on="listeners"
    v-if="!reset"
  />
</template>

<script>
import { mapState } from 'vuex'

import {
  MixinEditionDiscard,
  MixinEditionRemove,
  MixinError,
  MixinTranslation,
} from '@sayl/admin-common'

export default {
  name: 'LoyaltyProgramTier',

  mixins: [
    MixinEditionDiscard,
    MixinEditionRemove,
    MixinError,
    MixinTranslation,
  ],

  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      reset: false
    }
  },

  computed: {
    ...mapState({
      program: state => state.loyalty.loyalty.edition,
    }),

    attrs() {
      return {
        breadcrumb: this.breadcrumb,
        settings: this.$basil.get(this.program, 'tiersSettings'),
        creationArgs: {
          type: 'manual',
          related_entity: 'loyalty_program',
          related_entity_id: this.program.id,
        },
        readOnly: this.readOnly,
      }
    },

    breadcrumb() {
      return [
        { label: this.$t('sayl-ecommerce.loyalty'), icon: 'award', href: { name:'sayl-customer-loyalty_home'} },
        { label: this.$basil.get(this.program, 'name.all'), events: { click: this.onDiscard } },
      ]
    },

    isNew() {
      return this.$route.params.tier === 'create'
    },

    listeners() {
      return {
        close: this.onDiscard,
        back: this.onDiscard,
        saved: this.onReset,
        remove: (id) => this.onRemove({ id }),
      }
    },
  },

  methods: {
    back() {
      this.$router.push({ name: 'sayl-customer-loyalty_program-tiers' }).catch(() => {})
    },

    remove({ id }) {
      return new Promise((resolve, reject) => {
        window.$dl.tier.remove({ id })
          .then(() => {
            this.onReset()
            resolve()
          })
          .catch((e) => reject(e))
      })
    },

    onReset(item = null) {
      this.reset = true
      item != null && this.isNew && this.$router.replace({ name: 'sayl-customer-loyalty_program-tier', params: { tier: item.id }}).catch(() => {})
      window.$dl.tier.all({ args: { limit: 9999, page: 1, relatedEntity: 'loyalty_program', relatedId: this.$route.params.program }})
        .catch((e) => $console.error(e))
      setTimeout(() => this.reset = false, 10)
    }
  },
}
</script>