export default {
  "linkUtm": {
    "source": "referral",
    "medium": "url"
  },
  "referrerRewards": [
    {
      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
      "rules": [],
      "settings": {
        "credits_amount": 1
      },
      "type": "loyalty"
    },
    {
      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam consectetur, enim id tincidunt cursus.",
      "rules": [],
      "settings": {
        "nft_id": "3333333",
        "nft_attributes": []
      },
      "type": "nft"
    },
    {
      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam consectetur, enim id tincidunt cursus.",
      "rules": [],
      "settings": {
        "voucher_type": "discount",
        "voucher_template_id": "22222222",
        "voucher_validity_period": 1,
        "voucher_amount": 5
      },
      "type": "voucher"
    },
  ],
  "referringRewards": [
    {
      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam consectetur",
      "rules": [],
      "settings": {
        "credits_amount": 1
      },
      "type": "loyalty"
    },
    {
      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      "rules": [],
      "settings": {
        "nft_id": "3333333",
        "nft_attributes": []
      },
      "type": "nft"
    },
    {
      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam consectetur, enim id tincidunt cursus.",
      "rules": [],
      "settings": {
        "voucher_type": "discount",
        "voucher_template_id": "22222222",
        "voucher_validity_period": 1,
        "voucher_amount": 5
      },
      "type": "voucher"
    },
  ],
  // "user": { "code": "G3PADPCT" },
  // "user": null,
  "basePath": "https://bountiful-jakarta-02nah18oytmt.vapor-farm-a1.com/askemma/5eccefa6e92aa456ed14d372/pickup",
  "history": [
    {
      "key": "1111",
      "date": "2023-03-24T12:04:26.754Z",
      "title": "Purchase",
      "reward": "nft"
    },
    {
      "key": "2222",
      "date": "2023-03-08T09:21:40.238Z",
      "title": "Purchase",
      "reward": "loyalty"
    },
    {
      "key": "3333",
      "date": "2023-03-03T14:12:09.586Z",
      "title": "Purchase",
      "reward": "voucher"
    }
  ],
  "historyHasMore": true
}
