import fontSizes from './font-sizes'
import fontWeights from './font-weights'
import layout from './layout'
import mergeTags from './merge-tags'

export default {
  fontSizes,
  fontWeights,
  layout,
  mergeTags,
}
