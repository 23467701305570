<template>
  <ui-card :appearance="$pepper.Appearance.SUBTLE" article>
    <template #header>
      <div>
        <h3 
          class="ui-card__title"
          v-if="title"
        >{{ title }}</h3>
        
        <p 
          class="ui-card__description" 
          v-if="description"
        >{{ description }}</p>
      </div>
    </template>

    <article class="ui-card__article">
      <forms-list
        :actions="actions"
        :events="events"
        property="id"
        v-model="list"
        :select="select">
        <template #entry="{entry}">
          <div class="row group">
            <div>{{ getLabel(entry) }}</div>
            <data-tag :size="$pepper.Size.S">{{ getType(entry) }}</data-tag>
          </div>
        </template>

        <template #empty>
          <data-empty
            :title="$t(`sayl-loyalty.referral_no_${field}`)"
          />
        </template>
      </forms-list>
    </article>
  </ui-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ReferralRewards',

  props: {
    description: {
      type: String,
    },

    errors: {
      type: Object,
    },

    field: {
      type: String,
      required: true
    },

    readOnly: {
      type: Boolean,
      default: false,
    },

    rewards: {
      type: Array,
      default: () => [],
      required: true,
    },

    title: {
      type: String,
    }
  },

  data() {
    return {
      key: 1,
    }
  },

  computed: {
    ...mapState({
      vouchersTemplates: state => state.ecommerce.voucher.templates.all,
      nfts: state => state.loyalty.referral.nfts.all,
      programs: state => state.loyalty.loyalty.collection,
    }),

    actions() {
      let ret = []

      if(!this.readOnly) {
        ret.push({
          action: this.onAdd,
          label: this.$t('sayl.create'),
          iconPost: 'add',
          mode: this.$pepper.Action.GLOBAL,
        })

        ret.push({ 
          action: this.onRemove,
          label: this.$t('sayl.delete'),
          mode: this.$pepper.Action.SINGLE + this.$pepper.Action.SOME + this.$pepper.Action.PAGE
        })
      }

      return ret
    },

    events() {
      return {
        click: (row) => {
          this.$emit('edit', { value: this.rewards[row.id], index: row.id })
        }
      }
    },

    list() {
      return this.key && this.rewards.map((r, i) => {
        return {
          ...r, 
          id: i,
        }
      })
    },

    select() {
      return this.actions.length > 0 ? this.$pepper.Selection.PAGE : null
    }
  },

  methods: {
    onAdd() {
      this.$emit('add')
    },

    onRemove(items) {
      this.$confirm({
        primaryAction: this.$t('sayl.confirm'), 
        secondaryAction: this.$t('sayl.cancel'), 
        title: this.$t('sayl.delete_modal_title'),
        description: this.$t('sayl.delete_modal_message'),
        onPrimary: () => {
          let data = this.$basil.isArray(items) ? items : [items];
          data.sort((a, b) => b.id - a.id)
          data.forEach((r) => {
            this.rewards.splice(r.id, 1)
          })
        }
      })
    },

    remove(payload) {
      this.rewards.splice(payload.id, 1)
      return Promise.resolve()
    },

    getLabel(entry) {
      let name = null
      switch(entry.type) {
        case 'loyalty': 
          let pts = this.$basil.get(entry, 'settings.credits_amount', 0)
          let ptsName = this.$basil.get(this.programs, '[0].points.name.all')
          return `${pts} ${ptsName}`

        case 'nft': 
          let nft = this.nfts.find(n => n.id === this.$basil.get(entry, 'settings.nft_id', null))
          name = this.$t('sayl-loyalty.referral_nft_name_undefined')
          if(nft) {
            name = this.$basil.get(nft, 'name.all', name)
          }
          return name

        case 'voucher': 
          let voucher = this.vouchersTemplates.find(v => v.id === this.$basil.get(entry, 'settings.voucher_template_id', null))
          name = this.$t('sayl-loyalty.referral_voucher_name_undefined')
          if(voucher) {
            name = this.$basil.get(voucher, 'name.all', name)
          }
          return name

        default: 
          return ''
      }
    },

    getType(entry) {
      return entry.type.toUpperCase()
      return this.$t(`sayl-loyalty.referral_reward_type_${entry.type}`)
    },

    reset() {
      this.key++
    }
  },
}
</script>
