<template>
  <ui-card
    appearance="subtle"
    article
    class="forms-tags">
    
    <!-- Header -->
    <template v-slot:header>
      <div>
        <h3 class="ui-card__title">{{ $t('sayl.tags_title') }}</h3>
        <div class="ui-card__description">{{ $t('sayl.tags_description') }}</div>
      </div>
    </template>

    <!-- List -->
    <article 
      v-if="hasItem" 
      class="ui-card__article">
      <div class="forms-tags__list">
        <data-tag
          v-for="item in values"
          :key="item.id"
          :disabled="disabled || readOnly"
          :closable="!disabled"
          @close="onClose(item)"
          :size="$pepper.Size.S"
          :value="item"
          >{{ item.title }}</data-tag>
      </div>
    </article>

    <article 
      v-if="!readOnly"
      class="ui-card__article">
      <forms-autocomplete
        :disabled="disabled"
        allow-add
        auto-clear
        :auto-focus="false"
        :placeholder="$t('sayl.tags_placeholder')"
        property="title"
        :loading="loading"
        :provider="provider"
        :size="$pepper.Size.S"
        @change="onChange"
        @selected="onSelect" 
      />
    </article>
  </ui-card>
</template>

<script>
import { mapState } from 'vuex'

import {
  MixinEditionSave,
  MixinError,
} from '@sayl/admin-common'

import UiCard from '../ui/card'

export default {
  name: 'FormsTags',

  components: {
    UiCard
  },

  model: {
    prop: 'values',
    event: 'change'
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    entity: {
      type: String,
      require: true,
    },

    group: {
      type: String,
      default: null,
    },

    id: {
      type: String, 
      require: true,
    },

    readOnly: {
      type: Boolean,
      default: false
    },

    values: {
      type: Array,
      require: true,
    }
  },

  mixins: [ 
    MixinEditionSave,
    MixinError 
  ],

  data() {
    return { 
      errors: {},
      items: [],
      loading: true
    }
  },

  computed: {
    ...mapState({
      collection: state =>state.configuration.tag.entity.all,
      edition: state =>state.configuration.tag.edition,
    }),

    hasItem(){
      return this.values && this.values.length > 0;
    }
  },

  methods: {
    onChange(value) {
      if(value.isNew) {
        this.$configuration.tag.create({ args: { title: value.title, entity_id: this.id, entity_type: this.entity } })
          .then(() => this.onSave({}))
          .then((res) => this.values.push(res))
          .catch((e) => $console.error(e))
          .finally(() => this.reset())
      }      
    },

    onClose(value){
      this.$configuration.tag.unlink({ args: { id: value.id, entity_id: this.id }})
        .then(() => this.values.splice(this.values.indexOf(value), 1))
        .catch((e) => $console.error(e))
        .finally(() => this.reset())
    },

    onSelect(value, search) {
      this.$configuration.tag.link({ args: { id: value.id, entity_id: this.id }})
        .then((res) => this.values.push(res.data))
        .catch((e) => $console.error(e))
        .finally(() => this.reset())
    },

    provider(term) {
      let ret = this.collection || []
      ret = ret.filter(t => this.values.find(v => v.id === t.id) === undefined && t.title != null)
      ret = ret.filter(t => term ? JSON.stringify(t).toLowerCase().includes(term.toLowerCase()) : true)
      return ret
    },

    reset() {
      this.loading = true
      this.$configuration.tag.findByEntity({ args: { entity: this.entity } })
        .catch((e) => $console.error(e))
        .finally(() => this.loading = false)
    },

    save() {
      return this.$configuration.tag.save({ item: this.edition, isNew: true })
    }
  },

  mounted() {
    this.reset();
  }
}
</script>
