<template>
  <ui-card 
    :appearance="$pepper.Appearance.SUBTLE"
    article
    class="view-loyalty-config__row">
    <template v-slot:header>
      <h3 class="ui-card__title">{{ $t('sayl-ecommerce.loyalty_config_pass_about_title') }}</h3>
    </template>

    <article class="ui-card__article flow">
      <!-- Pass description -->
      <forms-input
        :class="hasTranslations('passDescription')"
        icon-post="l8n"
        :icon-post-interactive="true"
        :errors="getErrors('pass_description.all')"
        :hint="$t('sayl-ecommerce.loyalty_config_pass_about_description_hint')"
        :placeholder="$t('sayl-ecommerce.loyalty_config_pass_about_description_placeholder')"
        :read-only="readOnly"
        required
        type="text"
        v-model="edition.passDescription.all"
        @iconClick="onOpenTranslations('passDescription')"
        @input="onRemoveError('pass_description.all')"
      >{{$t('sayl-ecommerce.loyalty_config_pass_about_description_label')}}</forms-input>
    </article>

    <modal-translate 
      @close="onCloseTranslations"
      :readOnly="readOnly"
      :field="translationsField"
      :type="translationsType"
      :visible="showTranslations" 
    />
  </ui-card>
</template>

<script>
import { mapState } from 'vuex'
import { 
  MixinError,
  MixinTranslation 
} from '@sayl/admin-common'

export default {
  name: '',

  mixins: [
    MixinError,
    MixinTranslation,
  ],

  props: {
    errors: {
      type: Object,
    },

    readOnly: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    ...mapState({
      edition: state => state.loyalty.loyalty.edition,
      origin: state => state.loyalty.loyalty.origin,
    }),
  }
}
</script>
