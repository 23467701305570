<template>
  <article
    v-if="hasChildren" 
    class="n-primary-module-pane__impersonation impersonation">
    <!-- Impersonation::Title -->
    <div class="n-primary-module-pane__description impersonation__title">{{ $t('sayl.other_account')}}</div>

    <!-- Items -->
    <ul class="impersonation__list">

      <!-- Items::Children -->
      <li 
        v-for="(item, index) in children"
        :key="index"
        class="impersonation__item"
        :class="{ '-is-active': item.id === currentCustomerId }">
        <button 
          class="impersonation__link"
          @click="setUser(item)">

          <!-- Icon -->
          <ui-icon 
            class="impersonation__icon"
            glyph="user" />

          <div class="impersonation__group">
            <!-- Label -->
            <span class="impersonation__label">{{ item.companyname }}</span>
            
            <!-- Sub-Label -->
            <span
              class="impersonation__sublabel" 
              v-if="item.id === currentCustomerId">{{ $t('sayl.current_connection') }}</span>
          </div>
        </button>
      </li>

      <!-- Items::Stop -->
      <li 
        v-if="isImpersonating" 
        class="impersonation__item">
        <button 
          class="impersonation__link -danger"
          @click="resetUser">

          <!-- Icon -->
          <ui-icon 
            class="impersonation__icon"
            glyph="bolt" />

          <div class="impersonation__group">
            <!-- Label -->
            <span class="impersonation__label">{{ $t('sayl.impersonation_stop') }}</span>
          </div>
        </button>
      </li>
    </ul>
  
  </article>
</template>

<script>
import { mapState } from 'vuex'
import { MixinImpersonation } from '@sayl/admin-common'

export default {
  name: 'PrimaryNavigationImpersonation',

  mixins: [
    MixinImpersonation
  ],

  computed: {
    ...mapState({
      children: state => state['host'].children,
    }),

    hasChildren(){
      return !this.$basil.isNil(this.children) && this.children.length > 0
    },
  },

  methods: {
    logout() {
      $dl.logout()
    },

    setUser(item){
      window.$dl.host.startImpersonation({ item: {customer_id: item.id} })
        .then((response) => {
          window.location.reload()
        })
    },

    resetUser(){
      window.$dl.host.stopImpersonation({ })
      .then((response) => {
        window.location.reload()
      })
    }
  }
}
</script>
