<template>
  <ui-card 
    :appearance="$pepper.Appearance.SUBTLE" 
    article>
    <template #header>
      <h3 class="ui-card__title">{{ $t('sayl-loyalty.tier_settings_title') }}</h3>
    </template>

    <!-- Tier Activation -->
    <article class="ui-card__article">
      <forms-checkbox
        :appearance="$pepper.Appearance.TOGGLE"
        :hint="$t('sayl-loyalty.allow_tiers_hint')"
        :read-only="readOnly"
        :size="$pepper.Size.S"
        v-model="edition.allowTiers"
      >{{ $t('sayl-loyalty.allow_tiers_label') }}</forms-checkbox>
    </article>

    <!-- Tier Types and stuff -->
    <article class="ui-card__article">
      <forms-choice
        :options="tierTypes"
        :read-only="readOnly"
        required
        v-model="tierType"
      >{{ $t('sayl-loyalty.tiers_settings_type_label') }}</forms-choice>
    </article>
  </ui-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LoyaltyTierSettings',

  props: {
    readOnly: {
      type: Boolean, 
      default: false,
    },
  },

  computed: {
    ...mapState({
      edition: state => state.loyalty.loyalty.edition
    }),

    tierType: {
      get() {
        let ret = this.$basil.get(this.edition, 'tiersSettings.type')

        if(!this.$basil.isNil(ret)) {
          ret = this.tierTypes.find(t => t.value === ret)
        }
        return ret
      },

      set(value) {
        this.edition.tiersSettings.type = value.value
      }
    },

    tierTypes() {
      return [
        {
          label: this.$t(`sayl-loyalty.tier_settings_type_linear_points`),
          description: this.$t(`sayl-loyalty.tier_settings_type_linear_points_description`),
          value: 'linear_points',
        },
        {
          label: this.$t(`sayl-loyalty.tier_settings_type_linear_spent`),
          description: this.$t(`sayl-loyalty.tier_settings_type_linear_spent_description`),
          value: 'linear_spent',
        },
        {
          label: this.$t(`sayl-loyalty.tier_settings_type_linear_orders`),
          description: this.$t(`sayl-loyalty.tier_settings_type_linear_orders_description`),
          value: 'linear_orders',
        },
        {
          label: this.$t(`sayl-loyalty.tier_settings_type_segments`),
          description: this.$t(`sayl-loyalty.tier_settings_type_segments_description`),
          value: 'segments',
        },
      ]
    },
  }
}
</script>