<template>
  <canvas
    ref="canvas"></canvas>
</template>

<script>
import Chart from 'chart.js'

export default {
  name: 'ChartsBar',

  props: {
    datasets: {
      type: Array
    },

    stacked: {
      type: Boolean,
      default: true,
    },

    labels: {
      type: Array
    }
  },

  data(){
    return {
      chart: null
    }
  },
  
  computed: {
    borderColor(){
      return '#D5E0E6'
    },

    options() {
      return {
        cornerRadius: this.stacked ? 4 : 0,
        elements: {
          point: {
            radius: this.stacked ? 4 : 0,
            pointStyle: 'rectRounded'
          }
        },
        legend: {
          align: 'end',
          position: 'top',
          labels: {
            fontSize: 14,
            fontColor: '#6E7A81',
            fontFamily: 'Barlow'
          }
        },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          xAxes:[
            { 
              barThickness: 8,
              gridLines: {
                drawBorder: false,
                color: this.borderColor,
                zeroLineColor: this.borderColor,
              },
              stacked: this.stacked,
              // ticks: {
              //   sampleSize: 2
              // }
            },
          ],
          yAxes:[
            {
              gridLines:{
                drawBorder: false,
                gridLines: '#00ff00',
                zeroLineColor: this.borderColor,
              },
              stacked: this.stacked,
              ticks: {
                align: 'center',
                crossAlign: 'center',
                source: 'data',
                maxTicksLimit: 6,
                callback: function(value, index, values) {
                  return Intl.NumberFormat('en', { notation: 'compact' }).format(value)
                }
              },
              type: 'linear'
            }
          ],
        },
        showLines: false
      }
    },

    value(){
      let ret = {
        labels: this.labels,
        datasets: this.datasets
      }

      ret.datasets.forEach(d => d.barThickness = 8)
      
      return ret
    },
  },

  watch: {
    labels: {
      handler(){
        this.refresh()
      },
      deep: true
    }
  },

  methods: {
    refresh(){
      this.chart.data.labels = this.labels;
      this.chart.data.datasets = this.datasets;
      this.chart.update()
    }
  },

  mounted(){
    let canvas = this.$refs.canvas;
    let ctx = canvas.getContext('2d');
    this.chart = new Chart(ctx, {
      type: 'bar',
      data: this.value,
      options: this.options
    }) 
  }
}
</script>
