<template>
  <button 
    :class="classes"
    :disabled="disabled"
    @click="$emit('click')">

    <div 
      class="v-card-action__figure" 
      v-if="icon">
      <ui-icon
        class="v-card-action__icon"
        :glyph="icon"
      />
    </div>

    <div class="v-card-action__body">
      <h4 class="v-card-action__title">{{ title }}</h4>
      
      <p 
        class="v-card-action__description"
        v-if="description"
      >{{ description }}</p>
    </div>

    <div 
      class="v-card-action__post" 
      v-if="href">
      <ui-icon 
        class="v-card-action__icon"
        glyph="arrow-right"
      />
    </div>
  </button>
</template>


<script>
export default {
  name: 'VCardAction',

  props: {
    description: {
      type: String, 
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false
    },

    href: {
      type: String,
      default: null
    },

    icon: {
      type: String,
    },

    hue: {
      type: String,
      default: 'light',
      validator: (value) => ['light', 'red', 'yellow', 'teal', 'green'].includes(value)
    },

    title: {
      type: String,
      required: true,
    },
  },

  computed: {
    classes() {
      return {
        'v-card-action': true,
        [`-${this.hue}`]: true,
        '-is-disabled': this.disabled
      }
    }
  }
}
</script>
