<template>
  <button 
    class="card-action"
    :class="{ '-is-disabled': disabled }"
    :disabled="disabled"
    @click="action">

    <span class="card-action__first">
      <span 
        class="card-action__title" 
        v-if="title"
      >{{ title }}</span>
      
      <span 
        class="card-action__description"
        v-if="description"
      >{{ description }}</span>

      <span class="card-action__cta">
        <span>{{ cta }}</span>
        <ui-icon glyph="arrow-right" />
      </span>
    </span>

    <figure 
      class="card-action__second"
      v-if="image">
      <img 
        class="card-action__image"
        :src="$getImageSrc(image)" 
        :alt="title">
    </figure>

  </button>
</template>


<script>
export default {
  name: 'UiCardAction',

  props: {
    action: {
      type: Function,
      required: true,
    },

    cta: {
      type: String,
      required: true
    },

    description: {
      type: String, 
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false
    },

    image: {
      type: String,
      default: null,
    },

    title: {
      type: String,
      required: true,
    },
  },
}
</script>
