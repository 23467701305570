<template>
  <ui-card 
    :appearance="$pepper.Appearance.SUBTLE"
    article
    class="view-loyalty-config__row">
    <template v-slot:header>
      <h3 class="ui-card__title">{{ $t('sayl-ecommerce.loyalty_config_pass_image_title') }}</h3>
    </template>

    <div
      class="ui-card__loader" 
      v-if="loading">
      <ui-loader />
    </div>

    <article 
      class="ui-card__article flow" 
      v-if="!loading"
      :key="key">
      <div class="">
        <forms-image-input
          accept="image/png"
          :entity-id="$route.params.program"
          entity-type="loyalty_program"
          :errors="errorsImg['icon']"
          field-name="pass_icon"
          module="loyalty"
          :read-only="readOnly"
          required
          :title="$t('sayl-ecommerce.loyalty_config_pass_icon_image_title')"
          @clear="clearImages"
          @change="onIconChange"
        />
        <div class="view-loyalty-config__hint">{{ $t('sayl-ecommerce.loyalty_config_pass_icon_image_hint') }}</div>
      </div>

      <div class="">
        <forms-image-input
          accept="image/png"
          :entity-id="$route.params.program"
          entity-type="loyalty_program"
          :errors="errorsImg['strip']"
          field-name="pass_strip"
          module="loyalty"
          :read-only="readOnly"
          required
          :title="$t('sayl-ecommerce.loyalty_config_pass_strip_image_title')"
          @clear="clearImages"
          @change="onStripChange"
        />
        <div class="view-loyalty-config__hint">{{ $t('sayl-ecommerce.loyalty_config_pass_strip_image_hint') }}</div>
      </div>

      <div class="">
        <forms-image-input
          accept="image/png"
          :entity-id="$route.params.program"
          entity-type="loyalty_program"
          :errors="errorsImg['logo']"
          field-name="pass_logo"
          module="loyalty"
          :read-only="readOnly"
          required
          :title="$t('sayl-ecommerce.loyalty_config_pass_logo_image_title')"
          @clear="clearImages"
          @change="onLogoChange"
        />
        <div class="view-loyalty-config__hint">{{ $t('sayl-ecommerce.loyalty_config_pass_logo_image_hint') }}</div>
      </div>
    </article>
  </ui-card>
</template>

<script>
import { 
  MixinError,
  MixinImage
} from '@sayl/admin-common'

export default {
  name: 'LoyaltyPassConfigImages',

  mixins: [ 
    MixinError, 
    MixinImage 
  ],

  props: {
    errors: {
      type: Object,
    },

    readOnly: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      errorsImg: {},
      key: 1,

      loading: true,
      iconPreview: null,
      logoPreview: null,
      stripPreview: null,
    }
  },

  computed: {
    rules() {
      return {
        icon: {
          width: 29,
          height: 29,
        },

        logo: {
          width: 160,
          height: 50,
        },

        strip: {
          width: 375,
          height: 123
        }
      }
    }
  },
  
  methods: {
    tobase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      })
    },

    onIconChange(payload) {
      this.tobase64(payload.values[0].src) 
        .then((url) => {
          let base = this;
          var _URL = window.URL || window.webkitURL;

          let img = new Image();
          img.onload = function() {
            if(base.rules.icon.width === this.width || base.rules.icon.height === this.height) {
              payload.preview = url;
              base.errorsImg['icon'] = [];
              base.$emit('icon', payload);
            } else {
              base.errorsImg['icon'] = [base.$t('sayl-ecommerce.image_size_error', {width: base.rules.icon.width, height: base.rules.icon.height})]
              base.key++;
            }
          };
          img.src = _URL.createObjectURL(payload.values[0].src);
        })
        .catch((e) => $console.error(e))
    },

    onLogoChange(payload) {
      this.tobase64(payload.values[0].src) 
        .then((url) => {
          let base = this;
          var _URL = window.URL || window.webkitURL;

          let img = new Image();
          img.onload = function() {
            if(base.rules.logo.width === this.width || base.rules.logo.height === this.height) {
              payload.preview = url;
              base.errorsImg['logo'] = [];
              base.$emit('logo', payload);
            } else {
              base.errorsImg['logo'] = [base.$t('sayl-ecommerce.image_size_error', { width: base.rules.logo.width, height: base.rules.logo.height })]
              base.key++;
            }
          };
          img.src = _URL.createObjectURL(payload.values[0].src);
          
        })
        .catch((e) => $console.error(e))
    },

    onStripChange(payload) {
      this.tobase64(payload.values[0].src) 
        .then((url) => {
          let base = this;
          var _URL = window.URL || window.webkitURL;

          let img = new Image();
          img.onload = function() {
            if(base.rules.strip.width === this.width || base.rules.strip.height === this.height) {
              payload.preview = url;
              base.errorsImg['strip'] = [];
              base.$emit('strip', payload);
            } else {
              base.errorsImg['strip'] = [base.$t('sayl-ecommerce.image_size_error', { width: base.rules.strip.width, height: base.rules.strip.height })]
              base.key++;
            }
          };
          img.src = _URL.createObjectURL(payload.values[0].src);
        })
        .catch((e) => $console.error(e))
    },

    reset() {
      this.loading = true;
      this.$emit('loading', this.loading)
      
      this.$basil.sequence([
        this.$image.all.bind(this.$image, { data: { entityType: 'loyalty_program', entityId: this.$route.params.program, fieldName: 'pass_icon', module: 'loyalty' }}),
        this.$image.all.bind(this.$image, { data: { entityType: 'loyalty_program', entityId: this.$route.params.program, fieldName: 'pass_logo', module: 'loyalty' }}),
        this.$image.all.bind(this.$image, { data: { entityType: 'loyalty_program', entityId: this.$route.params.program, fieldName: 'pass_strip', module: 'loyalty' }})
      ]) 
        .catch((e) => $console.error(e))
        .finally(() => {
          this.loading = false;
          this.$emit('loading', this.loading)
        })
    }
  },

  mounted() {
    this.reset();
  }
}
</script>

