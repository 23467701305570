const wbase = [
  // Wallet Pass
  {
    component: 'forms-input',
    fqn: 'cards-style-wallet-pass-bg',
    label: 'bg',
    logged: true,
    property: '--slp-cards-wallet-pass-bg',
    section: 'cards',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'cards-style-wallet-pass-bc',
    label: 'bc',
    logged: true,
    property: '--slp-cards-wallet-pass-bc',
    section: 'cards',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'cards-style-wallet-pass-filigrane-bg',
    label: 'filigrane_bg',
    logged: true,
    property: '--slp-cards-wallet-pass-filigrane-bg',
    section: 'cards',
    subtype: 'color',
    type: 'style',
  },
]

const wtitle = [
  // Wallet Pass title
  {
    component: 'forms-input',
    fqn: 'cards-content-wallet-pass-title',
    label: 'title',
    logged: true,
    property: 'wallet-pass-title',
    section: 'cards',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'cards-style-wallet-pass-title-fc',
    label: 'fc',
    logged: true,
    property: '--slp-cards-wallet-pass-title-fc',
    section: 'cards',
    subtype: 'color',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'cards-style-wallet-pass-title-fs',
    label: 'fs',
    logged: true,
    options: 'fontSizes',
    property: '--slp-cards-wallet-pass-title-fs',
    section: 'cards',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'cards-style-wallet-pass-title-fw',
    label: 'fw',
    logged: true,
    options: 'fontWeights',
    property: '--slp-cards-wallet-pass-title-fw',
    section: 'cards',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const base = [
  {
    component: 'forms-input',
    fqn: 'cards-card-bg',
    label: 'bg',
    logged: true,
    property: '--slp-cards-card-bg',
    section: 'cards',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'cards-card-bc',
    label: 'bc',
    logged: true,
    property: '--slp-cards-card-bc',
    section: 'cards',
    subtype: 'color',
    type: 'style',
  },
]

const header = [
  {
    component: 'forms-input',
    fqn: 'cards-card-header-primary-bg',
    label: 'primary_bg',
    logged: true,
    property: '--slp-cards-card-header-primary-bg',
    section: 'cards',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'cards-card-header-secondary-bg',
    label: 'secondary_bg',
    logged: true,
    property: '--slp-cards-card-header-secondary-bg',
    section: 'cards',
    subtype: 'color',
    type: 'style',
  },
]

const title = [
  {
    component: 'forms-input',
    fqn: 'cards-content-digital-label',
    label: 'digital_label',
    logged: true,
    property: 'card-digital',
    section: 'cards',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'cards-content-physical-label',
    label: 'physical_label',
    logged: true,
    property: 'card-physical',
    section: 'cards',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'cards-style-card-title-fc',
    label: 'fc',
    logged: true,
    property: '--slp-cards-card-title-fc',
    section: 'cards',
    subtype: 'color',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'cards-style-card-title-fs',
    label: 'fs',
    logged: true,
    options: 'fontSizes',
    property: '--slp-cards-card-title-fs',
    section: 'cards',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'cards-style-card-title-fw',
    label: 'fw',
    logged: true,
    options: 'fontWeights',
    property: '--slp-cards-card-title-fw',
    section: 'cards',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const descr = [
  {
    component: 'forms-input',
    fqn: 'cards-style-card-descr-fc',
    label: 'fc',
    logged: true,
    property: '--slp-cards-card-descr-fc',
    section: 'cards',
    subtype: 'color',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'cards-style-card-descr-fs',
    label: 'fs',
    logged: true,
    options: 'fontSizes',
    property: '--slp-cards-card-descr-fs',
    section: 'cards',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'cards-style-card-descr-fw',
    label: 'fw',
    logged: true,
    options: 'fontWeights',
    property: '--slp-cards-card-descr-fw',
    section: 'cards',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const abase = [
  {
    component: 'forms-input',
    fqn: 'cards-style-card-add-bg',
    label: 'bg',
    logged: true,
    property: '--slp-cards-card-add-bg',
    section: 'cards',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'cards-style-card-add-bc',
    label: 'bc',
    logged: true,
    property: '--slp-cards-card-add-bc',
    section: 'cards',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'cards-style-card-add-bg-opacity',
    label: 'bg_opacity',
    logged: true,
    max: 100,
    min: 0,
    property: '--slp-cards-card-add-bg-opacity',
    section: 'cards',
    subtype: 'number',
    suffix: '%',
    type: 'style',
  },
]

const aicon = [
  {
    component: 'forms-input',
    fqn: 'cards-style-card-add-icon-bg',
    label: 'bg',
    logged: true,
    property: '--slp-cards-card-add-icon-bg',
    section: 'cards',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'cards-style-card-add-icon-fc',
    label: 'fc',
    logged: true,
    property: '--slp-cards-card-add-icon-fc',
    section: 'cards',
    subtype: 'color',
    type: 'style',
  },
]

const atitle = [
  {
    component: 'forms-input',
    fqn: 'cards-content-card-add-title',
    label: 'title',
    logged: true,
    property: 'card-add-title',
    section: 'cards',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'cards-style-card-add-title-fc',
    label: 'fc',
    logged: true,
    property: '--slp-cards-card-add-title-fc',
    section: 'cards',
    subtype: 'color',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'cards-style-card-add-title-fs',
    label: 'fs',
    logged: true,
    options: 'fontSizes',
    property: '--slp-cards-card-add-title-fs',
    section: 'cards',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'cards-style-card-add-title-fw',
    label: 'fw',
    logged: true,
    options: 'fontWeights',
    property: '--slp-cards-card-add-title-fw',
    section: 'cards',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const ainput = [
  {
    component: 'forms-input',
    fqn: 'cards-content-card-add-placeholder',
    label: 'title',
    logged: true,
    property: 'card-add-placeholder',
    section: 'cards',
    type: 'content',
  },
]

const aactions = [
  {
    component: 'forms-input',
    fqn: 'cards-content-card-add-btn-confirm',
    label: 'btn_confirm',
    logged: true,
    property: 'card-add-btn-confirm',
    section: 'cards',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'cards-content-card-add-btn-cancel',
    label: 'btn_cancel',
    logged: true,
    property: 'card-add-btn-cancel',
    section: 'cards',
    type: 'content',
  },
]

export default [
  ...wbase,
  ...wtitle,

  ...base,
  ...header,
  ...title,
  ...descr,

  ...abase,
  ...aicon,
  ...atitle,
  ...ainput,
  ...aactions,
]
