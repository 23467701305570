<template>
  <div :class="classes">
    <div 
      class="data-stat__header" 
      v-if="hasHeader">
      <slot name="header">
        <div 
          class="data-stat__icon"
          v-if="icon">
          <ui-icon :glyph="icon" />
        </div>
        
        <div 
          class="data-stat__icon-label" 
          :title="iconLabel"
          v-if="iconLabel"
        >{{ iconLabel }}</div>
      </slot>
    </div>

    <div class="data-stat__body">
      <div class="data-stat__output">{{ value }}</div>
      <div class="data-stat__label">{{ label }}</div>
    </div>

    <div 
      class="data-stat__footer" 
      v-if="!$basil.isNil(meta) && !metaIsArray"
    >{{ meta }}</div>

    <div 
      class="data-stat__footer" 
      v-if="!$basil.isNil(meta) && metaIsArray">
      <span 
        :key="`meta_${i}`"
        v-for="(m, i) in meta"
      >{{ m }}<br v-if="i < meta.length - 1"/></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataStat',

  props: {
    icon: {
      type: String,
    },

    iconLabel: {
      type: String,
    },

    intent: {
      type: String,
      default: 'default'
    },

    label: {
      type: String,
    },

    meta: {
      type: String | Array,
      default: null
    },

    trim: {
      type: Boolean,
      default: false
    },

    value: {}
  },

  computed: {
    classes() {
      return {
        'data-stat': true,
        '-is-warning': this.intent === 'warning',
        '-is-danger': this.intent === 'danger',
        '-trim': this.trim,
      }
    },

    hasHeader() {
      return !!this.$slots.header || (this.icon != null || this.iconLabel != null)
    },

    metaIsArray() {
      return this.$basil.isArray(this.meta)
    }
  }
}
</script>
