import { basil } from '@spices/basil'

import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../data/store'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.meta.scrollTop){
      return {
        behavior: 'smooth',
        x: 0,
        y: 0
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  store.commit('sayl/setBurger', false)

  if (to.name.indexOf('sayl') === 0){
    if (!to.meta){
      console.warn('resource and privilege must be set on the route', to)
      return next();
    }

    let p = to.meta.privilege || 'view'
    let r = to.meta.resource

    if (!r){
      return next()
    }
    
    let isAllowed = basil.sayl.isAllowed({
      role: basil.sayl.role,
      resource: r,
      privilege: p
    })

    if (!isAllowed){
      next({ name: '403', params: [to.path], replace: true})
    }
    else{
      next()
    }
  }
  else{
    next()
  }
})

export default router
