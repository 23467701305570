<template>
  <div 
    :class="classes" 
    :key="`nft-card__${key}`"
    @click="$emit('click', value)">

    <div 
      class="nft-card__selected" 
      v-if="isSelected">
      <ui-icon glyph="check" />
    </div>

    <div class="nft-card__figures" v-if="!$basil.isNil(image)">
      <img 
        :alt="$basil.get(value, 'name.all')" 
        class="nft-card__image"
        :src="image" 
        v-if="imageType === 'image'"
      />
      <video  
        autoplay 
        class="nft-card__video" 
        loop 
        muted
        :src="image"
        v-if="imageType === 'video'" 
      />
    </div>

    <div class="nft-card__label">
      <p>{{ $basil.get(value, 'name.all') }}</p>
    </div>

    <div 
      class="nft-card__description"
      v-if="hasDescription && !$basil.isNil($basil.get(value, 'description.all'))">
      <p v-html="$basil.get(value, 'description.all')"></p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NftCardChoice',

  props: {
    fillImage: {
      type: Boolean,
      default: false,
    },

    hasBorder: {
      type: Boolean,
      default: true
    },

    hasDescription: {
      type: Boolean,
      default: false,
    },

    hasSelection: {
      type: Boolean,
      default: true,
    },

    isSelected: {
      type: Boolean,
      default: false,
    },

    value: {
      type: Object, 
      required: true,
    },
  },

  data() {
    return {
      key: 1
    }
  },

  computed: {
    classes() {
      return {
        'nft-card': true,

        '-full': this.fillImage,
        '-has-selection': this.hasSelection,
        '-no-border': !this.hasBorder,
      }
    },

    image() {
      let ret = this.$basil.get(this.value, 'optimizedImageUrl')
      if(this.$basil.isNil(ret)) {
        ret = this.$basil.get(this.value, 'assetUrl')
      }

      return ret
    },

    imageType() {
      let type = this.$basil.get(this.value, 'imageType')
      
      if(!this.$basil.isNil(type) && type.includes('video')) {
        return this.key && 'video'
      }

      return this.key && 'image'
    },
  },

  watch: {
    'value': {
      deep: true,
      handler() {
        this.key++
      }
    },
  }
}
</script>
