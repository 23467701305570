<template>
  <article class="layout-modal__article">
    <forms-input
      :hint="$t('sayl-audiences.challenge_reward_type_loyalty_credits_amount_hint')"
      min="1"
      :placeholder="$t('sayl-audiences.challenge_reward_type_loyalty_credits_amount_placeholder')"
      required
      step="1"
      type="number"
      v-model="value.settings.credits_amount"
    >{{ $t('sayl-audiences.challenge_reward_type_loyalty_credits_amount_label') }}</forms-input>
  </article>
</template>

<script>
export default {
  name: 'ChallengeTypeLoyalty',

  props: {
    value: {}
  }
}
</script>
