<template>
  <article class="ui-card__article">
    <forms-input
      :errors="getErrors('amount')"
      input-type="number"
      :placeholder="$t('sayl.placeholder')"
      :read-only="readOnly"
      required
      type="number"
      @input="onRemoveError('amount')"
      v-model.number="edition.amount">
      <template>{{ $t('sayl.tier_amount_spent_label') }}</template>
      <template #suffix>{{ currency }}</template>
    </forms-input>
  </article>
</template>

<script>
import { mapState } from 'vuex'

import { MixinError } from '@sayl/admin-common'

export default {
  name: 'TierEditionTypeSpent',

  mixins: [
    MixinError,
  ],

  props: {
    errors: {},

    readOnly: {
      type: Boolean,
      default: false,
    },
  },


  computed: {
    ...mapState({
      edition: state => state.tier.tier.edition,
    }),

    currency() {
      let ret = this.$basil.i18n.currency(0)

      ret = ret.replace('0.00', '')
      return ret
    }
  },
}
</script>