<template>
  <layout-base class="layout-level2">
    <!-- Header -->
    <nav-header v-bind="header">
      <template slot="extra">
        <slot name="header"></slot>  
      </template>  

      <template v-slot:filters>
        <slot name="filters"></slot>
      </template>

      <template v-slot:meta>
        <slot name="meta"></slot>
      </template>
    </nav-header>

    <ui-feature>
      <ui-acl>
        <ui-errors 
          :errors="errors"
          @back="$emit('back')">
          <!-- Body -->
          <div class="layout-level2__body">
            <!-- Inheritance parent -> [child] -->
            <notice-banner 
              class="layout-level2__banner"
              :intent="$pepper.Intent.WARNING"
              v-if="inheritedFromParent">
              <template v-slot:title>
                <div class="row">
                  {{ $t('sayl.inheritance_parent_title') }}

                  <actions-button
                    :appearance="$pepper.Appearance.DEFAULT"
                    icon-post="open"
                    :size="$pepper.Size.S"
                    @click="onGoToMaster"
                    v-if="canGoToMaster"
                  >{{ $t('sayl.inheritance_go_to_master') }}</actions-button>
                </div>
              </template>

              <p>{{ $t('sayl.inheritance_parent_description') }}</p>
            </notice-banner>

            <!-- Inheritance [parent] -> child -->
            <notice-banner
              class="layout-level2__banner"
              v-if="inheritedFromChild">
              <template v-slot:title>
                <div class="row">
                  {{ $t('sayl.inheritance_child_title') }}

                  <actions-button
                    :appearance="$pepper.Appearance.DEFAULT"
                    icon-post="open"
                    :size="$pepper.Size.S"
                    @click="() => onGoToChild(customerEntity)"
                    v-if="canGoToChild"
                  >{{ $t('sayl.inheritance_go_to_child') }}</actions-button>
                </div>
              </template>

              <p>{{ $t('sayl.inheritance_child_description') }}</p>
            </notice-banner>
            
            <div 
              class="layout-level2__loader" 
              v-if="isLoading">
              <ui-loader />
            </div>

            <!-- Data -->
            <slot v-else></slot>

            <forms-save 
              @save="save"
              v-if="!pristine && !inheritedFromParent"
            />
          </div>

          <!-- Extra actions -->
          <popins-list-dropdown
            v-if="false"
            :target="$refs.extra"
            position="bottom-center"
            :visible="showExtra"
            :layout="['label']"
            :options="extraActions"
          />
        </ui-errors>
      </ui-acl>
    </ui-feature>
  </layout-base>
</template>

<script>
import { 
  MixinHeader, 
  MixinImpersonation,
  MixinInheritance,
} from '@sayl/admin-common'

import NavHeader from '../components/navigations/header'

export default {
  name: "LayoutLevel2",

  components: {
    NavHeader
  },

  mixins: [
    MixinHeader,
    MixinImpersonation,
    MixinInheritance,
  ],

  props: {
    errors: {
      type: Object,
      default:() => { 
        return {} 
      }
    },
  },

  computed: {
    classes() {
      return Object.assign(
        {
          'layout-level2': true,
          'layout-level2-sided': true
        },
        this.$attrs.class
      )
    },
  },
}
</script>
