<template>
  <div class="forms-wysiwyg forms-control">
    <div 
      class="forms-control__header" 
      v-if="hasDefault">
      <forms-label class="forms-control__label">
        <slot></slot>
      </forms-label>
    </div>

    <div :class="classes">
      <div :id="id"></div> 

      <!-- Post -->
      <component
        v-if="iconPost"
        class="forms-wysiwyg__post"
        :class="{'-is-interactive': iconPostInteractive}"
        :disabled="iconPostDisabled"
        :is="iconPostInteractive ? 'button' : 'div'"
        v-on="iconPostListerners">
        <ui-icon
          class="forms-wysiwyg__icon"
          :glyph="iconPost"
        ></ui-icon>
      </component>
    </div>

    <div class="forms-control__footer">
      <div 
        class="forms-control__hint" 
        v-html="hint"
        v-if="hint"
      ></div>

      <div 
        class="forms-wysiwyg__errors" 
        v-if="hasErrors">
        <forms-label
          :key="`errors_${i}`"
          v-for="(e, i) in errors"
          :intent="$pepper.Intent.DANGER"
        >{{ e }}</forms-label>
      </div>
    </div>

    <div v-if="showModalImage">
      <modal-image-explorer
        :entityType="entityType"
        :fieldName="fieldName"
        fill-image
        :has-border="false"
        :isMultiple="true"
        :module="module"
        :title="$t('sayl.wysiwyg_image_explorer_title')"
        :visible="showModalImage"
        @close="onClose"
        @confirm="onConfirm"
      />
    </div>

  </div>
</template>

<script>
export default {
  name: 'FormsWysiwyg',

  model: {
    event: 'change',
    prop: 'value'
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    errors: {
      type: Array,
      default: () => []
    },

    entityType: {
      type: String
    },

    iconPost: {
      type: String
    },

    iconPostDisabled: {
      type: Boolean,
      default: false
    },

    iconPostInteractive: {
      type: Boolean,
      default: false
    },

    fieldName: {
      type: String
    },

    module: { 
      type: String
    },

    hint: {
      type: String,
    },

    placeholder: {
      type: String,
    },

    readOnly: {
      type: Boolean, 
      default: false
    },

    required: {
      type: Boolean,
      default:false,
    },

    rows: {
      type: Number,
      default: 5
    },

    toolbar: {
      type: Array,
      default: null
    },

    value: {
      type: String | null,
      required: true
    }
  },

  data() {
    return {
      key: 1,
      showModalImage: false,
    }
  },

  computed: {
    cErrors() {
      return this.iErrors.length > 0 || this.errors.length > 0 ? this.iErrors.concat(this.errors) : []
    },

    classes() {
      return {
        'forms-wysiwyg__body': true,
        
        '-is-disabled': this.disabled,
        '-is-errored': this.hasErrors, 
        '-is-readonly': this.readOnly,
        '-is-required': this.required,
      }
    },

    hasErrors() {
      return this.$basil.get(this.errors, 'length', 0) > 0
    },

    iconPostListerners(){
      return this.iconPostInteractive ? {'click': (event) => this.onIconClick(event, 'post') } : {}
    },

    id() {
      return this.$basil.uniqId('editor_')
    },

    isValidImage() {
      return !this.$basil.isNil(this.fieldName) && 
              !this.$basil.isNil(this.entityType) &&
              !this.$basil.isNil(this.module)
    },

    iHistory() {
      return {
        delay: 2000,
        maxStack: 500,
        userOnly: true
      }
    },

    iToolbar() {
      return this.$basil.isNil(this.toolbar) ? [
         // text style
        ["bold","italic","underline","strike"],

        // header text 
        [{header:[1,2,3,4,5,6,false]}],

        // bullet points style
        [{list:"ordered"},{list:"bullet"}],

         // sub & supper script
        [{script:"sub"},{script:"super"}],

         // indentation
        [{indent:"+1"},{indent:"-1"}],

        // alignment
        [{align:[]}],

        // text size
        [{size:["small","large","huge",false]}],

        // adding image,link,video or formula 
        [this.isValidImage ? 'image' : null, 'link', 'video'].filter(s=>!this.$basil.isNil(s)),

        //adding text color  and background
        [{color:[]},{background:[]}],

        //adding font family
        // [{font:[]}],

        //adding code snipet
        ["code-block","blockquote"],

        //history
        // [{stack:[ {'redo': 1}, {'undo': 2}] }],   

        // clean current formating 
        ['clean'] 

        // direction incase for arrabic writting 
        [{'direction':'rt1'}]

      ] : this.toolbar
    },

    hasDefault() {
      return !this.$refs.default
    },
  },

  methods: {
    onIconClick(event, position){
      this.$emit('iconClick', event, position);
    },

    imageHandler() {
      this.showModalImage = true
    },

    onConfirm(images) {
      images = Array.isArray(images)?images:[images]
      
      let contents = this.editor.getContents()
      images.forEach((image) => contents.push({ insert: { image: this.$basil.get(image,'_image.all.src') } }))
      this.editor.setContents(contents)

      this.showModalImage = false
    },

    onClose() {
      this.showModalImage =false
    },

    reset() {
      this.iErrors = []
      
      this.editor = new Quill(`#${this.id}`, {
        class: 'forms-control__field',

        modules: {
          history:this.iHistory,
          toolbar: {
            container: this.iToolbar,
            handlers: {
              image: !this.readOnly ? this.imageHandler : () => {}
            }
          }
        },

        readOnly: this.readOnly,
        placeholder: !this.readOnly ? this.placeholder : null,
        theme: 'snow',
      })

      this.editor.on('editor-change', () => {
        this.$emit('change', this.editor.root.innerHTML, this.editor.getContents())
        this.$emit('input', this.editor.root.innerHTML, this.editor.getContents())
      })

      let minHeight = 12.5 * 2 + (this.rows) * 18.5
      let maxHeight  = (this.rows * 3) * 18.5

      this.editor.root.style.maxHeight = `${maxHeight}px`
      this.editor.root.style.minHeight = `${minHeight}px`

      if(!this.$basil.isNil(this.value)) {
        this.editor.root.innerHTML = this.value
      }

      return this.editor
    },
  },

  mounted() {
    !this.isValidImage && console.warn(`'fieldName', 'entityType','module' are required props`)
    this.reset()
  },

  beforeDestroy() {
    this.editor && this.editor.off('editor-change')
  }
}
</script>
