import { basil } from '@spices/basil'
import { SaylController } from '@spices/basil-sayl'

/**
 * Loyalty controller
 * 
 * for @sayl
 * @class
 */
export default class LoyaltyController extends SaylController {
  /**
   * @constructor
   * @param {Object} options
   * @param {Object} options.curry - Curry Controller 
   * @param {Object} options.store
   */
  constructor({ curry, fqn = 'loyalty', module = 'loyalty', store }) {
    super({ curry, fqn, module, store })

    let config = this.store.getters['sayl/config']
    this.curry._api._endpoints.page[0]._route = config.loyalty.page.url + 'default.json'
  }

  ////////////////////////////////////////
  /**
   * @param {Object} options 
   * @param {number} options.page 
   * @param {number} options.limit
   * @param {Number} options.from
   * @param {Number} options.to
   * @param {Array} options.events
   * @param {String} options.customerId
   * 
   * @return {Promise}
   */
  getHistory({page = 1, limit = 15, from, to, events, customerId }) {
    return new Promise((resolve, reject) => {
      this.store.commit(`${this.module}/loading`, { name: this.fqn + '.history', value: true })
      
      this.curry.getHistory({ page, limit, from, to, events, customerId })
        .then(({ collection, meta }) => {
          this.store.commit(`${this.module}/collection`, { name: this.fqn + '.history', values: collection, meta });
          resolve(collection);
        })
        .catch((e) => reject(e))
        .finally(() => this.store.commit(`${this.module}/loading`, { name: this.fqn + '.history', value: false }))
      })
  }

  /**
   * @param {Object} options 
   * @param {String} options.id
   * @param {Number} options.from
   * @param {Number} options.to
   * @param {Array} options.events
   * @param {String} options.customerId
   * 
   * @return {Promise}
   */
  getKpis({ id, from, to, events, customerId }) {
    return new Promise((resolve, reject) => {
      this.store.commit(`${this.module}/loading`, { name: this.fqn + '.kpis', value: true })
      
      this.curry.getKpis({ id, from, to, events, customerId })
        .then((value) => {
          this.store.commit(`${this.module}/kpis`, { name: this.fqn + '.kpis', value });
          resolve(value);
        })
        .catch((e) => reject(e))
        .finally(() => this.store.commit(`${this.module}/loading`, { name: this.fqn + '.kpis', value: false }))
    })
  }

  /**
   * @param {Object} options 
   * @param {String} options.id
   * @param {Number} options.from
   * @param {Number} options.to
   * @param {Array} options.events
   * @param {Number} options.customerId
   * @param {String} options.kpis
   * 
   * @return {Promise}
   */
  getTrendedKpis({ id, from, to, events, customerId, kpis }) {
    return new Promise((resolve, reject) => {
      this.curry.getKpis({ id, from, to, events, customerId })
        .then((value) => {
          let ttot = Math.abs(basil.get(value, 'redemption_aggregate[0].total', 0))
          let tot = Math.abs(basil.get(kpis, 'redemption_aggregate[0].total', 0))

          let totalTrend = 0
          let percent = (100 / ttot)
          let diff = (tot - ttot)

          if((!basil.isNil(ttot) && ttot !== 0)) {
            totalTrend = percent * diff
          } else if(!basil.isNil(tot) && tot !== 0) {
            totalTrend = 100
          } 

          kpis.trended = {
            percent: totalTrend,
            amount: !basil.isNil(ttot) && ttot > 0 ? tot - ttot : tot
          }
          
          this.store.commit(`${this.module}/kpis`, { name: this.fqn + '.kpis', value: kpis });
          resolve(value);
        })
        .catch((e) => reject(e))
        .finally(() => this.store.commit(`${this.module}/loading`, { name: this.fqn + '.kpis', value: false }))
    })
  }

  /**
   * @param {Object} options 
   * 
   * @return {Promise}
   */
  getShops({ }) {
    return new Promise((resolve, reject) => {
      this.store.commit(`${this.module}/loading`, { name: this.fqn + '.shops', value: true })

      this.curry.getShops({ })
        .then((collection) => {
          this.store.commit(`${this.module}/all`, { name: this.fqn + '.shops', values: collection });
          resolve(collection);
        })
        .catch((e) => reject(e))
        .finally(() => this.store.commit(`${this.module}/loading`, { name: this.fqn + '.shops', value: false }))
      })
  }

  /**
   * @param {Object} edition 
   * @param {Object} t Translator for the page
   * 
   * @return {Promise}
   */
  resetPage(edition, t = null) {
    return new Promise((resolve, reject) => {
      this.curry.reset(edition, t)
        .then((edition) => {
          this.store.commit(`${this.module}/edition`, { name: this.fqn, value: edition })
          resolve(edition)
        })
        .catch((e) => reject(e))
    })
  }
}
