<template>
    <!-- :provider="provider" -->
  <forms-autocomplete 
    class="forms-products"
    :case-sensitive="caseSensitive"
    :disabled="disabled"
    :loading="loading || iLoading"
    :auto-hide="false"
    :placeholder="iPlaceholder"
    :provider="provider"
    :read-only="readOnly"
    ref="field"
    size="s"
    :threshold="1"
    @selected="onSelect">
    <template v-slot:result="{value}">
      <div class="row">
        <div class="group">
          <data-status 
            class="data-table-cell-group__inherit" 
            :class="{ 'invisible': !value.inherited }"
            icon="parent" 
            :intent="$pepper.Intent.WARNING"
            :title="$t('sayl.impersonation_inherited')"
          />

          <div>{{ value.label }}</div>
          <code v-if="value.is === 'product'">{{ value.type === 'bundle' ? $basil.get(value, 'sku', null) : $basil.get(value, 'variants.0.sku', null) }}</code>
          
          <data-tag 
            v-if="value.type === 'bundle'"
            size="s"
          >bundle</data-tag>

          <data-tag 
            v-if="value.includedInBundle && value.is=='product'"
            size="s"
          >{{ $t('sayl-ecommerce.product_included_in_bundle')}}</data-tag>
          
          <data-tag 
            size="s" 
            v-if="value.is === 'tag'"
            >{{ $t('sayl.tag') }}</data-tag>
        </div>
        <data-badge
          v-if="value.is === 'product'" 
          :value="getProductPrice(value)"
        />
      </div>
    </template>

    <template v-slot:suffix>
      <data-tag 
        class="forms-products__type" 
        size="s" 
        v-if="hasType && showType"
      >{{ typeValue }}</data-tag>
    </template>
  </forms-autocomplete>
</template>

<script>
import { mapState } from 'vuex'

import {
  MixinImpersonation
} from '@sayl/admin-common'

export default {
  name: 'FormsProduct',

  props: {
    bundles: {
      type: Boolean, 
      default: false,
    },

    caseSensitive: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    limit: {
      type: Number,
      default: 50,
    },

    list: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false
    },

    parent: {
      type: Boolean,
      default: true
    
    },

    placeholder: {
      type: String,
      default: null,
    },

    property: {
      type: String,
      default: 'id'
    },

    readOnly: {
      type: Boolean,
      default: false
    },

    return: {
      type: String,
      default: 'products',
      validator: (value) => ['products', 'tags', 'both'].includes(value)
    },

    showType: { 
      type: Boolean,
      default: true,
    },

    type: {
      type: String,
      default: 'none',
      validator: (value) => ['products', 'tags', 'none'].includes(value)
    },
  },

  mixins: [
    MixinImpersonation,
  ],

  data() {
    return {
      key: 1,
      iLoading: false,
      search: null,
      debounce: null,
      iValues: [],
    }
  },

  computed: {
    ...mapState({
      products: state => state['ecommerce'].product.all,
      tags: state =>state.configuration.tag.entity.all,
    }),

    hasType() {
      return this.type !== 'none'
    },

    iPlaceholder() {
      return this.$basil.isNil(this.placeholder) ? this.$t('sayl.search_product_or_tags') : this.placeholder
    },

    typeValue() {
      return this.$t(`sayl-ecommerce.type_autocomplete_${this.type}`)
    },
  },

  methods: {
    getProductPrice(product) {
      let type = this.$basil.get(product, 'type', 'default');
      let ret = 0;
      
      if(type !== 'default') {
        ret = this.$basil.get(product, 'unitPrice', 0)
      } else {
        ret = this.$basil.get(product, 'variants.0.unitPrice', 0)
      }

      return this.$basil.i18n.currency(ret)
    },

    focus(){
      !this.disabled && this.$refs.field.focus();
    },

    formatProduct(p) {
      return Object.assign(p, { label: this.$basil.get(p, `title.all`, null), is: 'product', inherited: !this.isCurrentCustomer(p.customerId)})
    },

    formatTag(t) {
      return Object.assign(t, { label: this.$basil.get(t, `title`, null), is: 'tag'})
    },

    provider(term) {
      return new Promise((resolve, reject) => {
        let products = [];
        let tags = [];
        this.search = term;
        

        let args = {
          sortField: 'modified',
          sortOrder: 'DESC',
          visibilitySetting: this.parent ? 1 : 3
        }

        if(this.bundles === false) {
          args.type = 'default'
        }

        if(!this.$basil.isNil(this.search) && !this.$basil.isEmpty(this.search)) {
          args.sortField = 'title.all'
          args.search = this.search
        }

        clearTimeout(this.debounce)
        this.debounce = setTimeout(() => {
          this.$configuration.tag.findByEntity({ args: { entity: 'product' } })
            .then(() => {
              tags = this.tags.filter(t =>  this.$basil.isNil(this.list.find(l => l.id === t.id)))
              if(!this.$basil.isNil(args.search)) {
                this.iLoading = true
                this.$ecommerce.product.all({ args })
                  .then(() => {
                    products = this.products
                            .filter(p => this.$basil.isNil(this.list.find(l => l.id === p.id)))
                            .splice(0, this.limit)
      
                    tags = tags.filter(t => this.$basil.get(t, 'title', '').toLowerCase().includes(this.search))
                    
                    let ret = []
                    if(this.type !== 'products') {
                      ret = ret.concat(tags.map(t => this.formatTag(t)))
                    }

                    if(this.type !== 'tags') {
                      ret = ret.concat(products.map(p => this.formatProduct(p)))
                    }
                    resolve(ret)
                  })
                  .catch((e) => reject(e))
                  .finally(() => this.iLoading = false)
              } else {
                resolve(this.type !== 'products' ? tags.map(t => this.formatTag(t)) : [])
              }
            })
            .catch((e) => reject(e))
        }, 500)
      });
    },

    onSelect(item) {
      let ret = [];

      if(this.return === 'products') {
        ret = this.onReturnProducts(item)
      } 
      // else if(this.return === 'tags') {
      //   ret = this.onReturnTags(item)
      // } 
      else {
        ret = this.onReturnBoth(item)
      }
      
      this.$emit('selected', ret);
      this.key++;
    },

    /**
     * Only return products IDs
     */
    onReturnProducts(item) {
      return new Promise((resolve, reject) => {
        this.iLoading = true
        let ret = [];
        if(item.is === 'product') { 
          ret.push(Object.assign(item, { matchType: this.type })); 
          this.iLoading = false;
          resolve(ret)
        } else if(item.is === 'tag') { 
          this.$configuration.tag.findForEntities({ args: { id: item.id }})
            .then((data) => {
              data = data.filter(p => this.$basil.isNil(this.list.find(l => l.id === p.id)))
              resolve(data)
            })
            .catch((e) => reject(e))
            .finally(() => this.iLoading = false)
        }
      })
    },

    /**
     * Only return tags IDs
     */
    onReturnTags(item) {
      let ret = [];

      if(item.is === 'tags') { 
        ret.push(Object.assign(item)); 
      }
      else if(item.is === 'products') { 
        ret = this.tags.filter(p => p.tags.find(t => t.id === item.id) != null);
      }

      return ret
    },

    /**
     * Return id of the entity, with entity specify in matchType
     */
    onReturnBoth(item) {
      return Promise.resolve([item])
    },
  },
}
</script>
