<template>
  <section class="view-referral-kpis">
    <data-stat
      v-bind="kpi"
      :key="i"
      v-for="(kpi, i) in dataset" 
    />
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ReferralProgramKPIs',

  props: {
    range: {
      type: Object,
    }
  },

  data() {
    return {
      key: 1,
    }
  },

  computed: {
    ...mapState({
      state: state => state.loyalty.referral,
    }),

    all() {
      return this.key && this.state.all
    },

    program() {
      return this.key && this.$basil.get(this.all, '[0]')
    },

    kpis() {
      return this.key && this.state.kpis.value.kpis
    },

    loading() {
      return this.key && this.state.kpis.loading && !this.$basil.isNil(this.range)
    },

    dataset() {
      let ret = []
      let program = this.$basil.get(this.all, '0', null)

      if(!this.loading && !this.$basil.isNil(program)) {
        ret.push({
          iconLabel: this.$t('sayl-loyalty.referral_kpis_total_orders'),
          value: this.$basil.get(this.kpis, 'total_orders', 0),
          icon: 'bag',
        })
        ret.push({
          iconLabel: this.$t('sayl-loyalty.referral_kpis_total_purchased'),
          value: this.$basil.i18n.currency(this.$basil.get(this.kpis, 'total_purchased', 0)),
          icon: 'briefcase',
        })
        ret.push({
          iconLabel: this.$t('sayl-loyalty.referral_kpis_total_referrals'),
          value: this.$basil.get(this.kpis, 'total_referrals', 0),
          icon: 'users',
        })
      }

      return ret
    }
  },
}
</script>
