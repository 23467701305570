<template>
  <layout-level2-sided 
    v-bind="attrs"
    v-on="listeners">
    <div 
      class="layout-level2-sided__container container flow view-tier__body -cols" 
      v-if="!loading && !hasBlockingErrors">
      <div class="col">
        <ui-card :appearance="$pepper.Appearance.SUBTLE" article>
          <article class="ui-card__article flow">
            <div class="group">
              <forms-input
                :errors="getErrors('name')"
                :placeholder="$t('sayl.placeholder')"
                :read-only="readOnly"
                required
                type="text"
                @input="onRemoveError('name')"
                @change="onNameChange"
                v-model="edition.name"
              >{{ $t('sayl.tier_name_label') }}</forms-input>

              <forms-input
                :errors="getErrors('slug')"
                :placeholder="$t('sayl.placeholder')"
                :read-only="readOnly"
                required
                type="text"
                @input="onRemoveError('slug')"
                v-if="false"
                v-model="edition.slug"
              >{{ $t('sayl.tier_slug_label') }}</forms-input>
            </div>

            <div class="group">
              <forms-input
                :errors="getErrors('priority')"
                :min="0"
                :hint="$t('sayl.tier_priority_hint')"
                :placeholder="$t('sayl.placeholder')"
                :read-only="readOnly"
                required
                type="number"
                @input="onRemoveError('priority')"
                v-model.number="edition.priority"
              >{{ $t('sayl.tier_priority_label') }}</forms-input>

              <forms-checkbox
                :appearance="$pepper.Appearance.TOGGLE"
                :layout="$pepper.Layout.BLOCK"
                :read-only="readOnly"
                v-model="edition.active"
              >{{ $t('sayl.tier_active_label') }}</forms-checkbox>
            </div>

            <forms-textarea
              :class="hasTranslations('description')"
              icon-post="l8n"
              :icon-post-interactive="true"
              :hint="$t('sayl.tier_description_hint')"
              :placeholder="$t('sayl.placeholder')"
              :read-only="readOnly"
              @iconClick="onOpenTranslations('description', 'forms-textarea')"
              @input="onRemoveError('description')"
              v-model="edition.description.all"
            >{{ $t('sayl.tier_description_label') }}</forms-textarea>
          </article>
        </ui-card>

        <tier-edition-rewards 
          :read-only="readOnly"
          :tier="edition"
          v-if="!isNew"
        />
      </div>

      <div class="col">
        <tier-edition-types 
          :errors="errors"
          :read-only="readOnly"
          :settings="settings"
        />
      </div>
    </div>

    <modal-translate 
      @close="onCloseTranslations"
      :readOnly="readOnly"
      :field="translationsField"
      :type="translationsType"
      :visible="showTranslations" 
    />
  </layout-level2-sided>
</template>

<script>
import { mapState } from 'vuex'

import {
  MixinEditionDiscard,
  MixinEditionRemove,
  MixinEditionSave,
  MixinError,
  MixinTranslation,
} from '@sayl/admin-common'

import TierEditionRewards from './rewards/index.vue'
import TierEditionTypes from './types/index.vue'

export default {
  name: 'TierItem',

  components: {
    TierEditionRewards,
    TierEditionTypes,
  },

  mixins: [
    MixinEditionDiscard,
    MixinEditionRemove,
    MixinEditionSave,
    MixinError,
    MixinTranslation,
  ],

  props: {
    breadcrumb: {
      type: Array, 
      default: null
    },

    forceType: {
      type: String,
      default: null
    },

    creationArgs: {
      type: Object, 
      default: () => {}
    },  

    readOnly: {
      type: Boolean,
      default: false
    },
    
    settings: {
      type: Object,
      default: null
    },

    visible: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      errors: {},
      loading: true,
    }
  },

  computed: {
    ...mapState({
      edition: state => state.tier.tier.edition,
      origin: state => state.tier.tier.origin,
    }),

    attrs() {
      return {
        breadcrumb: this.iBreadcrumb,
        classes: {
          'view-tier': true
        },
        errors: this.errors,
        icon: 'crown',
        hasSaveButton: !this.readOnly,
        loading: this.loading,
        pristine: this.pristine,
        tertiary: this.popinsOptions,
        title: this.title,
        visible: true,
      }
    },

    iBreadcrumb() {
      let ret = [{ label: this.title }]

      if(this.breadcrumb != null) {
        ret.unshift(...this.breadcrumb)
      }

      return ret
    },

    isNew() {
      return this.$route.params.tier === 'create'
    },
    
    listeners() {
      return {
        close: this.onDiscard,
        back: this.onDiscard,
        save: this.onConfirm,
      }
    },

    popinsOptions() {
      let ret = []

      if(!this.readOnly && !this.isNew) { 
        ret.push({ 
          label: this.$t('sayl.delete'), 
          event: { click: () => this.remove({}) } 
        })
      }

      return ret
    },

    pristine() {
      return JSON.stringify(this.edition) === JSON.stringify(this.origin)
    },

    title() {
      let ret = this.$t('sayl.loading')

      if(!this.loading) {
        ret = this.isNew ? 
          this.$t('sayl.create_tier_title') : 
          this.$basil.get(this.edition, 'name')
      }

      return ret
    },
  },

  methods: {
    back() {
      this.$emit('back')
    },

    onConfirm() {
      this.onSave({})
        .then((e) => this.$emit('saved', e))
        .catch((e) => this.handleErrors(e))
      },

    onNameChange() {
      if(this.edition && (this.$basil.isNil(this.edition.slug) || this.$basil.isEmpty(this.edition.slug))) {
        this.edition.slug = this.$basil.slugify(this.edition.name)
      }
    },

    remove() {
      this.$emit('remove', this.edition.id)
    },

    reset() {
      this.loading = true

      let baseArgs = this.$basil.get(this.settings, 'type', null) === 'segments' ? 
        { options: { segment_evaluation_frequency: '0 0 * * *' } } : {}

      let prom = this.isNew ? 
        window.$dl.tier.create.bind(window.$dl.tier, { args: Object.assign(baseArgs, this.creationArgs) }) :
        window.$dl.tier.findById.bind(window.$dl.tier, { id: this.$route.params.tier }) 

      prom.apply()
        .catch((e) => this.handleErrors(e))
        .finally(() => this.loading = false)
    },

    save() {
      return window.$dl.tier.save({ item: this.edition, isNew: this.isNew })
    }
  },

  mounted() {
    this.reset()
  }
}
</script>