<template>
  <layout-base>
    <div class="notfound">
      <div class="notfound__inner">
        <header class="notfound__header">
          <img 
            :src="$getImageSrc('/statics/images/logos/sayl/glyph-white.svg')" 
            alt="Sayl Logo">
        </header>

        <section class="notfound__body">
          <h1 class="notfound__title">{{ $t('sayl.error_page_not_found_title') }}</h1> <!-- Page not found -->
          <h2 class="notfound__subtitle">{{ $t('sayl.error_page_not_found_subtitle') }}</h2> <!-- Sorry, but the page you were looking for could not be found. -->
        </section>

        <div class="footer notfound__footer">
          <div 
            class="notfound__description"
            v-html="$t('sayl.error_page_not_found_description')"
          ></div>
          <!-- You can return to our <router-link to="/">front page</router-link>, or <a href="mailto:info@infinity-mobile.io">drop us a line</a> if you can't find what you're looking for. -->
        </div>
      </div>
    </div>
  </layout-base>
</template>

<script>
import LayoutBase from '../layouts/base'

export default {

  components: {
    LayoutBase
  },
}
</script>