<template>
  <layout-base class="layout-level1">
    <!-- Header -->
    <nav-header v-bind="header">
      <template slot="extra">
        <slot name="header"></slot>  
      </template>  

      <template v-slot:filters v-if="!isResourceExcluded">
        <slot name="filters"></slot>
      </template>
    </nav-header>

    <!-- Body -->
    <ui-feature>
      <ui-acl>
        <ui-errors 
          :errors="errors"
          @back="$emit('back')">
          <div class="layout-level1__body">
            <slot></slot>
            
            <forms-save 
              v-if="!pristine"
              @save="save"
            />
          </div>
        </ui-errors>
      </ui-acl>
    </ui-feature>
  </layout-base>
</template>

<script>
import {
  MixinHeader, 
  MixinInheritance,
} from '@sayl/admin-common'

import NavHeader from '../components/navigations/header'

export default {
  name: "LayoutLevel1",
  
  components: {
    NavHeader
  },

  mixins: [
    MixinHeader,
    MixinInheritance,
  ],

  props: {
    errors: {
      type: Object,
      default: () => { return {} }
    },
  },

  computed: {
    hasOutro() {
      return !!this.$slots.header
    },

    isResourceExcluded() {
      let resource = this.$basil.get(this.$route, 'meta.resource')
      return window.$dl.invoice.isResourceExcluded(resource)
    }
  },
}
</script>
