<template>
  <popins-modal
    :closable="closable"
    :klass="classes"
    :visible="visible"
    position="top-center"
    v-on="$listeners">

    <template v-slot:inner>
      <component 
        v-if="currentView" 
        :is="currentView" 
        v-bind="bodyAttrs" 
        @close="close" 
        @changeIntern="onChange"
        @confirm="onConfirm"
        @search="onSearch"
      />
    </template>
  </popins-modal>
</template>

<script>
export default {
  name: "LayoutModalSteps",

  model: {
    prop: "current",
    event: "change"
  },

  props: {
    current: {
      type: Number
    },

    closable: {
      type: Boolean,
      default: false
    },

    value: {
      type: Object,
      default: () => {}
    },

    views: {
      type: Array
    },

    visible: {
      type: Boolean,
      default: false
    },

    resetOnClose: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      aggregate: {},
      search: '',
    }
  },

  computed: {
    bodyAttrs() {
      let ret = Object.assign({
        aggregate: this.aggregate,
        current: this.current,
        close: this.close,
        hasNext: this.hasNext,
        hasPrevious: this.hasPrevious,
        next: this.next,
        previous: this.previous,
        restart: this.restart,
        total: this.total,
        visible: this.visible,
        ...this.$attrs
      }, this.currentStep)

      delete ret.view
      return ret
    },

    classes(){
      let ret = this.$basil.get(this.$attrs, 'classes', {})
      ret = Object.assign(ret, {
        'layout-modal': true,
        'layout-modal-steps': true
      })

      return ret
    },

    currentStep(){
      return this.$basil.get(this, `views[${this.current}]`, null)
    },

    currentView() {
      return this.$basil.get(this, 'currentStep.view', null)
    },

    hasNext() {
      return (this.current + 1) < this.total
    },

    hasPrevious() {
      return this.current > 0
    },

    total() {
      return (this.views && this.views.length) || 0
    }
  },

  watch: {
    value: {
      handler: function(val){
        this.aggregate = val || {};
      },
      deep: true,
      immediate: true,
    }
  },

  methods: {
    close(data) {
      if(this.$listeners.close) {
        this.$listeners.close()
      } else {
        this.merge(data)
        this.search = '';
        this.$emit("closed", this.aggregate);
  
        // this.current = 0;
        if(this.resetOnClose){
          this.aggregate = {}
        }
      }
    },

    merge(data){
      if (!this.$basil.isNil(data)){
        this.aggregate = Object.assign(this.aggregate, data)
        this.$emit('input', this.aggregate)
      }
    },

    next(data) {
      this.merge(data)

      if (this.hasNext) {
        this.$emit("change", this.current + 1, this.aggregate);
      }
      else {
        this.close()
      }
    },

    previous(data) {
      this.merge(data)

      if (this.hasPrevious) {
        this.$emit("change", this.current - 1, this.aggregate);
      }
    },

    onSearch(value) {
      this.search = value;
    },

    onChange(values) {
      this.$emit('changeIntern', values);
    },

    onConfirm(values) {
      this.$emit('confirm', values);
    },
    
    onSearch(value) {
      this.$emit('search', value);
    },

    restart() {
      this.aggregate = {}
      this.$emit("change", 0, this.aggregate);
    }
  },
};
</script>
