import { basil } from '@spices/basil'
import { CurryTierDatalayer } from '@curry/tier'

import { 
  ACLController, 
  CPController,
  ElementsController,
  FieldsController,
  HostController,
  i18nController, 
  InvoiceController,
  OAuthController,
  TierController,
  TierRewardController,
  transports, 
  http 
} from './controllers'

import LoyaltyLayer from './controllers/loyalty/layer'

import DateRanges from './models/ranges'
import Formats from './models/export-formats'
import DaysOfWeek from './models/days-of-week'
import Types from './models/types'
import Blockchains from './models/blockchains'
import SocialMedias from './models/social-media'

/**
 * Sayl Data Layer
 * 
 * @class
 */
class DataLayer {

  /**
   * @constructor
   */
  constructor({ basil, eventbus, router, store, Vue }){
    this._router = router
    this._store = store
    this.__vue__ = Vue.prototype

    this._acl = null // new ACLController({ store, Vue })
    this._cp = new CPController({ store, Vue })
    this._i18n = new i18nController({ basil, eventbus, store, transports, Vue })
    this._invoice = null
    this._host = new HostController({ store, transports, i18n: this._i18n })
    this._oauth = new OAuthController({ store, Vue })
    this._fields = null
    this._elements = null

    this._curryTier = new CurryTierDatalayer({ transports })
    this._tier = new TierController({ curry: this._curryTier.controllers.find(c => c.fqn === 'ifm.tier'), store })
    this._tierReward = new TierRewardController({ curry: this._curryTier.controllers.find(c => c.fqn === 'ifm.tier.reward'), store })

    this._loyaltyLayer = new LoyaltyLayer({ store, transports })

    this._integrations = []
    this._integrationsRefreshed = false
  }

  /////////////////////////////////////////////////////////////////////
  /**
   * Return the list of the available blockchains to work within the 
   * SAYL platform
   * 
   * @property {Object} Blockchains
   * @readonly
   * @returns {Object}
   */
  get blockchains() {
    return Blockchains
  }

  /**
   * @property {Object} bootstrapData The bootstrap data
   */
  get bootstrapData() {
    return this._cp.data
  }

  get fields() {
    return this._fields
  }

  get elements() {
    return this._elements
  }

  /**
   * @property {VueI18n} i18nPlugin The VueI18n plugin instance
   */
  get i18nPlugin() {
    return this._i18n.plugin
  }

  /**
   * @property {i18nController} i18n The i18n controller instance
   */
  get i18n() {
    return this._i18n
  }

  get invoice() {
    return this._invoice
  }

  get loyalty() {
    return this._loyaltyLayer.loyalty
  }
  
  get loyaltyCard() {
    return this._loyaltyLayer.loyaltyCard
  }

  get referral() {
    return this._loyaltyLayer.referral
  }

  get ranges() {
    return DateRanges
  }

  get formats() {
    return Formats
  }

  get daysOfWeek() {
    return DaysOfWeek
  }

  get integrations() {
    return this._integrations
  }

  get socialMedias() {
    return SocialMedias
  }

  get types() {
    return Types
  }

  get host() {
    return this._host
  }

  get tier() {
    return this._tier
  }

  get tierReward() {
    return this._tierReward
  }

  /**
   * @property {Axios} $http The http axios connector 
   */
  get $http() {
    return http
  }

  /////////////////////////////////////////////////////////////////////

  /**
   * Triggers the user authentication process
   */
  authenticate({ hash = null }){
    $console.info('dl.authenticate')

    this._oauth.authenticate({ hash })
      .catch(e => $console.error('dl.authenticate', e)) 
  }

  /**
   * Bootstrap the application
   * 
   * @returns {Promise}
   */
  bootstrap() {
    return new Promise((resolve, reject) => {
      $console.group('dl.bootstrap')

      let hash = null
      
      const params = new URLSearchParams(window.location.search);
      if(params.has('onetime-login-hash')) {
        hash = params.get('onetime-login-hash')
      }

      let locale = params.has('locale') ? params.get('locale') : null

      this._cp.bootstrap({ i18n: this._i18n, locale: locale })
        .then((data) => this.initHost(data))
        .then((data) => {
          if(!basil.isNil(hash)) {
            try {
              const value = JSON.parse(atob(hash))

              if(!value || !value.id) {
                console.error('Invalid login hash')
              }
      
              if(!basil.isNil(data.user) && value.id !== basil.get(data, 'user.profile.id')) {
                this._oauth.logoutWithoutRedirect()
                  .then(() => this.authenticate({ hash: hash }))
                  .then((d) => window.location.replace(d.redirect))
                  .catch((e) => $console.error(e))
              }

            } catch (e) {
              console.error('Invalid login hash', e)
            }
          }

          let invoice = basil.get(data, 'project.invoicing', { active: false })
          let tiers = basil.get(data, 'invoicing.tiers', [])
          let currencies = basil.get(data, 'invoicing.currencies', [])
          let features = basil.get(data, 'invoicing.features', [])

          this._invoice = new InvoiceController({
            currencies: basil.isNil(currencies) ? [] : currencies, 
            features: basil.isNil(features) ? [] : features, 
            invoice: basil.isNil(invoice) ? { active: false } : invoice, 
            router: this._router, 
            tiers: basil.isNil(tiers) ? [] : tiers
          })

          // Fields Handling
          this._fields = new FieldsController(basil.get(data, 'fields', {}))

          // Elements
          this._elements = new ElementsController(basil.get(data, 'elements', {}))
          
          // ACL
          if(data.acl) {
            this.acl = new ACLController({ acl: data.acl, store: this._store })
          }

          if(!basil.isNil(data.user)) {
            if (!data.user.metadata || !data.user.metadata.front_role) {
              console.warn('User has no role defined.')
            }

            // @note this is temporary while all the users might not have the role set in the db.
            basil.sayl.role = basil.get(data, 'user.metadata.front_role', 'admin')
          }

          if(this.loyalty) {
            this.loyalty.collection({ translator: this.__vue__.$t.bind(this.__vue__) })
              .catch(e => $console.error(e))
          }

          resolve(data)
        })
        .catch(e => {
          $console.error('dl.bootstrap', e)
          if(e.response.status === 401) {
            setTimeout(() => this.authenticate({ hash }), 1000)
          }
          return reject(e)
        })
        .finally(() => $console.groupEnd('dl.bootstrap'))
    })
  }

  initHost(data) {
    return new Promise((resolve, reject) => {
      this._host.findById()
        .then(() => {
          if(basil.get(data, 'user.profile.is_master', false) === true) {
            return this._host.findChildren({})
          }
          return Promise.resolve()
        })
        .then(() => resolve(data))
        .catch((e) => $console.error(e))
    })
  }

  /**
   * Log the user out of the application
   * 
   * @returns {Promise}
   */
  logout() {
    return this._oauth.logout()
  }
}

export default DataLayer
