<template>
  <div 
    class="view-loyalty-config__fields view-loyalty-modal__fields flow"
    v-if="show">
    <!-- Rules::Points Redemption::Activation-->
    <!-- :hint="$t('sayl-ecommerce.loyalty_config_points_redemption_activation_hint')" -->
    <forms-checkbox
      :appearance="$pepper.Appearance.TOGGLE"
      :read-only="readOnly"
      :size="$pepper.Size.S"
      v-model="edition.allowPointsRedemption"
      v-if="showAllowPoints"
    >{{ $t('sayl-ecommerce.loyalty_config_points_redemption_activation_label') }}</forms-checkbox>

    <!-- Rules::Points Redemption -->
    <forms-input
      @input="onRemoveError('rules.points_to_money')"
      :errors="getErrors('rules.points_to_money')"
      :hint="$t('sayl-ecommerce.loyalty_config_points_to_money_hint', { points: 10, amount: pointMatches, symbol })"
      :placeholder="$t('sayl.placeholder')"
      :read-only="readOnly"
      required
      min="0"
      step="1"
      type="number"
      v-model="pointsToMoney"
      v-if="showPointsToMoney">
      <template>{{ $t('sayl-ecommerce.loyalty_config_points_to_money_label') }}</template>
      <template v-slot:suffix><span class="view-loyalty-config__equation view-loyalty-modal__equation">{{ symbol }} = 1{{ currencyCode }}</span></template>
    </forms-input>

    <!-- Rules::min_redemption_threshold-->
    <!-- :hint="$t('sayl-ecommerce.loyalty_config_min_redemption_threshold_hint')" -->
    <forms-checkbox
      :appearance="$pepper.Appearance.TOGGLE"
      :read-only="readOnly"
      :size="$pepper.Size.S"
      v-model="hasMinimumRedemptionThreshold"
      @change="onMinRedemptionTreshold"
      v-if="showMinRedemptionThreshold"
    >{{ $t('sayl-ecommerce.loyalty_config_min_redemption_threshold_label') }}</forms-checkbox>

    <!-- Rules::min_redemption_threshold value -->
    <forms-input
      :placeholder="$t('sayl.placeholder')"
      :errors="getErrors('rules.min_redemption_threshold')"
      :read-only="readOnly"
      :required="hasMinimumRedemptionThreshold"
      min="0"
      step="1"
      type="number"
      v-model="edition.rules.minRedemptionThreshold"
      @input="onRemoveError('rules.min_redemption_threshold')"
      v-if="hasMinimumRedemptionThreshold && showMinRedemptionThreshold">
      <template>{{ $t('sayl-ecommerce.loyalty_config_min_redemption_threshold_amount_label') }}</template>
      <template v-slot:suffix><span class="view-loyalty-config__equation view-loyalty-modal__equation">{{ symbol }}</span></template>
    </forms-input>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { MixinError } from '@sayl/admin-common'

export default { 
  name: 'LoyaltyProgramConfigRedemptionPoints',

  mixins: [ MixinError, ],

  props: {
    errors: {
      type: Object,
    },

    readOnly: {
      required: false,
      type: Boolean
    },
  },

  data() {
    return {
      hasMinimumRedemptionThreshold: false,
      pointsRedemptionActivation: true,
    }
  },

  computed: {
    ...mapState({
      edition: state => state.loyalty.loyalty.edition,
    }),

    currencyCode() {
      let symbol = this.$basil.i18n.currency(0, { currency: this.$basil.i18n.Currencies[this.$basil.get(this.$i18n.getNumberFormat(this.$i18n.locale), 'currency.currency', 'EUR')], locale: 'en-US' })
      symbol = symbol.split('0.00')[0].trim()
      symbol = symbol.split('0')[0].trim()
      return symbol
    },

    pointMatches() {
      let base = 10;
      return this.pointsToMoney > 0 ? (base / this.pointsToMoney).toFixed(2) : 0
    },

    pointsToMoney: {
      get() {
        let ret = this.$basil.get(this.edition, 'rules.pointsToMoney', 0)

        if(ret > 0) {
          ret = 1 / ret
        }
        
        return parseInt(ret) || 0
      },
      set(value) {
        this.edition.rules.pointsToMoney = value !== 0 ? 1/value : 0
      }
    },

    show() {
      return this.showAllowPoints ||
        this.showMinRedemptionThreshold ||
        this.showPointsToMoney
    },

    showAllowPoints() {
      return !window.$dl.fields.isPropHidden('loyalty_program', 'allow_points_redemption')
    },

    showMinRedemptionThreshold() {
      return !window.$dl.fields.isPropHidden('loyalty_program', 'rules.min_redemption_threshold') && this.edition.allowPointsRedemption === true
    },

    showPointsToMoney() {
      return !window.$dl.fields.isPropHidden('loyalty_program', 'rules.points_to_money') && this.edition.allowPointsRedemption === true
    },

    symbol() {
      let symbol = this.$basil.get(this.edition, 'points.symbol', null);
      return !this.$basil.isNil(symbol) && !this.$basil.isEmpty(symbol) ?
        symbol : 'P'
    }
  },

  methods: {
    onMinRedemptionTreshold() {
      this.edition.rules.minRedemptionThreshold = this.HasMinimumRedemptionThreshold ? this.origin.rules.minRedemptionThreshold : 0
    },
  },

  mounted() {
    if(!this.$basil.isNil(this.$route.params.program)) {
      this.hasMinimumRedemptionThreshold = this.$basil.get(this.edition, 'rules.minRedemptionThreshold', 0) > 0
    } else {
      this.hasMinimumRedemptionThreshold = false
    }
  }
}
</script>
