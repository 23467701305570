<template>
  <div 
    class="overview-preview" 
    v-if="!$basil.isNil(edition) && show">
    <ui-inner-feature resource="loyalty.program" feature="pass">
      <!-- Preview -->
      <loyalty-pass-preview 
        class="overview-preview__pass"
        :backgroundColor="edition.passBackgroundColor"
        :icon="iconPreview"
        :logo="logoPreview"
        :textColor="edition.passTextColor"
        :strip="stripPreview"
        :stripColor="edition.passStripColor"
        :pointsName="$basil.get(edition, 'points.name.all','points')"
        v-if="!iLoading && $basil.get(edition, 'allowDigitalCards', true)"
      />

      <div class="overview-preview__meta">
        <div 
          class="meta"
          :class="digitalClasses">
          <div class="meta__icons">
            <div class="meta__icon">
              <img :src="$getImageSrc('/statics/images/icons/android.svg')" />
            </div>

            <div class="meta__icon">
              <img :src="$getImageSrc('/statics/images/icons/apple.svg')" />
            </div>
          </div>

          <div class="meta__label">{{ digitalLabel }}</div>
        </div>
        
        <div 
          class="meta"
          :class="physicalClasses">
          <div class="meta__icons">
            <div class="meta__icon">
              <ui-icon glyph="credit-card" />
            </div>
          </div>

          <div class="meta__label">{{ physicalLabel }}</div>
        </div>
      </div>

      <div 
        class="overview-preview__footer" 
        v-if="!readOnly && $basil.get(edition, 'allowDigitalCards', true)">
        <actions-button
          appearance="subtle"
          size="s"
          icon-post="arrow-right"
          :href="{ name: 'sayl-customer-loyalty_program-passes', params: { program: edition.id } }"
        >{{ $t('sayl-ecommerce.loyalty_edit_pass')}}</actions-button>
      </div>
    </ui-inner-feature>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { MixinImage } from '@sayl/admin-common'
import LoyaltyPassPreview from './configuration/passes/preview'

export default {
  name: 'OverviewPassPreview',

  components: {
    LoyaltyPassPreview
  },

  mixins: [ MixinImage ],

  props: {
    edition: {
      type: Object,
      default: null
    },

    readOnly: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean, 
      default: false
    }
  },

  data() {
    return {
      iLoading: true,
    }
  },

  computed: {
    ...mapState({
      iconImg: state => state.loyalty.loyalty_program.pass_icon,
      logoImg: state => state.loyalty.loyalty_program.pass_logo.all,
      stripImg: state => state.loyalty.loyalty_program.pass_strip.all,
    }),

    digitalClasses() {
      return {
        '-is-disabled': !this.$basil.get(this.edition, 'allowDigitalCards', true),
      }
    },

    digitalLabel() {
      return this.$basil.get(this.edition, 'allowDigitalCards', true)
        ? this.$t('sayl-ecommerce.loyalty_support_digital') : this.$t('sayl-ecommerce.loyalty_no_support_digital')
    },

    physicalClasses() {
      return {
        '-is-disabled': !this.$basil.get(this.edition, 'allowPhysicalCards', true),
      }
    },

    physicalLabel() {
      return this.$basil.get(this.edition, 'allowPhysicalCards', true)
        ? this.$t('sayl-ecommerce.loyalty_support_physical') : this.$t('sayl-ecommerce.loyalty_no_support_physical')
    },

    iconPreview() {
      let ret = [
        !this.$basil.isNil(this.iconImg) ? this.$basil.get(this.iconImg, '[0]._image.all.src') : null,
        this.$getImageSrc('/statics/images/loyalty/icon.png')
      ];
      return ret.find(r => !this.$basil.isNil(r))
    },

    logoPreview() {
      let ret = [
        !this.$basil.isNil(this.logoImg) ? this.$basil.get(this.logoImg, '[0]._image.all.src') : null,
        this.$getImageSrc('/statics/images/loyalty/logo.png')
      ];
      return ret.find(r => !this.$basil.isNil(r))
    },

    show() {
      return this.showDigitalCard || this.showPhysicalCard
    },

    showDigitalCard() {
      return !window.$dl.fields.isPropHidden('loyalty_program', 'allow_digital_cards')
    },

    showPhysicalCard() {
      return !window.$dl.fields.isPropHidden('loyalty_program', 'allow_physical_cards')
    },

    stripPreview() {
      let ret = [
        !this.$basil.isNil(this.stripImg) ? this.$basil.get(this.stripImg, '[0]._image.all.src') : null,
        this.$getImageSrc('/statics/images/loyalty/strip.png')
      ];
      return ret.find(r => !this.$basil.isNil(r))
    },
  }, 

  watch: {
    edition: {
      deep: true,
      handler(newVal, oldVal) {
        this.reset()
      }
    }
  },
  
  methods: {
    reset() {
      if(!this.$basil.isNil(this.edition) && this.$basil.get(this.edition, 'allowDigitalCards', true)){
        this.iLoading = true

        this.$basil.sequence([
          this.$image.all.bind(this.$image, { data: { entityType: 'loyalty_program', entityId: this.edition.id, fieldName: 'pass_icon', module: 'loyalty' }}),
          this.$image.all.bind(this.$image, { data: { entityType: 'loyalty_program', entityId: this.edition.id, fieldName: 'pass_logo', module: 'loyalty' }}),
          this.$image.all.bind(this.$image, { data: { entityType: 'loyalty_program', entityId: this.edition.id, fieldName: 'pass_strip', module: 'loyalty' }})
        ])
          .catch((e) => $console.error(e))
          .finally(() => this.iLoading = false)
      } 
    }
  },

  mounted() {
    this.reset()
  }
}
</script>
