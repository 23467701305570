<template>
  <layout-modal-steps
    v-model="current"
    v-bind="attrs"
    v-on="listeners"
  />
</template>

<script>
import {
  mapGetters, 
  mapState
} from 'vuex'

import Step1 from './step1'
import Step2 from './step2'
// import Step3 from './step3'
import Step4 from './step4'

import { MixinEditionDiscard } from '@sayl/admin-common'

export default {
  name: 'TierRewardPerk',

  mixins: [ MixinEditionDiscard ],

  props: {
    value: {
      type: Object,
      required: true
    },

    visible: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      current: 0,
      loading: true,
    }
  },

  computed: {
    ...mapState({
      edition: state => state.tier.reward.edition,
      types: state => state.tier.tier.perkTypes.all,
    }),

    ...mapGetters({
      pristine: 'tier/reward.pristine'
    }),

    attrs() {
      return {
        loading: this.loading,
        views: this.views,
        visible: this.visible,
        close: () => this.onDiscard(),
        edition: this.edition,
      }
    },

    listeners() {
      return Object.assign(this.$listeners, { 
        close: () => this.onDiscard(),
        confirm: () => this.confirm(this.$attrs.aggregate)
      })
    },

    views() {
      return !this.loading ? [
        { view: Step1, },
        { view: Step2, },
        // this.$basil.isNil(this.nft) ? { view: Step3, } : null,
        { view: Step4, },
      ].filter((s) => !this.$basil.isNil(s)) : []
    }
  }, 

  watch: {
    visible() {
      this.current = 0
    }
  },

  methods: {
    back() {
      this.$emit('close')
    },
  },

  mounted() {
    this.current = 0
    this.loading = true

    let prom = this.value.id != null ? 
      window.$dl.tierReward.findById.bind(window.$dl.tierReward, { args: { tierId: this.$route.params.tier, id: this.value.id }}) :
      window.$dl.tierReward.create.bind(window.$dl.tierReward, { args: { type: 'perk' }})

    prom.apply()
      .then(() => window.$dl.tier.getPerkTypes())
      .catch((e) => $console.error(e))
      .finally(() => this.loading = false) 
  }
};
</script>
