<template>
  <div class="data-table-data-tag-cell">
    <div class="data-table-data-tag-cell__label">
      <span>{{content}}</span>
      <code v-if="meta && metaIsNumber">{{ meta }}</code>
      <data-badge v-if="meta && !metaIsNumber" :value="meta" />
    </div>
    <div class="data-table-data-tag-cell__tags">
      <data-tag
        v-for="tag in tags"
        :key="tag.content"
        :intent="tag.intent || 'default'"
        size="s"
      >{{tag.content}}</data-tag>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataTableDataTagCell',

  props: {
    content: {
      type: String
    },

    meta: {
      type: [String, Number]
    },

    tags: {
      type: Array
    }
  },

  computed: {
    metaIsNumber(){
      return Number.isNaN( this.meta );
    }
  }
}
</script>
