<template>
  <layout-level1 
    class="view-loyalty" 
    v-bind="attrs">

    <template #filters>
      <ui-filters 
        :has-reset="true"
        :filters="hasPrograms ? (filters || []) : []"
        :actions="hasPrograms ? (actions || []) : []"
        @reset="hasPrograms ? innerReset : () => {}"
      />
    </template>

    <!-- Empty -->
    <data-empty
      :class="emptyClass"
      :description="emptyDescription"
      :figure="figure"
      :primary-action="createActionLabel"
      :title="$t('sayl-ecommerce.no_loyalty_title')"
      @click="createAction"
      v-if="!hasPrograms && !loading"
    />

    <!-- Loading -->
    <div 
      class="view-loyalty__loader" 
      v-if="loading">
      <ui-loader />
    </div>

    <!-- View -->
    <div 
      class="container reversed"
      :class="{ '-cols': $route.name !== 'sayl-customer-loyalty_referral' || hasReferrals }"
      v-if="hasPrograms && !loading">
      <div :class="{ 'col': $route.name !== 'sayl-customer-loyalty_referral' || hasReferrals }">
        <router-view 
          @init="onInit"
          :read-only="isReadOnly"
        />
      </div>

      <div 
        class="col view-loyalty__rightcol" 
        v-if="$route.name !== 'sayl-customer-loyalty_referral'">
        <div class="layout-level1__header">
          <h3 class="layout-level1__title">{{ $t('sayl-ecommerce.loyalty_col_config_title') }}</h3>
          <p class="layout-level1__description">{{ $t('sayl-ecommerce.loyalty_col_config_description') }}</p>
        </div>

        <!-- Card item -->
        <loyalty-card-information
          class="view-loyalty__card"
          :edition="edition"
          :read-only="isReadOnly"
        /> 
        
        <overview-page-preview 
          :read-only="isReadOnly"
          :edition="edition"
          :loading="loading"
          v-if="!loading"
        />

        <overview-card-pass-preview 
          :key="key"
          :read-only="isReadOnly"
          :edition="edition"
          :loading="loading"
          v-if="!loading"
        />
      </div>

      <div 
        class="col view-loyalty__rightcol" 
        v-else-if="hasReferrals">
        <div class="layout-level1__header">
          <h3 class="layout-level1__title">{{ $t('sayl-loyalty.referral_col_config_title') }}</h3>
          <p class="layout-level1__description">{{ $t('sayl-loyalty.referral_col_config_description') }}</p>
        </div>

        <referral-card-information 
          class="view-loyalty__card"
          :edition="referral"
          :read-only="isReadOnly"
        />
      </div>
    </div>

    <modal-create-loyalty-program 
      :read-only="isReadOnly"
      :visible="showModalCreate"
      @close="showModalCreate = false"
    />

    <modal-visit-loyalty-page
      :read-only="isReadOnly"
      :visible="showModalVisit"
      @close="showModalVisit = false"
    />
  </layout-level1>
</template>

<script>
import { mapState } from 'vuex'

import { 
  MixinACL,
  MixinEditionRemove,
  MixinEditionSave,
  MixinError,
  MixinImage,
  MixinImpersonation,
} from '@sayl/admin-common'

import OverviewCardPassPreview from './pass-preview'
import OverviewPagePreview from './page-preview'
import LoyaltyCardInformation from './information'
import ReferralCardInformation from './referral-card.vue'

import ModalCreateLoyaltyProgram from './configuration/create'
import ModalVisitLoyaltyPage from './modal-loyalty-link.vue'

export default {
  name: 'ViewLoyalty',

  components: {
    OverviewCardPassPreview,
    OverviewPagePreview,
    LoyaltyCardInformation,
    ModalCreateLoyaltyProgram,
    ModalVisitLoyaltyPage,
    ReferralCardInformation,
  },

  mixins: [
    MixinACL,
    MixinEditionRemove,
    MixinEditionSave,
    MixinError,
    MixinImage,
    MixinImpersonation
  ],

  data() {
    return {
      loading: true,
      key: 1,
      showModalCreate: false,
      showModalVisit: false,

      actions: [],
      filters: [],
      innerReset: () => {},
    }
  },

  computed: {
    ...mapState({
      module: state => state.ginger.module,
      programs: state => state.loyalty.loyalty.collection,
      referrals: state => state.loyalty.referral.all,
      state: state => state.loyalty.loyalty,
    }),

    attrs(){
      return {
        breadcrumb: this.breadcrumb,
        description: this.description,
        icon: this.icon,
        tabs: this.tabs,
        title: this.title,
        secondary: this.hasPrograms ? {
          label: this.$t('sayl-loyalty.visit_loyalty_page'),
          click: () => this.showModalVisit = true
        } : null,
        tertiary: this.popinsOptions
      }
    },

    breadcrumb() {
      return [
        { label: this.$t('sayl-ecommerce.loyalty'), icon: this.icon, href: { name:'sayl-customer-loyalty_home'} }
      ]
    },

    createActionLabel() {
      return  this.canCreate && this.isMaster && !this.isImpersonating ?
        this.$t('sayl-ecommerce.no_loyalty_action') : null
    },

    description(){
      return this.$t(`sayl-ecommerce.loyalty_description`)
    },

    emptyClass() {
      return {
        'view-loyalty__empty': true,
        '-read-only': this.isReadOnly,
      }
    },

    emptyDescription() {
      return !this.isReadOnly ? 
        this.$t('sayl-ecommerce.no_loyalty_description') : 
        this.$t('sayl-ecommerce.no_loyalty_description_read_only')
    },

    edition() {
      return this.$basil.get(this.programs, '[0]')
    },

    figure() {
      return this.$getImageSrc('/statics/images/figures/loyalty-empty.svg')
    },

    filtersHasValues() {
      let ret = this.filters.find(f => !this.$basil.isNil(f.values) && !this.$basil.isEmpty(f.values))
      return !this.$basil.isNil(ret)
    },

    filtersValues() {
      let ret = this.filters.filter(f => !this.$basil.isNil(f.values) && !this.$basil.isEmpty(f.values))
      return ret
    },

    hasPrograms() {
      return !this.$basil.isNil(this.programs) && this.programs.length > 0
    },

    hasReferrals() {
      return !this.$basil.isNil(this.referrals) && this.referrals.length > 0
    },

    icon() {
      return 'award'
    },

    isReadOnly() {
      return !this.canEdit || !this.isMaster || this.isImpersonating
    },

    popinsOptions() {
      let ret = []

      if(this.canEdit && !this.isReadOnly && this.hasPrograms && this.$route.name !== 'sayl-customer-loyalty_referral') { 
        ret.push({ 
          label: this.$t('sayl.delete'), 
          event: { click: this.onRemove } 
        })
      } else if(this.canEdit && !this.isReadOnly && this.hasReferrals && this.$route.name === 'sayl-customer-loyalty_referral') {
        ret.push({ 
          label: this.$t('sayl.delete'), 
          event: { click: this.onRemove } 
        })
      }

      return ret
    },

    referral() {
      return this.$basil.get(this.referrals, '[0]')
    },

    tabs() {
      return {
        appearance: this.$pepper.Appearance.DEFAULT,
        children: [
          {
            icon: 'star',
            href: { name: `sayl-customer-loyalty_overview` },
            label: this.$t('sayl-ecommerce.loyalty_overview'),
          },
          {
            icon: 'credit-card',
            href: { name: `sayl-customer-loyalty_cards` },
            label: this.$t('sayl-ecommerce.loyalty_cards_library'),
          },
          this.hasPrograms ? {
            icon: 'users',
            href: { name: `sayl-customer-loyalty_referral` },
            label: this.$t('sayl-loyalty.loyalty_referral'),
          } : null,
        ].filter(s => !this.$basil.isNil(s)),
      }
    },

    title(){
      return this.$t(`sayl-ecommerce.loyalty`) 
    },
  },

  methods: {
    back() {
      return
    },

    createAction() {
      return this.canCreate && this.isMaster && !this.isImpersonating ?
        this.onCreate() : () => {}
    },

    onCloseModal() {
      this.showModalCreate = false
      this.reset()
    },

    onConfirm() {
      this.onSave({})
        .then(() => this.reset())
        .catch((e) => $console.error(e))
    },

    onCreate() {
      this.showModalCreate = true
    },

    onInit({ actions = [], filters = [], reset = () => {} }) {
      this.actions = actions
      this.filters = filters
      this.innerReset = reset
    },

    remove(payload) {
      return new Promise((resolve, reject) => {
        if(this.$route.name != 'sayl-customer-loyalty_referral') {
          this.loading = true
          this.state.invalid = true
          this.$basil.sequence([
            window.$dl.loyalty.remove.bind(window.$dl.loyalty, payload),
            window.$dl.loyalty.collection.bind(window.$dl.loyalty, {})
          ])
            .then(() => resolve())
            .catch((e) => reject(e))
            .finally(() => {
              this.loading = false
              this.reset()
            })
        } 
        else {
          this.loading = true
          this.state.invalid = true
          window.$dl.referral.remove({ id: this.$basil.get(this.referrals, '[0].id') })
            .catch(() => $console.error(e))
            .finally(() => {
              this.loading = false
              this.reset()
            })
        }
      })
    },

    reset() {
      this.loading = true

      window.$dl.loyalty.collection({ args: { translator: this.$t.bind(this) }})
        .then(() => this.$ecommerce.voucherTemplates.all({ args: { isTemplate: 1 }}))
        .then(() => window.$dl.referral.all({}))
        .then(() => {
          if(!this.hasPrograms && this.$route.name === 'sayl-customer-loyalty_referral') {
            this.$router.replace({ name: `sayl-customer-loyalty_overview` })
          }
        })
        .catch((e) => this.handleErrors(e, 'loyalty-program'))
        .finally(() => this.loading = false)
    },
  },

  mounted() {
    this.reset()
  }
}
</script>
