<template>
  <layout-modal
    v-bind="attrs"
    v-on="listeners">
    <!-- Loading -->
    <div 
      class="layout-modal__loader"
      v-if="loading">
      <ui-loader />
    </div>

    <template v-if="!loading">
      <article class="layout-modal__article flow">
        <div class="group">
          <!-- Name -->
          <forms-input
            :class="hasTranslations('name')"
            @iconClick="onOpenTranslations('name')"
            icon-post="l8n"
            :icon-post-interactive="true"
            @input="onRemoveError('name.all')"
            @change="onNameChange"
            :errors="getErrors('name.all')"
            :hint="$t('sayl-ecommerce.loyalty_config_name_hint')"
            :placeholder="$t('sayl-ecommerce.loyalty_config_name_placeholder')"
            :read-only="readOnly"
            required
            type="text"
            v-model="edition.name.all"
          >{{$t('sayl-ecommerce.loyalty_config_name_label')}}</forms-input>

          <!-- Slug -->
          <forms-input
            @input="onRemoveError('slug')"
            :errors="getErrors('slug')"
            :hint="$t('sayl-ecommerce.loyalty_config_slug_hint')"
            :placeholder="$t('sayl-ecommerce.loyalty_config_slug_placeholder')"
            :read-only="readOnly"
            required
            type="text"
            v-model="edition.slug"
          >{{ $t('sayl-ecommerce.loyalty_config_slug_label') }}</forms-input>
        </div>
      </article>

      <loyalty-program-config-points 
        class="view-loyalty-modal__details"
        :errors="errors"
        :read-only="readOnly"
      />

      <loyalty-program-config-rewards 
        class="view-loyalty-modal__details"
        :errors="errors"
        :read-only="readOnly"
      />

      <loyalty-program-config-loyalty-cards
        class="view-loyalty-modal__details"
        :errors="errors"
        :read-only="readOnly"
        v-if="edition.rewardEatin"
      />
      
      <loyalty-program-config-dates 
        class="view-loyalty-modal__details"
        :errors="errors"
        :read-only="readOnly"
      />
      
      <loyalty-program-config-redemption 
        class="view-loyalty-modal__details"
        :errors="errors"
        :read-only="readOnly"
      />

      <loyalty-program-config-breakage
        class="view-loyalty-modal__details"
        :errors="errors"
        :read-only="readOnly"
      />

      <modal-translate 
        @close="onCloseTranslations"
        :readOnly="readOnly"
        :field="translationsField"
        :type="translationsType"
        :visible="showTranslations" 
      />
    </template>

    <template v-slot:footer>
      <actions-button 
        :appearance="$pepper.Appearance.SUBTLE"
        :disabled="saving"
        @click="onDiscard"
        >{{ backLabel }}</actions-button>

      <actions-button 
        :appearance="$pepper.Appearance.PRIMARY" 
        :disabled="saving"
        :loading="saving"
        @click="onConfirm"
        >{{ $t('sayl.confirm') }}</actions-button>
    </template>
  </layout-modal>
</template>

<script>
import { 
  mapGetters, 
  mapState 
} from 'vuex'

import { 
  MixinACL, 
  MixinEditionDiscard, 
  MixinEditionSave,
  MixinError,
  MixinTranslation,
} from '@sayl/admin-common'

import LoyaltyProgramConfigBreakage from './program/breakage'
import LoyaltyProgramConfigDates from './program/dates'
import LoyaltyProgramConfigLoyaltyCards from './program/loyalty-cards'
import LoyaltyProgramConfigPoints from './program/points'
import LoyaltyProgramConfigRedemption from './program/redemption'
import LoyaltyProgramConfigRewards from './program/rewards'

export default {
  name: 'LoyaltyEditModal',

  components: {
    LoyaltyProgramConfigBreakage,
    LoyaltyProgramConfigDates,
    LoyaltyProgramConfigLoyaltyCards,
    LoyaltyProgramConfigPoints,
    LoyaltyProgramConfigRedemption,
    LoyaltyProgramConfigRewards,
  },

  mixins: [
    MixinACL,
    MixinEditionDiscard,
    MixinEditionSave,
    MixinError,
    MixinTranslation,
  ],

  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },

    visible: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      loading: true,
      errors: {},
    } 
  },

  computed: {
    ...mapState({
      edition: state => state.loyalty.loyalty.edition,
    }),

    ...mapGetters({
      pristine: 'ecommerce/loyalty.pristine'
    }),

    attrs() {
      return {
        classes: { 'view-loyalty-modal': true },
        description: this.description,
        visible: this.visible,
        closable: false,
        title: this.title
      }
    },

    backLabel() {
      return this.pristine ? this.$t('sayl.cancel') : this.$t('sayl.discard')
    },

    listeners() {
      return {
        back: this.onDiscard,
        discard: this.onDiscard,
        close: this.onDiscard,
        save: this.onConfirm,
      }
    },

    title() {
      return !this.loading ? 
        this.$t('sayl-ecommerce.loyalty_create_title') : 
        this.$t('sayl.loading')
    }
  },

  watch: {
    visible(newVal, oldVal) {
      if(newVal === true && (oldVal === false || oldVal == null)) {
        this.reset()
      }
    }
  },

  methods: {
    back() {
      this.$emit('close')
    },

    onConfirm() {
      this.onSave({})
        .then(() => window.$dl.loyalty.collection({}))
        .then(() => this.back())
        .catch((e) => $console.error(e))
    },

    onNameChange() {
      if(this.$basil.isNil(this.edition.slug) || this.$basil.isEmpty(this.edition.slug)) {
        this.edition.slug = this.$basil.slugify(this.edition.name.all);
        delete this.errors['slug'];
      }
    },

    reset() {
      this.loading = true
      let args = {
        translator: this.$t.bind(this),
        page_active: true,
      }

      args = Object.assign(args, window.$dl.fields.getDefaultForEntity('loyalty_program'))

      window.$dl.loyalty.create({ args: args })
        .catch((e) => $console.error(e))
        .finally(() => this.loading = false)
    },

    save() {
      this.errors = {};
      return window.$dl.loyalty.save({ item: this.edition, isNew: true })
    }
  },
}
</script>
