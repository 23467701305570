<template>
  <div class="flow">
    <notice-banner :intent="$pepper.Intent.WARNING">
      <template #title>{{ $t('sayl-ecommerce.loyalty_old_page_deprecated') }}</template>
      <template>
        <div class="flow">
          <span>{{ $t('sayl-ecommerce.loyalty_old_page_deprecated_description') }}</span>

          <div class="row">
            <forms-checkbox
              :appearance="$pepper.Appearance.TOGGLE"
              :read-only="readOnly || edition.pageActive"
              :size="$pepper.Size.S"
              v-model="edition.pageActive"
              @change="onPageActiveChange"
            >{{ $t('sayl-ecommerce.loyalty_page_active') }}</forms-checkbox>

            <actions-button
              :href="{ name: 'sayl-customer-loyalty_program-page', params: { program: $route.params.program } }"
              :size="$pepper.Size.S"
            >{{ $t('sayl-ecommerce.loyalty_page_go_to_tab') }}</actions-button>
          </div>
        </div>
      </template>
    </notice-banner>

    <ui-card  
      class="view-loyalty-config__resto"
      :appearance="$pepper.Appearance.SUBTLE"
      article>
      <template v-slot:header>
        <div>
          <h3 class="ui-card__title">{{ $t('sayl-ecommerce.loyalty_config_resto_loyalty_title') }}</h3>
          <p class="ui-card__description">{{ $t('sayl-ecommerce.loyalty_config_resto_loyalty_description') }}</p>
        </div>
      </template>

      <!-- Intro -->
      <details 
        class="ui-card__details layout-modal__details flow" 
        open>
        <summary class="ui-card__summary layout-modal__summary">
          <span class="view-loyalty-config__summary-title">{{ $t('sayl-ecommerce.loyalty_config_resto_loyalty_intro_title') }}</span>

          <div class="group">
            <actions-button
              :appearance="$pepper.Appearance.PRIMARY"
              :href="$t('sayl-ecommerce.loyalty_config_resto_tutorial_intro_link')"
              target="_blank"
              :size="$pepper.Size.S"
              v-if="$t('sayl-ecommerce.loyalty_config_resto_tutorial_intro_link') != 'sayl-ecommerce.loyalty_config_resto_tutorial_intro_link'"
            >{{ $t('sayl-ecommerce.loyalty_config_resto_view_tutorial_action') }}</actions-button>
          </div>
        </summary>
        
        <div class="ui-card__details-body layout-modal__details-body">
          <!-- Title -->
          <forms-input
            :class="hasTranslations('pageTitle')"
            icon-post="l8n"
            :icon-post-interactive="true"
            :errors="getErrors('page_title.all')"
            :hint="$t('sayl-ecommerce.loyalty_config_resto_page_title_hint')"
            :placeholder="$t('sayl-ecommerce.loyalty_config_resto_page_title_placeholder')"
            :read-only="true"
            type="text"
            @iconClick="onOpenTranslations('pageTitle')"
            @input="onRemoveError('pageTitle.all')"
            v-model="edition.pageTitle.all"
          >{{ $t('sayl-ecommerce.loyalty_config_resto_page_title_label') }}</forms-input>

          <!-- Subtitle -->
          <forms-input
            :class="hasTranslations('pageSubtitle')"
            icon-post="l8n"
            :icon-post-interactive="true"
            :errors="getErrors('page_subtitle.all')"
            :hint="$t('sayl-ecommerce.loyalty_config_resto_page_subtitle_hint')"
            :placeholder="$t('sayl-ecommerce.loyalty_config_resto_page_subtitle_placeholder')"
            :read-only="true"
            type="text"
            @iconClick="onOpenTranslations('pageSubtitle')"
            @input="onRemoveError('pageSubtitle.all')"
            v-model="edition.pageSubtitle.all"
          >{{ $t('sayl-ecommerce.loyalty_config_resto_page_subtitle_label') }}</forms-input>
        </div>
      </details>

      <!-- How it works -->
      <details 
        class="ui-card__details layout-modal__details flow" 
        open>
        <summary class="ui-card__summary layout-modal__summary">
          <span class="view-loyalty-config__summary-title">{{ $t('sayl-ecommerce.loyalty_config_resto_loyalty_how_title') }}</span>

          <div class="actions-group">
            <actions-button
              @click="() => { edition.placeholders.content = null }"
              :size="$pepper.Size.S"
            >{{ $t('sayl-ecommerce.loyalty_config_resto_page_clear_cms_action') }}</actions-button>
          </div>
        </summary>
        
        <div class="ui-card__details-body layout-modal__details-body">
          <div class="group">
            <forms-select 
              :hint="hint"
              :options="blocksList"
              :read-only="true"
              :placeholder="$t('sayl.select_placeholder')"
              v-model="edition.placeholders.content"
            >{{ $t('sayl-ecommerce.loyalty_config_resto_page_how_content_label') }}</forms-select>
          </div>
          
          <div class="view-loyalty-config__banner">
            <forms-image-input
              :entity-id="$route.params.program"
              entity-type="loyalty_program"
              :errors="getErrors('banner')"
              field-name="banner"
              module="loyalty"
              :read-only="true"
              :title="$t('sayl-ecommerce.loyalty_config_resto_page_banner_title')"
              @clear="clearImages"
              @change="saveImages"
            />

            <div class="view-loyalty-config__hint">{{ $t('sayl-ecommerce.loyalty_config_resto_page_banner_hint') }}</div>
          </div>
        </div>
      </details>

      <!-- <article class="ui-card__article view-loyalty-config__article-action row"> -->

      <template #footer>
        <actions-button
          :appearance="$pepper.Appearance.PRIMARY"
          icon-post="open"
          @click="$bus.$emit('url-builder', { path: 'user/loyalty' })"
        >{{ $t('sayl-ecommerce.loyalty_config_resto_loyalty_url_builder_action') }}</actions-button>
      </template>
      <!-- </article> -->

      <modal-translate 
        @close="onCloseTranslations"
        :readOnly="true"
        :field="translationsField"
        :type="translationsType"
        :visible="showTranslations" 
      />
    </ui-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import {
  MixinError,
  MixinImage,
  MixinTranslation
} from '@sayl/admin-common'

export default {
  name: 'LoyaltyRestoExplanations',

  mixins: [ 
    MixinError,
    MixinImage,
    MixinTranslation 
  ],

  props: {
    errors: {
      type: Object
    },

    readOnly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState({
      blocks: state => state.configuration.block.all,
      edition: state => state.loyalty.loyalty.edition,
    }),

    blocksList() {
      let ret = this.blocks || []
      
      return ret.map(b => {
        return {
          value: this.$basil.get(b, 'id'),
          label: this.$basil.get(b, 'name'),
        }
      })
    },

    hint() {
      let ret = this.$t('sayl-ecommerce.loyalty_config_resto_page_how_content_hint');
      let r = this.$router.resolve({ name: 'sayl-configuration_blocks' })
      ret += `<br/><a href='${r.href}' target='_blank'>${this.$t('sayl-ecommerce.loyalty_config_resto_page_cms_blocks_link_hint')}</a>`
      return ret
    }
  },

  methods: {
    onPageActiveChange(value) {
      if(value === true) {
        this.$confirm({
          primaryAction: this.$t('sayl.confirm'),
          secondaryAction: this.$t('sayl.cancel'),
          title: this.$t('sayl-ecommerce.loyalty_page_active_confirm_title'),
          description: this.$t('sayl-ecommerce.loyalty_page_active_confirm_description'),
          onPrimary: () => this.edition.pageActive = true,
          onSecondary: () => this.edition.pageActive = false,
        })
      } else {
        this.edition.pageActive = false
      }
    },
  },
}
</script>
