export default [
  {
    "key": "11111",
    "date": "2023-03-24T12:04:26.754Z",
    "title": "Purchase",
    "reward": "nft"
  },
  {
    "key": "22222",
    "date": "2023-03-08T09:21:40.238Z",
    "title": "Purchase",
    "reward": "loyalty"
  },
  {
    "key": "33333",
    "date": "2023-03-03T14:12:09.586Z",
    "title": "Purchase",
    "reward": "voucher"
  }
]
