<template>
  <!-- Claiming -->
  <ui-details 
    class="view-loyalty-config__details flow" 
    open
    v-if="show">
    <template #summary>{{ $t('sayl-ecommerce.loyalty_config_claims_title') }}</template>

    <div class="view-loyalty-config__fields view-loyalty-modal__fields flow">
      <!-- Claiming::Pending Days -->
      <forms-input
        :placeholder="$t('sayl.placeholder')"
        :errors="getErrors('settings.points_claim_expiration_days')"
        :read-only="readOnly"
        :min="1"
        :step="1"
        type="number"
        @input="onRemoveError('settings.points_claim_expiration_days')"
        v-model="edition.settings.pointsClaimExpirationDays"
        v-if="showExpirationDays">
        <template>{{ $t('sayl-ecommerce.loyalty_config_points_claim_expiration_days_label') }}</template>
        <template v-slot:suffix><span class="view-loyalty-config__equation view-loyalty-modal__equation">{{ $t('sayl.days') }}</span></template>
      </forms-input>

      <!-- Claiming::Throttles -->
      <forms-input
        :placeholder="$t('sayl.placeholder')"
        :errors="getErrors('settings.points_claim_throttle_minutes')"
        :read-only="readOnly"
        min="1"
        step="1"
        type="number"
        @input="onRemoveError('settings.points_claim_throttle_minutes')"
        v-model="edition.settings.pointsClaimThrottleMinutes"
        v-if="showThrottleMinutes">
        <template>{{ $t('sayl-ecommerce.loyalty_config_points_claim_throttle_minutes_label') }}</template>
        <template v-slot:suffix><span class="view-loyalty-config__equation view-loyalty-modal__equation">{{ $t('sayl.mins') }}</span></template>
      </forms-input>
    </div>
  </ui-details>
</template>

<script>
import { mapState } from 'vuex'

import { MixinError } from '@sayl/admin-common'

export default { 
  name: 'LoyaltyProgramConfigClaims',

  mixins: [ MixinError ],

  props: {
    errors: {
      type: Object,
    },

    readOnly: {
      required: false,
      type: Boolean
    },
  },

  computed: {
    ...mapState({
      edition: state => state.loyalty.loyalty.edition,
      origin: state => state.loyalty.loyalty.origin,
    }),

    show() {
      return this.showExpirationDays ||
        this.showThrottleMinutes
    },

    showExpirationDays() {
      return !window.$dl.fields.isPropHidden('loyalty_program', 'settings.points_claim_expiration_days')
    },

    showThrottleMinutes() {
      return !window.$dl.fields.isPropHidden('loyalty_program', 'settings.points_claim_throttle_minutes')
    }
  },
}
</script>
