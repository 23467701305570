<template>
  <forms-control
    :class="classes"
    :size="size">
    <!-------------------------- Header--------------------------------->

    <!-- Before -->
    <template v-slot:before>
      <slot name="before"></slot>
    </template>

    <!-- Label -->
    <template v-slot:default>
      <slot></slot>
    </template>

    <!-- After -->
    <template v-slot:after>
      <slot name="after"></slot>
    </template>

    <!-------------------------- Body --------------------------------->
    
    <!-- Content -->
    <template v-slot:content>
      <div 
        v-for="(v, i) in values"
        :key="i"
        :class="getClassesForValue(v)">
        <actions-button 
          v-bind="getAttrsForValue(v)"
          @click="onClick(v)"
          >{{ v.label }}</actions-button>      
      </div>
    </template>
  </forms-control>
</template>

<script>
export default {
  name: 'FormsToggle',

  model: {
    event: 'input', 
    prop: 'value'
  },

  props: {
    size: {
      type: String,
      value: 'm'
    },

    values: {
      type: Array,
      default: () => []
    },

    value: {}
  },

  computed: {
    classes(){
      return {
        'forms-toggle': true
      }
    }
  },

  methods: {
    getAttrsForValue(value){
      let ret = {
        iconPre: this.$basil.get(value, 'icon'),
        title: this.$basil.get(value, 'title')
      }

      return ret
    },

    getClassesForValue(value){
      return {
        'forms-toggle__item': true,
        '-is-active': this.value === value,
      }
    },

    onClick(value){
      let ret = this.value === value ? null : value
      this.$emit('input', ret)
    }
  }
}
</script>
