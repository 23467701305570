<template>
  <div 
    style="display: contents;" 
    v-if="canView">
    <slot />
  </div>

  <div 
    class="ui-acl" 
    v-else>
    <data-empty
      class="ui-acl__empty"
      figure="/statics/images/figures/feature-locked.png"
      :title="$t('sayl.acl_view_not_allowed_title')"
      :description="$t('sayl.acl_view_not_allowed_description', { role })"
    />
  </div>
</template>

<script>
import { MixinACL } from '@sayl/admin-common'

export default {
  name: 'UiAcl',

  mixins: [
    MixinACL
  ],
}
</script>
