<template>
  <!-- Icon parent -->
  <data-status 
    class="data-table-cell-group__inherit" 
    :class="{ 'invisible': !isInherited }"
    icon="parent" 
    :intent="$pepper.Intent.WARNING"
    :title="$t('sayl.impersonation_inherited')"
    />
</template>

<script>
import { MixinImpersonation } from '@sayl/admin-common'

export default {
  name: 'DataTableCellInheritance',

  mixins: [
    MixinImpersonation
  ],

  props: {
    customerId: {
      type: String
    }
  },

  computed: {
    isInherited(){
      return !this.isCurrentCustomer(this.customerId)
    }
  }
}
</script>
