const LIST = [
  {
    description: 'sayl-configuration.email_fallbacks_templates_description',
    fqn: 'sayl-configure-emails-fallbacks',
    icon: 'envelope',
    href: { name: 'sayl-configuration_emails-fallbacks-home' },
    label: 'sayl-configuration.email_fallbacks_templates_title',
    subs: [
      'sayl-configuration_emails-fallbacks-library',
      'sayl-configuration_emails-fallbacks-bindings',
      'sayl-configuration_emails-fallbacks-binding',
      'sayl-configuration_email-fallbacks-entry',
    ],
  },
]

export default LIST
