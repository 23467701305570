<template>
  <transitions-fade 
    appear 
    direction="up">
    <div class="forms-save">
      <div class="forms-save__first">
        <div class="forms-save__label">{{ $t('sayl.save_not_pristine_label') }}</div>
      </div>

      <div class="forms-save__second">
        <!-- Reset -->
        <actions-button
          v-if="reset"
          appearance="subtle"
          class="forms-save__reset"
          @click="onReset"
          :size="$pepper.Size.S">{{ $t('sayl.forms_save_reset') }}</actions-button>

        <!-- Save -->
        <actions-button
          appearance="primary"
          :size="$pepper.Size.S"
          @click="onConfirm">{{ $t('sayl.forms_save_save') }}</actions-button>
      </div>
    </div>
  </transitions-fade>
</template>

<script>
export default {
  name: 'FormsSave',

  props: {
    reset: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onConfirm(){
      this.$emit('save')
    },

    onReset(){
      this.$emit('reset')
    },
  }
}
</script>