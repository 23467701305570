<template>
  <!-- Breakage -->
  <ui-details 
    class="view-loyalty-config__details flow" 
    open>
    <template #summary>{{ $t('sayl-ecommerce.loyalty_config_breakage_title') }}</template>
    <!-- <summary class="ui-card__summary layout-modal__summary">{{ $t('sayl-ecommerce.loyalty_config_breakage_title') }}</summary> -->

    <div class="view-loyalty-config__fields view-loyalty-modal__fields flow">
      <!-- Breakage::Active-->
      <forms-checkbox
        :appearance="$pepper.Appearance.TOGGLE"
        :hint="$t('sayl-ecommerce.loyalty_config_breakage_active_hint')"
        :read-only="readOnly"
        :size="$pepper.Size.S"
        v-model="edition.breakage.active"
        @change="onBreakageActivation"
      >{{ $t('sayl-ecommerce.loyalty_config_breakage_active_label') }}</forms-checkbox>

      <!-- Breakage::Days -->
      <forms-input
        :placeholder="$t('sayl.placeholder')"
        :errors="getErrors('breakage.days')"
        :read-only="readOnly"
        :required="edition.breakage.active"
        min="1"
        step="1"
        type="number"
        @input="onRemoveError('breakage.days')"
        v-if="edition.breakage.active"
        v-model="edition.breakage.days ">
        <template>{{ $t('sayl-ecommerce.loyalty_config_breakage_days_label') }}</template>
        <template v-slot:suffix><span class="view-loyalty-config__equation view-loyalty-modal__equation">{{ $t('sayl-ecommerce.loyalty_config_breakage_days') }}</span></template>
      </forms-input>

      <!-- Breakage::AlertDays -->
      <forms-input
        :placeholder="$t('sayl.placeholder')"
        :errors="getErrors('breakage.alert_days')"
        :hint="$t('sayl-ecommerce.loyalty_config_breakage_alert_days_hint')"
        :read-only="readOnly"
        min="1"
        step="1"
        type="number"
        @input="onRemoveError('breakage.alert_days')"
        v-if="edition.breakage.active"
        v-model="edition.breakage.alert_days ">
        <template>{{ $t('sayl-ecommerce.loyalty_config_breakage_alert_days_label') }}</template>
        <template v-slot:suffix><span class="view-loyalty-config__equation view-loyalty-modal__equation">{{ $t('sayl-ecommerce.loyalty_config_breakage_alert_days') }}</span></template>
      </forms-input>
    </div>
  </ui-details>
</template>

<script>
import { mapState } from 'vuex'

import { MixinError } from '@sayl/admin-common'

export default { 
  name: 'LoyaltyProgramConfigBreakage',

  mixins: [ MixinError ],

  props: {
    errors: {
      type: Object,
    },

    readOnly: {
      required: false,
      type: Boolean
    },
  },

  computed: {
    ...mapState({
      edition: state => state.loyalty.loyalty.edition,
      origin: state => state.loyalty.loyalty.origin,
    }),
  },

  methods: {
    onBreakageActivation() {
      if(this.edition.breakage.active) {
        this.edition.breakage.days = this.edition.breakage.active ? this.origin.breakage.days : 1
        this.edition.breakage.alert_days = this.edition.breakage.active ? this.origin.breakage.alert_days : 1
      } else {
        this.edition.breakage.days = null
        this.edition.breakage.alert_days = null
      }
    },
  },
}
</script>
