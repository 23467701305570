// List of the base config
// https://infinitymobile.atlassian.net/wiki/spaces/IC/pages/2976907265/Structure+Navigation+-+Sayl+Admin

const json = {
  "sayl": [
    { "fqn": "sayl-overview" },
    { 
      "fqn": "sayl-customer",
      "children": [
        { "fqn": "sayl-customer-home" },
        { "fqn": "sayl-customer-companies" }
      ]
    },
    { "fqn": "sayl-customer-loyalty" },
    // { "fqn": "sayl-tiers" },
    { 
      "fqn": "sayl-customer-challenges",
      "children": [
        { "fqn": "sayl-customer-challenges-home" },
        { "fqn": "sayl-customer-challenges-settings" }
      ]
    },
    { 
      "fqn": "sayl-vouchers",
      "children": [
        { "fqn": "sayl-vouchers-analytics" },
        { "fqn": "sayl-vouchers-library" },
        { "fqn": "sayl-vouchers-templates" },
        { "fqn": "sayl-vouchers-gift-cards-pdf-settings" }
      ]
    },
    { 
      "fqn": "sayl-configure-user-portal",
      "children": [
        { fqn: 'sayl-configure-portal' },
        { fqn: 'sayl-widget' }
      ] 
    },
    { "fqn": "sayl-integrations" },
    {
      "fqn": "sayl-configure",
      "children": [
        { "fqn": "sayl-configure-users" },
        // { "fqn": "sayl-configure-user-portal" },
        { "fqn": "sayl-configure-emails" },
        { "fqn": "sayl-configure-notification-settings" },
        { "fqn": "sayl-configure-vats" },
        { "fqn": "sayl-configure-public-api-tokens" },
        { "fqn": "sayl-configure-project" },
        { "fqn": "sayl-configure-webhooks" },
        { "fqn": "sayl-configure-tags" },
        { "fqn": "sayl-cms" }
      ]
    }
  ],
  "sayl-resto": [
    { "fqn": "sayl-resto-orders" },
    {
      "fqn": "sayl-resto-commerce",
      "children": [
        { "fqn": "sayl-resto-commerce-catalogs" },
        { "fqn": "sayl-resto-commerce-products" },
        { "fqn": "sayl-resto-commerce-options" },
        { "fqn": "sayl-resto-commerce-displayrules" },
        { "fqn": "sayl-resto-commerce-productassociations" },
        { "fqn": "sayl-resto-commerce-inventory" },
        { "fqn": "sayl-resto-commerce-attributes" }
      ]
    },
    {
      "fqn": "sayl-resto-configure",
      "children": [
        { "fqn": "sayl-resto-shop" },
        { "fqn": "sayl-resto-preparationlocations" },
        { "fqn": "sayl-resto-seats" },
        { "fqn": "sayl-resto-embed" },
        { "fqn": "sayl-resto-overview" },
        { "fqn": "sayl-resto-layout" }
      ]
    }
  ],
  "sayl-conn3ct": [
    { 
      "fqn": "sayl-conn3ct-nft-module",
      "children": [
        { "fqn": "sayl-conn3ct-nft" },
        { "fqn": "sayl-conn3ct-nft-analytics" },
        { "fqn": "sayl-conn3ct-web3-triggers" },
        { "fqn": "sayl-conn3ct-web3-vouchers" }
      ]
    },
    { 
      "fqn": "sayl-conn3ct-settings-poaps",
      "children": [
        { "fqn": "sayl-conn3ct-settings-poaps-library" },
        { "fqn": "sayl-conn3ct-settings-poaps-overview" }
      ]
    },
    { 
      "fqn": "sayl-nft-messaging", 
      "children": [
        { "fqn": "sayl-nft-messaging-audiences" },
        { "fqn": "sayl-nft-messaging-campaigns" }
      ]
    },
    {
      "fqn": "sayl-conn3ct-shop",
      "children": [
        { "fqn": "sayl-conn3ct-settings-shop" },
        { "fqn": "sayl-conn3ct-settings-enablers" },
        { "fqn": "sayl-conn3ct-settings-claim" },
        { "fqn": "sayl-conn3ct-orders" }
      ],
    },
    // { 
    //   "fqn": "sayl-conn3ct-settings",
    //   "children": [
    //     { "fqn": "sayl-conn3ct-web3-wallet" },
    //     { "fqn": "sayl-conn3ct-web3-brand" }
    //   ] 
    // }
  ],
  "sayl-customer": [ 
    { "fqn": "sayl-customer-segments" },
    { "fqn": "sayl-customer-campaigns" },
    { "fqn": "sayl-customer-feedbacks" },
    { "fqn": "sayl-customer-behaviors" },
    { "fqn": "sayl-customer-activate" }
  ]
}

export default json
