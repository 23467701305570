// Sayl Resto
const LIST = [
  // Orders
  {
    fqn: 'sayl-resto-orders-home',
    icon: 'bag',
    icons: ['user', 'bag', 'building-store'],
    label: 'sayl-customer.orders',
    description: 'sayl-customer.orders_description',
    subs: [],
    children: []
  },
  {
    fqn: 'sayl-resto-orders',
    href: { name: 'sayl-sales_home' },
    icon: 'bag',
    description: 'sayl-customer.orders_description',
    label: 'sayl-customer.orders',
    subs: ['sayl-sales_entry'],
  },
  {
    fqn: 'sayl-resto-order-groups',
    href: { name: 'sayl-sales-groups_home' },
    icon: 'bag',
    description: 'sayl-customer.order_groups_description',
    label: 'sayl-customer.order_groups',
    subs: ['sayl-sales-groups_entry'],
  },

  // Commerce
  {
    description: 'sayl-ecommerce.description',
    fqn: 'sayl-resto-commerce',
    icon: 'building-store',
    icons: ['bag', 'building-store', 'delivery'],
    label: 'sayl-ecommerce.title',
    subs: [],
    children: []
  },
  {
    description: 'sayl-ecommerce.catalogs_description',
    fqn: 'sayl-resto-commerce-catalogs',
    href: { name: 'sayl-ecommerce_catalogs' },
    icon: 'folders',
    label: 'sayl-ecommerce.catalogs',
    subs: [
      'sayl-ecommerce_catalog', 
      'sayl-ecommerce_category'
    ],
  },
  {
    description: 'sayl-ecommerce.products_description',
    fqn: 'sayl-resto-commerce-products',
    icon: 'bucket',
    href: { name: 'sayl-ecommerce_products' },
    label: 'sayl-ecommerce.products',
    subs: ['sayl-ecommerce_product'],
  },
  {
    description: 'sayl-ecommerce.options_description',
    fqn: 'sayl-resto-commerce-options',
    icon: 'command',
    href: { name: 'sayl-ecommerce_options' },
    label: 'sayl-ecommerce.options',
    subs: ['sayl-ecommerce_option'],
  },
  {
    description: 'sayl-ecommerce.attributes_description',
    fqn: 'sayl-resto-commerce-attributes',
    href: { name: 'sayl-ecommerce_attributes' },
    icon: 'pennant',
    label: 'sayl-ecommerce.attributes',
    subs: ['sayl-ecommerce_attribute'],
  },
  
  {
    description: 'sayl-ecommerce.vouchers_description',
    icon: 'percent',
    fqn: 'sayl-resto-commerce-vouchers-templates',
    href: { name: 'sayl-ecommerce_vouchers-templates' },
    label: 'sayl-ecommerce.vouchers',
    subs: [
      'sayl-ecommerce_voucher',
      'sayl-ecommerce_voucher-template',
    ],
  },
  {
    description: 'sayl-ecommerce.display_rules_description',
    fqn: 'sayl-resto-commerce-displayrules',
    href: { name: 'sayl-ecommerce_display_rules' },
    icon: 'directions',
    label: 'sayl-ecommerce.display_rules',
    subs: ['sayl-ecommerce_display_rule'],
  },
  {
    description: 'sayl-ecommerce.associations_description',
    fqn: 'sayl-resto-commerce-productassociations',
    href: { name: 'sayl-ecommerce_associations' },
    icon: 'dna',
    label: 'sayl-ecommerce.associations',
    subs: ['sayl-ecommerce_association'],
  },
  {
    description: 'sayl-ecommerce.inventory_description',
    fqn: 'sayl-resto-commerce-inventory',
    href: { name: 'sayl-ecommerce_inventory-home' },
    icon: 'folders',
    label: 'sayl-ecommerce.inventory_title',
    subs: [
      'sayl-ecommerce_inventory-stocks',
      'sayl-ecommerce_inventory-view-entry'
    ],
  },

  // Settings
  {
    fqn: 'sayl-resto-configure',
    icon: 'adjustments',
    icons: ['building-store', 'cog', 'flag'],
    label: 'sayl-settings.configure',
    subs: [],
    children: []
  },
  {
    description: 'sayl-settings.overview_description',
    fqn: 'sayl-resto-overview',
    icon: 'eyeglass',
    href: { name: 'sayl-settings_overview' },
    label: 'sayl-settings.overview',
  },
  {
    description: 'sayl-settings.shops_description',
    fqn: 'sayl-resto-shop',
    icon: 'building-store',
    href: { name: 'sayl-settings_shops' },
    label: 'sayl-settings.shops',
    subs: ['sayl-settings_shop'],
  },
  {
    description: 'sayl-settings.pos_description',
    fqn: 'sayl-resto-preparationlocations',
    href: { name: 'sayl-settings_pos' },
    icon: 'bag',
    label: 'sayl-settings.pos',
    subs: ['sayl-settings_pos_item'],
  },
  {
    description: 'sayl-settings.layout_description',
    fqn: 'sayl-resto-layout',
    icon: 'star',
    href: { name: 'sayl-settings_askemma' },
    label: 'sayl-settings.layout',
    subs: ['sayl-settings_askemma_item'],
  },
  {
    description: 'sayl-eatin.locations_description',
    fqn: 'sayl-resto-seats',
    icon: 'eatin',
    href: { name: 'sayl-settings_seats' },
    label: 'sayl-eatin.title',
    subs: ['sayl-settings_seat-entry'],
  },
  {
    description: 'sayl-embed.embeds_description',
    fqn: 'sayl-resto-embed',
    icon: 'window',
    href: { name: 'sayl-settings_embeds' },
    label: 'sayl-embed.title',
    subs: ['sayl-settings_embed-entry'],
  },
]

export default LIST
