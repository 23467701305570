<template>
  <!-- Threshold -->
  <ui-details 
    class="view-loyalty-config__details flow" 
    open
    :auto-padding="false">
    <template #summary>{{ $t('sayl-ecommerce.loyalty_config_redemption_title') }}</template>
    
    <redemption-vouchers 
      class="view-loyalty-config__redemption view-loyalty-modal__redemption"
      :dirty="dirty"
      :errors="errors"
      :read-only="readOnly"
    />

    <redemption-points
      class="view-loyalty-config__redemption view-loyalty-modal__redemption"
      :errors="errors"
      :read-only="readOnly"
    />
  </ui-details>
</template>

<script>
import { mapState } from 'vuex'

import { MixinError } from '@sayl/admin-common'

import RedemptionPoints from './points'
import RedemptionVouchers from './vouchers'

export default { 
  name: 'LoyaltyProgramConfigRedemption',

  components: {
    RedemptionPoints,
    RedemptionVouchers,
  },

  mixins: [ MixinError, ],

  props: {
    dirty: {
      type: Array,
      default: () => [],
    },

    errors: {
      type: Object,
    },

    readOnly: {
      required: false,
      type: Boolean
    },
  },

  computed: {
    ...mapState({
      edition: state => state.loyalty.loyalty.edition,
    }),
  },
}
</script>
