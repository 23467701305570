/**
 * @readonly
 * @property {Object} SocialMedias 
 * Social Medias definitions 
 */
const SocialMedias = {}
let prefix = 'sayl.social_'

///////////////// Today
/**
 * @property {Object} FACEBOOK
 */
const FACEBOOK = {
  label: `${prefix}facebook`,
  value: 'facebook',
}
SocialMedias.FACEBOOK = FACEBOOK

/**
 * @property {Object} INSTAGRAM
 */
const INSTAGRAM = {
  label: `${prefix}instagram`,
  value: 'instagram',
}
SocialMedias.INSTAGRAM = INSTAGRAM

/**
 * @property {Object} LINKEDIN
 */
const LINKEDIN = {
  label: `${prefix}linkedin`,
  value: 'linkedin',
}
SocialMedias.LINKEDIN = LINKEDIN

/**
 * @property {Object} TWITTER
 */
const TWITTER = {
  label: `${prefix}twitter`,
  value: 'twitter',
}
SocialMedias.TWITTER = TWITTER

SocialMedias.ALL = [
  FACEBOOK,
  INSTAGRAM,
  LINKEDIN,
  TWITTER,
]


export default SocialMedias
