<template>
  <section class="view-loyalty-kpis">
    <article 
      v-for="(kpi, i) in dataset" 
      :key="i"
      class="view-loyalty-kpis__item">

      <!-- Overline -->
      <div class="view-loyalty-kpis__overline">{{ kpi.title }}</div>

      <!-- Figure -->
      <div class="view-loyalty-kpis__figure">
        <span class="view-loyalty-kpis__value">{{ kpi.value}}</span>
        <span class="view-loyalty-kpis__legend">{{ kpi.unity }}</span>
      </div>

      <!-- Meta -->
      <div class="view-loyalty-kpis__metas">
        <span 
          v-for="(m, j) in kpi.meta" 
          :key="j"
          class="view-loyalty-kpis__meta">{{ m.label }}</span>
      </div>
    </article>
  </section>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'LoyaltyProgramKPIs',

  props: {
    range: {
      type: Object,
    }
  },

  data() {
    return {
      key: 1,
    }
  },

  computed: {
    // Change for loyalty Cards
    ...mapState({
      state: state => state.loyalty.loyalty.card,
    }),

    total() {
      return this.key && this.$basil.get(this.state, 'pagination.total')
    },

    kpis() {
      return this.key && this.state.kpis.value
    },

    dataset() {
      let ret = [];

      let totalUser = this.$basil.get(this.kpis, 'user_count', 0);
      let userWithCard = this.$basil.get(this.kpis, 'user_with_card', 0);
      let digital = this.$basil.get(this.kpis, 'type_repartition.digital', 0);
      let physical = this.$basil.get(this.kpis, 'type_repartition.physical', 0);

      // User
      ret.push({
        title: this.$t('sayl-ecommerce.loyalty_cards_kpis_distribution_rate'),
        value: this.$basil.i18n.percent((totalUser > 0 && userWithCard > 0) ? ((100 / totalUser) * userWithCard)/100 : 0),
        meta: [
          { label: this.$t('sayl-ecommerce.loyalty_cards_kpis_total_customers', { count: totalUser }) },
        ]
      });

      // Physical
      ret.push({
        title: this.$t('sayl-ecommerce.loyalty_cards_kpis_physical'),
        value: this.$basil.i18n.percent((this.total > 0 && physical > 0) ? ((100 / this.total) * physical)/100 : 0),
        meta: [
          { label: this.$t('sayl-ecommerce.loyalty_cards_kpis_total_cards', { count: physical }) },
        ]
      });

      // Digital
      ret.push({
        title: this.$t('sayl-ecommerce.loyalty_cards_kpis_digital'),
        value: this.$basil.i18n.percent((this.total > 0 && digital > 0) ? ((100 / this.total) * digital)/100 : 0),
        meta: [
          { label: this.$t('sayl-ecommerce.loyalty_cards_kpis_total_cards', { count: digital }) },
        ]
      });

      return ret
    }
  },
}
</script>
