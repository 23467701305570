<template>
  <div class="notfound">
    <div class="notfound__inner">
      <header class="notfound__header">
        <img 
          :src="$getImageSrc('/statics/images/logos/sayl/glyph-white.svg')" 
          alt="Sayl Logo">
      </header>

      <section class="notfound__body">
        <h1 class="notfound__title">An error occured</h1>
        <h2 class="notfound__subtitle">A server error has been detected</h2>
      </section>
    </div>
  </div>
</template>

<script>
import LayoutBase from '../layouts/base'

export default {
  components: {
    LayoutBase
  },
}
</script>