<template>
  <data-status v-bind="attrs">{{ label }}</data-status>
</template>

<script>
export default {
  name: 'DataTableCellActive',

  props: {
    content: {
      type: Boolean
    }
  },

  computed: {
    active(){
      return this.content === true
    },

    attrs(){
      return {
        active: this.active === true,
        class: "data-table-cell-active",
        icon: 'bolt',
        intent: this.active === true ? this.$pepper.Intent.SUCCESS : null
      }
    },

    label(){
      return this.active === true ? 
             this.$t('sayl.active') :
             this.$t('sayl.inactive')
    }
  }

}
</script>