<template>
  <ui-card
    class="view-loyalty-config__resto"
    :appearance="$pepper.Appearance.SUBTLE"
    article>
    <template v-slot:header>
      <div>
        <h3 class="ui-card__title">{{ $t('sayl-ecommerce.loyalty_config_resto_checkout_title') }}</h3>
        <p class="ui-card__description">{{ $t('sayl-ecommerce.loyalty_config_resto_checkout_description') }}</p>
      </div>
    </template>

    <!-- Intro -->
    <details 
      class="ui-card__details layout-modal__details flow" 
      open>
      <summary class="ui-card__summary layout-modal__summary">
        <span class="view-loyalty-config__summary-title">{{ $t('sayl-ecommerce.loyalty_config_resto_checkout_intro_title') }}</span>

        <div class="group">
          <actions-button
            :appearance="$pepper.Appearance.PRIMARY"
            :href="$t('sayl-ecommerce.loyalty_config_resto_tutorial_checkout_link')"
            :size="$pepper.Size.S"
            target="_blank"
            v-if="$t('sayl-ecommerce.loyalty_config_resto_tutorial_checkout_link') != 'sayl-ecommerce.loyalty_config_resto_tutorial_checkout_link'"
          >{{ $t('sayl-ecommerce.loyalty_config_resto_view_tutorial_action') }}</actions-button>

          <actions-button
            @click="() => { edition.placeholders.checkout = null }"
            :size="$pepper.Size.S"
          >{{ $t('sayl-ecommerce.loyalty_config_resto_page_clear_cms_action') }}</actions-button>  
        </div>
      </summary>
      
      <div class="ui-card__details-body layout-modal__details-body">
        <forms-select 
          :hint="hint"
          :options="blocksList"
          :placeholder="$t('sayl.select_placeholder')"
          v-model="edition.placeholders.checkout"
        >{{ $t('sayl-ecommerce.loyalty_config_resto_page_checkout_content_label') }}</forms-select>
      </div>
    </details>
  </ui-card>
</template>


<script>
import { mapState } from 'vuex'

import { MixinError } from '@sayl/admin-common'

export default {
  name: 'LoyaltyRestoCheckout',

  mixins: [ MixinError ],

  props: {
    errors: {
      type: Object
    },

    readOnly: {
      type: Boolean,
      default: false
    }
  },
  
  computed: {
    ...mapState({
      blocks: state => state.configuration.block.all,
      edition: state => state.loyalty.loyalty.edition,
    }),

    blocksList() {
      let ret = this.blocks || []

      return ret.map(b => {
        return {
          value: this.$basil.get(b, 'id'),
          label: this.$basil.get(b, 'name'),
        }
      })
    },

    hint() {
      let ret = this.$t('sayl-ecommerce.loyalty_config_resto_page_checkout_content_hint');
      let r = this.$router.resolve({ name: 'sayl-configuration_blocks' })
      ret += `<br/><a href='${r.href}' target='_blank'>${this.$t('sayl-ecommerce.loyalty_config_resto_page_cms_blocks_link_hint')}</a>`
      return ret
    }
  },
}
</script>
