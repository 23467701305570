import { basil } from '@spices/basil'

export default class FieldsController {
  constructor(fields = {}) {
    this._fields = fields == null ? {} : fields
  }

  getDefaultForProperty(entity, prop, defaultValue = null) {
    if(entity == null || prop == null) {
      return defaultValue
    }

    return !this.isPropHidden ? defaultValue : basil.get(this._fields, `${entity}.${prop}.default`, defaultValue)
  }

  getDefaultForEntity(entity) {
    if(entity == null || this._fields[entity] == null) {
      return {}
    }

    let ret = {}
    Object.keys(this._fields[entity]).forEach(p => {
      ret[p] = this.getDefaultForProperty(entity, p)
    })
    return ret
  }

  getFieldsForEntity(entity) {
    return this._fields[entity] || {}
  }

  getValuesForProperty(entity, prop, defaultValues = []) {
    if(entity == null || prop == null) {
      return defaultValues
    }

    return basil.get(this._fields, `${entity}.${prop}.values`, defaultValues)
  }

  isPropHidden(entity, prop) {
    if(entity == null || prop == null) {
      return false
    }

    return basil.get(this._fields, `${entity}.${prop}.hidden`, false)
  }
}
