import { config, isDevelopment } from '@/env'
import { GingerModuleConfig } from '@spices/ginger'
import { basil } from '@spices/basil'

/**
 * Boostrap the application
 *  With the client server (all envs)
 *  With the client filesystem (only development)
 * 
 * @param {Object} options
 * @param {GingerCapabilities} options.capabilities
 * @param {Ginger} options.$ginger
 * @returns {Promise.resolve}
 */
export default function ({ capabilities, $ginger }) {
  $console.group('sayl-bootstrap')
  
  let Vue = capabilities.vue
  let store = capabilities.store

  return $dl.bootstrap()
    .then((data) => {
      $ginger.options = data
    
      if(!isDevelopment && store && store.state.host && store.state.host.edition && data.user) {
        let company = { 
          name: basil.get(store, 'state.host.edition.companyname'),
          user_id: basil.get(data, 'user.profile.id')
        }

        Vue.prototype.$bugsnag.addMetadata('company', company)
      }

      data.modules.filter(m => basil.get(m, 'preload', true) === true)
        .forEach(m => {
          let url = m.url
          if (url.length != 0) {
            url = [config.transports.module, url].join('');
          }

          $ginger.add(
            new GingerModuleConfig({
              fqn: m.fqn,
              name: m.name,
              manifest: url
            })
          )
        })
    })
    .finally(() => $console.groupEnd('sayl-bootstrap'))
}
