/**
 * @readonly
 * @property {Object} Blockchains 
 * Blockchains available for NFT
 */
const Blockchains = {}

let prefix = 'sayl.blockchain_';

//////////////////////////////////////
// SAYL
const SAYL = {
  label: `${prefix}sayl`,
  value: 'hedera',
  values: ['sayl', 'hedera', 'native'],
}
Blockchains.SAYL = SAYL

//////////////////////////////////////
// HEDERA
const HEDERA_MAINNET = {
  label: `${prefix}hedera_mainnet`,
  value: 'hedera_mainnet',
  values: ['hedera_mainnet'],
}
Blockchains.HEDERA_MAINNET = HEDERA_MAINNET

//////////////////////////////////////
// HEDERA
const HEDERA_TESTNET = {
  label: `${prefix}hedera_testnet`,
  value: 'hedera_testnet',
  values: ['hedera_testnet'],
}
Blockchains.HEDERA_TESTNET = HEDERA_TESTNET

//////////////////////////////////////t chain
// BSC => binance smar
const BSC = {
  label: `${prefix}bsc`,
  value: 'bsc',
  values: ['bsc'],
}
Blockchains.BSC = BSC

//////////////////////////////////////
// ETHEREUM
const ETHEREUM = {
  label: `${prefix}ethereum`,
  value: 'ethereum',
  values: ['eth', 'ethereum'],
}
Blockchains.ETHEREUM = ETHEREUM

//////////////////////////////////////
// matic - polygon
const MATIC = {
  label: `${prefix}matic`,
  value: 'matic',
  values: ['matic', 'polygon'],
}
Blockchains.MATIC = MATIC

//////////////////////////////////////
// polkadot 
const POLKADOT = {
  label: `${prefix}polkadot`,
  value: 'polkadot',
  values: ['polkadot'],
}
Blockchains.POLKADOT = POLKADOT

//////////////////////////////////////
// avalanche
const AVALANCHE = {
  label: `${prefix}avalanche`,
  value: 'avalanche',
  values: ['avalanche'],
}
Blockchains.AVALANCHE = AVALANCHE

//////////////////////////////////////
// fantom
const FANTOM = {
  label: `${prefix}fantom`,
  value: 'fantom',
  values: ['fantom'],
}
Blockchains.FANTOM = FANTOM

//////////////////////////////////////
// cronos
const CRONOS = {
  label: `${prefix}cronos`,
  value: 'cronos',
  values: ['cronos'],
}
Blockchains.CRONOS = CRONOS

//////////////////////////////////////
// stafi
const STAFI = {
  label: `${prefix}stafi`,
  value: 'stafi',
  values: ['stafi'],
}
Blockchains.STAFI = STAFI

//////////////////////////////////////
// algorand
const ALGORAND = {
  label: `${prefix}algorand`,
  value: 'algorand',
  values: ['algorand'],
}
Blockchains.ALGORAND = ALGORAND

//////////////////////////////////////
// heco
const HECO = {
  label: `${prefix}heco`,
  value: 'heco',
  values: ['heco'],
}
Blockchains.HECO = HECO

//////////////////////////////////////
// edgeware
const EDGEWARE = {
  label: `${prefix}edgeware`,
  value: 'edgeware',
  values: ['edgeware'],
}
Blockchains.EDGEWARE = EDGEWARE

//////////////////////////////////////
// zilliqa 
const ZILLIQA = {
  label: `${prefix}zilliqa`,
  value: 'zilliqa',
  values: ['zilliqa'],
}
Blockchains.ZILLIQA = ZILLIQA

//////////////////////////////////////
// ALL
Blockchains.ALL = [
  SAYL,
  HEDERA_MAINNET,
  HEDERA_TESTNET,
  BSC,
  ETHEREUM,
  MATIC,
  POLKADOT,
  AVALANCHE,
  FANTOM,
  CRONOS,
  STAFI,
  ALGORAND,
  HECO,
  EDGEWARE,
  ZILLIQA,
]

//////////////////////////////////////
// OTHERS
Blockchains.OTHERS = [
  HEDERA_MAINNET,
  HEDERA_TESTNET,
  BSC,
  ETHEREUM,
  MATIC,
  POLKADOT,
  AVALANCHE,
  FANTOM,
  CRONOS,
  STAFI,
  ALGORAND,
  HECO,
  EDGEWARE,
  ZILLIQA,
]

//////////////////////////////////////
// Find a value
/**
 * Find a blockchain in the list by it's value in our database
 * 
 * @param {String} value 
 * @returns {Object | null}
 */
Blockchains.findByValue = (value) => {
  let ret = Blockchains.ALL.find(p => p.value === value)
  let ret2 = Blockchains.ALL.find(p => p.values.includes(value))
  return ret2 != null ? ret2 : (ret !== null ? ret : null)
}

export default Blockchains
