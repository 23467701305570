<template>
  <ui-card 
    :appearance="$pepper.Appearance.SUBTLE" 
    article>
    <template #header>
      <h3 class="ui-card__title">{{ $t('sayl.tier_type_card_title') }}</h3>
    </template>

    <article class="ui-card__article flow">
      <forms-choice
        :options="types"
        required
        :read-only="readOnly"
        v-model="type"
      />
    </article>

    <component
      :errors="errors"
      :read-only="readOnly"
      :is="type.cmp"
      v-if="type && type.cmp"
    />
  </ui-card>
</template>

<script>
import { mapState } from 'vuex'

import TierEditionTypeOrders from './orders.vue'
import TierEditionTypePoints from './points.vue'
import TierEditionTypeSegments from './segments.vue'
import TierEditionTypeSpent from './spent.vue'

export default {
  name: 'TierEditionTypes',

  props: {
    errors: { 
      type: Object 
    },
    
    readOnly: {
      type: Boolean,
      default: false
    },

    settings: {
      type: Object,
      default: null,
    },
  },

  computed: {
    ...mapState({
      edition: state => state.tier.tier.edition,
    }),

    type: {
      get() {
        let ret = this.edition.type

        if(this.$basil.get(this.settings, 'type')) {
          ret = this.types.find(r => this.$basil.get(this.settings, 'type').includes(r.value))
        } else {
          ret = this.types.find(t => t.value === this.edition.type)
        }

        return ret
      }, 

      set(value) {
        this.edition.type = value.value
      }
    },
    
    types() {
      let ret = [
        { 
          description: this.$t(`sayl.tier_type_points_description`),
          label: this.$t(`sayl.tier_type_points`),
          value: 'points',
          cmp: TierEditionTypePoints
        },
        { 
          description: this.$t(`sayl.tier_type_orders_description`),
          label: this.$t(`sayl.tier_type_orders`),
          value: 'orders',
          cmp: TierEditionTypeOrders,
        },
        { 
          description: this.$t(`sayl.tier_type_spent_description`),
          label: this.$t(`sayl.tier_type_spent`),
          value: 'spent',
          cmp: TierEditionTypeSpent,
        },
        { 
          description: this.$t(`sayl.tier_type_segment_description`),
          label: this.$t(`sayl.tier_type_segment`),
          value: 'segment',
          cmp: TierEditionTypeSegments
        },
        { 
          description: this.$t(`sayl.tier_type_manual_description`),
          label: this.$t(`sayl.tier_type_manual`),
          value: 'manual',
        },
      ]

      if(this.$basil.get(this.settings, 'type')) {
        ret = ret.filter(r => this.$basil.get(this.settings, 'type').includes(r.value))
      }

      return ret
    },
  },
}
</script>