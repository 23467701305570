export default class Invoice {
  constructor({ active = false, currency = 'EUR', lang = 'en', name = null, teamleader = null, tier = null, vat_rate = 0 }) {
    this._active = active 
    this._currency = currency
    this._lang = lang
    this._name = name
    this._teamleader = teamleader
    this._tier = tier
    this._vatRate = vat_rate
  }

  /////////////////////////////////////////
  /// Getters
  get active() {
    return this._active
  }

  get currency() {
    return this._currency
  }

  get lang() {
    return this._lang
  }

  get name() {
    return this._name
  }

  get teamleader() {
    return this._teamleader
  }

  get tier() {
    return this._tier
  }

  get vat_rate() {
    return this._vat_rate
  }
  /////////////////////////////////////////
}