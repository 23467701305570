import { mapGetters, mapState } from 'vuex'

import { default as SaylItems } from './sayl'
import { default as SaylConn3ct } from './conn3ct'
import { default as SaylCustomer } from './customer'
import { default as SaylResto } from './resto'
import { default as SaylSu } from './su'

import DefaultOrder from './default-order'

import { MixinInvoicing } from '@sayl/admin-common'

let baseState = {
  'sayl': {
    class: 'n-primary-top__sayl',
    fqn: 'sayl-product-sayl',
    label: 'sayl.product_sayl',
    interactive: false,
    logo: '/statics/images/logos/sayl/sayl-logomark-teal.svg',
    subs: [],
    useChildren: false,
    children: []
  },

  'sayl-resto':  {
    class: 'n-primary-top__sayl-resto',
    fqn: 'sayl-product-resto',
    href: { name: 'sayl-homepage-resto_index' },
    label: 'sayl.product_commerce',
    logo: '/statics/images/logos/sayl/sayl-logomark-red.svg',
    subs: [
      'sayl-homepage-resto_dashboard', 
      'sayl-homepage-resto_resources'
    ],
    useChildren: false,
    children: []
  },

  'sayl-conn3ct': {
    class: 'n-primary-top__sayl-connect',
    fqn: 'sayl-product-conn3ct',
    href: { name: 'sayl-homepage-conn3ct_index' },
    label: 'sayl.product_conn3ct',
    logo: '/statics/images/logos/sayl/sayl-logomark-yellow.svg',
    subs: [
      'sayl-homepage-conn3ct_dashboard', 
      'sayl-homepage-conn3ct_resources'
    ],
    children: [],
    useChildren: false,
  },

  'sayl-customer': {
    class: 'n-primary-top__sayl-customer',
    href: { name: 'sayl-homepage-customer_index' },
    fqn: 'sayl-product-customer',
    label: 'sayl.product_customer',
    logo: '/statics/images/logos/sayl/sayl-logomark-green.svg',
    subs: [
      'sayl-homepage-customer_dashboard', 
      'sayl-homepage-customer_resources'
    ],
    children: [],
    useChildren: false,
  },
}

export default {
  watch: {
    'integrations.length': {
      handler() {
        this.key++
      }
    },

    'programs.length': {
      handler() {
        this.loyaltyKey++
        this.reset()
      }
    }
  },

  mixins: [ MixinInvoicing ],

  data() {
    return {
      key: 1,
      loyaltyKey: 1
    }
  }, 

  computed: {
    ...mapState({     
      bootstrap: state => state.sayl.bootstrap,
      integrations: state => state.sayl.integrationsRoutes,
      programs: state => state.loyalty.loyalty.collection,
    }),

    ...mapGetters({
      config: 'sayl/config'
    }),

    body() {
      let nav = this.$basil.get(this.bootstrap, 'navigation')
      let hasCustomNav = !this.$basil.isNil(nav)
      let actives = []
      let inactives = []

      if(!hasCustomNav) {
        nav = DefaultOrder
      }

      if(
        this.$basil.get(this.config, 'env') !== 'production' && 
        this.$basil.get(this.bootstrap, 'user.profile.metadata.front_role') === 'su' &&
        this.$basil.get(this.bootstrap, 'user.is_impersonating', true) === false
      ) {
        let sayl = nav['sayl'].find(f => f.fqn === 'sayl-configure')

        if(sayl && sayl.children && sayl.children.find(f => f.fqn === 'sayl-configure-emails-fallbacks') == null) {
          sayl.children.push({ fqn: 'sayl-configure-emails-fallbacks' })
        }
      }

      let loyalty = nav['sayl'].find(f => f.fqn === 'sayl-customer-loyalty')
      if(loyalty) {
        if(this.programs && this.programs.length > 0) {
          loyalty.children = [
            { fqn: 'sayl-customer-loyalty-overview' },
            { fqn: 'sayl-customer-loyalty-program' },
            { fqn: 'sayl-customer-loyalty-cards' },
            { fqn: 'sayl-customer-loyalty-referral' },
          ]
        } else {
          delete loyalty.children
        }
      }

      Object.keys(nav).forEach(k => {
        let v = this.$basil.get(baseState, k)
        if(this.$basil.isNil(v)) {
          return
        }
        
        v.children = this.productChildren(nav[k])
        
        if(this.isProductActive(v)) {
          actives.push(v)
        } else {
          inactives.push(v)
        }
      })

      return this.loyaltyKey && actives.concat(inactives)
    },

    list() {
      let loyalty = SaylItems.find(f => f.fqn === 'sayl-customer-loyalty')

      if(loyalty) {
        if(!this.programs || this.programs.length == 0) {
          loyalty.href = { name: 'sayl-customer-loyalty_overview' }
        } else {
          delete loyalty.href
        }
      }

      let ret = SaylItems
        .concat(SaylResto)
        .concat(SaylCustomer)
        .concat(SaylConn3ct)
        .concat(this.integrations)
      
      if(
        this.$basil.get(this.config, 'env') !== 'production' && 
        this.$basil.get(this.bootstrap, 'user.profile.metadata.front_role') === 'su' &&
        this.$basil.get(this.bootstrap, 'user.is_impersonating', true) === false
      ) {
        ret = ret.concat(SaylSu)
      }

      return this.key && ret
    },

    fullNavigation() {
      let ret = Object.keys(baseState).map(l => {
        return baseState[l]
      })
      return ret.concat(this.list)
    },

    navigation() {
      return this.loyaltyKey && {
        body: this.body,
        
        footer: [],

        identity: {
          active: false,
          description: 'sayl.profile_description',
          fqn: 'sayl-identity',
          icon: 'users',
          icons: ['star', 'user', 'award'],
          locked: false,
          children: [
            {
              active: false,
              description: 'sayl.profile_description',
              fqn: 'sayl-identity-identity',
              icon: 'users',
              locked: false,
            }
          ]
        }
      }
    }
  },

  methods: {
    format(item) {
      let ret = null

      let fqn = this.$basil.get(item, 'fqn')
      if(this.$basil.isNil(fqn)) {
        return 
      }
      
      ret = this.list.find(l => l.fqn === fqn)

      if(this.$basil.isNil(ret)) {
        return 
      }

      if(item.fqn === 'sayl-customer-loyalty' && (!this.programs || this.programs.length <= 0)) {
        delete ret.children 
      }

      let href = this.$basil.get(ret, 'href')
      if(!this.$basil.isNil(href)) {
        ret.locked = this.isRouteLocked(href)
      }

      let children = this.$basil.get(item, 'children', [])
      if(!this.$basil.isNil(children) && children.length > 0) {
        ret.children = []
        children.forEach(c => {
          let subs = this.$basil.get(c, 'subs', [])
          ret.children.push(this.format(c))
          c.href ? ret.subs.push(c.href) : null
          subs.forEach(s => !ret.subs.includes ? ret.subs.push(s) : null)
          ret.subs = ret.subs.flat(0)
        }) 
      }
      
      return ret
    },

    isRouteLocked(name) {
      let route = this.$router.resolve(name)
      let resolved = this.$basil.get(route, 'resolved')

      let resource = this.$basil.get(resolved, 'meta.resource')
      let feature = this.$basil.get(resolved, 'meta.feature', null)

      return this.isInnerLockedByInvoice(resource, feature)
    },

    productChildren(items) {
      let ret = []

      items.forEach(i => {
        let v = this.format(i)
        if(!this.$basil.isNil(v)) {
          ret.push(v)
        }
      })

      return ret
    },

    isProductActive(p) {
      let ret = []

      if(p.children) {
        p.children.forEach((c) => {
          if(c.children) {
            c.children.forEach(cc => {
              ret.push(cc.locked)
            })
          }
          ret.push(c.locked)
        })
      }
      
      return ret.filter(c => c === false).length > 0
    }
  }
}
