const ImageBinder = {}
ImageBinder.install = function (Vue, options) {
  Vue.prototype.$getImageSrc = function (path) {
    let basePath = options.store.getters['sayl/config'].cdn
    let src = basePath + path
    src = src.split('//').join('/')
    src = src.split('https:/').join('https://')
    return src
  }
}

/**
 * Register @spices/pepper
 * 
 * @param {Object} options
 * @param {GingerCapabilities} options.capabilities
 * @returns {Promise.resolve}
 */
export default function ({ capabilities }) {
  $console.group('images')
  
  let Vue = capabilities.vue
  Vue.use(ImageBinder, { store: capabilities.store })
  
  $console.groupEnd('images')
  return Promise.resolve()
}
