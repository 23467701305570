<template>
  <layout-level1 v-bind="$props">

    <!-- Header -->
    <template v-slot:header>
      <navigations-tabs>
        <navigations-tabs-item
          v-for="(view, i) in allowed"
          :key="i"
          :href="view.route"
          >{{ view.label }}</navigations-tabs-item>
      </navigations-tabs>
    </template>

    <!-- Body -->

    <ui-feature>
      <ui-acl>
        <ui-errors 
          :errors="errors"
          @back="$emit('back')">
          <router-view :key="$route.path" />
        </ui-errors>
      </ui-acl>
    </ui-feature>
  </layout-level1>
</template>

<script>
import { 
  MixinACL,
  MixinError, 
  MixinHeader, 
  MixinInheritance 
} from '@sayl/admin-common'

export default {
  name: 'LayoutLevel1Tabs',

  mixins: [
    MixinACL,
    MixinError, 
    MixinHeader,
    MixinInheritance
  ],

  props: {
    errors: {
      type: Object,
      default:() => { 
        return {} 
      }
    },

    views: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  computed:{
    allowed(){
      let ret = this.views;

      // Allowed route?
      ret.forEach(e => {
        let route = this.$router.resolve(e.route);
        if (route && route.route){
          route = route.route;
          let privilege = route.meta.privilege || this.$basil.sayl.VIEW;
          let resource = route.meta.resource;
          e.isAllowed = this.isAllowed(resource, privilege);
        }
      })

      ret = ret.filter(r => r.isAllowed === true);

      return ret;
    }
  },
};
</script>
