

export default class Currency {
  constructor({ created = null, id = null, iso_code = null, modified = null, name = null, sign = null }) {
    try {
      this._created = created ? new Date(created) : null
    } catch(e) {
      console.error('Tier:contructor:Created:', e.message)
    }
    this._id = id
    this._isoCode = iso_code
    try {
      this._modified = modified ? new Date(modified) : null
    } catch(e) {
      console.error('Tier:contructor:Modified:', e.message)
    }
    this._name = name
    this._sign = sign
  }

  /////////////////////////////////////////
  /// Getters
  get created() {
    return this._created
  }

  get id() {
    return this._id
  }

  get isoCode() {
    return this._isoCode
  }

  get modified() {
    return this._modified
  }

  get name() {
    return this._name
  }

  get sign() {
    return this._sign
  }
}