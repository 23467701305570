import { oauth } from './'
import { config } from '@/env'

export default {

  /**
   * Start the authentication process with the oauth server
   * 
   * @returns {Promise}
   */
  authenticate({ hash = null }) {
    let url = [config.transports.cp.oauth, 'initialize'].join('');
    
    let payload = {
      redirect: window.location.href,
    }
    
    if(hash) {
      let redirect = window.location.href
      let regexp = new RegExp(`(onetime-login-hash=${hash})([&])?`, 'g')
      redirect = redirect.replace(regexp, '')
      payload.redirect = redirect
      payload.onetime_login_hash = hash
    }

    return oauth.post(url, payload)
      .then((response) => {
        window.location = response.data.redirect;
      }).catch((error) => {
        // handle error
        console.error('bootstrap.api.authenticate failed with message: ', error.message);
      })
  },

  /**
   * Triggers the users logout
   */
  logout() {
    let url = [config.transports.cp.oauth, 'forget'].join('');
    
    return oauth.post(url)
      .then(() => window.location = '/')
      .catch((error) => console.error(error))
  },

  logoutWithoutRedirect() {
    let url = [config.transports.cp.oauth, 'forget'].join('');
    return oauth.post(url)
  }
}
