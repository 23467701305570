import errorsRoutes from './errors'
import EmailEditor from '../views/email-editor-demo'
import ExtractTranslations from '../views/extract-translations'
import Tiers from '../views/tiers'
import Tier from '../views/tiers/item'

import LoyaltyRoutes from './loyalty.js'

import { isDevelopment } from '../env'

const routes = [
  ...LoyaltyRoutes,

  isDevelopment ? {
    name: 'translation-extractor',
    path: '/extract-translations',
    component: ExtractTranslations,
  } : null,

  isDevelopment ?  {
    name: 'email-editor',
    path: '/email-editor/demo',
    component: EmailEditor
  } : null,


  {
    name: 'sayl-tiers',
    path: '/tiers',
    component: Tiers,
    meta: {
      resource: 'sayl.tiers'
    },
    children: [
      {
        name: 'sayl-tier',
        path: ':tier',
        component: Tier,
        meta: {
          resource: 'sayl.tiers'
        },
      }
    ]
  },
].filter(r => r != null)

export default routes
  .concat(errorsRoutes)
