<template>
  <div class="data-table-cell-code">{{ content }}</div>
</template>

<script>
export default {
  name: 'DataTableCellCode',

  props: {
    content: {}
  }
}
</script>
