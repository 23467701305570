<template>
  <div class="tiers-pretty-list">
    <div 
      class="tiers-pretty-list__loading" 
      v-if="loading">
      <ui-loader />
    </div>

    <div 
      class="tiers-pretty-list__tiers" 
      v-if="!loading">
      <div class="tiers-pretty-list__base-type">{{ type }}</div>
      
      <data-empty
        class="tiers-pretty-list__empty"
        :description="!readOnly ? $t('sayl.tier_empty_description') : $t('sayl.tier_empty_description_read_only')"
        :primary-action="!readOnly ? $t('sayl.tier_add_action') : null"
        :title="$t('sayl.tier_empty_title')"
        @click="!readOnly ? $emit('create') : () => {}"
        v-if="isEmpty"
      />

      <div 
        class="tiers-pretty-list__tier" 
        :class="{
          '-is-disabled': !tier.active || $basil.get(tier, 'rewards.length', 0) == 0
        }"
        v-for="tier in tiers">
        <div 
          class="tiers-pretty-list__inactive" 
          v-if="!tier.active || $basil.get(tier, 'rewards.length', 0) == 0">
          <ui-icon 
            glyph="alert-circle" 
            :title="tier.active ? null : $t('sayl.tier_is_inactive')"
            v-if="!tier.active"
          />

          <ui-icon 
            glyph="alert-triangle"
            :title="tier.active ? null : $t('sayl.tier_has_no_rewards')"
            v-if="$basil.get(tier, 'rewards.length', 0) == 0"
          />
        </div>


        <div class="tiers-pretty-list__tier-body">
          <div class="tiers-pretty-list__labels">
            <h4 class="tiers-pretty-list__title">{{ tier.name }}</h4>
            <p class="tiers-pretty-list__descr">{{ tier.description.all }}</p>
          </div>

          <div 
            class="tiers-pretty-list__meta" 
            v-if="getMeta(tier)"
          >{{ getMeta(tier) }}</div>

          <div class="tiers-pretty-list__actions actions-group">
            <actions-button
              icon-post="edit"
              :size="$pepper.Size.S"
              @click="$emit('edit', tier)"
            >{{ readOnly ? $t('sayl.tiers_view_action') : $t('sayl.tiers_edit_action') }}</actions-button>

            <actions-button
              icon-post="trash"
              :size="$pepper.Size.S"
              @click="$emit('remove', { fqn: tier.id })"
              v-if="!readOnly"
            />
          </div>
        </div>
      </div>

      <actions-button
        class="tiers-pretty-list__add"
        icon-post="plus"
        :size="$pepper.Size.S"
        @click="$emit('create')"
        v-if="!readOnly"
      >{{ $t('sayl.tiers_add_action') }}</actions-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TiersPrettyList',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    readOnly: {
      type: Boolean,
      default: false,
    },

    settings: {
      type: Object,
      default: null,
    },

    values: {
      type: Array,
      default: () => []
    }
  }, 

  computed: {
    ...mapState({
      segments: state => state.tier.tier.segments.all,
      program: state => state.tier.tier.loyalty.edition
    }),
    
    isEmpty() {
      return this.$basil.isNil(this.values) || this.$basil.isEmpty(this.values)
    },

    tiers() {
      let type = this.$basil.get(this.settings, 'type', null)

      if(type === 'segments') {
        return this.values.sort((a, b) => parseInt(a.priority, 10) - parseInt(b.priority, 10))
      }
      
      return this.values.sort((a, b) => parseInt(a.amount, 10) - parseInt(b.amount, 10))
    },
    
    type() {
      let ret = this.$basil.get(this.settings, 'type', null)
      return ret ? this.$t(`sayl.tier_settings_type_${ret}`) : ''
    }
  },

  methods: {
    getMeta(tier) {
      let type = this.$basil.get(this.settings, 'type', '')

      if(type === 'segments') {
        let segment = (this.segments || []).find((s) => s.id === this.$basil.get(tier, 'segmentId'))
        if(segment) {
          return this.$basil.get(segment, 'name')
        } 
        return null
      }

      let ret = this.$basil.get(tier, 'amount')
      let amount = this.$basil.get(tier, 'amount')

      if(amount) {
        amount = parseInt(amount, 10)
      }

      if(type.includes('points')) {
        ret = `${amount} ${this.$basil.get(this.program, 'points.symbol', '')}`
      } else if(type.includes('spent')) {
        ret = this.$basil.i18n.currency(amount)
      }

      return !this.$basil.isNil(amount) ? ret : ''
    },

    reset() {
      window.$dl.tier.getSegments()
        .then(() => window.$dl.tier.getLoyalty())
        .catch((e) => $console.error(e))
    }
  },

  mounted() {
    this.reset()
  }
}
</script>