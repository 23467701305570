<template>
  <article class="layout-modal__article flow"> 
    <div class="group">
      <forms-select
        :disabled="loading"
        :hint="typesHint"
        :placeholder="$t('sayl-audiences.challenge_reward_type_voucher_type_placeholder')"
        required
        :options="types"
        @change="getVouchers"
        v-model="value.settings.voucher_type"
      >{{ $t('sayl-audiences.challenge_reward_type_voucher_type_label') }}</forms-select>

      <forms-select
        :disabled="loading"
        :hint="$t('sayl-audiences.challenge_reward_type_voucher_template_hint')"
        :placeholder="$t('sayl-audiences.challenge_reward_type_voucher_template_placeholder')"
        required
        :loading="loading"
        :options="vouchers || []"
        @change="onVoucherChange"
        v-model="value.settings.voucher_template_id"
      >{{ $t('sayl-audiences.challenge_reward_type_voucher_template_label') }}</forms-select>

      <actions-button
        class="modal-reward__action"
        icon-post="redo"
        :disabled="loading"
        @click="getVouchers"
      />
    </div>

    <div class="group">
      <forms-input
        :hint=" $t('sayl-audiences.challenge_reward_type_voucher_validity_period_hint') "
        min="1"
        :placeholder=" $t('sayl-audiences.challenge_reward_type_voucher_validity_period_placeholder') "
        required
        step="1"
        type="number"
        v-model="value.settings.voucher_validity_period"
      >{{ $t('sayl-audiences.challenge_reward_type_voucher_validity_period_label') }}</forms-input>

      <forms-input
        :hint=" $t('sayl-audiences.challenge_reward_type_voucher_amount_hint') "
        min="1"
        :placeholder=" $t('sayl-audiences.challenge_reward_type_voucher_amount_placeholder') "
        required
        step="1"
        type="number"
        v-if="value.settings.voucher_type !== 'perks'"
        v-model="value.settings.voucher_amount"
      >{{ $t('sayl-audiences.challenge_reward_type_voucher_amount_label') }}</forms-input>
    </div>
  </article>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ChallengeTypeVoucher',

  props: {
    value: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      loading: true,
      type: null
    }
  },

  computed: {
    ...mapState({
      vouchers: state => state.audiences.challenge.vouchers.all
    }),

    typesHint() {
      let route = this.$router.resolve({ name: 'sayl-loyalty_vouchers-templates' })

      return `<a href="${route.href}" target="_blank">${this.$t('sayl-audiences.challenge_reward_type_voucher_type_hint')}</a>`
    },

    types() {
      return [
        {
          label: this.$t('sayl-loyalty.voucher_type_discount'),
          value: 'discount',
        },
        {
          label: this.$t('sayl-loyalty.voucher_type_wallet'),
          value: 'wallet',
        },
        {
          label: this.$t('sayl-loyalty.voucher_type_perks'),
          value: 'perks',
        },
      ]
    }
  },

  methods: {
    getVouchers() {
      this.loading = true

      this.$audiences.challenge.getVouchers({ type: this.value.settings.voucher_type || null })
        .then(() => {
          let v = this.vouchers.find(voucher => voucher.id === this.value.settings.voucher_template_id)
          if(this.$basil.isNil(v)) {
            this.value.settings.voucher_template_id = null
          }

          if(this.$basil.isNil(this.value.settings.voucher_validity_period)) {
            this.value.settings.voucher_validity_period = 365
          }
        })
        .catch((e) => $console.error(e))
        .finally(() => this.loading = false)
    },

    onVoucherChange(v) {
      let voucher = this.vouchers.find(voucher => voucher.value === v)

      this.value.settings.voucher_amount = voucher.amount
      this.value.settings.voucher_type = voucher.type
    }
  },

  mounted() {
    this.loading = true
    this.getVouchers()
  }
}
</script>
