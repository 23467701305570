<template></template>

<script>
import { mapState } from 'vuex'


export default {

  computed: {
    ...mapState({
      programs: state => state.loyalty.loyalty.collection,
    })
  },

  mounted() {
    window.$dl.loyalty.collection({})
      .then(() => {
        if(this.programs && this.programs.length > 0) {
          this.$router.replace({ name: 'sayl-customer-loyalty_program', params: { program: this.programs[0].id }})
          return
        }
        
        this.$router.replace({ name: 'sayl-customer-loyalty_home' }).catch((e) => {})
        return 
      })
      .catch((e) => $console.error(e))
  },
}
</script>