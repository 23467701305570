<template>
  <div class="layout-level2__container container view-loyalty-config__cols">
    <div class="col">
      <ui-card :appearance="$pepper.Appearance.SUBTLE" article>
        <article class="ui-card__article layout-modal__article flow">
          <div class="group">
            <!-- Name -->
            <forms-input
              :class="hasTranslations('name')"
              icon-post="l8n"
              :icon-post-interactive="true"
              :errors="getErrors('name.all')"
              :hint="$t('sayl-ecommerce.loyalty_config_name_hint')"
              :placeholder="$t('sayl-ecommerce.loyalty_config_name_placeholder')"
              :read-only="readOnly"
              required
              type="text"
              @iconClick="onOpenTranslations('name')"
              @input="onRemoveError('name.all')"
              @change="onNameChange"
              v-model="edition.name.all"
            >{{$t('sayl-ecommerce.loyalty_config_name_label')}}</forms-input>

            <!-- Slug -->
            <forms-input
              :errors="getErrors('slug')"
              :hint="$t('sayl-ecommerce.loyalty_config_slug_hint')"
              :placeholder="$t('sayl-ecommerce.loyalty_config_slug_placeholder')"
              :read-only="readOnly"
              required
              type="text"
              @input="onRemoveError('slug')"
              v-model="edition.slug"
            >{{ $t('sayl-ecommerce.loyalty_config_slug_label') }}</forms-input>
          </div>
        </article>
      </ui-card>

      <config-points 
        :errors="errors"
        :read-only="readOnly"
      />

      <config-rewards 
        :errors="errors"
        :read-only="readOnly"
      />

      <config-redemption 
        :dirty="dirtyVouchers"
        :errors="errors"
        :read-only="readOnly"
      />
    </div>

    <div class="col">
      <config-dates 
        :errors="errors"
        :read-only="readOnly"
      />
      
      <config-loyalty-cards
        :errors="errors"
        :read-only="readOnly"
      />

      <config-breakage
        :errors="errors"
        :read-only="readOnly"
      />

      <config-claims
        :errors="errors"
        :read-only="readOnly"
      />

      <config-communication
        :errors="errors"
        :read-only="readOnly"
        v-if="!isNew"
      />
      
      <config-excluded-shops 
        :errors="errors"
        :read-only="readOnly"
        v-if="!isNew"  
      />

    </div>

    <modal-translate 
      @close="onCloseTranslations"
      :readOnly="readOnly"
      :field="translationsField"
      :type="translationsType"
      :visible="showTranslations" 
    />
  </div>
</template>

<script>
import { 
  mapGetters, 
  mapState, 
} from 'vuex'

import {
  MixinACL,
  MixinEditionSave,
  MixinEditionDiscard,
  MixinError,
  MixinTranslation,
} from '@sayl/admin-common'

import ConfigBreakage from './breakage'
import ConfigClaims from './claims'
import ConfigCommunication from './communication'
import ConfigDates from './dates'
import ConfigExcludedShops from './excluded-shops'
import ConfigLoyaltyCards from './loyalty-cards'
import ConfigPoints from './points'
import ConfigRedemption from './redemption'
import ConfigRewards from './rewards'

export default {
  name: 'LoyaltyProgramConfig',

  components: {
    ConfigBreakage,
    ConfigClaims,
    ConfigCommunication,
    ConfigDates,
    ConfigExcludedShops,
    ConfigLoyaltyCards,
    ConfigPoints,
    ConfigRedemption,
    ConfigRewards,
  },

  props: {
    dirtyVouchers: {
      type: Array,
      default: () => [],
    },

    errors: {
      type: Object,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [
    MixinACL,
    MixinEditionDiscard,
    MixinEditionSave,
    MixinError,
    MixinTranslation,
  ],

  computed: {
    ...mapState({
      edition: state => state.loyalty.loyalty.edition,
      origin: state => state.loyalty.loyalty.origin,
    }),

    ...mapGetters({
      pristine: 'ecommerce/loyalty.pristine'
    }),

    isNew() {
      return this.$basil.isNil(this.$route.params.program)
    }
  },

  methods: {
    onNameChange() {
      if(this.$basil.isNil(this.edition.slug) || this.$basil.isEmpty(this.edition.slug)) {
        this.edition.slug = this.$basil.slugify(this.edition.name.all);
        delete this.errors['slug'];
      }
    },
  },
}
</script>
