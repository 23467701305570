<template>
  <div :class="classes">
    <forms-label 
      class="forms-control__label forms-cron__label"
      v-if="hasDefault"
    ><slot/></forms-label>

    <div class="forms-cron__body">
      <vue-cron-editor-buefy 
        :locale="$basil.i18n.locale.lang"
        :custom-locales="iI18n"
        :visibleTabs="iTabs"
        v-model="expression" 
      />
    </div>

    <div class="forms-control__footer">
      <forms-label 
        class="forms-control__hint"
        :size="$pepper.Size.S" 
        v-html="hint"
        v-if="hint"
      ></forms-label>

      <div
        class="forms-control__errors forms-cron__errors"
        v-if="hasErrors">
          <forms-label 
            class="forms-control__label"
            :key="`forms-cron-errors_${i}`"
            :intent="$pepper.Intent.DANGER"
            v-for="(e, i) in errors"
          >{{ e }}</forms-label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import VueCronEditorBuefy from 'vue-cron-editor-buefy';

export default {
  name: 'FormsCron',

  components: { VueCronEditorBuefy },

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    errors: {
      type: Array,
    },

    hint: {
      type: String,
      default: null
    },

    i18n: {
      type: Object,
      default: null,
    },
    
    readOnly: {
      type: Boolean,
      default: false,
    },

    required: {
      type: Boolean,
      default: false,
    },

    tabs: {
      type: Array,
      default: null,
    },

    value: {
      type: String | null,
      required: true,
      format: (value) => value === null ? '' : value
    }
  },

  data() {
    return {
      expression: ''
    }
  },

  computed: {
    ...mapState({
      host: state => state.host.edition
    }),

    classes() {
      return {
        'forms-cron': true,
        'forms-control': true,

        '-is-disabled': this.disabled,
        '-is-errored': this.hasErrors,
        '-is-readonly': this.readOnly,
        '-is-required': this.required
      }
    },

    iI18n() {
      if(!this.$basil.isNil(this.i18n)) {
        return this.i18n
      }

      return {
        [this.$basil.i18n.locale.lang]: {
          every: this.$t('sayl.cron_every'), //"Every",
          mminutes: this.$t('sayl.cron_mminutes'), // "minute(s)",
          hoursOnMinute: this.$t('sayl.cron_hours_on_minutes'), //"hour(s) on minute",
          daysAt: this.$t('sayl.cron_days_at'), //"day(s) at",
          at: this.$t('sayl.cron_at'), //"at",
          onThe: this.$t('sayl.cron_on_the'), //"On the",
          dayOfEvery: this.$t('sayl.cron_day_if_every'), //"day, of every",
          monthsAt: this.$t('sayl.cron_months_at'), //"month(s), at",
          everyDay: this.$t('sayl.cron_every_day'), //"Every",
          mon: this.$t('sayl.cron_mon'), //"Mon",
          tue: this.$t('sayl.cron_tue'), //"Tue",
          wed: this.$t('sayl.cron_wed'), //"Wed",
          thu: this.$t('sayl.cron_thu'), //"Thu",
          fri: this.$t('sayl.cron_fri'), //"Fri",
          sat: this.$t('sayl.cron_sat'), //"Sat",
          sun: this.$t('sayl.cron_sun'), //"Sun",
          hasToBeBetween: this.$t('sayl.cron_has_to_be_between'), //"Has to be between",
          and: this.$t('sayl.cron_and'), //"and",
          minutes: this.$t('sayl.cron_minutes'), //"MINUTES",
          hourly: this.$t('sayl.cron_hourly'), //"HOURLY",
          daily: this.$t('sayl.cron_daily'), //"DAILY",
          weekly: this.$t('sayl.cron_weekly'), //"WEEKLY",
          monthly: this.$t('sayl.cron_monthly'), //"MONTHLY",
          advanced: this.$t('sayl.cron_advanced'), //"ADVANCED",
          cronExpression: this.$t('sayl.cron_expression'), //"cron expression:"
        }
      }
    },

    iTabs() {
      if(this.tabs) {
        return this.tabs
      }
    },

    hasErrors() {
      return this.errors && this.errors.length > 0
    },

    hasDefault() {
      return !!this.$slots.default
    }
  },

  watch: {
    expression: {
      handler() {
        if(this.expression === null) {
          this.expression = ''
        }
        this.$emit('change', this.expression)
      }
    }
  },

  methods: {
    onChange(val) {
      this.$emit('change', val)
    },

    reset() {
      this.expression = this.value
    }
  },

  mounted() {
    this.reset()
  }
}
</script>
