import { mapState } from 'vuex'
import FormsEmbed from './embed'

import { MixinTranslation } from '@sayl/admin-common'
export default {
  mixins: [ MixinTranslation ],

  data() {
    return {
      key: 1
    }
  },

  computed: {
    ...mapState({ 
      embeds: state => state.tier.tier.embeds.all
    }),

    embedId() {
      let ret = this.embeds || []
      ret = ret.find(e =>  e.value === this.$basil.get(this.edition, 'perk.grant.embed_id', null))
      return this.key && !this.$basil.isNil(ret) ? ret.value : null
    },

    grantFields() {
      let g = this.grants.find(g => g.value === this.edition.perk.grant.type)
      return !this.$basil.isNil(g) ? g.fields : []
    },

    grants() {
      return [
        {
          fields: [
            {
              component: 'forms-input',
              errors: this.getErrors('grant.value'),
              key: this.$basil.uniqId(this.key),
              label: this.$t('sayl.tier_perk_grant_value'),
              listeners: {
                input: (value) => {
                  this.edition.perk.grant.value = parseFloat(value, 10)
                  this.onRemoveError('grant.value')
                  if(Number.isNaN(this.edition.perk.grant.value)) { 
                    this.edition.perk.grant.value = null
                  }
                  this.key++
                  this.$bus.$emit('refresh-card')
                }
              },
              min: '0',
              placeholder: this.$t('sayl.placeholder'),
              property: 'value',
              required: true,
              type: 'number',
              value: this.edition.perk.grant.value,
              suffix: '%'
            }
          ],
          label: this.$t('sayl.tier_perk_grant_type_discount_perc'),
          value: 'discount_perc',
        },
        {
          fields: [
            {
              component: 'forms-input',
              errors: this.getErrors('grant.value'),
              key: this.$basil.uniqId(this.key),
              label: this.$t('sayl.tier_perk_grant_value'),
              listeners: {
                input: (value) => {
                  this.edition.perk.grant.value = parseFloat(value, 10)
                  this.onRemoveError('grant.value')
                  if(Number.isNaN(this.edition.perk.grant.value)) { 
                    this.edition.perk.grant.value = null
                  }
                  this.key++
                  this.$bus.$emit('refresh-card')
                },
              },
              min: '0',
              placeholder: this.$t('sayl.placeholder'),
              property: 'value',
              required: true,
              type: 'number',
              value: this.edition.perk.grant.value,
              suffix: this.$basil.get(this.$i18n.getNumberFormat(this.$i18n.locale), 'currency.currency', 'EUR'),
            }
          ],
          label: this.$t('sayl.tier_perk_grant_type_discount_value'),
          value: 'discount_amount',
        },
        {
          fields: [
            {
              component: FormsEmbed,
              label: '',
              eErrors: this.errors,
              property: 'embed_id',
              required: true,
              listeners: {
                change: (value) => {
                  this.key++
                }
              }
            },
          ],
          label: this.$t('sayl.tier_perk_grant_type_grant_access'),
          value: 'grant_access',
        },
        {
          fields: [
            {
              class: this.edition && this.$basil.get(this.edition, 'perk.grant.message') != null ? this.hasTranslations('perk.grant.message', this.edition) : {},
              component: 'forms-textarea',
              'icon-post': 'l8n',
              'icon-post-interactive': true,
              errors: this.getErrors('perk.grant.message.all'),
              key: this.$basil.uniqId(this.key),
              label: this.$t('sayl.tier_instruction_shop_associate'),
              listeners: {
                iconClick: () => this.onOpenTranslations('perk.grant.message', 'forms-textarea'),
                input: (value) => {
                  if(this.$basil.isNil(this.edition.perk.grant.message)) {
                    this.edition.perk.grant.message = { all: null }
                  }
                  this.edition.perk.grant.message.all = value
                  this.onRemoveError('perk.grant.message.all')
                  this.key++
                  this.$bus.$emit('refresh-card')
                }
              },
              placeholder: this.$t('sayl.placeholder'),
              property: 'message.all',
              required: true,
              rows: 5,
              value: this.$basil.get(this.edition, 'perk.grant.message.all', null),
            }
          ],
          label: this.$t('sayl.tier_perk_grant_type_offline'),
          value: 'offline',
        }
      ]
    },
    
    isGrantValid() {
      let ret = !this.$basil.isNil(this.edition.perk.grant.type) && !this.$basil.isEmpty(this.edition.perk.grant.type)
      
      let c = this.grantFields.filter(f => { 
        let v = this.edition.perk.grant[f.property]
        if(!f.ignore && f.property.includes('.')){
          let props = f.property.split('.')
          props.forEach(p => {
            v = this.$basil.isNil(v) ? this.edition.perk.grant[p] : v[p]
          })
        }

        let r = f.ignore !== true && (this.$basil.isNil(v) || (!this.$basil.isNumber(v) && this.$basil.isEmpty(v)) || (this.$basil.isNumber(v) && v <= 0))
        
        if(r) {
          this.errors['grant.' + f.property] = [this.$t('validation.field_is_required')]
        }
        return r
      })

      return ret && c.length === 0
    },
  },

  methods: {
    onGrantChange() {
      if(this.edition.perk.grant.type === this.origin.grant.type) {
        this.edition.perk.grant = JSON.parse(JSON.stringify(this.origin.grant))
      } else {
        this.edition.perk.grant = {
          type: this.edition.perk.grant.type
        }
      }
    },
  },
}
