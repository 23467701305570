export default {
  name: 'MixinLoyaltyPageProperties',

  props: {
    isLogged: {
      type: Boolean,
      default: false,
    },

    pageContent: {
      type: Object
    },

    pageStyle: {
      type: Object,
    },

    readOnly: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      key: 1,
      add: {
        how: {
          title: {
            all: null
          },
          description: {
            all: null
          }
        },
        ways: {}
      },

      addKey: 1,

      addErrors: {},

      defaultImages: {
        page_header: 'https://dczszawruqwxj.cloudfront.net/infinitycommerce/loyalty_program/63f89da8911045102a005fad/63f89da8e7526.png',
        page_ways_0: 'https://dczszawruqwxj.cloudfront.net/infinitycommerce/loyalty_program/63fcc04846b4cacc3c092168/63fcc048786b2.png',
        page_ways_1: 'https://dczszawruqwxj.cloudfront.net/infinitycommerce/loyalty_program/63fcc07aa85771630108feba/63fcc07a7efff.png',
        page_ways_2: 'https://dczszawruqwxj.cloudfront.net/infinitycommerce/loyalty_program/6400b8c346b4cacc3c092194/6400b8c3696ed.png',
      }
    }
  },

  watch: {
    isLogged: {
      immediate: true,
      handler() {
        this.key++
      }
    }
  },

  methods: {
    getContentValue(section, name, logged = this.isLogged) {
      return this.pageContent.get(section).getTranslatable(name, { logged: logged })
    },

    getStyleValue(name) {
      return this.pageStyle.get(name)
    },

    onContentChange(section, name, value, logged = this.isLogged) {
      this.pageContent.get(section).getTranslatable(name, { logged: logged }).value.all = value
      this.$emit('change')
    },

    onContentAdd(section) {
      this.addErrors = {}

      section.section.children.forEach((child) => {
        if(this.$basil.isNil(this.add[section.section.section][child.subproperty].all)) {
          this.addErrors[child.subproperty] = [this.$t('validation.field_is_required')]
        }
      })
      
      if(Object.keys(this.addErrors).length === 0) {
        section.models.push(this.add[section.section.section])

        this.add[section.section.section] = []
        section.section.children.forEach((child) => {
          this.add[section.section.section][child.subproperty] = { all: null }
        })
        this.addKey++
  
        this.$emit('change')
      }
    },

    onContentRemove(values, index) {
      this.$confirm({
        primaryAction: this.$t('sayl.confirm'), 
        secondaryAction: this.$t('sayl.cancel'), 
        title: this.$t('sayl-loyalty.page_confirm_remove_item_title'), //'Are you sure?',
        description: this.$t('sayl-loyalty.page_confirm_remove_item_description'), //'Do you want to remove this item from the display?',
        onPrimary: () => {
          values.models.splice(index, 1)
          this.$emit('change')
        }
      })
    },

    onImageClear({ data, images, multiple, values }, section, property, model) {
      this.clearImages({ data, images, multiple, values })
      let fieldName =  data.fieldName
      
      if(model) {
        model[property].all = this.defaultImages[fieldName]
      } else {
        this.pageContent.get(section).set(property, this.defaultImages[fieldName], { logged: null, lang: 'all' })
      }
      
      this.$emit('change')
    },

    onImageChange({ data, images, multiple, values }, section, property, model = null) {
      this.saveImages({ data, images, multiple, values })
        .then((data) => {
          if(data[0]) {
            if(model) {
              model[property].all = this.$basil.get(data, '[0].url.all')
            } else {
              this.pageContent.get(section).set(property, this.$basil.get(data, '[0].url.all'), { logged: null })
            }
            this.$emit('change')
          }
        })
        .catch((e) => $console.error(e))
    },


    onStyleChange(value, name) {
      this.pageStyle.set(name, value && value.target && value.target.value ? value.target.value : value)
      this.$emit('change')
    },

    onSummary(event) {
      event.preventDefault()
      this.$emit('open')
    },

    translastionClosed() {
      this.onCloseTranslations()
      this.$emit('change')
    },
  }
}
