const base = [
  {
    clearable: true,
    component: 'forms-input',
    fqn: 'how-style-bg',
    label: 'bg',
    property: '--slp-how-bg',
    section: 'how',
    subtype: 'color',
    type: 'style',
  },
]

const title = [
  {
    component: 'forms-input',
    fqn: 'how-content-title',
    label: 'title',
    property: 'title',
    section: 'how',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'how-style-title-fc',
    label: 'fc',
    property: '--slp-how-title-fc',
    section: 'how',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-select',
    fqn: 'how-style-title-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-how-title-fs',
    section: 'how',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component: 'forms-select',
    fqn: 'how-style-title-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-how-title-fw',
    section: 'how',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const description = [
  {
    component: 'forms-textarea',
    fqn: 'how-content-descr',
    label: 'description',
    property: 'description',
    section: 'how',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'how-style-descr-fc',
    label: 'fc',
    property: '--slp-how-descr-fc',
    section: 'how',
    subtype: 'color',
    type: 'style',
  },
  {
    fqn: 'how-style-descr-fs',
    component:'forms-select',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-how-descr-fs',
    section: 'how',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    fqn: 'how-style-descr-fw',
    component:'forms-select',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-how-descr-fw',
    section: 'how',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const card = [
  {
    component: 'forms-input',
    fqn: 'how-content-meta',
    label: 'label',
    logged: true,
    property: 'meta',
    section: 'how',
    subtype: 'text',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'how-style-card-bg',
    label: 'bg',
    logged: true,
    property: '--slp-how-card-bg',
    section: 'how',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'how-style-card-fc',
    label: 'fc',
    logged: true,
    property: '--slp-how-card-fc',
    section: 'how',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'how-style-card-stroke-bg',
    label: 'stroke',
    logged: true,
    property: '--slp-how-card-stroke-fc',
    section: 'how',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'how-style-card-secondary-bg',
    label: 'bg_secondary',
    logged: true,
    property: '--slp-how-card-secondary-bg',
    section: 'how',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'how-style-card-tertiary-bg',
    label: 'bg_tertiary',
    logged: true,
    property: '--slp-how-card-tertiary-bg',
    section: 'how',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'how-style-card-quaternary-bg',
    label: 'bg_quaternary',
    logged: true,
    property: '--slp-how-card-quaternary-bg',
    section: 'how',
    subtype: 'color',
    type: 'style',
  },
]

const buttons = [
  {
    component: 'forms-input',
    fqn: 'how-content-btn-redeem',
    label: 'btn_redeem',
    logged: true,
    property: 'btn-redeem',
    section: 'how',
    subtype: 'text',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'how-content-btn-history',
    label: 'btn_history',
    logged: true,
    property: 'btn-history',
    section: 'how',
    subtype: 'text',
    type: 'content',
  },
]

const item = [
  {
    addable: true,
    fqn: 'how-content-items',
    label: 'item',
    logged: false,
    property: 'content',
    removable: true,
    section: 'how',
    type: 'array',

    children: [
      {
        component: 'forms-input',
        fqn: 'how-content-item-title',
        label: 'title',
        property: 'content',
        section: 'how',
        subproperty: 'title',
        subtype: 'text',
        type: 'content',
      },
      {
        component: 'forms-textarea',
        fqn: 'how-content-item-description',
        label: 'description',
        property: 'content',
        section: 'how',
        subproperty: 'description',
        subtype: 'text',
        type: 'content',
      },
    ]
  },
]

const itemTitle = [
  {
    component: 'forms-input',
    fqn: 'how-style-item-title-fc',
    label: 'fc',
    property: '--slp-how-item-title-fc',
    section: 'how',
    subtype: 'color',
    type: 'style',
  },
  {
    fqn: 'how-style-item-title-fs',
    component:'forms-select',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-how-item-title-fs',
    section: 'how',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    fqn: 'how-style-item-title-fw',
    component:'forms-select',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-how-item-title-fw',
    section: 'how',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const itemDescr = [
  {
    component: 'forms-input',
    fqn: 'how-style-item-descr-fc',
    label: 'fc',
    property: '--slp-how-item-descr-fc',
    section: 'how',
    subtype: 'color',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'how-style-item-descr-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-how-item-descr-fs',
    section: 'how',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'how-style-item-descr-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-how-item-descr-fw',
    section: 'how',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const itemIndex = [
  {
    component: 'forms-input',
    fqn: 'how-style-item-index-fc',
    label: 'fc',
    property: '--slp-how-item-index-fc',
    section: 'how',
    subtype: 'color',
    type: 'style',
  },
]

export default [ 
  ...base, 
  ...title, 
  ...description,
  // ...meta, 
  ...card,
  ...buttons,
  ...item,
  ...itemTitle,
  ...itemDescr,
  ...itemIndex,
]
