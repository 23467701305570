<template>
  <ui-card 
    appearance="subtle"
    :class="classes">

    <template 
      v-slot:header 
      v-if="hasHeader">
      <slot name="header"></slot>
    </template>

    <div 
      class="ui-overview__loader ui-overview__body" 
      v-if="loading">
      <ui-loader />
    </div>

    <div 
      class="ui-overview__body"
      v-if="!loading">
      <section class="ui-overview__left">
        <article 
          class="ui-overview__figure"
          :key="$basil.uniqId(i)"
          v-for="(f, i) in value.left" >
          <h3 class="ui-overview__legend">{{ f.label }}</h3>
          <div class="ui-overview__value">
            <component
              class="ui-overview__output -primary"
              :is="f.component"
              :value="f.value"
            />

            <span 
              class="ui-overview__post-label"
              v-if="f.postLabel">{{ f.postLabel }}</span>
          </div>
        </article>
      </section>

      <section class="ui-overview__right">
        <article 
          class="ui-overview__item"
          v-for="(s, i) in value.right" 
          :key="$basil.uniqId(i)">
          <div class="ui-overview__item-body">
            <div class="ui-overview__item-label">{{ s.label }}</div>
            <div class="ui-overview__item-output">
              <component
                :is="s.component"
                :value="s.value"
              />
            </div>
          </div>
        </article>
      </section>
    </div>

    <div 
      class="ui-overview__footer"
      v-if="hasFooter">
      <slot name="footer"></slot>
    </div>
  </ui-card>
</template>

<script>
export default {
  name: 'SegmentItemOverview',

  props: {
    hasBorder: {
      type: Boolean,
      default: true
    },


    loading: {
      type: Boolean,
      default: false,
    },

    value: {
      type: Object | null,
      required: true
    }
  },

  computed: {
    classes() {
      return {
        'ui-overview': true,
        '-no-header': !this.hasHeader,
        '-no-footer': !this.hasFooter,
        '-no-border': !this.hasBorder
      }
    },

    hasFooter(){
      return !!this.$slots.footer
    },

    hasHeader() {
      return !!this.$slots.header
    },
  }
}
</script>
