import { version } from '../package.json'

const config = {
  askemma: {
    url: null
  },
  userPortal: {
    url: null
  },
  image: {
    url: process.env.VUE_APP_IMAGE_URL || 'https://prod-images.cloud.infinity-commerce.io/'
  },
  conn3ct: {
    bootstrap: {
      url: process.env.VUE_APP_BOOTSTRAP_WALLET_URL || 'https://cdn-apps.sayl.cloud/staging/conn3ct-wallet-bootstrap/latest/wallet.min.js',
    },
    landing: {
      url: process.env.VUE_APP_CONNECT_LANDING_URL || 'https://cdn.sayl.cloud/conn3ct/staging/latest'
    },
    store: {
      url: process.env.VUE_APP_STORE_URL || 'https://bountiful-jakarta-02nah18oytmt.vapor-farm-a1.com/conn3ct-store/',
    },

    wallet: {
      url: process.env.VUE_APP_WALLET_URL || 'dev.wallet.infinity-commerce.io/login'
    },

    claims: {
      url: process.env.VUE_APP_CLAIMS_URL || 'https://dev.wallet.infinity-commerce.io/claims/'
    }
  },
  deliverect: {
    webhook: {
      url: null,
    }
  },
  editor: {
    url: process.env.VUE_APP_CMS_EDITOR_URL || 'https://dev.app.sayl.cloud/editor',
    origin: process.env.VUE_APP_CMS_EDITOR_ORIGIN || 'https://dev.app.sayl.cloud',
  },
  env: process.env.VUE_APP_ENV || 'production',
  explorers: {
    sayl: { url: 'https://testnet.dragonglass.me/hedera/', path: 'tokens/', },
    hedera: { url: 'https://testnet.dragonglass.me/hedera/', path: 'tokens/', },
    hedera_testnet: { url: 'https://testnet.dragonglass.me/hedera/', path: 'tokens/', },
    hedera_mainnet: { url: 'https://app.dragonglass.me/hedera/', path: 'tokens/', },
    bsc: { url: 'https://bscscan.com/', path: 'address/', },
    eth: { url: 'https://etherscan.io/', path: 'address/', },
    matic: { url: 'https://polygonscan.com/', path: 'address/', },
    polkadot: { url: 'https://explorer.polkascan.io/polkadot/', path: 'block/', },
    avalanche: { url: 'https://explorer.avax.network/', path: 'address/', },
    fantom: { url: 'https://ftmscan.com/', path: 'address/', },
    cronos: { url: 'https://cronoscan.com/', path: 'address/', },
    stafi: { url: 'https://testnet-explorer.stafihub.io/stafi-hub-testnet/', path: 'blocks/', },
    algorand: { url: 'https://algoexplorer.io/', path: '', },
    heco: { url: 'https://hecoinfo.com/', path: 'block/', },
    edgeware: { url: 'https://edgscan.live/', path: '', },
    zilliqua: { url: 'https://viewblock.io/zilliqa', path: '', },
  },
  lightspeed: {
    auth: process.env.VUE_APP_LIGHTSPEED_AUTH || 'https://extensions.cloud.infinity-commerce.io/api/v1/extension/lightspeed/pos/'
  },
  loyalty: {
    page: {
      url: process.env.VUE_APP_LOYALTY_PAGE_URL || 'https://cdn-apps.sayl.cloud/loyalty-page/latest/'
    }
  },
  challenge: {
    page: {
      url: process.env.VUE_APP_CHALLENGE_PAGE_URL || 'https://cdn-apps.sayl.cloud/challenge-page/latest/'
    }
  },
  snippets: {
    widget: {
      cdn: process.env.VUE_APP_WIDGET_CDN || 'https://cdn.sayl.cloud/widget/production/app.js'
    }
  },
  ssm: {
    url: process.env.VUE_APP_SSM_URL || 'https://ssm.sayl.cloud/'
  },
  transports: {
    cp: {
      api: process.env.VUE_APP_API,
      oauth: process.env.VUE_APP_OAUTH,
    },

    module: process.env.VUE_APP_MODULE,
    preview: !!process.env.VUE_APP_PREVIEW,
    next: !!process.env.VUE_APP_NEXT,
  },
  version: {
    client: version
  },
  next: !!process.env.VUE_APP_NEXT,
  cdn: process.env.VUE_APP_CDN || 'https://cdn.sayl.cloud/admin/next/',
  publicApi: {
    url: process.env.VUE_APP_PUBLIC_API_URL || 'https://public-api.sayl.cloud/',
    doc: {
      url: 'https://docs.sayl.cloud/'
    }
  },
  vouchers: process.env.VUE_APP_VOUCHER_MAX_GENERATION || 2500
}

const isDevelopment = config.env === 'development'
const isStaging = config.env === 'staging'
const isProduction = config.env === 'production'

config.askemma.url = !isProduction ? 
                     'https://bountiful-jakarta-02nah18oytmt.vapor-farm-a1.com/askemma/' : 
                     'https://apps.sayl.cloud/askemma/'

config.userPortal.url = !isProduction ? 
                     'https://apps-dev.sayl.cloud/user/' : 
                     'https://apps.sayl.cloud/user/'

config.conn3ct.landing.url = !isProduction ? 
                     'https://cdn.sayl.cloud/conn3ct/staging/latest' :
                     'https://cdn.sayl.cloud/conn3ct/latest'

config.conn3ct.bootstrap.url = !isProduction ? 
                     'https://cdn-apps.sayl.cloud/staging/conn3ct-wallet-bootstrap/latest/wallet.min.js' : 
                     'https://cdn-apps.sayl.cloud/conn3ct-wallet-bootstrap/latest/wallet.min.js' 

config.conn3ct.store.url = !isProduction ? 
                     'https://bountiful-jakarta-02nah18oytmt.vapor-farm-a1.com/conn3ct-store/' : 
                     'https://apps.sayl.cloud/conn3ct-store/'

config.ssm.url = !isProduction ? 
                 'https://ordermanager3.infinity-commerce.io/login/' : 
                 'https://ssm.sayl.cloud/login/'

config.deliverect.webhook.url = !isProduction ? 
                                'https://dev-extensions.cloud.infinity-commerce.io/api/v1/extension/deliverect/pos/webhook/channel_status':
                                'https://extensions.cloud.infinity-commerce.io/api/v1/extension/deliverect/pos/webhook/channel_status'

// Blockchains explorers handling
config.explorers.sayl.url = !isProduction ? 'https://testnet.dragonglass.me/hedera/' : 'https://app.dragonglass.me/hedera/'
config.explorers.hedera.url = !isProduction ? 'https://testnet.dragonglass.me/hedera/' : 'https://app.dragonglass.me/hedera/'
config.explorers.hedera_testnet.url = 'https://testnet.dragonglass.me/hedera/'
config.explorers.hedera_mainnet.url = 'https://app.dragonglass.me/hedera/'
config.explorers.bsc.url = 'https://bscscan.com/'
config.explorers.eth.url = 'https://etherscan.io/'
config.explorers.matic.url = 'https://polygonscan.com/'
config.explorers.polkadot.url = 'https://explorer.polkascan.io/polkadot/'
config.explorers.avalanche.url = 'https://explorer.avax.network/'
config.explorers.fantom.url = 'https://ftmscan.com/'
config.explorers.cronos.url = 'https://cronoscan.com/'
config.explorers.stafi.url = 'https://testnet-explorer.stafihub.io/stafi-hub-testnet/'
config.explorers.algorand.url = 'https://algoexplorer.io/'
config.explorers.heco.url = 'https://hecoinfo.com/'
config.explorers.edgeware.url = 'https://edgscan.live/'
config.explorers.zilliqua.url = 'https://viewblock.io/zilliqa'

config.publicApi.url = !isProduction ? 'https://next-public-api.sayl.cloud/' : 'https://public-api.sayl.cloud/'

export {
  config,
  isDevelopment,
  isStaging,
  isProduction
}
