<template>
  <layout-modal-step-item 
    v-bind="attrs" 
    @search="onSearch">
    <!-- Loading -->
    <div 
      class="layout-modal-steps__loader" 
      v-if="isLoading" >
      <ui-loader />
    </div>

    <div 
      class="layout-modal__article"
      :id="label"
      :key="`categ_type_${label}`"
      v-for="(categ, label) in types">

      <h3 class="modal-create-trigger__title">{{ $t(`sayl.perk_category_type_${label}`) }}</h3>

      <div 
        class="modal-create-trigger__perks"
        v-if="filter(categ).length > 0">
        <perk-card 
          :is-selected="isSelected(type)"
          :key="`perk_type_${j}`"
          :value="type"
          @select="onSelectPerk"
          v-for="(type, j) in filter(categ)" 
        />
      </div>

      <div v-else-if="!$basil.isNil(search) && !$basil.isEmpty(search)">
        <data-empty :title="$t('sayl.perk_create_type_search_not_found')" />
      </div>
    </div>

    <template v-slot:footer>
      <actions-button
        :appearance="$pepper.Appearance.SUBTLE"
        @click="onClose"
      >{{ $t('sayl.close') }}</actions-button>

      <actions-button
        appearance="primary"
        icon-post="arrow-right"
        :disabled="!isValid"
        @click="onConfirm"
      >{{ $t('sayl.continue') }}</actions-button>
    </template>
  </layout-modal-step-item>
</template>

<script>
import { mapState } from 'vuex'
import PerkCard from './card'

export default {
  name: 'TierRewardPerk1',

  components: {
    PerkCard,
  },
  
  data() {
    return { 
      errors: {},
      category: null,
      key: 1,
      selected: null,

      search: null
    }
  },

  computed: {
    ...mapState({
      types: state => state.tier.tier.perkTypes.all,
    }),

    attrs() {
      return Object.assign({
        class: 'modal-create-trigger',
        title: this.$t('sayl.perk_create'),
        searchable: true
      }, this.$attrs)
    },

    categories() {
      return !this.$basil.isNil(this.types) ? 
        Object.keys(this.types).map((c, i) => { 
          return {
            id: c,
            group: c,
            label: this.$t(`sayl.perk_category_type_${c}`),
            value: c,
          }
        })
        : []
    },

    edition() {
      return this.key && this.$attrs.edition
    },

    grantType() {
      return this.$basil.get(this.edition, 'perk.grant.type')
    },

    isLoading() {
      return this.$basil.isNil(this.edition) || this.$basil.get(this.$attrs, 'loading', false)
    },

    offsetTop() {
      // Header height + padding of body
      return -1 * ( 146 + 16)
    },

    isValid() {
      return [
        this.$basil.isNil(this.selected) || this.$basil.isNil(this.grantType) || this.$basil.isEmpty(this.grantType) ? false : true
      ].filter(v => v === false).length === 0
    },

    flatTypes() {
      let ret = []

      Object.keys(this.types).forEach((k) => {
        ret.push(...this.types[k])
      })
      return ret
    }
  },

  methods: { 
    filter(category) {
      if(this.$basil.isNil(this.search) || this.$basil.isEmpty(this.search)) {
        return category
      }

      return category.filter((type) => {
        return this.$basil.get(type, 'name.all', '').toLowerCase().includes(this.search.toLowerCase())
      })
    },

    isSelected(type) {
      return this.grantType === type.grant_type && type === this.selected
    },

    onCategory(category) {
      let e = document.getElementById(category.group)
      e.offsetParent.offsetParent.scrollTo({ top: e.offsetTop, behavior: 'smooth' })
    },

    onConfirm() {
      if(this.isValid) {
        let ret = Object.assign(this.$attrs.aggregate, { type: this.selected })
        this.$attrs.next(ret)
      }
    },

    onClose() {
      this.$attrs.close()
    },

    onSearch(value) {
      this.search = value
    },

    onSelectPerk(perk) {
      this.selected = perk
      this.edition.perk.grant.type = perk.grant_type
      this.edition.perk.grant.subtype = perk.grant_subtype

      this.key++
    },

    reset() {
      if(this.category == null) {
        this.category = this.categories[0]
      }

      if(!this.$basil.isNil(this.$basil.get(this.edition, 'perk.grant.type'))) {
        let type = this.$basil.get(this.edition,'perk.grant.type')
        let subtype = this.$basil.get(this.edition,'perk.grant.subtype')
        this.selected = this.flatTypes.find(t => t.grant_type === type && t.grant_subtype === subtype)
      }

      if(!this.$basil.isNil(this.$basil.get(this.$attrs, 'aggregate.type'))) {
        this.selected = this.$basil.get(this.$attrs, 'aggregate.type')
      }
    },

    updatePositions(entries) {
      let children = document.getElementsByClassName('layout-modal__article')
      entries.forEach(e => {
        let i = -1
        
        if(children && this.$basil.isArray(children)) {
          children.forEach((c, j) => {
            if (c === e.target) {
              i = j
            }
          })
        }

        if (i > -1) {
          this.indexes[i] = e.isIntersecting
        }
      })

      let n = this.indexes.indexOf(true)
      this.category = this.categories[n]
    },
  },

  updated() {
    let children = document.getElementsByClassName('layout-modal__article')
    let root = this.$basil.get(children[0], 'offsetParent.offsetParent')
    this.indexes = Array(this.categories.length).fill(false)
    this.observer = new IntersectionObserver(this.updatePositions, { root: root })
    if(children && this.$basil.isArray(children)) {
      children.forEach( c => this.observer.observe(c) )
    }
  },

  mounted() {
    this.reset()
  }
}
</script>
