import { SaylController } from '@spices/basil-sayl'

/**
 * Embed controller
 * for @sayl/web3-triggers
 * @class
 */
export default class TierRewardController extends SaylController {
  /**
   * @constructor
   * @param {Object} options
   * @param {Object} options.curry - Curry Controller 
   * @param {Object} options.fqn  
   * @param {Object} options.module 
   * @param {Object} options.store
   */
  constructor({ curry, fqn = 'reward', module = 'tier', store }) {
    super({ curry, fqn, module, store })
  }

  all(payload) {
    return new Promise((resolve, reject) => {
      super.all(payload)
        .then((all) => {
          let rewards = all.sort((a, b) => a.position - b.position)
          this._store.commit(`${this.module}/all`, { name: `${this.fqn}`, values: rewards })
          resolve(rewards)
        })
        .catch((e) => reject(e))
    })
  }
}
