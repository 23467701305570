<template>
  <layout-modal
    v-bind="attrs"
    v-on="listeners">
    <div 
      class="layout-modal__loader" 
      v-if="loading">
      <ui-loader />
    </div>

    <article 
      class="layout-modal__article flow" 
      v-if="!loading"> 
      <!-- Name -->
      <forms-input
        :class="hasTranslations('name')"
        :errors="getErrors('name.all')"
        :hint="$t('sayl.tier_reward_name_hint')"
        icon-post="l8n"
        :icon-post-interactive="true"
        :placeholder="$t('sayl.placeholder')"
        :read-only="readOnly"
        required
        type="text"
        @iconClick="onOpenTranslations('name')"
        @input="onRemoveError('name.all')" 
        v-model="edition.name.all"
      >{{ $t('sayl.tier_reward_name') }}</forms-input>

      <!-- Description -->
      <forms-textarea
        :class="hasTranslations('description')"
        :errors="getErrors('description.all')"
        :hint="$t('sayl.tier_reward_description_hint') "
        input-mode="text"
        icon-post="l8n"
        :icon-post-interactive="true"
        :placeholder="$t('sayl.placeholder')"
        required
        :read-only="readOnly"
        :rows="3"
        type="text"
        @iconClick="onOpenTranslations('description', 'forms-textarea')"
        @input="onRemoveError('description.all')"  
        v-model="edition.description.all"
      >{{ $t('sayl.tier_reward_description') }}</forms-textarea>
    </article>

    <article 
      class="layout-modal__article flow" 
      v-if="!loading"> 
      <forms-select
        :disabled="loadingVouchers"
        :errors="getErrors('voucher_id')"
        :hint="typesHint"
        :placeholder="$t('sayl.select_placeholder')"
        redoable
        required
        :loading="loadingVouchers"
        :options="vouchers || []"
        @change="onVoucherChange"
        @redo="getVouchers"
        v-model="edition.voucherId"
      >{{ $t('sayl.tier_reward_voucher')}}</forms-select>

      <div class="group">
        <forms-input
          :errors="getErrors('validity_period')"
          min="1"
          :placeholder=" $t('sayl.placeholder') "
          required
          step="1"
          type="number"
          @input="onRemoveError('validity_period')"
          v-model.number="edition.validityPeriod">
          <template>{{ $t('sayl.tier_reward_voucher_validity_period_label') }}</template>
          <template #suffix>{{ $t('sayl.validity_period_days') }}</template>
        </forms-input>

        <forms-input
          :errors="getErrors('amount')"
          min="1"
          :placeholder=" $t('sayl.placeholder') "
          required
          step="1"
          type="number"
          @input="onRemoveError('amount')"
          v-model.number="edition.amount">
          <template>{{ $t('sayl.tier_reward_voucher_amount') }}</template>
          <template #suffix>{{ amountUnity }}</template>
        </forms-input>
      </div>
    </article>

    <modal-translate 
      :field="translationsField"
      :read-only="readOnly"
      :type="translationsType"
      :visible="showTranslations" 
      @close="onCloseTranslations"
    />

    <template v-slot:footer>
      <actions-button
        :appearance="$pepper.Appearance.SUBTLE"
        @click="onDiscard"
      >{{ $t('sayl.close') }}</actions-button>

      <actions-button
        :appearance="$pepper.Appearance.PRIMARY"
        @click="onConfirm"
      >{{ $t('sayl.confirm') }}</actions-button>
    </template>
  </layout-modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { 
  MixinEditionDiscard,
  MixinEditionSave,
  MixinError,
  MixinTranslation,
} from '@sayl/admin-common'

export default {
  name: 'TierRewardVoucher',

  mixins: [ 
    MixinEditionDiscard,
    MixinEditionSave,
    MixinError,
    MixinTranslation,
  ],

  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },

    value: {
      type: Object,
      required: true
    },

    visible: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      errors: {},
      loading: true,
      loadingVouchers: true,
      type: null
    }
  },

  computed: {
    ...mapState({
      edition: state => state.tier.reward.edition,
      vouchers: state => state.tier.tier.vouchers.all,
    }),

    ...mapGetters({
      pristine: 'tier/reward.pristine'
    }),

    amountUnity() {
      let voucher = this.vouchers.find(voucher => voucher.id === this.edition.voucherId)
      let currency = this.$basil.i18n.currency(0).replace('0.00', '')

      let ret = currency
      if(voucher && voucher.amountType === 'perc') {
        ret = '%'
      }
      return ret
    },

    attrs() {
      return {
        classes: {
          '-body-p0': true
        },
        loading: this.loading,
        title: this.$t('sayl.tier_modal_reward_voucher_title'),
        visible: this.visible,
      }
    },

    listeners() {
      return {
        close: this.onDiscard
      }
    },

    typesHint() {
      let route = this.$router.resolve({ name: 'sayl-ecommerce_vouchers-templates' })
      return `<a href="${route.href}" target="_blank">${this.$t('sayl.tier_reward_voucher_hint')}</a>`
    },

    types() {
      return [
        {
          label: this.$t('sayl-ecommerce.voucher_type_discount'),
          value: 'discount',
        },
        {
          label: this.$t('sayl-ecommerce.voucher_type_wallet'),
          value: 'wallet',
        },
        {
          label: this.$t('sayl-ecommerce.voucher_type_perks'),
          value: 'perks',
        },
      ]
    }
  },

  watch: {
    visible() {
      if(this.visible) {
        this.reset()
      }
    }
  }, 

  methods: {
    back() {
      this.$emit('close')
    },

    getVouchers() {
      this.loadingVouchers = true
      return new Promise((resolve, reject) => {
        window.$dl.tier.getVouchers({  })
          .then(() => resolve())
          .catch((e) => {
            $console.error(e)
            reject(e)
          })
          .finally(() => this.loadingVouchers = false)
      })
    },

    onConfirm() {
      this.onSave({}) 
        .then(() => this.back())
        .catch((e) => $console.error(e))
    },

    onVoucherChange(v) {
      let voucher = this.vouchers.find(voucher => voucher.value === v)
      this.edition.amount = voucher.amount

      this.onRemoveError('voucher_id') 
      this.onRemoveError('amount') 
    },

    reset() {
      this.loading = true
      this.errors = {}

      let prom = this.value.id != null ? 
        window.$dl.tierReward.findById.bind(window.$dl.tierReward, { args: { tierId: this.$route.params.tier, id: this.value.id }}) :
        window.$dl.tierReward.create.bind(window.$dl.tierReward, { args: { type: 'voucher', validity_period: 365 }})

      prom.apply()
        .then(() => this.getVouchers())
        .catch((e) => $console.error(e))
        .finally(() => this.loading = false) 
    },

    save({}) {
      return window.$dl.tierReward.save({ args: { tierId: this.$route.params.tier }, item: this.edition, isNew: this.edition.id == null })
    }
  },

  mounted() {
    this.reset()
  }
}
</script>