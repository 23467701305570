<template>
  <ui-card class="info-card">
    <template v-slot:header>
      <h2 class="ui-card__heading">{{title}}</h2>
    </template>

    <div
      class="info-card__row"
      :key="`${item.value}_${i}`"
      v-for="(item, i) in list"
    >
      <div
        v-if="item.icon"
        :class="`info-card__listitem-first ${item.icon}`"></div>

      <div
        v-else
        class="info-card__listitem-first"
      >{{item.label}}</div>

      <router-link
        v-if="item.link"
        :to="item.link"
        class="info-card__listitem-second"
      >{{item.value}}</router-link>

      <div
        v-else
        class="info-card__listitem-second"
      >{{item.value}}</div>
    </div>
  </ui-card>
</template>

<script>
import UiCard from './card'

export default {
  name: 'UiInfo',

  components: {
    UiCard
  },

  props: {
    list: {
      type: Array,
      required: true
    },

    title: {
      type: String,
      required: true
    }
  }
}
</script>
