export default [
  {
    label: '10px',
    value: '10px',
  },
  {
    label: '12px',
    value: '12px',
  },
  {
    label: '14px',
    value: '14px',
  },
  {
    label: '16px',
    value: '16px',
  },
  {
    label: '18px',
    value: '18px',
  },
  {
    label: '20px',
    value: '20px',
  },
  {
    label: '22px',
    value: '22px',
  },
  {
    label: '24px',
    value: '24px',
  },
  {
    label: '28px',
    value: '28px',
  },
  {
    label: '30px',
    value: '30px',
  },
  {
    label: '36px',
    value: '36px',
  },
  {
    label: '42px',
    value: '42px',
  },
  {
    label: '48px',
    value: '48px',
  },
  {
    label: '60px',
    value: '60px',
  },
  {
    label: '64px',
    value: '64px',
  },
  {
    label: '72px',
    value: '72px',
  },
  {
    label: '85px',
    value: '85px',
  },
  {
    label: '128px',
    value: '128px',
  },
]
