<template>
  <layout-modal-step-item
    v-bind="attrs">

    <!-- Loader -->
    <template v-if="loading">
      <div class="layout-modal__loader">
        <ui-loader />
      </div>
    </template>

    <template v-if="!loading">
      <article class="layout-modal__article">
        <notice-banner :intent="$pepper.Intent.WARNING">
          <template v-slot:title>{{ reviewTitle }}</template>
          <template>
            <p>{{ reviewDescr }}</p>
            <ul v-if="reviewDescrAttrs.length > 0">
              <li 
                :key="$basil.uniqId(i)"
                v-for="(a, i) in reviewDescrAttrs"
              >{{ a }}</li></ul></template>
        </notice-banner>
      </article>
    </template>

    <template v-slot:footer>
      <actions-button
        :appearance="$pepper.Appearance.SUBTLE"
        icon-pre="arrow-left"
        :size="$pepper.Size.S"
        @click="$attrs.previous"
      >{{ $t('sayl.previous') }}</actions-button>

      <actions-button
        appearance="primary"
        :size="$pepper.Size.S"
        @click="onConfirm"
      >{{ $t('sayl.confirm') }}</actions-button>
    </template>
  </layout-modal-step-item>
</template>

<script>
export default {
  name: 'ModalExportStepReview',

  data(){
    return {
      loading: false,
    }
  },

  computed: {
    attrs() {
      return Object.assign({
        loading: this.loading
      }, this.$attrs)
    },

    filename() {
      let args = this.$basil.get(this.$attrs, 'aggregate', {})

      return `${args.name}.${args.format}`
    },

    reviewTitle() {
      return this.$t('sayl.export_review_generic_title')
    },

    reviewDescr() {
      return this.$t('sayl.export_review_generic_descr', { filename: this.filename })
    },

    reviewDescrAttrs() {
      let args = this.$basil.get(this.$attrs, 'aggregate', {})
      let keys = Object.keys(args)

      keys = keys.filter(k => k!=='name' && k!=='format')
      return keys.map(k => {
        let value = args[k].value ? args[k].value : args[k]
        let format = args[k].format ? args[k].format : null
        return `${k.charAt(0).toUpperCase() + k.substring(1)}: ${!this.$basil.isNil(format) ? format(value) : value}`
      })
    }
  },

  methods: {
    onConfirm() {
      let args = this.$basil.get(this.$attrs, 'aggregate', {})
      let keys = Object.keys(args)
      let payload = {}

      keys.forEach(k => payload[k] = args[k].value ? args[k].value : args[k])
      this.$emit('confirm', payload)
    }
  },
}
</script>
