var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout-level1',_vm._b({},'layout-level1',_vm.attrs,false),[_c('div',{staticClass:"layout-level1__container"},[(_vm.hasTiers)?_c('div',{staticClass:"view-upgrade__body"},[_c('table',{staticClass:"view-upgrade__table"},[_c('thead',{staticClass:"view-upgrade__header"},[_c('tr',[_c('th',{staticClass:"view-upgrade__heading view-upgrade__sticky"},[_vm._v(_vm._s(_vm.$t('sayl-invoice.col_features')))]),_vm._l((_vm.tiers),function(t){return _c('th',{class:{ 
                'view-upgrade__heading -first': true, 
                '-is-active': _vm.tier.id !== t.id && t.priority === 2
              }},[_vm._v(" "+_vm._s(t.name)+" "),(_vm.tier.id === t.id)?_c('data-tag',{staticClass:"view-upgrade__current",attrs:{"appearance":_vm.$pepper.Appearance.PRIMARY,"size":_vm.$pepper.Size.S}},[_vm._v(_vm._s(_vm.$t('sayl-invoice.tag_current_plan')))]):_vm._e(),(_vm.tier.id !== t.id && t.priority === 2)?_c('data-tag',{staticClass:"view-upgrade__recommended",attrs:{"appearance":_vm.$pepper.Appearance.PRIMARY,"size":_vm.$pepper.Size.S}},[_vm._v(_vm._s(_vm.$t('sayl-invoice.tag_recommended_plan')))]):_vm._e()],1)})],2),_c('tr',[_c('th',{staticClass:"view-upgrade__sticky"}),_vm._l((_vm.tiers),function(t){return _c('th',{class:{ 'view-upgrade__heading': true, '-is-active': _vm.tier.id !== t.id && t.priority === 2 }},[_c('div',{staticClass:"view-upgrade__pricing"},[_c('div',{staticClass:"view-upgrade__price",domProps:{"innerHTML":_vm._s(_vm.formatBasePrice(t))}}),(!t.name.includes('free'))?_c('actions-button',{staticClass:"view-upgrade__pricing-action",attrs:{"appearance":_vm.$pepper.Appearance.DEFAULT,"icon-post":"info","title":_vm.$t('sayl-invoice.view_price_grid_action'),"size":_vm.$pepper.Size.S},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onShowPricesForTier(t)}}}):_vm._e()],1)])})],2)]),_c('tbody',[_vm._l((_vm.functionalities),function(f,j){return _c('tr',[_c('td',{staticClass:"view-upgrade__label view-upgrade__sticky"},[_vm._v(_vm._s(f.label))]),_vm._l((_vm.tiers),function(t,i){return _c('td',{class:{ 'view-upgrade__cell': true, '-is-active': _vm.tier.id !== t.id && t.priority === 2 }},[_c('div',{staticClass:"view-upgrade__functionality"},[_c('div',{class:{ 
                  'view-upgrade__enable': true,
                  '-is-disabled': _vm.isFunctionliatiesExcluded(f, t) 
                }},[_c('ui-icon',{staticClass:"view-upgrade__status",attrs:{"glyph":_vm.isFunctionliatiesExcluded(f, t) ? 'cross' : 'check'}})],1)])])})],2)}),_c('tr',[_c('td',{staticClass:"view-upgrade__sticky -events"},[_vm._v(_vm._s(_vm.$t('sayl-invoice.events_retention')))]),_vm._l((_vm.tiers),function(t){return _c('td',{class:{ 'view-upgrade__cell -events': true, '-is-active': _vm.tier.id !== t.id && t.priority === 2 }},[_vm._v(" "+_vm._s(_vm.$t('sayl-invoice.events_retention_days', { value: _vm.$basil.get(t, 'eventsRetention', 0)}))+" ")])})],2),_c('tr',[_c('td',{staticClass:"view-upgrade__sticky"}),_vm._l((_vm.tiers),function(t){return _c('td',{class:{ 'view-upgrade__cell -bottom': true, '-is-active': _vm.tier.id !== t.id && t.priority === 2 }},[_c('div',{staticClass:"-action"},[(t.id !== _vm.tier.id)?_c('actions-button',{attrs:{"size":_vm.$pepper.Size.S},on:{"click":_vm.onContactUs}},[_vm._v(_vm._s(_vm.$t('sayl-invoice.contact_us_action')))]):_vm._e()],1)])})],2)],2)])]):_vm._e(),(!_vm.hasTiers)?_c('data-empty',{attrs:{"icon":"file-invoice","description":_vm.$t('sayl-invoice.no_tiers_defined_description'),"title":_vm.$t('sayl-invoice.no_tiers_defined')}}):_vm._e()],1),(_vm.showPrices)?_c('modal-upgrade-prices',{attrs:{"tier":_vm.selectedTier,"visible":_vm.showPrices},on:{"close":function($event){_vm.showPrices = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }