import { SaylController } from '@spices/basil-sayl'

/**
 * Referral controller
 * 
 * for @sayl
 * @class
 */
export default class ReferralController extends SaylController {
  /**
   * @constructor
   * @param {Object} options
   * @param {Object} options.curry - Curry Controller 
   * @param {Object} options.store
   */
  constructor({ curry, fqn = 'referral', module = 'loyalty', store }) {
    super({ curry, fqn, module, store })
  }

  ////////////////////////////////////////
  getKpis(payload) {
    return new Promise((resolve, reject) => {
      this.store.commit(`${this.module}/loading`, { name: `${this.fqn}.kpis`, value: true })

      let kpis = null
      let timeline = null

      this.curry.getKpis(payload)
        .then((k) => {
          kpis = k
          return this.curry.getKpisTimeline(payload)
        })
        .then((t) => {
          timeline = t

          this.store.commit(`${this.module}/kpis`, { name: `${this.fqn}.kpis`, value: { kpis, timeline } })
          resolve({ kpis, timeline })
        })
        .catch((e) => reject(e))
        .finally(() => this.store.commit(`${this.module}/loading`, { name: `${this.fqn}.kpis`, value: false }))
    })
  }

  getHistory(payload) {
    return new Promise((resolve, reject) => {
      this.store.commit(`${this.module}/loading`, { name: `${this.fqn}.history`, value: true })

      this.curry.getHistory(payload)
        .then(({ collection, meta }) => {
          this.store.commit(`${this.module}/collection`, { name: `${this.fqn}.history`, values: collection, meta })
          resolve(history)
        })
        .catch((e) => reject(e))
        .finally(() => this.store.commit(`${this.module}/loading`, { name: `${this.fqn}.history`, value: false }))
    })
  }

  getNFTs({ images = false }) {
    return new Promise((resolve, reject) => {
      this.store.commit(`${this.module}/loading`, { name: `${this.fqn}.nfts`, value: true })

      this.curry.getNFTs({ images })
        .then((nfts) => {
          this.store.commit(`${this.module}/all`, { name: `${this.fqn}.nfts`, values: nfts })
          resolve(nfts)
        })
        .catch((e) => reject(e))
        .finally(() => this.store.commit(`${this.module}/loading`, { name: `${this.fqn}.nfts`, value: false }))
    })
  }

  getNFT({ id = null }) {
    return new Promise((resolve, reject) => {
      this.store.commit(`${this.module}/loading`, { name: `${this.fqn}.nfts`, value: true })

      this.curry.getNFT({ id })
        .then((nft) => {
          this.store.commit(`${this.module}/edition`, { name: `${this.fqn}.nfts`, value: nft })
          resolve(nft)
        })
        .catch((e) => reject(e))
        .finally(() => this.store.commit(`${this.module}/loading`, { name: `${this.fqn}.nfts`, value: false }))
    })
  }
}
