<template>
  <div class="view-loyalty-referral__row">
    <div 
      class="view-loyalty-referral__tag -teal"
      v-if="tag">{{ tag }}</div>

    <div 
      class="view-loyalty-referral__loader" 
      v-if="loading">
      <ui-loader />
    </div>

    <data-empty 
      class="view-loyalty-referral__empty"
      :class="{ '-is-errored': hasErrors }"
      :title="$t('sayl-loyalty.referral_no_reward_selected_title')"
      :description="$t('sayl-loyalty.referral_no_reward_selected_description')"
      v-if="!loading && $basil.isNil(value)"
    />

    <!-- Reward -->
    <ui-preview 
      entity="action"
      icon="award"
      :legend="$t('sayl-loyalty.referral_reward_legend')"
      :read-only="readOnly"
      :template="template"
      :values="value"
      @click="onClick"
      v-if="!loading && !$basil.isNil(value)"
    />

    <forms-label 
      class="row view-loyalty-referral__error"
      :intent="$pepper.Intent.DANGER"
      v-if="hasErrors"
    >{{ errors.join(', ') }}</forms-label>

    <actions-button
      icon-pre="plus"
      :size="$pepper.Size.S"
      @click="onClick"
      v-if="!loading && !readOnly && $basil.isNil(value)"
    >{{ $t('sayl-loyalty.referral_reward_add') }}</actions-button>

    <modal-reward 
      :read-only="readOnly"
      :value="reward"
      :visible="showModal"
      @close="onClose"
      @confirm="onConfirm"
      v-if="!readOnly && showModal"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import ModalReward from './reward/index.vue'
import UiPreview from './preview.vue'

export default {
  name: 'LoyaltyItemReward',

  components: {
    ModalReward,
    UiPreview
  },

  props: {
    edition: {
      type: Object,
      required: true,
    },

    errors: {
      type: Array,
    },

    readOnly: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: null
    }
  },

  data() {
    return { 
      loading: true,
      reward: null,
      showModal: false,
    }
  },

  computed: {
    ...mapState({
      vouchers: state => state.ecommerce.voucher.templates.all,
      nfts: state => state.loyalty.referral.nfts.all,
      nft: state => state.loyalty.referral.nfts.edition,
      programs: state => state.loyalty.loyalty.collection,
    }),

    hasErrors() {
      return this.errors != null && this.errors.length > 0
    },

    value() {
      let ret = null

      switch(this.$basil.get(this.edition, 'type')) {
        case 'loyalty':
          ret = {
            points: this.$basil.get(this.edition, 'settings.credits_amount'),
            symbol: this.$basil.get(this.programs, '[0].points.symbol')
          }
          break

        case 'nft':
          if(!this.$basil.isNil(this.nft)) {
            ret = { nft: this.$basil.get(this.nft, 'name.all') }
          }
          break

        case 'voucher': 
          let voucher = this.vouchers.find(v => v.id === this.$basil.get(this.edition, 'settings.voucher_template_id'))

          ret = {
            voucher: this.$basil.get(voucher, 'name.all'),
            type: this.$t(`sayl-loyalty.referral_reward_voucher_type_${this.$basil.get(this.edition, 'settings.voucher_type')}_template`)
          }
          break
      }

      return ret
    },

    template() {
      let ret = 'sayl-loyalty.referral_reward_'
      let type = this.$basil.get(this.edition, 'type')
      if(!this.$basil.isNil(type)) {
        ret += `${type}_template`
      }
      return this.$t(ret)
    }
  },

  methods: {
    onClick() {
      this.reward = this.edition
      this.showModal = true
    },

    onClose() {
      this.reward = null
      this.showModal = false
    },

    onConfirm({ type, settings }) {
      this.edition.type = type
      this.edition.settings = settings
      this.reset()
      this.onClose()
      this.$emit('change')
    },

    reset() {
      this.loading = true
      let prom = Promise.resolve.bind(Promise)

      switch(this.edition.type) {
        case 'nft':
          prom = window.$dl.referral.getNFT.bind(window.$dl.referral, { id: this.$basil.get(this.edition, 'settings.nft_id')})
          break

        // case 'voucher':
        //   prom = window.$dl.referral.getVouchers.bind(this.$audiences.challenge, { type: this.$basil.get(this.edition, 'settings.voucher_type') })
        //   break
      }
      
      prom.apply()
        .catch((e) => $console.error(e))
        .finally(() => this.loading = false)
    }
  },

  mounted() {
    this.reset()
  }
}
</script>
