<template>
  <div v-if="!loading">
    <charts-bar
      :key="key"
      class="view-loyalty-referral__chart"
      :datasets="datasets" 
      :labels="labels"
      :stacked="false"
    ></charts-bar>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LoyaltyProgramChart',

  props: {
    loading: {
      type: Boolean,
      default: true,
    },

    range: {
      type: Object,
    }
  },

  data() {
    return {
      key: 1
    }
  },

  computed: {
    ...mapState({
      state: state => state.loyalty.referral.kpis,
      kpis: state => state.loyalty.referral.kpis.value,
    }),

    months() {
      return [
        this.$t('sayl.january'),
        this.$t('sayl.febuary'),
        this.$t('sayl.march'),
        this.$t('sayl.april'),
        this.$t('sayl.may'),
        this.$t('sayl.june'),
        this.$t('sayl.july'),
        this.$t('sayl.august'),
        this.$t('sayl.september'),
        this.$t('sayl.october'),
        this.$t('sayl.november'),
        this.$t('sayl.december'),
      ].reverse()
    },

    days() {
      return [
        this.$t('sayl.monday'),
        this.$t('sayl.tuesday'),
        this.$t('sayl.wednesday'),
        this.$t('sayl.thursday'),
        this.$t('sayl.friday'),
        this.$t('sayl.saturday'),
        this.$t('sayl.sunday'),
      ].reverse()
    },

    dates() {
      let ret = [];
      if(!this.loading && this.range) {
        let start = this.range.from
        let end = this.range.to
        let step = 'day'

        let temp = start
        let r = $dl.ranges.getByValue({ from: start, to: end })
        if(r.toString() === 'this_year' || r.toString() === 'last_12_months') {
          step = 'month'
        }
        
        while(temp < end) {
          ret.push(this.$moment(temp).toDate())
          temp = this.$moment(temp).add(1, step).toDate().getTime()
        }
      }
      return ret
    },

    labels() {
      let ret = !this.loading ? this.dates : []
      let format = 'DD/MM'
      
      let r = $dl.ranges.getByValue({ from: this.range.from, to: this.range.to })
      if(r.toString() === 'this_year' || r.toString() === 'last_12_months') {
        format = 'MM/YYYY'
      }
      return ret.map((d) => this.$moment(d).format(format));
    },

    datasets() {
      let ret = [
        { 
          label: this.$t('sayl-loyalty.referral_purchase_count'),
          backgroundColor: '#1480AB',
          data: []
        },
        {
          label: this.$t('sayl-loyalty.referral_purchase_total'),
          backgroundColor: '#CC4B23',
          data: [], 
        }
      ]

      if(!this.loading) {
        let charts = this.$basil.get(this.kpis, 'timeline', {})

        this.dates.forEach(date => {
          let d = this.$moment(date).startOf('day').format('YYYY-MM-DDTHH:mm:ss')
          let tmp = charts[d]
          let accrual = this.$basil.get(tmp, 'count', 0)
          let redemption = this.$basil.get(tmp, 'total', 0)
          ret[0].data.push(accrual)
          ret[1].data.push(redemption)
        })
      }

      return ret
    }
  },

  methods: {
    getLabels({ format, limit, range }) {
      let ret = []

      for(let i = 0; i < limit; i++) {
        ret.unshift(this.$moment().set('minute', 0).subtract(i, range).format(format))
      }

      return ret
    },
  },
}
</script>
