<template>
  <div ref="config" class="flow">
    <ui-card 
      :appearance="$pepper.Appearance.SUBTLE" 
      article
      class="view-loyalty-page__toggles"
      :class="{ '-closed': !detailsOpened }"
      :key="key">
      <article class="ui-card__article">
        <forms-checkbox
          :appearance="$pepper.Appearance.TOGGLE"
          :size="$pepper.Size.S"
          :checked="isLogged"
          :value="isLogged"
          @change="$emit('logged', !isLogged)"
        >{{ $t('sayl-loyalty.loyalty_page_logged_action')}}</forms-checkbox>
      </article>

      <ui-details 
        class="view-loyalty-page__states"
        :open="detailsOpened"
        :auto-padding="false"
        @summary="onOpenDetails">
        <template #summary>
          <!-- <h4 class="ui-card__subtitle">States</h4> -->
          <h4 class="ui-card__subtitle">{{ $t('sayl-loyalty.loyalty_settings_states') }}</h4>
        </template>

        <article 
          class="ui-card__article"
          v-if="isLogged">
          <forms-checkbox
            :appearance="$pepper.Appearance.TOGGLE"
            :size="$pepper.Size.S"
            :checked="state.historyEmpty"
            :value="state.historyEmpty"
            @change="onState('historyEmpty', !state.historyEmpty)"
          >{{ $t('sayl-loyalty.loyalty_page_history_empty_state')}}</forms-checkbox>
            <!-- >Empty history</forms-checkbox> -->
        </article>

        <article class="ui-card__article">
          <forms-checkbox
            :appearance="$pepper.Appearance.TOGGLE"
            :size="$pepper.Size.S"
            :checked="state.vouchersEmpty"
            :value="state.vouchersEmpty"
            @change="onState('vouchersEmpty', !state.vouchersEmpty)"
          >{{ $t('sayl-loyalty.loyalty_page_voucher_empty_state')}}</forms-checkbox>
            <!-- >Empty Vouchers</forms-checkbox> -->
        </article>

        <article class="ui-card__article">
          <forms-checkbox
            :appearance="$pepper.Appearance.TOGGLE"
            :size="$pepper.Size.S"
            :checked="state.referralCode"
            :value="state.referralCode"
            @change="onState('referralCode', !state.referralCode)"
          >{{ $t('sayl-loyalty.loyalty_page_referral_code_state')}}</forms-checkbox>
            <!-- >Referral Filled</forms-checkbox> -->
        </article>
      </ui-details>
    </ui-card>

    <loyalty-page-target
      :page-content="pageContent"
      :is-logged="isLogged"
      :page-style="pageStyle"
      :read-only="readOnly"
      :target="target"
      ref="target"
      @clear="onClear"
      @change="() => $emit('change')"
    />

    <loyalty-page-property
      :detail="detail"
      :is-logged="isLogged"
      :key="detail.name"
      :opened="opened === detail.name"
      :page-content="pageContent"
      :page-style="pageStyle"
      :read-only="readOnly"
      :ref="detail.name"
      :target="target"
      @summary="() => onOpen(detail.name)"
      @change="() => $emit('change')"
      v-for="detail in page"
    />
    
    <modal-translate 
      :bind="translationsType.includes('merge-tags') ? { mergeTags: tags } : {}"
      @close="translastionClosed"
      :field="translationsField"
      :read-only="readOnly"
      :type="translationsType"
      :visible="showTranslations" 
    />
  </div>
</template>

<script>
import { MixinImage } from '@sayl/admin-common'
import MixinBuilder from './builder.js'

import LoyaltyPageProperty from './property.vue'
import LoyaltyPageTarget from './target.vue'

export default {
  name: 'LoyaltyPageProperties',

  components: {
    LoyaltyPageProperty,
    LoyaltyPageTarget
  },

  inject: [ '$image' ],

  mixins: [
    MixinBuilder,
    MixinImage,
  ],

  props: {
    isLogged: {
      type: Boolean,
      default: false,
    },

    pageContent: {
      type: Object
    },

    pageStyle: {
      type: Object,
    },

    state: {
      type: Object,
    },

    target: {},
  },

  data() {
    return {
      focused: null,
      opened: null,

      detailsOpened: false,
      key: 1,
    }
  },

  watch: {
    target: {
      immediate: true,
      handler() {
        if(!this.$basil.isNil(this.target)) {
          this.onOpen('target')
        }
      }
    }
  },
  
  methods: {
    onClear() {
      this.$emit('clear')
    },

    onOpenDetails() {
      this.detailsOpened = !this.detailsOpened
      this.key++
    },

    onOpen(name) {
      this.opened = this.opened === name && name !== 'target' ? null : name
      this.opened !== 'target' && this.$emit('clear')

      setTimeout(() => {
        let ref = this.$refs[name]
        if(ref && this.opened === name) {
          ref = Array.isArray(ref) && ref.length > 0 ? ref[0] : ref
          document.documentElement.scrollTo({ top: ref.$el.offsetTop, behavior: 'smooth' })
          this.opened !== 'target' && this.$emit('scroll', name)
        } 
      }, 50)
    },

    onState(name, value) {
      this.state[name] = value      
      this.$emit('state', this.state)
    }
  },
}
</script>
