<template>
  <div class="layout-level2__container container view-loyalty-config__cols view-loyalty-config__pass">
    <div class="col">
      <loyalty-pass-config-about
        :errors="errors"
        :read-only="readOnly"
      />

      <loyalty-pass-config-images
        :errors="errors"
        :read-only="readOnly"
        @icon="onIcon"
        @logo="onLogo"
        @strip="onStrip"
        @loading="onLoading"
      />

      <loyalty-pass-config-colors
        :errors="errors"
        :read-only="readOnly"
      />
    </div>

    <div class="col view-loyalty-config__pass-preview">
      <ui-card appearance="subtle">
        <div
          class="ui-card__loader" 
          v-if="loading">
          <ui-loader />
        </div>

        <loyalty-pass-config-preview 
          :backgroundColor="edition.passBackgroundColor"
          :icon="iconPreview"
          :logo="logoPreview"
          :textColor="edition.passTextColor"
          :strip="stripPreview"
          :stripColor="edition.passStripColor"
          :pointsName="$basil.get(edition, 'points.name.all','points')"
          v-if="!loading"
        />
      </ui-card>
    </div>
  </div>
</template>

<script>
import { mapState, } from 'vuex'
import { MixinImage } from '@sayl/admin-common'
import LoyaltyPassConfigImages from './pass_images'
import LoyaltyPassConfigColors from './pass_colors'
import LoyaltyPassConfigPreview from './preview'
import LoyaltyPassConfigAbout from './pass_about'

export default {
  name: 'LoyaltyPassConfig',

  components: {
    LoyaltyPassConfigAbout,
    LoyaltyPassConfigColors,
    LoyaltyPassConfigImages,
    LoyaltyPassConfigPreview,
  },

  mixins: [ MixinImage ],

  props: {
    errors: {
      type: Object,
    },

    readOnly: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      loading: true,
      icon: null,
      logo: null, 
      strip: null,
    }
  },

  computed: {
    ...mapState({
      edition: state => state.loyalty.loyalty.edition,
      origin: state => state.loyalty.loyalty.origin,

      iconImg: state => state.loyalty.loyalty_program.pass_icon.all,
      logoImg: state => state.loyalty.loyalty_program.pass_logo.all,
      stripImg: state => state.loyalty.loyalty_program.pass_strip.all,
    }),

    iconPreview() {
      let ret = [
        !this.$basil.isNil(this.icon) ? this.$basil.get(this.icon, 'preview') : null,
        !this.$basil.isNil(this.iconImg) ? this.$basil.get(this.iconImg, '[0]._image.all.src') : null,
        this.$getImageSrc('/statics/images/loyalty/icon.png'),
      ];

      return ret.find(r => !this.$basil.isNil(r))
    },

    logoPreview() {
      let ret = [
        !this.$basil.isNil(this.logo) ? this.$basil.get(this.logo, 'preview') : null,
        !this.$basil.isNil(this.logoImg) ? this.$basil.get(this.logoImg, '[0]._image.all.src') : null,
        this.$getImageSrc('/statics/images/loyalty/logo.png'),
      ];

      return ret.find(r => !this.$basil.isNil(r))
    },

    stripPreview() {
      let ret = [
        !this.$basil.isNil(this.strip) ? this.$basil.get(this.strip, 'preview') : null,
        !this.$basil.isNil(this.stripImg) ? this.$basil.get(this.stripImg, '[0]._image.all.src') : null,
        this.$getImageSrc('/statics/images/loyalty/strip.png'),
      ];

      return ret.find(r => !this.$basil.isNil(r))
    },
  },

  methods: {
    onIcon(payload) {
      this.icon = payload
      this.$emit('icon', payload)
    },

    onLoading(value) {
      this.loading = value
    },

    onLogo(payload) {
      this.loading = true
      this.logo = payload
      this.$emit('logo', payload)
      setTimeout(() => this.loading = false, 100)
    },

    onStrip(payload) {
      this.strip = payload
      this.$emit('strip', payload)
    },
  }
}
</script>
