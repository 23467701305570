import { SaylController } from '@spices/basil-sayl'
import { basil } from '@spices/basil'

/**
 * Embed controller
 * for @sayl/web3-triggers
 * @class
 */
export default class TierController extends SaylController {
  /**
   * @constructor
   * @param {Object} options
   * @param {Object} options.curry - Curry Controller 
   * @param {Object} options.fqn  
   * @param {Object} options.module 
   * @param {Object} options.store
   */
  constructor({ curry, fqn = 'tier', module = 'tier', store }) {
    super({ curry, fqn, module, store })
  }

  getEmbeds(payload = {}) {
    return new Promise((resolve, reject) => {
      this._curry.getEmbeds(payload)
        .then((embeds) => {
          this._store.commit(`${this.module}/all`, { name: `${this.fqn}.embeds`, values: embeds })
          resolve(embeds)
        })
        .catch((e) => reject(e))
    })
  }

  getLoyalty(payload = {}) {
    return new Promise((resolve, reject) => {
      let p = basil.get(this._store, 'state.tier.tier.loyalty.edition', null)

      if(p == null) {
        this._curry.getLoyaltyProgram(payload)
          .then((program) => {
            this._store.commit(`${this.module}/edition`, { name: `${this.fqn}.loyalty`, value: program })
            resolve(program)
          })
          .catch((e) => reject(e))
      } else {
        resolve(p)
      }
    })
  }

  getPerkTypes(payload = {}) {
    return new Promise((resolve, reject) => {
      let types = basil.get(this._store, 'state.tier.perkTypes', null)

      if(types == null) {
        this._curry.getPerkTypes(payload)
          .then((types) => {
            this._store.commit(`${this.module}/all`, { name: `${this.fqn}.perkTypes`, values: types })
            resolve(types)
          })
          .catch((e) => reject(e))
      } else {
        resolve(types)
      }
    })
  }

  getSegments(payload = {}) {
    return new Promise((resolve, reject) => {
      this._curry.getSegments(payload)
        .then((segments) => {
          this._store.commit(`${this.module}/all`, { name: `${this.fqn}.segments`, values: segments })
          resolve(segments)
        })
        .catch((e) => reject(e))
    })
  }

  getVouchers(payload = {}) {
    return new Promise((resolve, reject) => {
      this._curry.getVouchers(payload)
        .then((vouchers) => {
          this._store.commit(`${this.module}/all`, { name: `${this.fqn}.vouchers`, values: vouchers })
          resolve(vouchers)
        })
        .catch((e) => reject(e))
    })
  }


  // savePerk({ tier, item, isNew = true }) {
  //   return new Promise((resolve, reject) => {
  //     let payload = {
  //       id: tier,
  //       perkId: item.id || null,
  //       item: item.toAPI ? item.toAPI() : item,
  //     }
  //     if(isNew) {
  //       this._curry.savePerk(payload)
  //         .then(() => resolve())
  //         .catch((e) => reject(e))
  //     }
  //   })
  // }
}
