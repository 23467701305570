<template>
  <view-edition-tier 
    v-bind="attrs"
    v-on="listeners"
    v-if="!reset"
  />
</template>

<script>
import { mapState } from 'vuex'

import {
  MixinEditionDiscard,
  MixinEditionRemove,
  MixinError,
  MixinTranslation,
} from '@sayl/admin-common'

export default {
  name: 'LoyaltyProgramTier',

  mixins: [
    MixinEditionDiscard,
    MixinEditionRemove,
    MixinError,
    MixinTranslation,
  ],

  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      reset: false
    }
  },

  computed: {
    ...mapState({
      edition: state => state.tier.tier.edition,
      program: state => state.tier.tier.loyalty.edition
    }),

    attrs() {
      return {
        breadcrumb: this.breadcrumb,
        settings: this.getSettings(), 
        creationArgs: {},
        readOnly: this.readOnly,
      }
    },

    breadcrumb() {
      return [
        { label: this.$t('sayl.tiers_title'), icon: 'crown', href: { name:'sayl-tiers' } },
        { label: this.title }
      ]
    },

    isNew() {
      return this.$route.params.tier === 'create'
    },

    listeners() {
      return {
        close: this.onDiscard,
        back: this.onDiscard,
        saved: this.onReset,
        remove: (id) => this.onRemove({ id }),
      }
    },
  },

  methods: {
    back() {
      this.$router.push({ name: 'sayl-tiers' }).catch(() => {})
    },

    getSettings() {
      if(this.$basil.get(this.edition, 'relatedEntity') === 'loyalty_program') {
        return this.$basil.get(this.program, 'tiersSettings')
      }
      return null
    },

    remove({ id }) {
      return new Promise((resolve, reject) => {
        window.$dl.tier.remove({ id })
          .then(() => {
            this.onReset()
            resolve()
          })
          .catch((e) => reject(e))
      })
    },

    onReset(item = null) {
      this.reset = true
      item != null && this.isNew && this.$router.replace({ name: 'sayl-tier', params: { tier: item.id }}).catch(() => {})
      window.$dl.tier.all({ args: { limit: 9999, page: 1 }})
        .catch((e) => $console.error(e))

      setTimeout(() => this.reset = false, 10)
    }
  },
}
</script>