<template>
  <popins-modal 
    :klass="classes"
    :closable="true"
    :visible="visible"
    position="top-right"
    :size="size"
    @back="$emit('back')"
    @close="$emit('close')">
    <!-- Header -->
    <nav-header v-bind="header">
      <template slot="extra">
        <slot name="header"></slot>
      </template>  

      <!-- Filters -->
      <template v-slot:filters>
        <slot name="filters"></slot>
      </template>
    </nav-header>

    <div class="layout-level2__body">
      <ui-feature>
        <ui-acl>
          <ui-errors 
            :errors="errors"
            @back="$emit('back')">
            <!-- Body -->
            <!-- Inheritance parent -> [child] -->
            <notice-banner 
              class="layout-level2-sided__banner"
              :intent="$pepper.Intent.WARNING"
              v-if="inheritedFromParent">
              <template v-slot:title>
                <div class="row">
                  {{ $t('sayl.inheritance_parent_title') }}

                  <actions-button
                    :appearance="$pepper.Appearance.DEFAULT"
                    icon-post="open"
                    size="s"
                    @click="onGoToMaster"
                    v-if="canGoToMaster"
                  >{{ $t('sayl.inheritance_go_to_master') }}</actions-button>
                </div>
              </template>

              <p>{{ $t('sayl.inheritance_parent_description') }}</p>
            </notice-banner>

            <!-- Inheritance [parent] -> child -->
            <notice-banner
              class="layout-level2-sided__banner"
              v-if="inheritedFromChild">
              <template v-slot:title>
                <div class="row">
                  {{ $t('sayl.inheritance_child_title') }}

                  <actions-button
                    :appearance="$pepper.Appearance.DEFAULT"
                    icon-post="open"
                    size="s"
                    @click="() => onGoToChild(customerEntity)"
                    v-if="canGoToChild"
                  >{{ $t('sayl.inheritance_go_to_child') }}</actions-button>
                </div>
              </template>

              <p>{{ $t('sayl.inheritance_child_description') }}</p>
            </notice-banner>
            
            <!-- Loading -->
            <div 
              class="layout-level2__loader"
              v-if="loading">
              <ui-loader />
            </div>

            <!-- Data -->
            <slot v-else></slot>

            <forms-save 
              @save="save"
              v-if="!pristine && !inheritedFromParent"
            />
          </ui-errors>
        </ui-acl>
      </ui-feature>
    </div>
  </popins-modal>
</template>

<script>
import { 
  MixinHeader, 
  MixinImpersonation,
  MixinInheritance,
  MixinError 
} from '@sayl/admin-common'

import NavHeader from '../components/navigations/header'

export default {
  name: 'LayoutLevel2Sided',

  components: {
    NavHeader
  },

  mixins: [
    MixinHeader,
    MixinImpersonation,
    MixinInheritance,
    MixinError,
  ],
  
  props: {  
    errors: {
      type: Object,
      default:() => { 
        return {} 
      }
    },

    size: {
      type: String,
      default: 'l'
    },

    visible: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    classes(){
      return Object.assign({
        'layout-level2': true,
        'layout-level2-sided': true
      }, this.$attrs.classes);
    },
  },

  mounted() {
    document.body.style.overflowY = 'hidden'
  },

  beforeDestroy() {
    document.body.style.overflowY = 'auto'
  }
}
</script>
