import { basil } from '@spices/basil'

import Currency from './currency'
import Feature from './feature'
import Invoice from './invoice'
import Tier from './tier'

import Upgrade from '@/views/upgrade/index.vue'

export default class InvoiceController {
  constructor({ currencies = [], features = [], invoice = { active: false }, router, tiers = null }) {
    this._currencies = !basil.isNil(currencies) && !basil.isEmpty(currencies) ? currencies.map(c => new Currency(c)) : []
    this._features = !basil.isNil(features) && !basil.isEmpty(features) ? features.map(f => new Feature(f)) : []

    this._invoice = new Invoice(invoice)
    this._tiers = !basil.isNil(tiers) && !basil.isEmpty(tiers) ? tiers.map(t => new Tier(t)) : []

    if(this.isActive) {
      router.addRoute({
        name: 'sayl-upgrade',
        path: '/upgrade',
        component: Upgrade
      })
    }
  }

  get currency() {
    return basil.get(this._invoice, 'currency')
  }

  get currencies() {
    return this._currencies
  }

  get features() {
    return this._features
  }

  get isActive() {
    return basil.get(this._invoice, 'active', false) && !basil.isNil(basil.get(this._invoice, 'tier'))
  }

  get planName() {
    if(!this.isActive) {
      return null
    }

    return basil.get(this._invoice, 'name')
  }

  get tier() {
    if(!this.isActive) {
      return null
    }

    let ret = this._tiers.find(t => t.name === basil.get(this._invoice, 'tier'))
    
    if((this.isActive && basil.isNil(ret))) {
      return null
    }

    return ret
  }

  get tiers() {
    return this._tiers
  }

  /////////////////////////////////////////
  /// Methods
  isResourceExcluded(resource) {
    if(!this.isActive || basil.isNil(this.tier)) {
      return false
    }

    return this.tier.isResourceExcluded(resource)
  }

  isFeatureExcluded(resource, feature) {
    if(!this.isActive || basil.isNil(this.tier)) {
      return false
    }

    return this.tier.isFeatureExcluded(resource, feature)
  }
}
