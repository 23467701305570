<template>
  <layout-modal 
    v-on="listeners" 
    v-bind="attrs">
    <div class="flow">
      <div>
        <div class="loyalty-modal-voucher__group group">
          <forms-select
            autofocus
            :disabled="loading"
            :errors="getErrors('voucher_id')"
            :loading="loading"
            redoable
            required
            :options="vouchersList"
            :placeholder="$t('sayl-ecommerce.loyalty_redeemable_voucher_placeholder')"
            v-model="edition.voucher_id"
            @redo="getVouchers"
            @input="onRemoveError('voucher_id')"
          >{{ $t('sayl-ecommerce.loyalty_redeemable_voucher_label') }}</forms-select>

          <!-- <actions-button 
            :disabled="loading"
            :loading="loading"
            icon-pre="redo"
            @click="getVouchers"
          />   -->
        </div>

        <forms-label 
          :size="$pepper.Size.S" 
          v-html="voucherHint"
        ></forms-label>
      </div>

      <!-- <div class="group"> -->
        <!-- Rules::min_redemption_threshold value -->
        <forms-input
          :placeholder="$t('sayl.placeholder')"
          :errors="getErrors('required_points')"
          :hint="$t('sayl-ecommerce.loyalty_redeemable_voucher_required_points_hint')"
          required
          :min="1"
          :step="1"
          type="number"
          v-model.number="edition.required_points"
          @input="onRemoveError('required_points')">
          <template>{{ $t('sayl-ecommerce.loyalty_redeemable_voucher_required_points_label') }}</template>
          <template v-slot:suffix>{{ symbol }}</template>
        </forms-input>

        <!-- Rules::min_redemption_threshold value -->
        <forms-input
          :placeholder="$t('sayl.placeholder')"
          :errors="getErrors('validity_period')"
          :hint="$t('sayl-ecommerce.loyalty_redeemable_voucher_validity_period_hint')"
          required
          :min="1"
          :step="1"
          type="number"
          v-model.number="edition.validity_period"
          @input="onRemoveError('validity_period')">
          <template>{{ $t('sayl-ecommerce.loyalty_redeemable_voucher_validity_period_label') }}</template>
          <template v-slot:suffix>{{ $t('sayl.days') }}</template>
        </forms-input>
      <!-- </div> -->
    </div>

    <template v-slot:footer>
      <actions-button 
        @click="onDiscard"
      >{{ pristine ? $t('sayl.close') : $t('sayl.discard') }}</actions-button>

      <actions-button 
        :appearance="$pepper.Appearance.PRIMARY" 
        @click="onConfirm"
      >{{ $t('sayl.confirm') }}</actions-button>
    </template>
  </layout-modal>
</template>

<script>
import { mapState } from 'vuex'

import { 
  MixinEditionDiscard,
  MixinError,
} from '@sayl/admin-common'

export default {
  name: 'LoyaltyModalVoucher',

  mixins: [ 
    MixinEditionDiscard,
    MixinError,
  ],

  props: {
    index: {
      type: Number, 
      default: -1
    },

    isNew: { 
      type: Boolean, 
      default: true
    },

    value: {
      type: Object,
      required: true,
    },

    visible: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      edition: {},
      errors: {},
      loading: true
    }
  },

  computed: {
    ...mapState({
      vouchers: state => state.ecommerce.voucher.templates.all,
      program: state => state.loyalty.loyalty.edition,
    }),

    attrs() {
      return {
        classes: {
          'loyalty-modal-voucher': true,
        },
        title: this.$t('sayl-ecommerce.loyalty_modal_edit_redeemable_voucher'),
        visible: this.visible,
      }
    },

    isValid() {
      this.errors = {}
      let errored = true

      if(this.$basil.isNil(this.edition.voucher_id) || this.$basil.isEmpty(this.edition.voucher_id)) {
        this.errors['voucher_id'] = ['voucher_id_is_required']
        errored = false
      }

      if(this.$basil.isNil(this.edition.required_points) || this.edition.required_points === 0) {
        this.errors['required_points'] = ['required_points_must_be_higher_than_0']
        errored = false
      }

      if(this.$basil.isNil(this.edition.validity_period) || (this.$basil.isString(this.edition.validity_period) && this.$basil.isEmpty(this.edition.validity_period)) || (this.$basil.isNumber(this.edition.validity_period) && this.edition.validity_period === 0)) {
        this.errors['validity_period'] = ['validity_period_must_be_higher_than_0']
        errored = false
      }

      return errored
    },

    listeners() {
      return {
        back: this.onDiscard,
        close: this.onDiscard,
        discard: this.onDiscard,
      }
    },

    pristine() {
      return JSON.stringify(this.edition) === JSON.stringify(this.value)
    },

    symbol() {
      return this.$basil.get(this.program, 'points.symbol')
    },

    voucherHint() {
      let ret = this.$t('sayl-ecommerce.loyalty_redeemable_voucher_id_hint')
      let route = this.$router.resolve({ name: 'sayl-ecommerce_vouchers-templates' })
      return `<a href="${route.href}" target="_blank">${ret}</a>`
    },

    vouchersList() {
      let ret = this.vouchers || []
      return ret.map((v, i) => {
        return {
          label: this.$basil.get(v, 'name.all'),
          value: this.$basil.get(v, 'id')
        }
      })
    },
  },

  methods: {
    back() {
      this.$emit('close')
    },

    getVouchers() {
      this.loading = true

      this.$ecommerce.voucherTemplates.all({ args: { isTemplate: 1 }})
        .catch((e) => $console.error(e))
        .finally(() => this.loading = false)
    },

    onConfirm() {
      this.isValid && this.$emit('confirm', this.edition, this.index)
    },

    reset() { 
      this.loading = true

      this.edition = JSON.parse(JSON.stringify(this.value))
      this.getVouchers()
    },
  },

  mounted() {
    this.reset()
  }
}
</script>
