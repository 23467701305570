<template>
  <layout-modal 
    v-bind="attrs"
    v-on="$listeners">

    <div 
      class="layout-modal__article"
      v-for="feat in features">

      <h4 class="modal-upgrade-prices__title">{{ $t(`sayl-invoice.feature_name_${feat.name}`) }}</h4>
      <p class="modal-upgrade-prices__descr">{{ $t(`sayl-invoice.feature_name_${feat.name}_description`) }}</p>
    
      <table class="modal-upgrade-prices__table">
        <thead>
          <tr>
            <th 
              class="modal-upgrade-prices__header"
              v-if="feat.type === 'tiered'">{{ $t(`sayl-invoice.col_${feat.name}`) }}</th>
            
            <th
              class="modal-upgrade-prices__header"
            >{{ $t('sayl-invoice.col_price') }}</th>

            <th
              class="modal-upgrade-prices__header"
            >{{ $t('sayl-invoice.col_price_type') }}</th>
          </tr>
        </thead>

        <tr  
          class="modal-upgrade-prices__row"
          v-for="price in feat.prices">
          <td 
            class="modal-upgrade-prices__cell"
            v-if="price.max != null"
          >{{ price.max }}</td>

          <td class="modal-upgrade-prices__cell">{{ price.price }}</td>

          <td class="modal-upgrade-prices__cell">{{ $t(`sayl-invoice.feature_price_type_${price.type}`) }}</td>
        </tr>
      </table>
    </div>
  </layout-modal>
</template>

<script>
export default {
  name: 'ModalUpgradePrices',

  props: {
    tier: {
      type: Object, 
      require: true,
    },

    visible: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    attrs() {
      return {
        closable: true,
        classes: { 
          'modal-upgrade-prices': true,
          '-body-p0': true,
        },
        title: this.$t('sayl-invoice.modal_upgrade_prices_title', { plan: this.tier.name }), //`Pricing for ` + this.tier.name,
        visible: this.visible,
      }
    },

    currency() {
      return this.$basil.get(window.$dl, 'invoice.currency')
    },

    features() {
      let feats = this.$basil.get(window, '$dl.invoice.features', [])
      feats = feats.filter(r => this.$basil.get(r, 'pricing.type') != 'delegated')

      let ret = []

      feats.forEach(f => {
        let fe = {
          name: f.name,
          priority: f.priority,
          prices: [],
          type: f.pricing.type
        }

        if(f.pricing.type === 'tiered') {
          let tiers = f.pricing.tiers
          tiers.forEach(t => {
            let price = t.prices.find(p => p.tier === this.tier.name)

            if(price) {
              let pp = JSON.parse(JSON.stringify(price))
              pp.max = t.max
              pp.price = this.$basil.get(pp, `prices.${this.currency}`)
              pp.price = this.$basil.i18n.currency(pp.price || 0, { currency: this.$basil.i18n.Currencies.getByAlpha(this.currency) })
              fe.prices.push(pp)
            }
          })
        }

        if(f.pricing.type === 'unitary') {
          let price = f.pricing.prices.find(p => p.tier === this.tier.name)
          let pp = JSON.parse(JSON.stringify(price))
          pp.price = this.$basil.get(pp, `prices.${this.currency}`)
          pp.price = this.$basil.i18n.currency(pp.price || 0, { currency: this.$basil.i18n.Currencies.getByAlpha(this.currency) })
          pp.type = 'unitary'
          fe.prices.push(pp)
        }
        
        ret.push(fe)
      })

      return ret
    },
  },
}
</script>