<template>
  <data-timeline 
    :loading="iLoading"
    :rows="rows"
    :pagination="pagination"
    @page="onPage"
  />
</template>

<script>
import { mapState } from 'vuex'
import Mustache from 'mustache'

import { 
  MixinImpersonation,
  MixinTable
} from '@sayl/admin-common'

export default {
  name: 'LoyaltyHistory',

  mixins: [
    MixinImpersonation,
    MixinTable,
  ],

  props: {
    args: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      iLoading: true,
    }
  }, 

  computed: {
    ...mapState({
      state: state => state.loyalty.loyalty.history,
      programs: state => state.loyalty.loyalty.collection,
    }),

    isEmpty() {
      return !this.loading && !this.iLoading && (this.$basil.isNil(this.collection) || this.$basil.get(this.collection, 'length', 0) === 0)
    },

    rows() {
      let ret = this.collection || []
      return ret.map((r) => {
        return {
          labels: this.getLabel(r),
          icon: {
            glyph: this.getIcon(r),
            hue: this.getIconHue(r),
          },
          meta: this.getMeta(r),
          badge: {
            value: this.getPoints(r),
            hue: this.getPointsHue(r)
          }
        }
      })
    }
  },

  methods: {
    onPage(page) {
      this.pagination.currentPage = page
      this.reset()
    },

    getIcon(item) {
      return this.$t(`sayl-ecommerce.loyalty_history_event_${this.$basil.get(item, 'message')}_icon`)
    },

    getIconHue(item) {
      switch(this.$basil.get(item, 'message')) {
        // case 'paid_order': 
        //   return '-blue';

        // case 'subscribed_reward':
        //   return '-blue';

        // case 'in_store_reward': 
        //   return '-blue';

        case 'redeemed_credits':
        case 'breakage':
          return '-red'

        case 'loyalty_trade': 
          return '-yellow'

        default: 
          return '-blue'
      }
    },

    getLabel(item) {
      let txt = this.$t(`sayl-ecommerce.loyalty_history_event_${this.$basil.get(item, 'message')}_template`)
      let render = Mustache.parse(txt, ['{{', '}}'])

      let amount = this.$basil.get(item, 'order.price_total', null)
      let metadata = this.$basil.get(item, 'metadata', {})
      let code = null

      if(!Array.isArray(metadata) && Object.keys(metadata).length > 0) {
        let m = Object.keys(metadata).find(met => metadata[met].hasOwnProperty('code'))
        code = this.$basil.get(metadata, `${m}.code`, null)
      }

      let orderPickupCode = this.$basil.get(item, 'order.pickup_code')

      let itemsCount = this.$basil.get(item, 'order.items_count', null)
      let name = [
        this.$basil.get(item, 'user.firstname', null),
        !this.$basil.isNil(this.$basil.get(item, 'user.lastname', null)) ? 
          this.$basil.get(item, 'user.lastname').toUpperCase() : null
      ];
      let voucher = this.$basil.get(item, 'voucher.name.all', null)
      
      name = name.filter(n => !this.$basil.isNil(n))

      let values = {
        code,
        name: this.$basil.get(name, 'length', 0) > 0 ? name.join(' ') : null,
        itemsCount: !this.$basil.isNil(itemsCount) ? itemsCount : null,
        amount: !this.$basil.isNil(amount) ? this.$basil.i18n.currency(amount) : null,
        voucher: !this.$basil.isNil(voucher) ? voucher : null,
        pickupCode: orderPickupCode
      }

      return render.map(([type, name, start, stop]) => {
        let r = {}

        // Dynamic value
        if (type === 'name'){
          r.bind = {
            class: 'bold'
          }
          r.type = 'span'
          r.value = this.$basil.get(values, name, '')
        } else{
          r.bind = {}
          r.type = 'span'
          r.value = txt.substring(start, stop)
        }

        // The value is an array
        if (this.$basil.isArray(r.value)){
          r = r.value.map(e => {
            let i = Object.assign({}, r)
            i.value = e

            return i
          })
        }
        return r
      })
    },

    getMeta(item) {
      let date = this.$basil.get(item, 'created')

      let ret = [
        {
          label: this.$basil.i18n.datetime(new Date(date)),
          icon: 'time'
        },

        !this.$basil.isNil(this.$basil.get(item, 'order', null)) ? 
          {
            label: `#${this.$basil.get(item, 'order.pickup_code')}`,
            icon: 'bag',
            href: { name: 'sayl-sales_entry', params: { id: this.$basil.get(item, 'order.id') }}
          } : null,

        !this.$basil.isNil(this.$basil.get(item, 'order.shop_name', null)) ? 
          {
            label: this.$basil.get(item, 'order.shop_name', null),
            icon: 'building-store',
            href: { name: 'sayl-settings_shop', params: { id: this.$basil.get(item, 'shopId') }}
          } : null,

        !this.$basil.isNil(this.$basil.get(item, 'voucher.id', null)) ? 
          {
            label: this.$basil.get(item, 'voucher.name.all', null),
            icon: 'bag',
            href: { name: 'sayl-ecommerce_voucher', params: { id: this.$basil.get(item, 'voucher.id') }}
          } : null,

        !this.$basil.isNil(this.$basil.get(item, 'user', null)) && this.$basil.get(item, 'message') === 'subscribed_reward' ? 
          {
            label: `${this.$basil.get(item, 'user.firstname')}`,
            icon: 'user',
            href: { name: 'sayl-customer_entry', params: { id: this.$basil.get(item, 'user.id') }}
          } : null,
      ];

      return ret.filter(r => !this.$basil.isNil(r))
    },

    getPoints(item) {
      let ret = this.$basil.get(item, 'amount')
      let symbol = this.$basil.get(this.programs, '[0].points.symbol')
      
      return `${this.$basil.i18n.number(ret, { sign: this.$basil.i18n.NumberSigns.ALWAYS })}${symbol}`
    },

    getPointsHue(item) {
      let ret = this.$pepper.Hue.BLUE
      
      if(this.$basil.get(item, 'amount') < 0) {
        ret = this.$pepper.Hue.RED
      }
      
      return `-${ret}`
    },

    reset() {
      this.iLoading = true
      this.pagination.pageSize = 10
      let args = Object.assign(this.args, { limit: this.pagination.pageSize, page: this.pagination.currentPage })

      return new Promise((resolve, reject) => {
        window.$dl.loyalty.getHistory(args)
          .then((r) => resolve(r))
          .catch((e) => reject(e))
          .finally(() => this.iLoading = false)
      })
    }, 
  },
}
</script>
