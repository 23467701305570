<template>
  <details 
    :class="classes"
    :open="open">
    <summary class="ui-details__summary" @click="onSummaryClick">
      <slot name="summary"></slot>
    </summary>  

    <div class="ui-details__body">
      <slot></slot>
    </div>
  </details>
</template>

<script>
export default {
  name: 'UiDetails',

  props: {
    article: {
      type: Boolean,
      default: false,
    },

    appearance: {
      type: String,
    },

    autoPadding: {
      type: Boolean,
      default: true
    },

    bordered: {
      type: Boolean,
      default: false,
    },

    open: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes() {
      return {
        'ui-details': true,
        '-is-subtle': this.appearance === this.$pepper.Appearance.SUBTLE,
        '-is-auto-padding': this.autoPadding,
        '-article': this.article,
        '-bordered': this.bordered,
      }
    }
  },

  methods: {
    onSummaryClick(event) {
      this.$emit('summary', event)
    }
  }
}
</script>
