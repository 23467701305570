import button from './button'
import input from './input'

const base = [
  {
    component: 'forms-input',
    fqn: 'general-style-bg',
    label: 'bg',
    property: '--slp-bg',
    section: 'general',
    subtype: 'color',
    type: 'style',
  },
]

const mergeTags = [
  {
    component: 'forms-input',
    fqn: 'general-style-merge-tags-fc',
    label: 'fc',
    property: '--slp-merge-tag-fc',
    section: 'general',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-select',
    fqn: 'general-style-merge-tags-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-merge-tag-fw',
    section: 'header',
    subtype: 'fontWeight',
    type: 'style',
  },
]

export default [
  ...base,
  ...button,
  ...input,
  ...mergeTags,
]
