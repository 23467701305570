<template>
  <div 
    class="loyalty-page-preview" 
    v-if="!$basil.isNil(edition)">
    <div 
      class="loyalty-page-preview__page"
      id="sayl-loyalty-page" 
    ></div>
    
    <div class="loyalty-page-preview__footer">
      <actions-button
        :appearance="$pepper.Appearance.SUBTLE"
        :size="$pepper.Size.S"
        icon-post="arrow-right"
        :href="{ name: 'sayl-customer-loyalty_program-page', params: { program: edition.id } }"
      >{{ !readOnly ? $t('sayl-loyalty.loyalty_edit_page') : $t('sayl-loyalty.loyalty_view_page') }}</actions-button>
    </div>
  </div>
</template>

<script>
import { 
  mapGetters, 
  mapState 
} from 'vuex'

import { loadScript } from './configuration/page/loadscript.js'

export default {
  name: 'LoyaltyPagePreview',

  props: {
    edition: {
      type: Object,
      required: true
    },

    readOnly: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      iLoading: true,
      key: 1,
    }
  },

  computed: {
    ...mapState({
      vouchersTemplates: state => state.ecommerce.voucher.templates.all
    }),

    ...mapGetters({
      config: 'sayl/config',
    }),

    dataset() {
      let points = this.$basil.get(this.edition, 'points')
      let transPoints = this.$basil.get(points, 'name.all')

      let pointsRet = {
        symbol: points.symbol,
        name: transPoints
      }

      let content = !this.$basil.isNil(this.edition ? this.edition.pageContent : null) ? this.edition.pageContent.toDataset('all') : {}
      let style = !this.$basil.isNil(this.edition ? this.edition.pageStyle: null) ? this.edition.pageStyle.toDataset() : []

      return this.key && {
        content,
        points: pointsRet,
        rules: this.$basil.get(this.edition, 'rules'),
        style,
        user: null,
        vouchers: this.vouchers,
      }
    },

    vouchers() {
      let vouchers = this.edition ? this.edition.redeemableVouchers : []
      let ret = []
      
      vouchers.forEach(v => {
        let value = this.vouchersTemplates.find(t => t.id === v.voucher_id)
        let titleTrans = null

        titleTrans = value ? value.name[this.lang] : null

        if(titleTrans == null) {
          titleTrans = value && value.name && value.name.all ? value.name.all : null
        }

        ret.push({
          key: value.id,
          title: titleTrans,
          description: null,
          points: v.required_points,
          validityPeriod: v.validity_period,
        })
      })

      return ret
    },


    scriptUrl() {
      return this.$basil.get(this.config, 'loyalty.page.url', 'https://cdn-apps.sayl.cloud/loyalty-page/') + 'index.js'
    }
  },

  methods: {
    reset() {
      loadScript(this.scriptUrl, this.scriptLoaded)
    },
    
    scriptLoaded() {
      this.iLoading = true

      this.loyalty = window.SaylLoyaltyPage.init(
        this.dataset, 
        {
          context: document.getElementById('sayl-loyalty-page'), 
          shadow: true,
          hasHeight: true,
        }
      )
      
      if(this.loyalty) {
        this.loyalty.registerCallback('redeem', (voucher, done) => {
          setTimeout(() => { 
            voucher.code = 'ABCD-1234'
            voucher.redeemed = true

            // voucher.error = 'Some error occured'
            done()
          }, 1000)
        })

        this.loyalty.registerCallback('login', (done) => {
          setTimeout(() => { 
            this.loyalty.set('user', this.user)
            this.isLogged = true
            done() 
          }, 1000)
        })

        this.loyalty.registerCallback('subscribe', (done) => {
          setTimeout(() => { 
            this.loyalty.set('user', this.user)
            this.isLogged = true
            done() 
          }, 1000)
        })
      }

      this.iLoading = false
    },
  },

  mounted() {
    this.iLoading = true
    this.reset()
  },

  beforeDestroy() {
    let page = document.getElementById('sayl-loyalty-page')
    page && page.remove()
  }
}
</script>
