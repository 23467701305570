<template>
  <layout-modal-step-item 
    v-bind="attrs"
    @search="onSearch">

    <!-- Loading -->
    <div 
      class="layout-modal-steps__loader" 
      v-if="loading">
      <ui-loader />
    </div>

    <component
      :is="rewardIs"
      :search="search"
      :value="value"
      v-if="!loading"
    />

    <template v-slot:footer>
      <actions-button
        icon-pre="arrow-left"
        @click="$attrs.previous"
      >{{ $t('sayl.previous') }}</actions-button>

      <actions-button
        appearance="primary"
        :icon-post="$attrs.hasNext ? 'arrow-right' : null"
        :disabled="!isValid"
        @click="onConfirm"
      >{{ $attrs.hasNext ? $t('sayl.next') : $t('sayl.confirm') }}</actions-button>
    </template>
  </layout-modal-step-item>
</template>

<script>
import { MixinError } from '@sayl/admin-common'

import Loyalty from './components/loyalty'
import NFT from './components/nft'
import Voucher from './components/voucher'

export default {
  name: 'ModalChallengeRewardStep1',

  components: {
    Loyalty,
    NFT,
    Voucher
  },

  mixins: [ MixinError,],

  data() {
    return {
      errors: {},
      loading: true,
      search: null,
    }
  },

  computed: {
    attrs() {
      return Object.assign({
        classes: { '-body-p0': true },
        title: this.$t('sayl-audiences.challenge_reward_step_2'),
        searchable: this.value.type === 'nft',
      }, this.$attrs)
    },

    isValid() {
      switch(this.value.type) {
        case 'loyalty':
          return this.loading || [
            !(
              this.$basil.isNil(this.value.settings.credits_amount) || 
              (this.$basil.isString(this.value.settings.credits_amount) && this.$basil.isEmpty(this.value.settings.credits_amount)) ||
              (this.$basil.isNumber(this.value.settings.credits_amount) && this.value.settings.credits_amount <= 0)
            )
          ].filter(r => r === false).length === 0
        
        case 'nft': 
          return this.loading || [
            !(this.$basil.isNil(this.value.settings.nft_id) || this.$basil.isEmpty(this.value.settings.nft_id))
          ].filter(r => r === false).length === 0

        case 'voucher':
          return this.loading || [
            !(this.$basil.isNil(this.value.settings.voucher_type) || this.$basil.isEmpty(this.value.settings.voucher_type)),
            !(this.$basil.isNil(this.value.settings.voucher_template_id) || this.$basil.isEmpty(this.value.settings.voucher_template_id)),
            !(
              this.$basil.isNil(this.value.settings.voucher_validity_period) || 
              (this.$basil.isString(this.value.settings.voucher_validity_period) && this.$basil.isEmpty(this.value.settings.voucher_validity_period)) ||
              (this.$basil.isNumber(this.value.settings.voucher_validity_period) && this.value.settings.voucher_validity_period <= 0)
            ),
            !(
              this.$basil.isNil(this.value.settings.voucher_amount) || 
              (this.$basil.isString(this.value.settings.voucher_amount) && this.$basil.isEmpty(this.value.settings.voucher_amount)) ||
              (this.$basil.isNumber(this.value.settings.voucher_amount) && this.value.settings.voucher_amount <= 0)
            ),
          ].filter(r => r === false).length === 0
      }

      return this.loading || [
        !(this.$basil.isNil(this.value, 'settings.nft_id') || this.$basil.isEmpty(this.value, 'settings.nft_id'))
      ].filter(r => r === false).length === 0
    },

    rewardIs() {
      switch(this.value.type) {
        case 'loyalty':
          return Loyalty
        
        case 'nft': 
          return NFT

        case 'voucher':
          return Voucher
      }
    },

    value() {
      return this.$attrs.aggregate
    }
  },

  methods: { 
    onConfirm() {
      this.$attrs.hasNext ? 
        this.$attrs.next(this.value) : 
        this.$emit('confirm', this.value)
    },

    onSearch(value) {
      this.search = value
    },

    reset() {
      this.errors = {}
      this.loading = false
    },
  },

  mounted() {
    this.reset()
  }
}
</script>
