export default {
  user: {
    name: 'sayl-loyalty.page_merge_tags_user',
    mergeTags: {
      points: {
        name: 'sayl-loyalty.page_merge_tags_user_points',
        value: '{{user.points}}'
      }
    }
  },

  points: {
    name: 'sayl-loyalty.page_merge_tags_points',
    mergeTags: {
      title: {
        name: 'sayl-loyalty.page_merge_tags_points_name',
        value: '{{points.name}}'
      },
      symbol: {
        name: 'sayl-loyalty.page_merge_tags_points_symbol',
        value: '{{points.symbol}}'
      },
    }
  },

  voucher: {
    name: 'sayl-loyalty.page_merge_tags_vouchers',
    mergeTags: {
      title: {
        name: 'sayl-loyalty.page_merge_tags_voucher_valitidy_period',
        value: '{{voucher.validityPeriod}}'
      },
    }
  }
}
