<template>
  <layout-modal-step-item v-bind="attrs">
    <!-- Loading -->
    <div 
      class="layout-modal-steps__loader" 
      v-if="loading" >
      <ui-loader />
    </div>

    <article class="layout-modal__article flow">
      <!-- Perk::Name -->
      <forms-input
        :class="hasTranslations('name')"
        :errors="getErrors('name.all')"
        :hint="$t('sayl.tier_perk_name_hint')"
        icon-post="l8n"
        :icon-post-interactive="true"
        :placeholder="$t('sayl.placeholder')"
        :read-only="readOnly"
        required
        type="text"
        @iconClick="onOpenTranslations('name')"
        @input="onRemoveError('name.all') && key++" 
        v-model="edition.name.all"
      >{{ $t('sayl.tier_perk_name') }}</forms-input>

      <!-- Perk::ClientMessage -->
      <forms-textarea
        :class="hasTranslations('description')"
        :errors="getErrors('description.all')"
        :hint="$t('sayl.tier_perk_description_hint') "
        input-mode="text"
        icon-post="l8n"
        :icon-post-interactive="true"
        :placeholder="$t('sayl.placeholder')"
        required
        :read-only="readOnly"
        :rows="3"
        type="text"
        @iconClick="onOpenTranslations('description', 'forms-textarea')"
        @input="onRemoveError('description.all') && key++"  
        v-model="edition.description.all"
      >{{ $t('sayl.tier_perk_description') }}</forms-textarea>
    </article>

    <!-- Perk::WalletMessage -->
    <article class="layout-modal__article">
      <forms-textarea
        :class="hasTranslations('perk.walletMessage')"
        :errors="getErrors('wallet_message.all')"
        :hint="$t('sayl.tier_perk_wallet_message_hint') "
        input-mode="text"
        icon-post="l8n"
        :icon-post-interactive="true"
        :placeholder="$t('sayl.placeholder')"
        :read-only="readOnly"
        :rows="3"
        type="text"
        @iconClick="onOpenTranslations('perk.walletMessage', 'forms-textarea')"
        @input="onRemoveError('wallet_message.all') && key++" 
        v-model="edition.perk.walletMessage.all"
      >{{ $t('sayl.tier_perk_wallet_message') }}</forms-textarea>
    </article>

    <modal-translate 
      :field="translationsField"
      :read-only="readOnly"
      :type="translationsType"
      :visible="showTranslations" 
      @close="onCloseTranslations"
    />

    <template v-slot:footer>
      <actions-button
        :appearance="$pepper.Appearance.SUBTLE"
        icon-pre="arrow-left"
        @click="$attrs.previous"
      >{{ $t('sayl.previous') }}</actions-button>

      <actions-button
        appearance="primary"
        :disabled="!isValid"
        @click="onConfirm"
      >{{ $t('sayl.confirm') }}</actions-button>
    </template>
  </layout-modal-step-item>
</template>

<script>
import { 
  MixinEditionSave,
  MixinError,
  MixinTranslation,
} from '@sayl/admin-common'

export default {
  name: 'TierRewardPerk3',

  mixins: [ 
    MixinEditionSave,
    MixinError,
    MixinTranslation,
  ],

  data() {
    return { 
      errors: {},
      key: 1,
      loading: false,
    }
  },

  computed: {
    attrs() {
      return Object.assign({
        title: this.$t('sayl.tier_create_message_title'),
      }, this.$attrs)
    },

    edition() {
      return this.$attrs.edition
    },

    readOnly() {
      return this.$attrs.readOnly || false
    },

    isLoading() {
      return this.$basil.isNil(this.edition) || this.$basil.get(this.$attrs, 'loading', false)
    },

    isValid() {
      return this.key &&  [
        this.$basil.isNil(this.$basil.get(this.edition, 'description.all')) || this.$basil.isEmpty(this.$basil.get(this.edition, 'description.all')) ? false : true,
        this.$basil.isNil(this.$basil.get(this.edition, 'name.all')) || this.$basil.isEmpty(this.$basil.get(this.edition, 'name.all')) ? false : true
      ].filter(v => v === false).length === 0
    },
  },

  methods: { 
    onConfirm() {
      if(this.isValid) {
        this.onSave({}) 
          .then(() => {
            // this.$basil.isNil(this.$basil.get(this.$attrs, 'nft')) ? 
              // this.$router.push({ name: 'sayl-web3-triggers_item', params: { id: this.edition.id }}) : 
              this.$attrs.close()
          })
          .catch((e) => $console.error(e))
      }
    },

    save({}) {
      return window.$dl.tierReward.save({ args: { tierId: this.$route.params.tier }, item: this.edition, isNew: this.edition.id == null })
    }
  },
}
</script>
