<template>
  <div class="charts-funnel">
    <div 
      class="charts-funnel__loader"
      v-if="loading">
      <ui-loader />
    </div>

    <div 
      class="charts-funnel__graph"
      ref="graph"
      v-if="!loading"
    ></div>

    <div 
      class="charts-funnel__items"
      v-if="!loading">
      <div 
        class="charts-funnel__item"
        :key="$basil.uniqId(i)"
        v-for="(c, i) in columns">
        
        <div class="charts-funnel__label">{{ c.label }}</div>
        <div 
          class="charts-funnel__percent"
          v-if="i > 0"
        ><span class="value">{{ getPercentage(c, i) }}</span></div>
        <data-badge 
          :appearance="$pepper.Appearance.PRIMARY"
          class="charts-funnel__value" 
          :value="getValue(c)" 
        />
      </div>
    </div>
  </div>
</template>

<script>
import { SVG } from '@svgdotjs/svg.js'

export default {
  name: 'ChartsFunnel',

  props: {
    loading: {
      type: Boolean,
      required: false,
    },

    values: {
      type: Array | null,
      required: true
    }
  },

  computed: {
    columns() {
      return [
        {
          label: this.$t('sayl.funnel_landing'),
          value: 'landing'
        },
        // {
        //   label: this.$t('sayl.funnel_cart'),
        //   value: 'cart'
        // },
        {
          label: this.$t('sayl.funnel_checkout'),
          value: 'checkout'
        },
        {
          label: this.$t('sayl.funnel_payment'),
          value: 'payment'
        },
        {
          label: this.$t('sayl.funnel_order'),
          value: 'order'
        },
      ]
    },

    total() {
      let ret = 0;
      this.values.forEach(v => ret += this.$basil.get(v, 'value', 0))
      return ret === 0 ? 20 : ret
    },

    graph() {
      let ret = {
        min: -1,
        max: 0,
        points: []
      };

      this.columns.forEach((c, i) => {
        let v = this.values.find(val => val.name === c.value)

        if(!this.$basil.isNil(v)) {
          if(ret.max < v.value) {
            ret.max = v.value
          }
  
          if(ret.min === -1 || ret.min > v.value) {
            ret.min = v.value;
          }
          
          ret.points.push(v.value)
        } else {
          ret.points.push(0)
        }
        
      })
      return ret
    },
  }, 

  watch: {
    loading: {
      immediate: true,
      handler(val, old) {
        if(val === false && old === true) {
          setTimeout(() => this.getGraph(), 250)
        } else {
          let graph = this.$refs.graph
          if(!this.$basil.isNil(graph) && graph.hasChildNodes()) {
            while (graph.firstChild) {
                graph.removeChild(graph.firstChild);
            }
          }
        }
      }
    }
  },

  methods: {
    getPercentage(c, index) {
      let value = this.getValue(c);
      let previousValue = this.$basil.get(this.values, '0.value', 0);

      return value != 0 ? this.$basil.i18n.percent((value / previousValue), {fraction: 2}) : 0
    },

    getValue(c) {
      let ret = this.values.find(v => v.name === c.value)
      return this.$basil.isNil(ret) ? 0 : ret.value
    },

    getGraph() {
      let graph = this.$refs.graph

      if(!this.$basil.isNil(graph) && graph.hasChildNodes()) {
        while (graph.firstChild) {
          graph.removeChild(graph.firstChild);
        }
      }

      if(!this.$basil.isNil(graph)) {
        let width = graph.getBoundingClientRect().width
        let height = graph.getBoundingClientRect().height + 10
        let minHeight = (height / 20)

        let hStep = (height - minHeight) / this.graph.max
        let wStep = width / this.columns.length
  
        let points = []
  
        if(this.graph.max > 0){
          this.graph.points.forEach((p, i) => {
            let x = -16
    
            if(i === 1){
              x = (wStep * i) + (wStep / 2)
            } else if(i !== 0) {
              x = (wStep * i) + (wStep / 2)
            }
    
            let y = ((height) - minHeight - 10) - (hStep * p)
    
            x = x < 0 ? 0 : x
            y = y < 0 ? 0 : y
    
            points.push([x, y])
            if(i === (this.graph.points.length - 1)) {
              points.push([width, y])
            }
          })
        }
  
        points.push(
          [width + 16, height],
          [-16, height]
        )
        var draw = SVG().addTo(this.$refs.graph).size('100%', '100%')
        var polygon = draw.polygon(points)
        polygon.fill({ color: '#EDF8FD', opacity: 0.7 })
        polygon.stroke({ color: '#4FB5E1', linecap: 'round', linejoin: 'round' })
      }
    }
  },

  mounted() {
    this.getGraph()
    window.addEventListener('resize', this.getGraph)
  }
}
</script>
