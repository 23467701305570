const base = [
  {
    component: 'forms-input',
    fqn: 'referral-style-primary-bg',
    label: 'bg_primary',
    property: '--slp-referral-primary-bg',
    section: 'referral',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'referral-style-secondary-bg',
    label: 'bg_secondary',
    property: '--slp-referral-secondary-bg',
    section: 'referral',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'referral-style-filigrane',
    label: 'filigrane_stroke',
    property: '--slp-referral-filigrane-bg',
    section: 'referral',
    subtype: 'color',
    type: 'style',
  },
]

const title = [
  {
    component: 'forms-input',
    fqn: 'referral-content-title',
    label: 'title',
    property: 'title',
    section: 'referral',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'referral-style-title-fc',
    label: 'fc',
    property: '--slp-referral-title-fc',
    section: 'referral',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-select',
    fqn: 'referral-style-title-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-referral-title-fs',
    section: 'referral',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component: 'forms-select',
    fqn: 'referral-style-title-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-referral-title-fw',
    section: 'referral',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const descr = [
  {
    component: 'forms-textarea',
    fqn: 'referral-content-descr',
    label: 'description',
    property: 'description',
    section: 'referral',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'referral-style-descr-fc',
    label: 'fc',
    property: '--slp-referral-descr-fc',
    section: 'referral',
    subtype: 'color',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'referral-style-descr-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-referral-descr-fs',
    section: 'referral',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'referral-style-descr-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-referral-descr-fw',
    section: 'referral',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const actions = [
  {
    component: 'forms-input',
    fqn: 'referral-content-btn-register',
    label: 'btn_regsiter',
    logged: false,
    property: 'btn-register',
    section: 'referral',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'referral-content-btn-login',
    label: 'btn_login',
    logged: false,
    property: 'btn-register',
    section: 'referral',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'referral-content-btn-activate',
    label: 'btn_activate',
    logged: true,
    property: 'btn-activate',
    section: 'referral',
    type: 'content',
  },
]

const sharing = [
  {
    component: 'forms-input',
    fqn: 'referral-content-link',
    label: 'link',
    logged: true,
    property: 'link',
    section: 'referral',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'referral-content-copied',
    label: 'copied',
    logged: true,
    property: 'copied',
    section: 'referral',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'referral-content-btn-share',
    label: 'btn_share',
    logged: true,
    property: 'btn-share',
    section: 'referral',
    type: 'content',
  },
]

// Rewards
const rewardTitles = [
  {
    component: 'forms-input',
    fqn: 'referral-content-referrer-title',
    label: 'referrer',
    property: 'referrer-title',
    section: 'referral',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'referral-content-referring-title',
    label: 'referring',
    property: 'referring-title',
    section: 'referral',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'referral-style-subtitle-fc',
    label: 'fc',
    property: '--slp-referral-subtitle-fc',
    section: 'referral',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-select',
    fqn: 'referral-style-subtitle-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-referral-subtitle-fs',
    section: 'referral',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component: 'forms-select',
    fqn: 'referral-style-subtitle-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-referral-subtitle-fw',
    section: 'referral',
    subtype: 'fontWeight',
    type: 'style',
  },
]

// Reward Item
const rewardItem = [
  {
    component: 'forms-input',
    fqn: 'referral-style-reward-bg',
    label: 'bg',
    property: '--slp-referral-reward-bg',
    section: 'referral',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'referral-style-reward-bc',
    label: 'bc',
    property: '--slp-referral-reward-bc',
    section: 'referral',
    subtype: 'color',
    type: 'style',
  },
]

const rewardItemTitle = [
  {
    component: 'forms-input',
    fqn: 'referral-content-referrer-type-loyalty',
    label: 'type_loyalty',
    property: 'type-loyalty',
    section: 'referral',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'referral-content-referrer-type-nft',
    label: 'type_nft',
    property: 'type-nft',
    section: 'referral',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'referral-content-referrer-type-voucher',
    label: 'type_voucher',
    property: 'type-voucher',
    section: 'referral',
    type: 'content',
  },

  {
    component: 'forms-input',
    fqn: 'referral-style-reward-title-fc',
    label: 'fc',
    property: '--slp-referral-reward-title-fc',
    section: 'referral',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-select',
    fqn: 'referral-style-reward-title-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-referral-reward-title-fs',
    section: 'referral',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component: 'forms-select',
    fqn: 'referral-style-reward-title-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-referral-reward-title-fw',
    section: 'referral',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const rewardItemDescr = [
  {
    component: 'forms-input',
    fqn: 'referral-style-reward-descr-fc',
    label: 'fc',
    property: '--slp-referral-reward-descr-fc',
    section: 'referral',
    subtype: 'color',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'referral-style-reward-descr-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-referral-reward-descr-fs',
    section: 'referral',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'referral-style-reward-descr-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-referral-reward-descr-fw',
    section: 'referral',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const rewardItemIcons = [
  // Loyalty
  {
    component: 'forms-input',
    fqn: 'referral-style-reward-icon-loyalty-bg',
    label: 'bg',
    property: '--slp-referral-reward-icon-loyalty-bg',
    section: 'referral',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'referral-style-reward-icon-loyalty-fc',
    label: 'fc',
    property: '--slp-referral-reward-icon-loyalty-fc',
    section: 'referral',
    subtype: 'color',
    type: 'style',
  },

  // NFT
  {
    component: 'forms-input',
    fqn: 'referral-style-reward-icon-nft-bg',
    label: 'bg',
    property: '--slp-referral-reward-icon-nft-bg',
    section: 'referral',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'referral-style-reward-icon-nft-fc',
    label: 'fc',
    property: '--slp-referral-reward-icon-nft-fc',
    section: 'referral',
    subtype: 'color',
    type: 'style',
  },

  // Voucher
  {
    component: 'forms-input',
    fqn: 'referral-style-reward-icon-voucher-bg',
    label: 'bg',
    property: '--slp-referral-reward-icon-voucher-bg',
    section: 'referral',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'referral-style-reward-icon-voucher-fc',
    label: 'fc',
    property: '--slp-referral-reward-icon-voucher-fc',
    section: 'referral',
    subtype: 'color',
    type: 'style',
  },
]

// History
const historyBase = [
  {
    clearable: true,
    component: 'forms-input',
    fqn: 'referral-history-style-bg',
    label: 'bg',
    property: '--slp-referral-history-bg',
    section: 'referral',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'referral-history-content-empty-title',
    label: 'empty_title',
    property: 'history-empty-title',
    section: 'history',
    type: 'content',
  },
]

const historyTitle = [
  {
    component: 'forms-input',
    fqn: 'referral-history-content-title',
    label: 'title',
    property: 'history-title',
    section: 'referral',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'referral-history-style-title-fc',
    label: 'fc',
    property: '--slp-referral-history-title-fc',
    section: 'referral',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-select',
    fqn: 'referral-history-style-title-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-referral-history-title-fs',
    section: 'referral',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component: 'forms-select',
    fqn: 'referral-history-style-title-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-referral-history-title-fw',
    section: 'referral',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const historyDescr = [
  {
    component: 'forms-input',
    fqn: 'referral-history-content-descr',
    label: 'description',
    property: 'history-description',
    section: 'referral',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'referral-history-style-descr-fc',
    label: 'fc',
    property: '--slp-referral-history-descr-fc',
    section: 'referral',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-select',
    fqn: 'referral-history-style-descr-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-referral-history-descr-fs',
    section: 'referral',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component: 'forms-select',
    fqn: 'referral-history-style-descr-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-referral-history-descr-fw',
    section: 'referral',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const historyItemBase = [
  {
    component: 'forms-input',
    fqn: 'referral-history-style-item-bg',
    label: 'bg',
    property: '--slp-referral-history-item-bg',
    section: 'referral',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'referral-history-style-item-bc',
    label: 'bc',
    property: '--slp-referral-history-item-bc',
    section: 'referral',
    subtype: 'color',
    type: 'style',
  },

  // Labels
  {
    component: 'forms-input',
    fqn: 'referral-history-style-item-fc',
    label: 'fc',
    property: '--slp-referral-history-item-fc',
    section: 'referral',
    subtype: 'color',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'referral-history-style-item-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-referral-history-item-fs',
    section: 'referral',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component: 'forms-select',
    fqn: 'referral-history-style-item-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-referral-history-item-fw',
    section: 'referral',
    subtype: 'fontWeight',
    type: 'style',
  },

  // Date
  {
    component: 'forms-input',
    fqn: 'referral-history-style-item-date-fc',
    label: 'fc',
    property: '--slp-referral-history-item-date-fc',
    section: 'referral',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-select',
    fqn: 'referral-history-style-item-date-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-referral-history-item-date-fw',
    section: 'referral',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const historyActions = [
  {
    component: 'forms-input',
    fqn: 'referral-history-content-btn-more',
    label: 'btn_more',
    logged: true,
    property: 'btn-more',
    section: 'referral',
    type: 'content',
  },
]

export default [
  ...base,
  ...title,
  ...descr,
  ...actions,
  ...sharing,

  // Reward
  ...rewardTitles,
  ...rewardItem,
  ...rewardItemTitle,
  ...rewardItemDescr,
  ...rewardItemIcons,

  // History
  ...historyBase,
  ...historyTitle,
  ...historyDescr,
  ...historyItemBase,
  ...historyActions,
]
