<template>
  <layout-base class="email-editor-demo">
    <layout-level1 v-bind="attrs" v-on="listeners">
      <div class="layout-level1__container flow">
        <FormsEmailEditor 
          class="email-editor-demo__form"
          ref="emailEditor"
          v-on:load="editorLoaded"
          mode="preview"
          v-model="design"
        />
      </div>
    </layout-level1>
  </layout-base>
</template>

<script>
import { MixinError } from '@sayl/admin-common'

export default {
  name: 'EmailEditorDemo',

  mixins: [
    MixinError,
  ],

  data() {
    return {
      design: {},
      basedesign: {
        "counters": {
            "u_column": 25,
            "u_row": 21,
            "u_content_image": 1,
            "u_content_heading": 4,
            "u_content_divider": 8,
            "u_content_text": 17,
            "u_content_html": 3
        },
        "body": {
            "id": "xOULqEVY2U",
            "rows": [
                {
                    "id": "fR8q0HOJYm",
                    "cells": [
                        1
                    ],
                    "columns": [
                        {
                            "id": "zlLTsoIDww",
                            "contents": [
                                {
                                    "id": "pZAmvjEFLW",
                                    "type": "image",
                                    "values": {
                                        "containerPadding": "0px 0px 20px",
                                        "anchor": "",
                                        "src": {
                                            "url": "https://dczszawruqwxj.cloudfront.net/infinitycommerce/product/633add2350c582401d09c358/633add23990fa.png",
                                            "width": 6420,
                                            "height": 1500,
                                            "autoWidth": false,
                                            "maxWidth": "50%"
                                        },
                                        "textAlign": "center",
                                        "altText": "",
                                        "action": {
                                            "name": "web",
                                            "values": {
                                                "href": "https://saylcloud.com",
                                                "target": "_blank"
                                            },
                                            "attrs": {
                                                "href": "{{href}}",
                                                "target": "{{target}}"
                                            }
                                        },
                                        "hideDesktop": false,
                                        "displayCondition": null,
                                        "_meta": {
                                            "htmlID": "u_content_image_1",
                                            "htmlClassNames": "u_content_image"
                                        },
                                        "selectable": true,
                                        "draggable": true,
                                        "duplicatable": true,
                                        "deletable": true,
                                        "hideable": true
                                    }
                                }
                            ],
                            "values": {
                                "backgroundColor": "",
                                "padding": "0px",
                                "border": {},
                                "_meta": {
                                    "htmlID": "u_column_1",
                                    "htmlClassNames": "u_column"
                                }
                            }
                        }
                    ],
                    "values": {
                        "displayCondition": null,
                        "columns": false,
                        "backgroundColor": "",
                        "columnsBackgroundColor": "",
                        "backgroundImage": {
                            "url": "",
                            "fullWidth": true,
                            "repeat": false,
                            "center": true,
                            "cover": false
                        },
                        "padding": "0px",
                        "anchor": "",
                        "hideDesktop": false,
                        "_meta": {
                            "htmlID": "u_row_1",
                            "htmlClassNames": "u_row"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true
                    }
                },
                {
                    "id": "fyMdKK5E9q",
                    "cells": [
                        1
                    ],
                    "columns": [
                        {
                            "id": "eDGk5_gM92",
                            "contents": [
                                {
                                    "id": "XjLdb29EzW",
                                    "type": "heading",
                                    "values": {
                                        "containerPadding": "20px 10px 0px",
                                        "anchor": "",
                                        "headingType": "h1",
                                        "fontFamily": {
                                            "label": "Arial",
                                            "value": "arial,helvetica,sans-serif"
                                        },
                                        "fontSize": "22px",
                                        "textAlign": "left",
                                        "lineHeight": "100%",
                                        "linkStyle": {
                                            "inherit": true,
                                            "linkColor": "#0000ee",
                                            "linkHoverColor": "#0000ee",
                                            "linkUnderline": true,
                                            "linkHoverUnderline": true
                                        },
                                        "hideDesktop": false,
                                        "displayCondition": null,
                                        "_meta": {
                                            "htmlID": "u_content_heading_1",
                                            "htmlClassNames": "u_content_heading"
                                        },
                                        "selectable": true,
                                        "draggable": true,
                                        "duplicatable": true,
                                        "deletable": true,
                                        "hideable": true,
                                        "text": "<div>\n<div>\n<div>\n<div>Your {{ order.service }} order is {{ order.order_status }}</div>\n</div>\n</div>\n</div>"
                                    }
                                },
                                {
                                    "id": "agAXiedQ09",
                                    "type": "heading",
                                    "values": {
                                        "containerPadding": "4px 10px 10px",
                                        "anchor": "",
                                        "headingType": "h3",
                                        "fontFamily": {
                                            "label": "Arial",
                                            "value": "arial,helvetica,sans-serif"
                                        },
                                        "fontSize": "18px",
                                        "color": "#95a5a6",
                                        "textAlign": "left",
                                        "lineHeight": "140%",
                                        "linkStyle": {
                                            "inherit": true,
                                            "linkColor": "#0000ee",
                                            "linkHoverColor": "#0000ee",
                                            "linkUnderline": true,
                                            "linkHoverUnderline": true
                                        },
                                        "hideDesktop": false,
                                        "displayCondition": null,
                                        "_meta": {
                                            "htmlID": "u_content_heading_2",
                                            "htmlClassNames": "u_content_heading"
                                        },
                                        "selectable": true,
                                        "draggable": true,
                                        "duplicatable": true,
                                        "deletable": true,
                                        "hideable": true,
                                        "text": "<div>\n<div>\n<div>Here is the overview:</div>\n</div>\n</div>"
                                    }
                                }
                            ],
                            "values": {
                                "backgroundColor": "",
                                "padding": "0px",
                                "border": {
                                    "borderTopWidth": "1px",
                                    "borderTopStyle": "solid",
                                    "borderTopColor": "#ecf0f1",
                                    "borderLeftWidth": "4px",
                                    "borderLeftStyle": "solid",
                                    "borderLeftColor": "#ba372a",
                                    "borderRightWidth": "1px",
                                    "borderRightStyle": "solid",
                                    "borderRightColor": "#ecf0f1",
                                    "borderBottomWidth": "0px",
                                    "borderBottomStyle": "solid",
                                    "borderBottomColor": "#ecf0f1"
                                },
                                "borderRadius": "0px",
                                "_meta": {
                                    "htmlID": "u_column_2",
                                    "htmlClassNames": "u_column"
                                }
                            }
                        }
                    ],
                    "values": {
                        "displayCondition": null,
                        "columns": false,
                        "backgroundColor": "",
                        "columnsBackgroundColor": "",
                        "backgroundImage": {
                            "url": "",
                            "fullWidth": true,
                            "repeat": false,
                            "center": true,
                            "cover": false
                        },
                        "padding": "0px",
                        "anchor": "",
                        "hideDesktop": false,
                        "_meta": {
                            "htmlID": "u_row_2",
                            "htmlClassNames": "u_row"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true,
                        "mergeTagGroup": null,
                        "mergeTagRule": null
                    }
                },
                {
                    "id": "ZLIK3x2Khf",
                    "cells": [
                        1
                    ],
                    "columns": [
                        {
                            "id": "zdfyDkQKCA",
                            "contents": [
                                {
                                    "id": "g-MlrrbJnU",
                                    "type": "text",
                                    "values": {
                                        "containerPadding": "10px",
                                        "anchor": "",
                                        "textAlign": "left",
                                        "lineHeight": "140%",
                                        "linkStyle": {
                                            "inherit": true,
                                            "linkColor": "#0000ee",
                                            "linkHoverColor": "#0000ee",
                                            "linkUnderline": true,
                                            "linkHoverUnderline": true
                                        },
                                        "hideDesktop": false,
                                        "displayCondition": null,
                                        "_meta": {
                                            "htmlID": "u_content_text_12",
                                            "htmlClassNames": "u_content_text"
                                        },
                                        "selectable": true,
                                        "draggable": true,
                                        "duplicatable": true,
                                        "deletable": true,
                                        "hideable": true,
                                        "text": "<p style=\"font-size: 14px; line-height: 140%;\"><span style=\"color: #95a5a6; font-size: 14px; line-height: 19.6px;\">Order #{{ order.pickup_code }}</span></p>\n<p style=\"font-size: 14px; line-height: 140%;\"><span style=\"color: #95a5a6; font-size: 14px; line-height: 19.6px;\">Paid via {{ order.payment_method }}</span></p>"
                                    }
                                }
                            ],
                            "values": {
                                "backgroundColor": "",
                                "padding": "0px",
                                "border": {
                                    "borderTopWidth": "0px",
                                    "borderTopStyle": "solid",
                                    "borderTopColor": "#ecf0f1",
                                    "borderLeftWidth": "4px",
                                    "borderLeftStyle": "solid",
                                    "borderLeftColor": "#ba372a",
                                    "borderRightWidth": "1px",
                                    "borderRightStyle": "solid",
                                    "borderRightColor": "#ecf0f1",
                                    "borderBottomWidth": "0px",
                                    "borderBottomStyle": "solid",
                                    "borderBottomColor": "#ecf0f1"
                                },
                                "borderRadius": "0px",
                                "_meta": {
                                    "htmlID": "u_column_18",
                                    "htmlClassNames": "u_column"
                                }
                            }
                        }
                    ],
                    "values": {
                        "displayCondition": null,
                        "columns": false,
                        "backgroundColor": "",
                        "columnsBackgroundColor": "",
                        "backgroundImage": {
                            "url": "",
                            "fullWidth": true,
                            "repeat": false,
                            "center": true,
                            "cover": false
                        },
                        "padding": "0px",
                        "anchor": "",
                        "hideDesktop": false,
                        "_meta": {
                            "htmlID": "u_row_14",
                            "htmlClassNames": "u_row"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true
                    }
                },
                {
                    "id": "QjbN0cqNXZ",
                    "cells": [
                        1
                    ],
                    "columns": [
                        {
                            "id": "g_ICaFbOqx",
                            "contents": [
                                {
                                    "id": "kQIhpSTSoq",
                                    "type": "divider",
                                    "values": {
                                        "width": "100%",
                                        "border": {
                                            "borderTopWidth": "1px",
                                            "borderTopStyle": "solid",
                                            "borderTopColor": "#ecf0f1"
                                        },
                                        "textAlign": "center",
                                        "containerPadding": "4px 0px",
                                        "anchor": "",
                                        "hideDesktop": false,
                                        "displayCondition": null,
                                        "_meta": {
                                            "htmlID": "u_content_divider_5",
                                            "htmlClassNames": "u_content_divider"
                                        },
                                        "selectable": true,
                                        "draggable": true,
                                        "duplicatable": true,
                                        "deletable": true,
                                        "hideable": true
                                    }
                                }
                            ],
                            "values": {
                                "backgroundColor": "",
                                "padding": "0px",
                                "border": {
                                    "borderTopWidth": "0px",
                                    "borderTopStyle": "solid",
                                    "borderTopColor": "#CCC",
                                    "borderLeftWidth": "4px",
                                    "borderLeftStyle": "solid",
                                    "borderLeftColor": "#ba372a",
                                    "borderRightWidth": "1px",
                                    "borderRightStyle": "solid",
                                    "borderRightColor": "#ecf0f1",
                                    "borderBottomWidth": "0px",
                                    "borderBottomStyle": "solid",
                                    "borderBottomColor": "#CCC"
                                },
                                "borderRadius": "0px",
                                "_meta": {
                                    "htmlID": "u_column_9",
                                    "htmlClassNames": "u_column"
                                }
                            }
                        }
                    ],
                    "values": {
                        "displayCondition": null,
                        "columns": false,
                        "backgroundColor": "",
                        "columnsBackgroundColor": "",
                        "backgroundImage": {
                            "url": "",
                            "fullWidth": true,
                            "repeat": false,
                            "center": true,
                            "cover": false
                        },
                        "padding": "0px",
                        "anchor": "",
                        "hideDesktop": false,
                        "_meta": {
                            "htmlID": "u_row_7",
                            "htmlClassNames": "u_row"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true
                    }
                },
                {
                    "id": "irF7JEOhOh",
                    "cells": [
                        63.92,
                        36.08
                    ],
                    "columns": [
                        {
                            "id": "b_cb6shNQ_",
                            "contents": [
                                {
                                    "id": "TE7c-bG8kY",
                                    "type": "text",
                                    "values": {
                                        "containerPadding": "10px 10px 4px",
                                        "anchor": "",
                                        "textAlign": "left",
                                        "lineHeight": "100%",
                                        "linkStyle": {
                                            "inherit": true,
                                            "linkColor": "#0000ee",
                                            "linkHoverColor": "#0000ee",
                                            "linkUnderline": true,
                                            "linkHoverUnderline": true
                                        },
                                        "hideDesktop": false,
                                        "displayCondition": null,
                                        "_meta": {
                                            "htmlID": "u_content_text_2",
                                            "htmlClassNames": "u_content_text"
                                        },
                                        "selectable": true,
                                        "draggable": true,
                                        "duplicatable": true,
                                        "deletable": true,
                                        "hideable": true,
                                        "text": "<p style=\"font-size: 14px; line-height: 100%;\"><strong><span style=\"color: #e03e2d; font-size: 14px; line-height: 14px;\">{{ quantity }}x</span></strong><span style=\"font-size: 14px; line-height: 14px;\"><span style=\"font-size: 14px; line-height: 14px;\"> <span style=\"font-size: 14px; line-height: 14px;\">{{ title }}</span></span></span></p>"
                                    }
                                },
                                {
                                    "id": "9y0Q4oJ_vj",
                                    "type": "html",
                                    "values": {
                                        "html": "<span style=\"padding: 0; margin: 0px; color: darkgray; line-height: 100%; text-align: left; word-wrap: break-word; font-weight: normal; font-family: arial,helvetica,sans-serif; font-size: 12px;\">\n{{#each bundle }}\n\t{{ locale_title }} <br/>\n  \t<span style=\"padding-left: 1rem; margin: 0px; color: lightgray; line-height: 100%; text-align: left; word-wrap: break-word; font-weight: normal; font-family: arial,helvetica,sans-serif; font-size: 12px;\">\n  \t{{#each options }}\n  \t\t{{ locale }} <br/>\n  \t{{/each}}\n  \t</span>\n{{/each}}\n</span>",
                                        "hideDesktop": false,
                                        "displayCondition": null,
                                        "containerPadding": "0px 10px 4px",
                                        "anchor": "",
                                        "_meta": {
                                            "htmlID": "u_content_html_1",
                                            "htmlClassNames": "u_content_html"
                                        },
                                        "selectable": true,
                                        "draggable": true,
                                        "duplicatable": true,
                                        "deletable": true,
                                        "hideable": true
                                    }
                                },
                                {
                                    "id": "GJYoXTbBfS",
                                    "type": "html",
                                    "values": {
                                        "html": "<span style=\"padding: 0; margin: 0px; color: darkgray; line-height: 100%; text-align: left; word-wrap: break-word; font-weight: normal; font-family: arial,helvetica,sans-serif; font-size: 12px;\">\n{{#each options }}\n\t{{ locale }} <br/>\n{{/each}}\n</span>",
                                        "hideDesktop": false,
                                        "displayCondition": null,
                                        "containerPadding": "0px 10px 4px",
                                        "anchor": "",
                                        "_meta": {
                                            "htmlID": "u_content_html_3",
                                            "htmlClassNames": "u_content_html"
                                        },
                                        "selectable": true,
                                        "draggable": true,
                                        "duplicatable": true,
                                        "deletable": true,
                                        "hideable": true
                                    }
                                }
                            ],
                            "values": {
                                "backgroundColor": "",
                                "padding": "0px",
                                "border": {
                                    "borderTopWidth": "0px",
                                    "borderTopStyle": "solid",
                                    "borderTopColor": "#ecf0f1",
                                    "borderLeftWidth": "4px",
                                    "borderLeftStyle": "solid",
                                    "borderLeftColor": "#ba372a",
                                    "borderRightWidth": "0px",
                                    "borderRightStyle": "solid",
                                    "borderRightColor": "#ecf0f1",
                                    "borderBottomWidth": "0px",
                                    "borderBottomStyle": "solid",
                                    "borderBottomColor": "#CCC"
                                },
                                "borderRadius": "0px",
                                "_meta": {
                                    "htmlID": "u_column_3",
                                    "htmlClassNames": "u_column"
                                }
                            }
                        },
                        {
                            "id": "yWtXPTbPhw",
                            "contents": [
                                {
                                    "id": "9ySpz9u7c2",
                                    "type": "text",
                                    "values": {
                                        "containerPadding": "10px",
                                        "anchor": "",
                                        "color": "#7e8c8d",
                                        "textAlign": "left",
                                        "lineHeight": "100%",
                                        "linkStyle": {
                                            "inherit": true,
                                            "linkColor": "#0000ee",
                                            "linkHoverColor": "#0000ee",
                                            "linkUnderline": true,
                                            "linkHoverUnderline": true
                                        },
                                        "hideDesktop": false,
                                        "displayCondition": null,
                                        "_meta": {
                                            "htmlID": "u_content_text_3",
                                            "htmlClassNames": "u_content_text"
                                        },
                                        "selectable": true,
                                        "draggable": true,
                                        "duplicatable": true,
                                        "deletable": true,
                                        "hideable": true,
                                        "text": "<p style=\"font-size: 14px; line-height: 100%; text-align: right;\">{{ $currency total_price }}</p>"
                                    }
                                }
                            ],
                            "values": {
                                "backgroundColor": "",
                                "padding": "0px",
                                "border": {
                                    "borderTopWidth": "0px",
                                    "borderTopStyle": "solid",
                                    "borderTopColor": "#CCC",
                                    "borderLeftWidth": "0px",
                                    "borderLeftStyle": "solid",
                                    "borderLeftColor": "#CCC",
                                    "borderRightWidth": "1px",
                                    "borderRightStyle": "solid",
                                    "borderRightColor": "#ecf0f1",
                                    "borderBottomWidth": "0px",
                                    "borderBottomStyle": "solid",
                                    "borderBottomColor": "#CCC"
                                },
                                "borderRadius": "0px",
                                "_meta": {
                                    "htmlID": "u_column_5",
                                    "htmlClassNames": "u_column"
                                }
                            }
                        }
                    ],
                    "values": {
                        "displayCondition": null,
                        "columns": false,
                        "backgroundColor": "",
                        "columnsBackgroundColor": "",
                        "backgroundImage": {
                            "url": "",
                            "fullWidth": true,
                            "repeat": false,
                            "center": true,
                            "cover": false
                        },
                        "padding": "0px",
                        "anchor": "",
                        "hideDesktop": false,
                        "_meta": {
                            "htmlID": "u_row_3",
                            "htmlClassNames": "u_row"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true,
                        "mergeTagGroup": "orderItems",
                        "mergeTagRule": "repeat",
                        "_override": {
                            "mobile": {
                                "noStackMobile": true
                            }
                        }
                    }
                },
                {
                    "id": "C85k_Ix1Ua",
                    "cells": [
                        1
                    ],
                    "columns": [
                        {
                            "id": "1XxWbInu9O",
                            "contents": [
                                {
                                    "id": "jSmvaMriz_",
                                    "type": "divider",
                                    "values": {
                                        "width": "100%",
                                        "border": {
                                            "borderTopWidth": "1px",
                                            "borderTopStyle": "solid",
                                            "borderTopColor": "#ecf0f1"
                                        },
                                        "textAlign": "center",
                                        "containerPadding": "4px 0px",
                                        "anchor": "",
                                        "hideDesktop": false,
                                        "displayCondition": null,
                                        "_meta": {
                                            "htmlID": "u_content_divider_6",
                                            "htmlClassNames": "u_content_divider"
                                        },
                                        "selectable": true,
                                        "draggable": true,
                                        "duplicatable": true,
                                        "deletable": true,
                                        "hideable": true
                                    }
                                }
                            ],
                            "values": {
                                "backgroundColor": "",
                                "padding": "0px",
                                "border": {
                                    "borderTopWidth": "0px",
                                    "borderTopStyle": "solid",
                                    "borderTopColor": "#CCC",
                                    "borderLeftWidth": "4px",
                                    "borderLeftStyle": "solid",
                                    "borderLeftColor": "#ba372a",
                                    "borderRightWidth": "1px",
                                    "borderRightStyle": "solid",
                                    "borderRightColor": "#ecf0f1",
                                    "borderBottomWidth": "0px",
                                    "borderBottomStyle": "solid",
                                    "borderBottomColor": "#CCC"
                                },
                                "borderRadius": "0px",
                                "_meta": {
                                    "htmlID": "u_column_10",
                                    "htmlClassNames": "u_column"
                                }
                            }
                        }
                    ],
                    "values": {
                        "displayCondition": null,
                        "columns": false,
                        "backgroundColor": "",
                        "columnsBackgroundColor": "",
                        "backgroundImage": {
                            "url": "",
                            "fullWidth": true,
                            "repeat": false,
                            "center": true,
                            "cover": false
                        },
                        "padding": "0px",
                        "anchor": "",
                        "hideDesktop": false,
                        "_meta": {
                            "htmlID": "u_row_8",
                            "htmlClassNames": "u_row"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true
                    }
                },
                {
                    "id": "mtOIRLPXld",
                    "cells": [
                        50.12,
                        49.88
                    ],
                    "columns": [
                        {
                            "id": "ZYgwe_-Ife",
                            "contents": [
                                {
                                    "id": "gBxToiKPXU",
                                    "type": "text",
                                    "values": {
                                        "containerPadding": "10px",
                                        "anchor": "",
                                        "textAlign": "left",
                                        "lineHeight": "140%",
                                        "linkStyle": {
                                            "inherit": true,
                                            "linkColor": "#0000ee",
                                            "linkHoverColor": "#0000ee",
                                            "linkUnderline": true,
                                            "linkHoverUnderline": true
                                        },
                                        "hideDesktop": false,
                                        "displayCondition": null,
                                        "_meta": {
                                            "htmlID": "u_content_text_4",
                                            "htmlClassNames": "u_content_text"
                                        },
                                        "selectable": true,
                                        "draggable": true,
                                        "duplicatable": true,
                                        "deletable": true,
                                        "hideable": true,
                                        "text": "<p style=\"font-size: 14px; line-height: 140%;\"><span style=\"font-size: 14px; line-height: 19.6px;\"><strong>Total</strong></span></p>"
                                    }
                                }
                            ],
                            "values": {
                                "backgroundColor": "",
                                "padding": "0px",
                                "border": {
                                    "borderTopWidth": "0px",
                                    "borderTopStyle": "solid",
                                    "borderTopColor": "#ecf0f1",
                                    "borderLeftWidth": "4px",
                                    "borderLeftStyle": "solid",
                                    "borderLeftColor": "#ba372a",
                                    "borderRightWidth": "0px",
                                    "borderRightStyle": "solid",
                                    "borderRightColor": "#ecf0f1",
                                    "borderBottomWidth": "0px",
                                    "borderBottomStyle": "solid",
                                    "borderBottomColor": "#ecf0f1"
                                },
                                "borderRadius": "0px",
                                "_meta": {
                                    "htmlID": "u_column_7",
                                    "htmlClassNames": "u_column"
                                }
                            }
                        },
                        {
                            "id": "_vrbHIj_3g",
                            "contents": [
                                {
                                    "id": "_EIh9mg8vS",
                                    "type": "text",
                                    "values": {
                                        "containerPadding": "10px",
                                        "anchor": "",
                                        "color": "#7e8c8d",
                                        "textAlign": "left",
                                        "lineHeight": "140%",
                                        "linkStyle": {
                                            "inherit": true,
                                            "linkColor": "#0000ee",
                                            "linkHoverColor": "#0000ee",
                                            "linkUnderline": true,
                                            "linkHoverUnderline": true
                                        },
                                        "hideDesktop": false,
                                        "displayCondition": null,
                                        "_meta": {
                                            "htmlID": "u_content_text_5",
                                            "htmlClassNames": "u_content_text"
                                        },
                                        "selectable": true,
                                        "draggable": true,
                                        "duplicatable": true,
                                        "deletable": true,
                                        "hideable": true,
                                        "text": "<p style=\"font-size: 14px; line-height: 140%; text-align: right;\">{{ $currency order.price_total }}</p>"
                                    }
                                }
                            ],
                            "values": {
                                "backgroundColor": "",
                                "padding": "0px",
                                "border": {
                                    "borderTopWidth": "0px",
                                    "borderTopStyle": "solid",
                                    "borderTopColor": "#ecf0f1",
                                    "borderLeftWidth": "0px",
                                    "borderLeftStyle": "solid",
                                    "borderLeftColor": "#CCC",
                                    "borderRightWidth": "1px",
                                    "borderRightStyle": "solid",
                                    "borderRightColor": "#ecf0f1",
                                    "borderBottomWidth": "0px",
                                    "borderBottomStyle": "solid",
                                    "borderBottomColor": "#ecf0f1"
                                },
                                "borderRadius": "0px",
                                "_meta": {
                                    "htmlID": "u_column_8",
                                    "htmlClassNames": "u_column"
                                }
                            }
                        }
                    ],
                    "values": {
                        "displayCondition": null,
                        "columns": false,
                        "backgroundColor": "",
                        "columnsBackgroundColor": "",
                        "backgroundImage": {
                            "url": "",
                            "fullWidth": true,
                            "repeat": false,
                            "center": true,
                            "cover": false
                        },
                        "padding": "0px",
                        "anchor": "",
                        "hideDesktop": false,
                        "_meta": {
                            "htmlID": "u_row_6",
                            "htmlClassNames": "u_row"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true,
                        "mergeTagGroup": null,
                        "mergeTagRule": null,
                        "_override": {
                            "mobile": {
                                "noStackMobile": true
                            }
                        }
                    }
                },
                {
                    "id": "QEgybm3CXu",
                    "cells": [
                        1
                    ],
                    "columns": [
                        {
                            "id": "o9zPP24lP2",
                            "contents": [
                                {
                                    "id": "Q2Ch3qBGaW",
                                    "type": "divider",
                                    "values": {
                                        "width": "100%",
                                        "border": {
                                            "borderTopWidth": "1px",
                                            "borderTopStyle": "solid",
                                            "borderTopColor": "#ecf0f1"
                                        },
                                        "textAlign": "center",
                                        "containerPadding": "4px 0px",
                                        "anchor": "",
                                        "hideDesktop": false,
                                        "displayCondition": null,
                                        "_meta": {
                                            "htmlID": "u_content_divider_8",
                                            "htmlClassNames": "u_content_divider"
                                        },
                                        "selectable": true,
                                        "draggable": true,
                                        "duplicatable": true,
                                        "deletable": true,
                                        "hideable": true
                                    }
                                }
                            ],
                            "values": {
                                "backgroundColor": "",
                                "padding": "0px",
                                "border": {
                                    "borderTopWidth": "0px",
                                    "borderTopStyle": "solid",
                                    "borderTopColor": "#CCC",
                                    "borderLeftWidth": "4px",
                                    "borderLeftStyle": "solid",
                                    "borderLeftColor": "#ba372a",
                                    "borderRightWidth": "1px",
                                    "borderRightStyle": "solid",
                                    "borderRightColor": "#ecf0f1",
                                    "borderBottomWidth": "0px",
                                    "borderBottomStyle": "solid",
                                    "borderBottomColor": "#CCC"
                                },
                                "borderRadius": "0px",
                                "_meta": {
                                    "htmlID": "u_column_23",
                                    "htmlClassNames": "u_column"
                                }
                            }
                        }
                    ],
                    "values": {
                        "displayCondition": null,
                        "columns": false,
                        "backgroundColor": "",
                        "columnsBackgroundColor": "",
                        "backgroundImage": {
                            "url": "",
                            "fullWidth": true,
                            "repeat": false,
                            "center": true,
                            "cover": false
                        },
                        "padding": "0px",
                        "anchor": "",
                        "hideDesktop": false,
                        "_meta": {
                            "htmlID": "u_row_19",
                            "htmlClassNames": "u_row"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true
                    }
                },
                {
                    "id": "iGonLotrOq",
                    "cells": [
                        1
                    ],
                    "columns": [
                        {
                            "id": "6lwpQEvgWY",
                            "contents": [
                                {
                                    "id": "M3SFw3XKFi",
                                    "type": "heading",
                                    "values": {
                                        "containerPadding": "10px",
                                        "anchor": "",
                                        "headingType": "h1",
                                        "fontFamily": {
                                            "label": "Arial",
                                            "value": "arial,helvetica,sans-serif"
                                        },
                                        "fontSize": "22px",
                                        "textAlign": "left",
                                        "lineHeight": "140%",
                                        "linkStyle": {
                                            "inherit": true,
                                            "linkColor": "#0000ee",
                                            "linkHoverColor": "#0000ee",
                                            "linkUnderline": true,
                                            "linkHoverUnderline": true
                                        },
                                        "hideDesktop": false,
                                        "displayCondition": null,
                                        "_meta": {
                                            "htmlID": "u_content_heading_4",
                                            "htmlClassNames": "u_content_heading"
                                        },
                                        "selectable": true,
                                        "draggable": true,
                                        "duplicatable": true,
                                        "deletable": true,
                                        "hideable": true,
                                        "text": "<div>{{ order.service }}</div>"
                                    }
                                }
                            ],
                            "values": {
                                "backgroundColor": "",
                                "padding": "0px",
                                "border": {
                                    "borderTopWidth": "0px",
                                    "borderTopStyle": "solid",
                                    "borderTopColor": "#ecf0f1",
                                    "borderLeftWidth": "4px",
                                    "borderLeftStyle": "solid",
                                    "borderLeftColor": "#ba372a",
                                    "borderRightWidth": "1px",
                                    "borderRightStyle": "solid",
                                    "borderRightColor": "#ecf0f1",
                                    "borderBottomWidth": "0px",
                                    "borderBottomStyle": "solid",
                                    "borderBottomColor": "#ecf0f1"
                                },
                                "borderRadius": "0px",
                                "_meta": {
                                    "htmlID": "u_column_13",
                                    "htmlClassNames": "u_column"
                                }
                            }
                        }
                    ],
                    "values": {
                        "displayCondition": null,
                        "columns": false,
                        "backgroundColor": "",
                        "columnsBackgroundColor": "",
                        "backgroundImage": {
                            "url": "",
                            "fullWidth": true,
                            "repeat": false,
                            "center": true,
                            "cover": false
                        },
                        "padding": "0px",
                        "anchor": "",
                        "hideDesktop": false,
                        "_meta": {
                            "htmlID": "u_row_10",
                            "htmlClassNames": "u_row"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true
                    }
                },
                {
                    "id": "utwxcszLgk",
                    "cells": [
                        1
                    ],
                    "columns": [
                        {
                            "id": "xsupiLDQGa",
                            "contents": [
                                {
                                    "id": "FWFdZA0ata",
                                    "type": "text",
                                    "values": {
                                        "containerPadding": "10px",
                                        "anchor": "",
                                        "textAlign": "left",
                                        "lineHeight": "140%",
                                        "linkStyle": {
                                            "inherit": true,
                                            "linkColor": "#0000ee",
                                            "linkHoverColor": "#0000ee",
                                            "linkUnderline": true,
                                            "linkHoverUnderline": true
                                        },
                                        "hideDesktop": false,
                                        "displayCondition": null,
                                        "_meta": {
                                            "htmlID": "u_content_text_14",
                                            "htmlClassNames": "u_content_text"
                                        },
                                        "selectable": true,
                                        "draggable": true,
                                        "duplicatable": true,
                                        "deletable": true,
                                        "hideable": true,
                                        "text": "<p style=\"font-size: 14px; line-height: 140%;\">Address:</p>\n<p style=\"font-size: 14px; line-height: 140%;\"> </p>\n<p style=\"font-size: 14px; line-height: 140%;\">{{ order.shop_name }}</p>\n<p style=\"font-size: 14px; line-height: 140%;\">{{ order.fulfillment_location_address }}</p>\n<p style=\"font-size: 14px; line-height: 140%;\"> </p>\n<p style=\"font-size: 14px; line-height: 140%;\">{{ order.shop_phone_number }}</p>",
                                        "_override": {
                                            "desktop": {
                                                "hideDesktop": true
                                            }
                                        }
                                    }
                                }
                            ],
                            "values": {
                                "backgroundColor": "",
                                "padding": "0px",
                                "border": {},
                                "borderRadius": "0px",
                                "_meta": {
                                    "htmlID": "u_column_21",
                                    "htmlClassNames": "u_column"
                                },
                                "_override": {
                                    "mobile": {
                                        "border": {
                                            "borderTopWidth": "0px",
                                            "borderTopStyle": "solid",
                                            "borderTopColor": "#CCC",
                                            "borderLeftWidth": "4px",
                                            "borderLeftStyle": "solid",
                                            "borderLeftColor": "#ba372a",
                                            "borderRightWidth": "1px",
                                            "borderRightStyle": "solid",
                                            "borderRightColor": "#ecf0f1",
                                            "borderBottomWidth": "0px",
                                            "borderBottomStyle": "solid",
                                            "borderBottomColor": "#CCC"
                                        }
                                    }
                                }
                            }
                        }
                    ],
                    "values": {
                        "displayCondition": null,
                        "columns": false,
                        "backgroundColor": "",
                        "columnsBackgroundColor": "",
                        "backgroundImage": {
                            "url": "",
                            "fullWidth": true,
                            "repeat": false,
                            "center": true,
                            "cover": false
                        },
                        "padding": "0px",
                        "anchor": "",
                        "hideDesktop": false,
                        "_meta": {
                            "htmlID": "u_row_17",
                            "htmlClassNames": "u_row"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true
                    }
                },
                {
                    "id": "TdOHLz_WjA",
                    "cells": [
                        1
                    ],
                    "columns": [
                        {
                            "id": "y2FysImMKY",
                            "contents": [
                                {
                                    "id": "sXb_IgPJhB",
                                    "type": "divider",
                                    "values": {
                                        "width": "100%",
                                        "border": {
                                            "borderTopWidth": "1px",
                                            "borderTopStyle": "solid",
                                            "borderTopColor": "#ecf0f1"
                                        },
                                        "textAlign": "center",
                                        "containerPadding": "4px 0px",
                                        "anchor": "",
                                        "hideDesktop": false,
                                        "displayCondition": null,
                                        "_meta": {
                                            "htmlID": "u_content_divider_7",
                                            "htmlClassNames": "u_content_divider"
                                        },
                                        "selectable": true,
                                        "draggable": true,
                                        "duplicatable": true,
                                        "deletable": true,
                                        "hideable": true
                                    }
                                }
                            ],
                            "values": {
                                "backgroundColor": "",
                                "padding": "0px",
                                "border": {
                                    "borderTopWidth": "0px",
                                    "borderTopStyle": "solid",
                                    "borderTopColor": "#CCC",
                                    "borderLeftWidth": "4px",
                                    "borderLeftStyle": "solid",
                                    "borderLeftColor": "#ba372a",
                                    "borderRightWidth": "1px",
                                    "borderRightStyle": "solid",
                                    "borderRightColor": "#ecf0f1",
                                    "borderBottomWidth": "0px",
                                    "borderBottomStyle": "solid",
                                    "borderBottomColor": "#CCC"
                                },
                                "borderRadius": "0px",
                                "_meta": {
                                    "htmlID": "u_column_6",
                                    "htmlClassNames": "u_column"
                                }
                            }
                        }
                    ],
                    "values": {
                        "displayCondition": null,
                        "columns": false,
                        "backgroundColor": "",
                        "columnsBackgroundColor": "",
                        "backgroundImage": {
                            "url": "",
                            "fullWidth": true,
                            "repeat": false,
                            "center": true,
                            "cover": false
                        },
                        "padding": "0px",
                        "anchor": "",
                        "hideDesktop": false,
                        "_meta": {
                            "htmlID": "u_row_5",
                            "htmlClassNames": "u_row"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true,
                        "_override": {
                            "desktop": {
                                "hideDesktop": true
                            }
                        }
                    }
                },
                {
                    "id": "t_H7dW4I9S",
                    "cells": [
                        1
                    ],
                    "columns": [
                        {
                            "id": "qBwrSF5wOC",
                            "contents": [
                                {
                                    "id": "Vn8_Lt3qie",
                                    "type": "text",
                                    "values": {
                                        "containerPadding": "10px",
                                        "anchor": "",
                                        "textAlign": "left",
                                        "lineHeight": "140%",
                                        "linkStyle": {
                                            "inherit": true,
                                            "linkColor": "#0000ee",
                                            "linkHoverColor": "#0000ee",
                                            "linkUnderline": true,
                                            "linkHoverUnderline": true
                                        },
                                        "hideDesktop": false,
                                        "displayCondition": null,
                                        "_meta": {
                                            "htmlID": "u_content_text_15",
                                            "htmlClassNames": "u_content_text"
                                        },
                                        "selectable": true,
                                        "draggable": true,
                                        "duplicatable": true,
                                        "deletable": true,
                                        "hideable": true,
                                        "text": "<p style=\"font-size: 14px; line-height: 140%;\">Time of collection:</p>\n<p style=\"font-size: 14px; line-height: 140%;\"> </p>\n<p style=\"font-size: 14px; line-height: 140%;\"><span style=\"color: #ba372a; font-size: 14px; line-height: 19.6px;\">{{ $date order.pickup_date }}</span></p>",
                                        "_override": {
                                            "desktop": {
                                                "hideDesktop": true
                                            }
                                        }
                                    }
                                }
                            ],
                            "values": {
                                "backgroundColor": "",
                                "padding": "0px",
                                "border": {},
                                "borderRadius": "0px",
                                "_meta": {
                                    "htmlID": "u_column_22",
                                    "htmlClassNames": "u_column"
                                },
                                "_override": {
                                    "mobile": {
                                        "border": {
                                            "borderTopWidth": "0px",
                                            "borderTopStyle": "solid",
                                            "borderTopColor": "#CCC",
                                            "borderLeftWidth": "4px",
                                            "borderLeftStyle": "solid",
                                            "borderLeftColor": "#ba372a",
                                            "borderRightWidth": "1px",
                                            "borderRightStyle": "solid",
                                            "borderRightColor": "#ecf0f1",
                                            "borderBottomWidth": "1px",
                                            "borderBottomStyle": "solid",
                                            "borderBottomColor": "#ecf0f1"
                                        }
                                    }
                                }
                            }
                        }
                    ],
                    "values": {
                        "displayCondition": null,
                        "columns": false,
                        "backgroundColor": "",
                        "columnsBackgroundColor": "",
                        "backgroundImage": {
                            "url": "",
                            "fullWidth": true,
                            "repeat": false,
                            "center": true,
                            "cover": false
                        },
                        "padding": "0px",
                        "anchor": "",
                        "hideDesktop": false,
                        "_meta": {
                            "htmlID": "u_row_18",
                            "htmlClassNames": "u_row"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true
                    }
                },
                {
                    "id": "DHPqV8Jcs2",
                    "cells": [
                        1,
                        1
                    ],
                    "columns": [
                        {
                            "id": "47rkRvtqi_",
                            "contents": [
                                {
                                    "id": "j-n-pumLsO",
                                    "type": "text",
                                    "values": {
                                        "containerPadding": "10px",
                                        "anchor": "",
                                        "textAlign": "left",
                                        "lineHeight": "140%",
                                        "linkStyle": {
                                            "inherit": true,
                                            "linkColor": "#0000ee",
                                            "linkHoverColor": "#0000ee",
                                            "linkUnderline": true,
                                            "linkHoverUnderline": true
                                        },
                                        "hideDesktop": false,
                                        "displayCondition": null,
                                        "_meta": {
                                            "htmlID": "u_content_text_6",
                                            "htmlClassNames": "u_content_text"
                                        },
                                        "selectable": true,
                                        "draggable": true,
                                        "duplicatable": true,
                                        "deletable": true,
                                        "hideable": true,
                                        "text": "<p style=\"font-size: 14px; line-height: 140%;\"><span style=\"color: #34495e; font-size: 14px; line-height: 19.6px;\">Address:</span></p>\n<p style=\"font-size: 14px; line-height: 140%;\"> </p>\n<p style=\"font-size: 14px; line-height: 140%;\">{{ order.shop_name }}</p>\n<p style=\"font-size: 14px; line-height: 140%;\">{{ order.fulfillment_location_address }}</p>\n<p style=\"font-size: 14px; line-height: 140%;\"> </p>\n<p style=\"font-size: 14px; line-height: 140%;\">{{ order.shop_phone_number }}</p>",
                                        "_override": {
                                            "mobile": {
                                                "hideMobile": true
                                            }
                                        }
                                    }
                                }
                            ],
                            "values": {
                                "backgroundColor": "",
                                "padding": "0px",
                                "border": {
                                    "borderTopWidth": "0px",
                                    "borderTopStyle": "solid",
                                    "borderTopColor": "#ecf0f1",
                                    "borderLeftWidth": "4px",
                                    "borderLeftStyle": "solid",
                                    "borderLeftColor": "#ba372a",
                                    "borderRightWidth": "0px",
                                    "borderRightStyle": "solid",
                                    "borderRightColor": "#ecf0f1",
                                    "borderBottomWidth": "1px",
                                    "borderBottomStyle": "solid",
                                    "borderBottomColor": "#ecf0f1"
                                },
                                "borderRadius": "0px",
                                "_meta": {
                                    "htmlID": "u_column_11",
                                    "htmlClassNames": "u_column"
                                }
                            }
                        },
                        {
                            "id": "pJDG3ej1Bo",
                            "contents": [
                                {
                                    "id": "2egNEKYLkh",
                                    "type": "text",
                                    "values": {
                                        "containerPadding": "10px",
                                        "anchor": "",
                                        "textAlign": "left",
                                        "lineHeight": "140%",
                                        "linkStyle": {
                                            "inherit": true,
                                            "linkColor": "#0000ee",
                                            "linkHoverColor": "#0000ee",
                                            "linkUnderline": true,
                                            "linkHoverUnderline": true
                                        },
                                        "hideDesktop": false,
                                        "displayCondition": null,
                                        "_meta": {
                                            "htmlID": "u_content_text_7",
                                            "htmlClassNames": "u_content_text"
                                        },
                                        "selectable": true,
                                        "draggable": true,
                                        "duplicatable": true,
                                        "deletable": true,
                                        "hideable": true,
                                        "text": "<p style=\"font-size: 14px; line-height: 140%;\">Time of collection:</p>\n<p style=\"font-size: 14px; line-height: 140%;\"> </p>\n<p style=\"font-size: 14px; line-height: 140%;\"><span style=\"color: #e03e2d; font-size: 14px; line-height: 19.6px;\">{{ $date order.pickup_date }}</span></p>",
                                        "_override": {
                                            "mobile": {
                                                "hideMobile": true
                                            }
                                        }
                                    }
                                }
                            ],
                            "values": {
                                "backgroundColor": "",
                                "padding": "0px",
                                "border": {
                                    "borderTopWidth": "0px",
                                    "borderTopStyle": "solid",
                                    "borderTopColor": "#ecf0f1",
                                    "borderLeftWidth": "0px",
                                    "borderLeftStyle": "solid",
                                    "borderLeftColor": "#ced4d9",
                                    "borderRightWidth": "1px",
                                    "borderRightStyle": "solid",
                                    "borderRightColor": "#ecf0f1",
                                    "borderBottomWidth": "1px",
                                    "borderBottomStyle": "solid",
                                    "borderBottomColor": "#ecf0f1"
                                },
                                "borderRadius": "0px",
                                "_meta": {
                                    "htmlID": "u_column_12",
                                    "htmlClassNames": "u_column"
                                }
                            }
                        }
                    ],
                    "values": {
                        "displayCondition": null,
                        "columns": false,
                        "backgroundColor": "",
                        "columnsBackgroundColor": "",
                        "backgroundImage": {
                            "url": "",
                            "fullWidth": true,
                            "repeat": false,
                            "center": true,
                            "cover": false
                        },
                        "padding": "0px",
                        "anchor": "",
                        "hideDesktop": false,
                        "_meta": {
                            "htmlID": "u_row_9",
                            "htmlClassNames": "u_row"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true
                    }
                },
                {
                    "id": "ExlItcvdk6",
                    "cells": [
                        1,
                        1
                    ],
                    "columns": [
                        {
                            "id": "LCuUE7p1Ya",
                            "contents": [
                                {
                                    "id": "RZXFsXzYFw",
                                    "type": "text",
                                    "values": {
                                        "containerPadding": "10px",
                                        "anchor": "",
                                        "textAlign": "left",
                                        "lineHeight": "140%",
                                        "linkStyle": {
                                            "inherit": true,
                                            "linkColor": "#0000ee",
                                            "linkHoverColor": "#0000ee",
                                            "linkUnderline": true,
                                            "linkHoverUnderline": true
                                        },
                                        "hideDesktop": false,
                                        "displayCondition": null,
                                        "_meta": {
                                            "htmlID": "u_content_text_8",
                                            "htmlClassNames": "u_content_text"
                                        },
                                        "selectable": true,
                                        "draggable": true,
                                        "duplicatable": true,
                                        "deletable": true,
                                        "hideable": true,
                                        "text": "<p style=\"font-size: 14px; line-height: 140%; text-align: right;\"><a rel=\"noopener\" href=\"https://saylcloud.com/terms\" target=\"_blank\" data-u-link-value=\"eyJuYW1lIjoid2ViIiwiYXR0cnMiOnsiaHJlZiI6Int7aHJlZn19IiwidGFyZ2V0Ijoie3t0YXJnZXR9fSJ9LCJ2YWx1ZXMiOnsiaHJlZiI6Imh0dHBzOi8vc2F5bGNsb3VkLmNvbS90ZXJtcyIsInRhcmdldCI6Il9ibGFuayJ9fQ==\">Terms &amp; conditions</a></p>"
                                    }
                                }
                            ],
                            "values": {
                                "backgroundColor": "",
                                "padding": "0px",
                                "border": {},
                                "borderRadius": "0px",
                                "_meta": {
                                    "htmlID": "u_column_14",
                                    "htmlClassNames": "u_column"
                                }
                            }
                        },
                        {
                            "id": "zmX6d3xTrA",
                            "contents": [
                                {
                                    "id": "mINP3Mr0Gs",
                                    "type": "text",
                                    "values": {
                                        "containerPadding": "10px",
                                        "anchor": "",
                                        "textAlign": "left",
                                        "lineHeight": "140%",
                                        "linkStyle": {
                                            "inherit": true,
                                            "linkColor": "#0000ee",
                                            "linkHoverColor": "#0000ee",
                                            "linkUnderline": true,
                                            "linkHoverUnderline": true
                                        },
                                        "hideDesktop": false,
                                        "displayCondition": null,
                                        "_meta": {
                                            "htmlID": "u_content_text_9",
                                            "htmlClassNames": "u_content_text"
                                        },
                                        "selectable": true,
                                        "draggable": true,
                                        "duplicatable": true,
                                        "deletable": true,
                                        "hideable": true,
                                        "text": "<p style=\"font-size: 14px; line-height: 140%;\"><a rel=\"noopener\" href=\"https://saylcloud.com/privacy\" target=\"_blank\" data-u-link-value=\"eyJuYW1lIjoid2ViIiwiYXR0cnMiOnsiaHJlZiI6Int7aHJlZn19IiwidGFyZ2V0Ijoie3t0YXJnZXR9fSJ9LCJ2YWx1ZXMiOnsiaHJlZiI6Imh0dHBzOi8vc2F5bGNsb3VkLmNvbS9wcml2YWN5IiwidGFyZ2V0IjoiX2JsYW5rIn19\">Privacy</a></p>"
                                    }
                                }
                            ],
                            "values": {
                                "backgroundColor": "",
                                "padding": "0px",
                                "border": {},
                                "borderRadius": "0px",
                                "_meta": {
                                    "htmlID": "u_column_15",
                                    "htmlClassNames": "u_column"
                                }
                            }
                        }
                    ],
                    "values": {
                        "displayCondition": null,
                        "columns": false,
                        "backgroundColor": "",
                        "columnsBackgroundColor": "",
                        "backgroundImage": {
                            "url": "",
                            "fullWidth": true,
                            "repeat": false,
                            "center": true,
                            "cover": false
                        },
                        "padding": "0px",
                        "anchor": "",
                        "hideDesktop": false,
                        "_meta": {
                            "htmlID": "u_row_11",
                            "htmlClassNames": "u_row"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true,
                        "_override": {
                            "mobile": {
                                "hideMobile": true
                            }
                        }
                    }
                },
                {
                    "id": "wm7Sfo-OXK",
                    "cells": [
                        1
                    ],
                    "columns": [
                        {
                            "id": "-8okg4Fv0o",
                            "contents": [
                                {
                                    "id": "cvR0MfgOyJ",
                                    "type": "text",
                                    "values": {
                                        "containerPadding": "10px",
                                        "anchor": "",
                                        "textAlign": "left",
                                        "lineHeight": "140%",
                                        "linkStyle": {
                                            "inherit": true,
                                            "linkColor": "#0000ee",
                                            "linkHoverColor": "#0000ee",
                                            "linkUnderline": true,
                                            "linkHoverUnderline": true
                                        },
                                        "hideDesktop": false,
                                        "displayCondition": null,
                                        "_meta": {
                                            "htmlID": "u_content_text_17",
                                            "htmlClassNames": "u_content_text"
                                        },
                                        "selectable": true,
                                        "draggable": true,
                                        "duplicatable": true,
                                        "deletable": true,
                                        "hideable": true,
                                        "text": "<p style=\"font-size: 14px; line-height: 140%; text-align: center;\"><a rel=\"noopener\" href=\"https://saylcloud.com/privacy\" target=\"_blank\" data-u-link-value=\"eyJuYW1lIjoid2ViIiwiYXR0cnMiOnsiaHJlZiI6Int7aHJlZn19IiwidGFyZ2V0Ijoie3t0YXJnZXR9fSJ9LCJ2YWx1ZXMiOnsiaHJlZiI6Imh0dHBzOi8vc2F5bGNsb3VkLmNvbS9wcml2YWN5IiwidGFyZ2V0IjoiX2JsYW5rIn19\">Terms &amp; conditions</a></p>",
                                        "_override": {
                                            "desktop": {
                                                "hideDesktop": true
                                            }
                                        }
                                    }
                                }
                            ],
                            "values": {
                                "backgroundColor": "",
                                "padding": "0px",
                                "border": {},
                                "borderRadius": "0px",
                                "_meta": {
                                    "htmlID": "u_column_25",
                                    "htmlClassNames": "u_column"
                                }
                            }
                        }
                    ],
                    "values": {
                        "displayCondition": null,
                        "columns": false,
                        "backgroundColor": "",
                        "columnsBackgroundColor": "",
                        "backgroundImage": {
                            "url": "",
                            "fullWidth": true,
                            "repeat": false,
                            "center": true,
                            "cover": false
                        },
                        "padding": "0px",
                        "anchor": "",
                        "hideDesktop": false,
                        "_meta": {
                            "htmlID": "u_row_21",
                            "htmlClassNames": "u_row"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true
                    }
                },
                {
                    "id": "TFuAzU3IOf",
                    "cells": [
                        1
                    ],
                    "columns": [
                        {
                            "id": "Atd5c7mUgR",
                            "contents": [
                                {
                                    "id": "w9PmYaYxyA",
                                    "type": "text",
                                    "values": {
                                        "containerPadding": "10px",
                                        "anchor": "",
                                        "textAlign": "left",
                                        "lineHeight": "140%",
                                        "linkStyle": {
                                            "inherit": true,
                                            "linkColor": "#0000ee",
                                            "linkHoverColor": "#0000ee",
                                            "linkUnderline": true,
                                            "linkHoverUnderline": true
                                        },
                                        "hideDesktop": false,
                                        "displayCondition": null,
                                        "_meta": {
                                            "htmlID": "u_content_text_16",
                                            "htmlClassNames": "u_content_text"
                                        },
                                        "selectable": true,
                                        "draggable": true,
                                        "duplicatable": true,
                                        "deletable": true,
                                        "hideable": true,
                                        "text": "<p style=\"font-size: 14px; line-height: 140%; text-align: center;\"><a rel=\"noopener\" href=\"https://saylcloud.com/privacy\" target=\"_blank\" data-u-link-value=\"eyJuYW1lIjoid2ViIiwiYXR0cnMiOnsiaHJlZiI6Int7aHJlZn19IiwidGFyZ2V0Ijoie3t0YXJnZXR9fSJ9LCJ2YWx1ZXMiOnsiaHJlZiI6Imh0dHBzOi8vc2F5bGNsb3VkLmNvbS9wcml2YWN5IiwidGFyZ2V0IjoiX2JsYW5rIn19\">Privacy</a></p>",
                                        "_override": {
                                            "desktop": {
                                                "hideDesktop": true
                                            }
                                        }
                                    }
                                }
                            ],
                            "values": {
                                "backgroundColor": "",
                                "padding": "0px",
                                "border": {},
                                "borderRadius": "0px",
                                "_meta": {
                                    "htmlID": "u_column_24",
                                    "htmlClassNames": "u_column"
                                }
                            }
                        }
                    ],
                    "values": {
                        "displayCondition": null,
                        "columns": false,
                        "backgroundColor": "",
                        "columnsBackgroundColor": "",
                        "backgroundImage": {
                            "url": "",
                            "fullWidth": true,
                            "repeat": false,
                            "center": true,
                            "cover": false
                        },
                        "padding": "0px",
                        "anchor": "",
                        "hideDesktop": false,
                        "_meta": {
                            "htmlID": "u_row_20",
                            "htmlClassNames": "u_row"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true
                    }
                },
                {
                    "id": "ddKPR85X97",
                    "cells": [
                        1
                    ],
                    "columns": [
                        {
                            "id": "BVoatavAS6",
                            "contents": [
                                {
                                    "id": "xiKoZhCbS3",
                                    "type": "text",
                                    "values": {
                                        "containerPadding": "10px",
                                        "anchor": "",
                                        "textAlign": "left",
                                        "lineHeight": "140%",
                                        "linkStyle": {
                                            "inherit": true,
                                            "linkColor": "#0000ee",
                                            "linkHoverColor": "#0000ee",
                                            "linkUnderline": true,
                                            "linkHoverUnderline": true
                                        },
                                        "hideDesktop": false,
                                        "displayCondition": null,
                                        "_meta": {
                                            "htmlID": "u_content_text_10",
                                            "htmlClassNames": "u_content_text"
                                        },
                                        "selectable": true,
                                        "draggable": true,
                                        "duplicatable": true,
                                        "deletable": true,
                                        "hideable": true,
                                        "text": "<p style=\"font-size: 14px; line-height: 140%; text-align: center;\">C 2022 <a rel=\"noopener\" href=\"https://saylcloud.com\" target=\"_blank\" data-u-link-value=\"eyJuYW1lIjoid2ViIiwiYXR0cnMiOnsiaHJlZiI6Int7aHJlZn19IiwidGFyZ2V0Ijoie3t0YXJnZXR9fSJ9LCJ2YWx1ZXMiOnsiaHJlZiI6Imh0dHBzOi8vc2F5bGNsb3VkLmNvbSIsInRhcmdldCI6Il9ibGFuayJ9fQ==\">Sayl</a></p>"
                                    }
                                }
                            ],
                            "values": {
                                "backgroundColor": "",
                                "padding": "0px",
                                "border": {},
                                "borderRadius": "0px",
                                "_meta": {
                                    "htmlID": "u_column_16",
                                    "htmlClassNames": "u_column"
                                }
                            }
                        }
                    ],
                    "values": {
                        "displayCondition": null,
                        "columns": false,
                        "backgroundColor": "",
                        "columnsBackgroundColor": "",
                        "backgroundImage": {
                            "url": "",
                            "fullWidth": true,
                            "repeat": false,
                            "center": true,
                            "cover": false
                        },
                        "padding": "0px",
                        "anchor": "",
                        "hideDesktop": false,
                        "_meta": {
                            "htmlID": "u_row_12",
                            "htmlClassNames": "u_row"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true
                    }
                }
            ],
            "values": {
                "popupPosition": "center",
                "popupWidth": "600px",
                "popupHeight": "auto",
                "borderRadius": "10px",
                "contentAlign": "center",
                "contentVerticalAlign": "center",
                "contentWidth": "500px",
                "fontFamily": {
                    "label": "Arial",
                    "value": "arial,helvetica,sans-serif"
                },
                "textColor": "#000000",
                "popupBackgroundColor": "#FFFFFF",
                "popupBackgroundImage": {
                    "url": "",
                    "fullWidth": true,
                    "repeat": false,
                    "center": true,
                    "cover": true
                },
                "popupOverlay_backgroundColor": "rgba(0, 0, 0, 0.1)",
                "popupCloseButton_position": "top-right",
                "popupCloseButton_backgroundColor": "#DDDDDD",
                "popupCloseButton_iconColor": "#000000",
                "popupCloseButton_borderRadius": "0px",
                "popupCloseButton_margin": "0px",
                "popupCloseButton_action": {
                    "name": "close_popup",
                    "attrs": {
                        "onClick": "document.querySelector('.u-popup-container').style.display = 'none';"
                    }
                },
                "backgroundColor": "#ffffff",
                "backgroundImage": {
                    "url": "",
                    "fullWidth": true,
                    "repeat": false,
                    "center": true,
                    "cover": false
                },
                "preheaderText": "",
                "linkStyle": {
                    "body": true,
                    "linkColor": "#0000ee",
                    "linkHoverColor": "#0000ee",
                    "linkUnderline": true,
                    "linkHoverUnderline": true
                },
                "_meta": {
                    "htmlID": "u_body",
                    "htmlClassNames": "u_body"
                }
            }
        },
        "schemaVersion": 10
      },

      mergeTags: {
        user: {
          name: 'User',
          mergeTags: {
            firstname: {
              name: 'Firstname',
              value: '{{ order.user_firstname }}'
            },
        
            lastname: {
              name: 'Lastname',
              value: '{{ order.user_lastname }}'
            },
          },
        },

        shop: {
          name: 'Shop',
          mergeTags: {
            address: {
              name: 'Address',
              value: '{{ order.fulfillment_location_address }}'
            },

            name: {
              name: 'Name',
              value: '{{ order.shop_name }}'
            },

            phoneNumber: {
              name: 'Phone',
              value: '{{ order.shop_phone_number }}'
            },
          },
        },

        order: {
          name: 'Order',
          mergeTags: {
            paymentMethod: {
              name: 'Payment method',
              value: '{{ order.payment_method }}'
            },

            pickupCode: {
              name: 'Reference',
              value: '{{ order.pickup_code }}'
            },

            pickupDate: {
              name: 'Date',
              value: '{{ $date order.pickup_date }}'
            },

            service: {
              name: 'Service',
              value: '{{ $t order.service "service" }}'
            },

            status: {
              name: 'Status',
              value: '{{ $t order.order_status "order_status" }}'
            },

            totalPrice: {
              name: 'Total price',
              value: '{{ $currency order.price_total }}'
            },

            currency: {
              name: 'Currency',
              value: '{{ order.currency.symbol }}'
            }
          }
        },

        orderItems: {
          name: 'Order items',
          rules: {
            repeat: {
              name: 'Repeat for each order item',
              before: '{{#each order.order_items}}',
              after: '{{/each}}',
            },
          },

          mergeTags: {
            productName: {
              name: 'Name',
              value: '{{ title }}'
            },

            productOptions: {
              name: 'Options',
              value: '{{ options }}'
            },

            productPrice: {
              name: 'Price',
              value: '{{ $currency total_price }}'
            },

            quantity: {
              name: 'Quantity',
              value: '{{ quantity }}'
            },
          }
        },
      },
    }
  },

  computed: {
    attrs() {
      return {
        title: 'Email Editor Demo',
        hasSaveButton: true,
        secondary: {
          label: 'Load design',
          click: this.onLoadDesign
        }
      }
    },

    listeners() {
      return {
        save: this.onSave
      }
    }
  },

  methods: {
    editorLoaded() {
      // Pass your template JSON here
      // this.onLoadDesign()
    },

    exportHtml() {
      return new Promise((resolve) => {
        this.$refs.emailEditor.editor.exportHtml(
          (data) => resolve(data)
        )
      })
    },

    onLoadDesign() {
      this.design = this.basedesign
      this.$refs.emailEditor.editor.loadDesign(this.design)
      this.$refs.emailEditor.editor.setMergeTags(this.mergeTags)
    },

    onSave() {
      this.exportHtml()
        .then((html) => {
          this.html = html
          return this.saveDesign()
        })
        .then((design) => {
          this.design = design
        })
        .catch((e) => $console.error(e))
    },
    
    saveDesign() {
      return new Promise((resolve) => {
        this.$refs.emailEditor.editor.saveDesign(
          (design) => {
            resolve(design)
          }
        )
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.email-editor-demo {
  $root: '.email-editor-demo';

  #{$root}__form {
    height: 40rem;
  }
}
</style>
