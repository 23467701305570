<template>
  <data-timeline 
    :empty-description="$t('sayl-loyalty.referral_history_empty_description')"
    :empty-title="$t('sayl-loyalty.referral_history_empty_title')"
    :loading="iLoading"
    :rows="rows"
    :pagination="pagination"
    @page="onPage">
  </data-timeline>
</template>

<script>
import { mapState } from 'vuex'
import Mustache from 'mustache'

import { 
  MixinImpersonation,
  MixinTable
} from '@sayl/admin-common'

export default {
  name: 'LoyaltyHistory',

  mixins: [
    MixinImpersonation,
    MixinTable,
  ],

  props: {
    args: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      iLoading: true,
    }
  }, 

  computed: {
    ...mapState({
      state: state => state.loyalty.referral.history,
      programs: state => state.loyalty.referral.all,
    }),

    isEmpty() {
      return !this.loading && !this.iLoading && (this.$basil.isNil(this.collection) || this.$basil.get(this.collection, 'length', 0) === 0)
    },

    rows() {
      let ret = this.collection || []
      return ret.map((r) => {
        return {
          labels: this.getLabel(r),
          icon: {
            glyph: this.getIcon(r),
            hue: this.getIconHue(r),
          },
          meta: this.getMeta(r),
        }
      })
    }
  },

  methods: {
    onPage(page) {
      this.pagination.currentPage = page
      this.reset()
    },

    getIcon(item) {
      switch(this.$basil.get(item, 'type')) {
        case 'account':
          return 'user'

        case 'purchase': 
          return 'bag'

        default: 
          return 'star'
      }
    },

    getIconHue(item) {
      switch(this.$basil.get(item, 'type')) {
        case 'account':
          return '-yellow'

        case 'purchase': 
          return '-green'

        default: 
          return '-blue'
      }
    },

    getLabel(item) {
      let txt = this.$t(`sayl-loyalty.loyalty_history_event_${this.$basil.get(item, 'type')}_template`)

      let render = Mustache.parse(txt, ['{{', '}}'])

      let referrer = this.$basil.get(item, 'referrer.firstname') + ` ` + this.$basil.get(item, 'referrer.lastname')
      let referring = this.$basil.get(item, 'referring.firstname') + ` ` + this.$basil.get(item, 'referring.lastname')
      
      let type = this.$basil.get(item, 'generated_entity.type')
      if(type) {
        type = this.$t('sayl-loyalty.referral_generated_type_' + type)
      }
      
      let values = {
        referrer,
        referring,
        type
      }

      return render.map(([type, name, start, stop]) => {
        let r = {}

        // Dynamic value
        if (type === 'name'){
          r.bind = {
            class: 'bold'
          }
          r.type = 'span'
          r.value = this.$basil.get(values, name, '')
        } else{
          r.bind = {}
          r.type = 'span'
          r.value = txt.substring(start, stop)
        }

        // The value is an array
        if (this.$basil.isArray(r.value)){
          r = r.value.map(e => {
            let i = Object.assign({}, r)
            i.value = e

            return i
          })
        }
        return r
      })
    },

    getMeta(item) {
      let date = this.$basil.get(item, 'created')

      let ret = [
        {
          label: this.$basil.i18n.datetime(new Date(date)),
          icon: 'time'
        },

        !this.$basil.isNil(this.$basil.get(item, 'order', null)) ? 
          {
            label: `#${this.$basil.get(item, 'order.pickup_code')}`,
            icon: 'bag',
            href: { name: 'sayl-sales_entry', params: { id: this.$basil.get(item, 'order.id') }}
          } : null,

        !this.$basil.isNil(this.$basil.get(item, 'order.shop_name', null)) ? 
          {
            label: this.$basil.get(item, 'order.shop_name', null),
            icon: 'building-store',
          } : null,
      ];

      return ret.filter(r => !this.$basil.isNil(r))
    },

    getPoints(item) {
      return null
    },

    getPointsHue(item) {
      let ret = this.$pepper.Hue.BLUE
      
      if(this.$basil.get(item, 'amount') < 0) {
        ret = this.$pepper.Hue.RED
      }
      
      return `-${ret}`
    },

    reset() {
      this.iLoading = true
      this.pagination.pageSize = 10
      let args = Object.assign(this.args, { limit: this.pagination.pageSize, page: this.pagination.currentPage })
      
      return new Promise((resolve, reject) => {
        window.$dl.referral.getHistory(args)
          .then((r) => resolve(r))
          .catch((e) => reject(e))
          .finally(() => this.iLoading = false)
      })
    }, 
  },
}
</script>
