<template>
  <layout-level1 
    class="view-tiers"
    v-bind="attrs">
    <template #filters>
      <ui-filters 
        :has-reset="true"
        :filters="filters || []"
        :actions="actions || []"
        @reset="onRefresh"
      />
    </template>

    <div class="layout-level1__container container flow">
      <data-table
        :actions="actionsTable"
        :columns="columns"
        :loading="loading"
        :pagination="pagination"
        ref="table"
        :rows="rows"
        :select="select"
        @page="page"
        @sort="onSort"
        v-model="tSelection"
        v-if="!isEmpty"
      />

      <!-- Empty -->
      <data-empty
        :description="$t('sayl.no_tier_description')"
        :primary-action="createLabel"  
        :title="$t('sayl.no_tier_title')"
        @click="createAction"
        v-if="isEmpty"
      />
    </div>

    <router-view />
  </layout-level1>
</template>

<script>
import { mapState } from 'vuex'
import { 
  MixinACL,
  MixinError,
  MixinImpersonation, 
  MixinTable
} from '@sayl/admin-common'

export default {
  name: 'ViewTiers',

  mixins: [ 
    MixinTable,
    MixinACL,
    MixinError,
    MixinImpersonation, 
  ],

  computed: {
    ...mapState({
      state: state => state.tier.tier,
      loyalty: state => state.tier.tier.loyalty.edition
    }),

    attrs() {
      return {
        breadcrumb: this.breadcrumb,
        title: this.$t('sayl.tiers_title'),
        description: this.$t('sayl.tiers_description'),
        icon: 'crown'
      }
    },

    actions() {
      let ret = []

      if(this.canCreate) {
        ret.push({
          events: {
            click: this.onCreate,
          },
          label: this.$t('sayl.add'),
          icon: 'plus',
        })
      }
      
      if(this.canDelete){
        ret.push({
          events: {
            click: () => this.onRemove(),
          },
          label: this.$t('sayl.delete'),
          disabled: this.$basil.get(this.tSelection, 'length', 0) === 0
        })
      }

      return ret
    },

    actionsTable() {
      let ret = []

      if (this.canDelete){
        ret.push({
          action: this.onRemove,
          label: this.$t('sayl.delete'),
          mode: this.$pepper.Action.SINGLE,
        })
      }

      return ret
    },

    breadcrumb() {
      return [
        { label: this.$t('sayl.tiers_title'), icon: 'crown' },
      ]
    },

    columns() {
      return [
        {
          fqn: 'name',
          label: this.$t('sayl.tier_col_name')
        },
        {
          fqn: 'type',
          label: this.$t('sayl.tier_col_type')
        },
        {
          fqn: 'related_entity',
          label: this.$t('sayl.tier_col_related_entity')
        },
        {
          fqn: 'status',
          label: this.$t('sayl.tier_col_status')
        },
        {
          fqn: 'modified',
          label: this.$t('sayl.tier_col_modified'),
          sortable: true, 
          sort: this.sort.field === 'modified' ? this.sort._direction : 0,
          type: this.$pepper.DataType.DATETIME,
        }
      ]
    },

    createAction() {
      return this.canCreate ? 
              this.onCreate : () => {} 
    },

    createLabel() {
      return this.canCreate ? 
              this.$t('sayl.create_tier') : ''
    },

    filters() {
      return []
    },

    isEmpty() {
      return !this.loading && (this.$basil.isNil(this.collection) || this.$basil.isEmpty(this.collection))
    },

    rows() {
      let ret = this.collection || []

      return ret.map((elem) => {
        let date = this.$basil.get(elem, 'modified', null)
        let related = this.$basil.get(elem, 'relatedEntity')

        return {
          href: {
            name: 'sayl-tier',
            params: { tier: elem.id },
          },
          fqn: elem.id,
          data: [
            { content: this.$basil.get(elem, 'name') },
            { content: this.$t(`sayl.tier_type_${this.$basil.get(elem, 'type')}`) },
            { content: related ? this.$t(`sayl.tier_related_entity_${related}`) : null },
            { 
              component: 'DataTableCellActive', 
              content: this.$basil.get(elem, 'active', false) 
            },
            { content: date ? date : null },
          ]
        }
      })
    },

    select() {
      return this.actions.length > 1 ? this.$pepper.Selection.PAGE: null
    },
  },

  methods: {
    onCreate() {
      this.$router.push({ name: 'sayl-tier', params: { tier: 'create' } }).catch(() => {})
    },

    remove(payload) {
      return window.$dl.tier.remove(payload)
    },

    reset() {
      window.$dl.tier.getLoyalty({})
        .catch((e) => {})
      
      this.sort.field = this.$basil.isNil(this.sort.field) ? 'modified' : this.sort.field

      let args = { 
        page: this.pagination.currentPage, 
        limit: this.pagination.pageSize, 
        sortOrder: this.sort.direction,
        sortField: this.sort.field
      }

      return window.$dl.tier.collection({ args })
    },
  },
}
</script>