<template>
  <!-- Container -->
  <section class="view-loyalty-overview flow">
    <!-- Header -->
    <header class="layout-level1__header flow">
      <div>
        <h3 class="layout-level1__title">{{ $t('sayl-ecommerce.loyalty_title') }}</h3> 
        <p class="layout-level1__description">{{ $t('sayl-ecommerce.loyalty_description') }}</p>
      </div>
    </header>

    <!-- Loading -->
    <div 
      class="view-loyalty__loader"
      v-if="loading">
      <ui-loader />
    </div>

    <!-- Body -->
    <div 
      class="flow" 
      v-if="!loading && hasLoyaltyProgram && !$basil.isNil(range)">
      <!-- KPIS -->
      <loyalty-kpis
        class="view-loyalty-overview__kpis" 
        :range="{ from: from, to: to }" 
      /> 

      <!-- Chart -->
      <loyalty-chart 
        class="view-loyalty-overview__graph"
        :loading="loading"
        :range="{ from: from, to: to }" 
      />

      <!-- History -->
      <ui-card 
        :appearance="$pepper.Appearance.SUBTLE"
        article
        no-padding>
        <loyalty-history :args="args" /> 
      </ui-card>
    </div>

    <router-view />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { 
  MixinACL,
  MixinCalendar
} from '@sayl/admin-common'

import LoyaltyChart from './chart'
import LoyaltyHistory from './history'
import LoyaltyKpis from './kpis'

export default {
  name: 'ViewLoyaltyOverview',

  components: {
    LoyaltyChart,
    LoyaltyHistory,
    LoyaltyKpis,
  },

  mixins: [ 
    MixinACL,
    MixinCalendar 
  ],

  props: {
    readOnly: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return { 
      loading: true,
      from: null,
      to: null,
      key: 1,

      account: null,
      debounce: null,
      events: [],
    }
  },

  computed: {
    ...mapState({
      children: state => state['host'].children || [],
      edition: state => state.loyalty.loyalty.edition,
      module: state => state.ginger.module,
      state: state => state.loyalty.loyalty,
    }),

    actions() {
      return [
        this.hasLoyaltyProgram ? {
          label: !this.readOnly ? 
            this.$t('sayl-ecommerce.loyalty_edit_config') : 
            this.$t('sayl-ecommerce.loyalty_view_config'),
          events: {
            click: () => this.$bus.$emit('edit-loyalty')
          },
        } : null
      ].filter(s => !this.$basil.isNil(s))
    },

    args() {
      return {
        id: this.$basil.get(this.collection, '[0].id'), 
        from: !this.$basil.isNil(this.from) ? this.from : this.$basil.get(this.defaultRange, 'from'),
        to: !this.$basil.isNil(this.to) ? this.to : this.$basil.get(this.defaultRange, 'to'),
        events: this.events.length > 0 ? this.events.map(e => e.value).join(',') : null,
        customerId: !this.$basil.isNil(this.account) ? this.account.value : null,
      };
    },

    collection() {
      return this.key && (this.state.collection || [])
    },

    defaultRange() {
      return window.$dl.ranges.THIS_MONTH.value
    },

    maxDate() {
      return new Date(window.$dl.ranges.THIS_YEAR.value.to)
    },

    isReadOnly(){
      return !this.canEdit || !this.isMaster || this.isImpersonating
    },

    eventsList() {
      return [
        { 
          label: this.$t('sayl-ecommerce.loyalty_event_subscribed_reward_label'), 
          value: 'subscribed_reward',
        },

        { 
          label: this.$t('sayl-ecommerce.loyalty_event_paid_order_label'),
          value: 'paid_order',
        },

        { 
          label: this.$t('sayl-ecommerce.loyalty_event_in_store_reward_label'), 
          value: 'in_store_reward',
        },

        { 
          label: this.$t('sayl-ecommerce.loyalty_event_redeemed_credits_label'), 
          value: 'redeemed_credits',
        },

        { 
          label: this.$t('sayl-ecommerce.loyalty_event_breakage_label'), 
          value: 'breakage',
        },
      ]
    },

    filters() {
      let ret = this.hasLoyaltyProgram ? [
        {
          type: Date,
          values: this.date,
          ranges: this.ranges,
          label: this.range,
          minDate: this.minDate,
          maxDate: this.maxDate,
          events: {
            input: (date) => {
              this.tmpDate = date
            },

            apply: () => {
              this.onDateApply()
              this.onFilters()
            }
          }
        },

        {
          type: 'forms-autocomplete',
          multiple: true,
          options: this.eventsList,
          values: this.events,
          label: `Events`,
          events: {
            change: (values) => {
              this.events = values
              this.onFilters()
            },

            close: (value) => {
              let index = this.events.indexOf(value);
              this.events.splice(index, 1);
              this.onFilters()
            }
          }
        },
        
        {
          type: 'forms-autocomplete',
          options: this.children.map((c) => {
            return {
              label: this.$basil.get(c, 'companyname'),
              value: this.$basil.get(c, 'id'),
            }
          }),
          values: !this.$basil.isNil(this.account) ? [this.account] : [],
          label: `Account`,
          events: {
            change: (value) => {
              this.account = value;
              this.onFilters();
            },

            close: () => {
              this.account = null;
              this.onFilters();
            }
          }
        }
      ] : []

      return this.key && ret
    },  

    figure(){
      return '/statics/images/figures/loyalty-empty.svg'
    },

    hasLoyaltyProgram() {
      return !this.$basil.isNil(this.collection) && !this.$basil.isEmpty(this.collection) 
    },

    ranges() {
      return [
        window.$dl.ranges.TODAY,
        window.$dl.ranges.YESTERDAY,
        window.$dl.ranges.THIS_WEEK,
        window.$dl.ranges.LAST_WEEK,
        window.$dl.ranges.THIS_MONTH,
        window.$dl.ranges.LAST_MONTH,
        window.$dl.ranges.LAST_YEAR,
      ].map(r => {
        let ret = {
          label: this.$t(r.label),
          start: new Date(r.value.from),
          end: new Date(r.value.to)
        }
        
        let start = this.$basil.get(this.date, 'start', null)
        let end = this.$basil.get(this.date, 'end', null)

        start = !this.$basil.isNil(start) ? start.getTime() : null
        end = !this.$basil.isNil(end) ? end.getTime() : null

        ret.active = start === ret.start && end === ret.end
        return ret
      })
    }
  },

  methods: {
    onFilters() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => this.reset(), 300);
    },

    reset() {
      this.loading = true
      
      if(this.$basil.isNil(this.range)) {
        this.range = this.defaultRange
      }

      this.$emit('init', { actions: this.hasLoyaltyProgram ? this.actions : [], filters: this.hasLoyaltyProgram ? this.filters : [], reset: this.reset })

      let trendArgs = Object.assign({}, this.args)
      let range = this.$moment(this.args.from).diff(this.$moment(this.args.to), 'days') - 1

      trendArgs.from = this.$moment(this.args.from).subtract(((-1) * range), 'days').valueOf()
      trendArgs.to = this.$moment(this.args.to).subtract(((-1) * range), 'days').valueOf()

      window.$dl.loyalty.getKpis(this.args)
        .then(() => {
          trendArgs.kpis = this.state.kpis.value
          return window.$dl.loyalty.getTrendedKpis(trendArgs)
        })
        .catch((e) => $console.error(e))
        .finally(() => this.loading = false)
    },
  },

  mounted() {
    this.reset()
  }
}
</script>
