<template>
  <div class="view-loyalty-referral__row">
    <div class="view-loyalty-referral__tag -blue">{{ $t('sayl-loyalty.referral_tag_when') }}</div>

    <data-empty 
      class="view-loyalty-referral__empty"
      :class="{ '-is-errored': hasErrors }"
      :title="$t('sayl-loyalty.referral_no_rules_selected_title')"
      :description="$t('sayl-loyalty.referral_no_rules_selected_description')"
      v-if="!loading && value.length === 0"
    />

    <!-- Reward -->
    <ui-preview 
      :actions="actions"
      entity="event"
      :key="`email_template_${i}`"
      icon="target"
      :legend="$t('sayl-loyalty.referral_rule_legend')"
      :read-only="readOnly"
      :template="`{{type}} {{operator}} {{value}}`"
      :values="v"
      @click="onClick(i)"
      @select="onSelect(i)"
      v-for="(v, i) in value"
    />
      <!-- :template="$t('sayl-loyalty.referral_rule_template')" -->

    <forms-label 
      class="row view-loyalty-referral__error"
      :intent="$pepper.Intent.DANGER"
      v-if="hasErrors"
    >{{ errors.join(', ') }}</forms-label>

    <actions-button
      icon-pre="plus"
      :size="$pepper.Size.S"
      @click="onAdd"
      v-if="!loading && !readOnly"
    >{{ $t('sayl-loyalty.referral_rule_add') }}</actions-button>

    <modal-rule 
      :read-only="readOnly"
      :value="rule"
      :is-new="isNew"
      :visible="showModal"
      @close="onClose"
      @confirm="onConfirm"
      v-if="!readOnly && showModal"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import ModalRule from './rule/index.vue'
import UiPreview from './preview.vue'

export default {
  name: 'LoyaltyItemReward',

  components: {
    ModalRule,
    UiPreview
  },

  props: {
    edition: {
      type: Object,
      required: true,
    },

    errors: {
      type: Array,
    },

    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return { 
      loading: false,

      rule: null,
      isNew: false,
      index: null,
      showModal: false,

      selectedIndex: null,
    }
  },

  computed: {
    ...mapState({
      referral: state => state.loyalty.referral.edition,
    }),

    actions() {
      let ret = []
      if(!this.readOnly) {
        ret.push({
          label: this.$t('sayl.delete'),
          event: { click: () => this.onRemove() }
        })
      }
      return ret
    },

    hasErrors() {
      return this.errors != null && this.errors.length > 0
    },

    value() {
      return this.edition.rules && this.edition.rules.length > 0 ? this.edition.rules.map((r) => {
        return {
          type: this.$t('sayl-loyalty.referral_rule_type_' + r.type + '_label'),
          operator: this.$t('sayl.operator_' + r.operator),
          value: r.value,
        }
      }) : []
    },
  },

  methods: {
    onAdd() {
      this.rule = this.referral.createRule()
      this.isNew = true
      this.index = -1
      this.showModal = true
    },

    onClick(index) {
      this.rule = this.edition.rules[index]
      this.isNew = false
      this.index = index
      this.showModal = true
    },

    onClose() {
      this.showModal = false
      this.rule = null
      this.isNew = false
      this.index = null
    },

    onConfirm(rule) {
      if(this.index > -1) {
        this.edition.rules[this.index].type = rule.type
        this.edition.rules[this.index].operator = rule.operator
        this.edition.rules[this.index].value = rule.value
      } else {
        this.edition.rules.push(rule)
      }
      this.$emit('change')
      this.onClose()
    },

    onRemove() {
      this.$confirm({
        primaryAction: this.$t('sayl.confirm'), 
        secondaryAction: this.$t('sayl.cancel'), 
        title: this.$t('sayl-loyalty.referral_remove_rule_title'),
        description: this.$t('sayl-loyalty.referral_remove_rule_title'),
        onPrimary: () => this.edition.rules.splice(this.index, 1)
      })
    },

    onSelect(i) {
      this.selectedIndex = i
    },

    reset() {
      
    }
  },

  mounted() {
    this.reset()
  }
}
</script>
