import { basil } from '@spices/basil'

export default class ElementsController {
  constructor(elements = {}) {
    this._elements = elements == null ? {} : elements
  }

  isHidden(element, defaultValue = false) {
    if(element == null) {
      return false
    }

    return basil.get(this._elements, `${element}.hidden`, defaultValue)
  }
}
