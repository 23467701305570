<template>
  <!-- Rules -->
  <ui-details 
    article
    class="view-loyalty-config__details flow" 
    open>
    <template #summary>{{ $t('sayl-ecommerce.loyalty_config_rules_title') }}</template>
    <!-- <summary class="ui-card__summary layout-modal__summary">{{ $t('sayl-ecommerce.loyalty_config_rules_title') }}</summary> -->
    
    <article class="ui-details__article view-loyalty-config__fields view-loyalty-modal__fields flow -first">
      <!-- Rules::Reward Per Order -->
      <forms-input
        @input="onRemoveError('rules.money_to_points')"
        :errors="getErrors('rules.money_to_points')"
        :hint="$t('sayl-ecommerce.loyalty_config_money_to_points_hint', { amount: 10, points: moneyMatches, symbol })"
        :placeholder="$t('sayl.placeholder')"
        :read-only="readOnly"
        min="0"
        step="1"
        type="number"
        v-model="moneyToPoints">
        <template>{{ $t('sayl-ecommerce.loyalty_config_money_to_points_label') }}</template>
        <template v-slot:suffix><span class="view-loyalty-config__equation view-loyalty-modal__equation">{{ currencyCode }} = 1{{ symbol }}</span></template>
      </forms-input>

      <!-- Rules::Reward account? -->
      <forms-checkbox
        :appearance="$pepper.Appearance.TOGGLE"
        :hint="$t('sayl-ecommerce.loyalty_config_has_reward_creation_account_hint')"
        :read-only="readOnly"
        :size="$pepper.Size.S"
        v-model="hasWelcomeReward"
        @change="onWelcomeRewardChange"
      >{{ $t('sayl-ecommerce.loyalty_config_has_reward_creation_account_label') }}</forms-checkbox>

      <!-- Rules::Reward account value -->
      <forms-input
        :errors="getErrors('rules.welcome_reward')"
        :placeholder="$t('sayl.placeholder')"
        :read-only="readOnly"
        :required="hasWelcomeReward"
        min="0"
        step="1"
        type="number"
        v-if="hasWelcomeReward"
        v-model="edition.rules.welcomeReward"
        @input="onRemoveError('rules.welcome_reward')">
        <template>{{ $t('sayl-ecommerce.loyalty_config_welcome_reward_amount_label') }}</template>
        <template v-slot:suffix><span class="view-loyalty-config__equation view-loyalty-modal__equation">{{ symbol }}</span></template>
      </forms-input>
    </article>

    <article class="ui-details__article view-loyalty-config__fields view-loyalty-modal__fields flow">
      <!-- Rules::Reward birthday? -->
      <forms-checkbox
        :appearance="$pepper.Appearance.TOGGLE"
        :read-only="readOnly"
        :size="$pepper.Size.S"
        v-model="hasBirthdayReward"
        @change="onHasBirthdayRewardChange"
      >{{ $t('sayl-ecommerce.loyalty_config_has_birthday_reward_label') }}</forms-checkbox>

      <!-- Rules::Reward birthday value -->
      <forms-input
        :errors="getErrors('rules.birthday_reward')"
        :placeholder="$t('sayl.placeholder')"
        :read-only="readOnly"
        :required="hasBirthdayReward"
        min="0"
        step="1"
        type="number"
        v-if="hasBirthdayReward"
        v-model.number="edition.rules.birthdayReward"
        @input="onRemoveError('rules.birthday_reward')">
        <template>{{ $t('sayl-ecommerce.loyalty_config_birthday_reward_label') }}</template>
        <template v-slot:suffix><span class="view-loyalty-config__equation view-loyalty-modal__equation">{{ symbol }}</span></template>
      </forms-input>

      <!-- Rules::Reward birthday delay value -->
      <forms-input
        :errors="getErrors('rules.birthday_delay')"
        :hint="$t('sayl-ecommerce.loyalty_config_birthday_delay_hint')"
        :placeholder="$t('sayl.placeholder')"
        :read-only="readOnly"
        :required="hasBirthdayReward"
        min="0"
        step="1"
        type="number"
        v-if="hasBirthdayReward"
        v-model="edition.rules.birthdayDelay"
        @input="onRemoveError('rules.birthday_delay')">
        <template>{{ $t('sayl-ecommerce.loyalty_config_birthday_delay_label') }}</template>
        <template v-slot:suffix><span class="view-loyalty-config__equation view-loyalty-modal__equation">{{ $t('sayl.days') }}</span></template>
      </forms-input>
    </article>

    <!-- Rules::Reward Eatin?
    <article class="ui-details__article view-loyalty-config__fields view-loyalty-modal__fields flow" v-if="false">
       <forms-checkbox
        :appearance="$pepper.Appearance.TOGGLE"
        :hint="$t('sayl-ecommerce.loyalty_config_has_reward_eatin_account_hint')"
        :read-only="readOnly"
        :size="$pepper.Size.S"
        v-model="edition.rewardEatin"
        @change="onRewardEatinChange"
        v-if="showRewardEatin"
      >{{ $t('sayl-ecommerce.loyalty_config_has_reward_eatin_account_label') }}</forms-checkbox>
    </article> -->
  </ui-details>
</template>

<script>
import { mapState } from 'vuex'

import { MixinError } from '@sayl/admin-common'

export default { 
  name: 'LoyaltyProgramConfigRewards',

  mixins: [ MixinError, ],

  props: {
    errors: {
      type: Object,
    },

    readOnly: {
      required: false,
      type: Boolean
    },
  },

  data() {
    return {
      hasWelcomeReward: false,
      hasBirthdayReward: false,
    }
  },

  computed: {
    ...mapState({
      edition: state => state.loyalty.loyalty.edition,
      origin: state => state.loyalty.loyalty.origin,
    }),
    
    currencyCode() {
      let symbol = this.$basil.i18n.currency(0, { currency: this.$basil.i18n.Currencies[this.$basil.get(this.$i18n.getNumberFormat(this.$i18n.locale), 'currency.currency', 'EUR')], locale: 'en-US' })
      symbol = symbol.split('0.00')[0].trim()
      symbol = symbol.split('0')[0].trim()
      return symbol
    },

    moneyToPoints: {
      get() {
        let ret = this.$basil.get(this.edition, 'rules.moneyToPoints', 0)

        if(ret > 0) {
          ret = 1 / ret
        }
        
        return parseFloat(ret) || 0
      },
      set(value) {
        this.edition.rules.moneyToPoints = value !== 0 ? 1/value : 0
      }
    },

    moneyMatches() {
      let money = this.moneyToPoints
      let base = 10;
      return money > 0 ? (base / money).toFixed(2) : 0
    },

    // showRewardEatin() {
    //   return !window.$dl.fields.isPropHidden('loyalty_program', 'reward_eatin')
    // },

    symbol() {
      let symbol = this.$basil.get(this.edition, 'points.symbol', null);
      return !this.$basil.isNil(symbol) && !this.$basil.isEmpty(symbol) ?
        symbol : 'P'
    }
  },

  methods: {
    onHasBirthdayRewardChange() {
      this.edition.rules.birthdayReward = this.hasBirthdayReward ? this.origin.rules.birthdayReward : 0
    },

    onWelcomeRewardChange() {
      this.edition.rules.welcomeReward = this.hasWelcomeReward ? this.origin.rules.welcomeReward : 0
    },

    // onRewardEatinChange() {
    //   if(this.showRewardEatin) {
    //     this.edition.allowDigitalCards = this.edition.rewardEatin ? this.origin.allowDigitalCards || false : false
    //     this.edition.allowPhysicalCards = this.edition.rewardEatin ? this.origin.allowPhysicalCards || false : false
    //   }
    // },
  },

  mounted() {
    if(!this.$basil.isNil(this.$route.params.program)) {
      let welcomeReward = this.$basil.get(this.edition, 'rules.welcomeReward', 0)
      this.hasWelcomeReward = !this.$basil.isNil(welcomeReward) && welcomeReward > 0

      let birthdayReward = this.$basil.get(this.edition, 'rules.birthdayReward', 0)
      this.hasBirthdayReward = !this.$basil.isNil(birthdayReward) && birthdayReward > 0
    } else {
      this.hasWelcomeReward = false
      this.hasBirthdayReward = false
    }
  }
}
</script>
