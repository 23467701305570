import Handlebars from 'handlebars'
import {basil} from '@spices/basil'

export default function ({ capabilities }) {
  $console.group('utils-handlebars')

  let Vue = capabilities.vue

  Handlebars.registerHelper('$date', function (aString) {
    let ret = null
    if(aString != null) {
      ret = Number.isNaN(parseInt(aString, 10)) ? new Date(parseInt(aString, 10) * 1000) : new Date(aString)
    }
    return !basil.isNil(ret) ? basil.i18n.datetime(ret) : null
  })

  Handlebars.registerHelper('$currency', function (aString) {
    return basil.i18n.currency(parseFloat(aString, 10))
  })

  Handlebars.registerHelper('equals', function(arg1, arg2, options) {
    return (arg1 == arg2) ? options.fn(this) : options.inverse(this)
  })

  Handlebars.registerHelper('gt', function(arg1, arg2, options) {
    return (arg1 > arg2) ? options.fn(this) : options.inverse(this)
  })

  Handlebars.registerHelper('gte', function(arg1, arg2, options) {
    return (arg1 >= arg2) ? options.fn(this) : options.inverse(this)
  })

  Handlebars.registerHelper('lt', function(arg1, arg2, options) {
    return (arg1 < arg2) ? options.fn(this) : options.inverse(this)
  })

  Handlebars.registerHelper('lte', function(arg1, arg2, options) {
    return (arg1 <= arg2) ? options.fn(this) : options.inverse(this)
  })

  Vue.prototype.$handlebars = Handlebars

  $console.groupEnd('utils-handlebards')
  return Promise.resolve()
}
