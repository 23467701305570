<template>
  <div 
    class="ui-date-range-picker" 
    :key="key">
    <actions-button
      :class="getClasses(range)"
      v-for="(range, i) in rangesList"
      :key="$basil.uniqId(i)"
      :size="$pepper.Size.S"
      @click="onRangeClick(range)"
    >{{ $t(range.label) }}</actions-button>

    <v-date-picker 
      class="-calendar actions-button -s ui-date-range-picker__item"
      :class="getClasses(custom)"
      is-range
      @input="onCustomDate"
      v-if="hasCustomRange"
      v-model="dates">
      <template v-slot="{ togglePopover }">
        <actions-button
          :key="key"
          icon-pre="calendar"
          @click="togglePopover({ placement: 'auto-start' })"
          :size="$pepper.Size.S">
          <span v-if="isCustomRange">{{ getDate }}</span>
        </actions-button>
      </template>
    </v-date-picker>
  </div>
</template>

<script>
export default {
  name: 'UiDateRangePicker',

  props: {
    ranges: {
      type: Array,
      required: true
    },

    hasCustomRange: {
      type: Boolean,
      default: false,
    },

    defaultRange: {
      type: Object, 
      default: null,
    }
  },

  data() {
    return {
      dates: null,
      key: 1,
      range: null,
    }
  },

  computed: {
    custom() {
      return window.$dl.ranges.CUSTOM
    },

    isCustomRange() {
      return this.isRangeSelected(this.custom)
    },

    getDate() {
      let f = new Date(this.dates.start);
      let t = new Date(this.dates.end);

      let ret = [];
      ret.push(`${f.getDate()}/${f.getMonth()+1}`)
      ret.push(`${t.getDate()}/${t.getMonth()+1}`)

      return ret[0] == ret[1] ? ret[0] : ret.join(' - ');
    },

    rangesList() {
      let width = window.innerWidth;

      return this.key && this.ranges.filter((r, i) => {
        if(width >= 525) {
          return true
        }
        
        return i < 1 || i > this.ranges.length - 3
      })
    }
  }, 

  methods: {
    getClasses(range) {
      return {
        'ui-date-range-picker__item': true,
        '-selected': this.isRangeSelected(range)
      }
    },

    isRangeSelected(range) {
      return !this.$basil.isNil(this.range) && this.range.value === range.value
    },

    onRangeClick(range) {
      this.range = range
      this.dates = null
      this.$emit('change', range.value)
    },

    onCustomDate(value) {
      this.range = window.$dl.ranges.CUSTOM
      this.dates = {
        start: value.start,
        end: value.end
      }
      this.key++

      let from = new Date(this.dates.start);
      from.setHours(0, 0, 0);

      let to = new Date(this.dates.end);
      to.setHours(23, 59, 59)
      this.$emit('change', { from, to })
    }
  },

  mounted() {
    if(this.$basil.isNil(this.range)) {
      this.$basil.isNil(this.defaultRange) ? 
        this.onRangeClick(this.ranges[0]) :
        this.onRangeClick(this.defaultRange)
    } 

    window.addEventListener('resize', () => {this.key++})
  }
}
</script>
