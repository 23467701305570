<template>
  <!-- Content::Table -->
  <section class="view-loyalty-cards">
    <!-- Header -->
    <header class="view-loyalty__header flow">
      <div>
        <h3 class="view-loyalty__title">{{ $t('sayl-ecommerce.loyalty_cards_title') }}</h3> 
        <p class="view-loyalty__description">{{ $t('sayl-ecommerce.loyalty_cards_description') }}</p>
      </div>
    </header>

    <loyalty-cards-kpis 
      class="view-loyalty__kpis"
    />

    <data-table
      :actions="[]"
      :appearance="$pepper.Appearance.SUBTLE"
      class="view-loyalty-cards__table -subtle"
      :columns="columns"
      :loading="loading || exporting || iLoading"
      :pagination="pagination"
      ref="table"
      :rows="rows"
      :search="search.value"
      searchable
      @page="page"
      @search="onSearch"
      v-if="!isEmpty"
      v-model="tSelection">
      <template v-slot:empty>
        <!-- Empty -->
        <data-empty
          :description="$t('sayl-ecommerce.no_results_search_description')"
          :primary-action="$t('sayl-ecommerce.no_results_clear')"  
          :title="$t('sayl-ecommerce.no_results_search')"
          @click="onClear"
        />
      </template>
    </data-table> 

    <div 
      class="view-loyalty__empty" 
      v-if="isEmpty">
      <!-- Empty -->
      <data-empty
        :description="$t('sayl-ecommerce.no_loyalty_cards_description')"
        :primary-action="createLabel"
        :title="$t('sayl-ecommerce.no_loyalty_cards_title')"
        @click="createAction"
        v-if="isEmpty"
      />
    </div>

    <modal-create-cards 
      :visible="showModalCreate"
      @close="onCreateClose"
    />

    <modalExport 
      :filename="`loyalty_cards_${name}`"
      :visible="showExport"
      @close="() => showExport = false"
      @confirm="onExportConfirm"
    /> 
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { 
  MixinACL, 
  MixinImpersonation,
  MixinTable,
  MixinTableExport, 
} from '@sayl/admin-common'

import ModalCreateCards from './modals/create'
import LoyaltyCardsKpis from './kpis'

export default {
  name: 'LoyaltyCards',

  components: {
    LoyaltyCardsKpis,
    ModalCreateCards,
  },

  mixins: [ 
    MixinACL, 
    MixinImpersonation,
    MixinTable,
    MixinTableExport
  ],

  data(){
    return {
      showExport: false,
      showModalCreate: false,
      fields: 'token,user_firstname,user_lastname,user_email,activation_date,type',
      key: 1,
      iLoading: false,

      debounce: null,
      type: null,
      status: null,
    }
  },

  computed: {
    ...mapState({
      module: state => state.ginger.module,
      state: state => state.loyalty.loyalty.card,
    }),

    actions() {
      let ret = [];

      if (this.canCreate && this.isMaster && !this.isImpersonating) {
        ret.push(
          {
            events: {
              click: this.onCreate,
            },
            icon: 'plus',
            label: this.$t('sayl.create'),
            mode: this.$pepper.Action.GLOBAL,
          },
        )
      }

      if(this.canView && this.isMaster && !this.isImpersonating) {
        ret.push(
          { 
            events: {
              click: () => this.showExport = true
            },
            icon: 'download',
            disabled: this.exporting,
            label: this.$t('sayl.export'),
            mode: this.$pepper.Action.GLOBAL,
          },
        )
      }

      return ret
    },

    breadcrumb() {
      return [
        { label: this.$t('sayl.ecommerce'), icon: this.$basil.get(this.module, 'manifest.icon'), href: { name:'sayl-ecommerce'} },
        { label: this.title }
      ]
    },

    columns() {
      return  [
        {
          fqn: 'type', 
          label: this.$t('sayl-ecommerce.type'),
        },
        {
          fqn: 'token', 
          label: this.$t('sayl-ecommerce.token'),
        },
        {
          fqn: 'customer', 
          label: this.$t('sayl-ecommerce.customer'),
        },
        {
          fqn: 'status', 
          label: this.$t('sayl-ecommerce.status'),
        },
        {
          fqn: 'activation_date', 
          label: this.$t('sayl-ecommerce.activation_date'),
          type: this.$pepper.DataType.DATETIME,
        },
      ];
    },

    createAction() {
      return this.canCreate && this.isCreationAllowed ? 
              this.onCreate : () => {} 
    },

    createLabel() {
      return this.canCreate && this.isCreationAllowed ? 
              this.$t('sayl-ecommerce.no_loyalty_cards_create') : ''
    },

    filters() {
      let ret = [
        {
          type: 'forms-autocomplete',
          options: this.types,
          values: !this.$basil.isNil(this.type) ? [this.type] : [],
          label: this.$t('sayl-ecommerce.type'),
          events: {
            change: (value) => { 
              this.type = value;
              this.onFilters()
            },

            close: () => {
              this.type = null;
              this.onFilters()
            }
          }
        },

        {
          type: 'forms-autocomplete',
          options: this.statuses,
          values: !this.$basil.isNil(this.status) ? [this.status] : [],
          label: this.$t('sayl-ecommerce.status'),
          events: {
            change: (value) => { 
              this.status = value;
              this.onFilters()
            },

            close: () => {
              this.status = null;
              this.onFilters()
            }
          }
        },
      ]

      return ret
    },  

    isEmpty() {
      return !this.loading && 
        this.collection.length === 0 && 
        (this.$basil.isNil(this.search.value) || this.$basil.isEmpty(this.search.value))
    },

    defaultRange() {
      return window.$dl.ranges.LAST_30_DAYS
    },

    rows() {
      let ret = this.collection || [];
      return ret.map((elem, i) => {
        let d = this.$basil.get(elem, 'activationDate', null)
        if(d != null) {
          d = d.split(' ').join('T')
        }
        
        let name = [
          this.$basil.get(elem, 'userFirstname', null),
          this.$basil.get(elem, 'userLastname', null)
        ].filter(n => !this.$basil.isNil(n))

        let row = {
          fqn: elem.id,
          label: elem.id,
          data: [ 
            { 
              component: 'data-tag',
              content: this.$t(`sayl-ecommerce.loyalty_card_type_${elem.type}`),
              value: this.$t(`sayl-ecommerce.loyalty_card_type_${elem.type}`),
              fqn: 'loyalty_card_type_' + i,
              size: this.$pepper.Size.S
            },
            { 
              content: elem.token,
              fqn: 'loyalty_card_token_' + i
            },
            { 
              content: name.length > 0 ? name.join(' ') : null,
              fqn: 'loyalty_card_is_linked_' + i
            },
            { 
              content: elem.status === 'active',
              component: 'data-table-cell-active',
              fqn: 'loyalty_card_active_' + i
            },
            { 
              content: !this.$basil.isNil(d) ? new Date(d) : null,
              fqn: 'loyalty_card_activation_date_' + i
            },
            
          ],
          value: elem
        };

        if(!this.$basil.isNil(this.$basil.get(elem, 'userId', null))) {
          row.href = { name: 'sayl-customer_entry', params: { id: elem.userId }};
        }

        return row
      });
    },

    statuses() {
      return [
        {
          label: this.$t('sayl.active'),
          value: 'active'
        },

        {
          label: this.$t('sayl.inactive'),
          value: 'inactive',
        }
      ]
    },

    types() {
      return [
        { 
          label: this.$t('sayl-ecommerce.loyalty_card_type_digital'),
          value: 'digital',
        },

        {
          label: this.$t('sayl-ecommerce.loyalty_card_type_physical'),
          value: 'physical',  
        }
      ]
    }
  },

  methods: {
    onCreate() {
      this.showModalCreate = true
    },

    onCreateClose(){
      this.showModalCreate = false
      this.onRefresh();
    },

    onClear() {
      this.search.value = null
      this.onRefresh()
    },

    onExportConfirm(args) {
      this.showExport = false;
      return this.onExport({
        type: args.format,
        name: args.name,
      });
    },

    export(options) {
      let payload = Object.assign({}, options)

      payload.fields = this.fields

      if(!this.$basil.isNil(this.search.value)){
        if(this.search.value.match(/^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i)) {
          payload.user_id = this.search.value
        } else {
          payload.search = this.search.value
        }
      }

      return window.$dl.loyaltyCard.export({ args: payload })
    },

    onFilters() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => this.state.invalid = true, 300);
    },
    
    reset() {
      let query = this.$basil.get(this.$route, 'query', {});

      if(query.user_id){
        this.search.value = query.user_id;
      } else if(query.token) {
        this.search.value = query.token;
      } 

      let args = { 
        page: this.pagination.currentPage, 
        search: this.search.value,
        user_id: query.user_id || null,
        pageSize: this.pagination.pageSize,
        type: !this.$basil.isNil(this.type) ? this.type.value : null,
        status: !this.$basil.isNil(this.status) ? this.status.value : null,
      };

      !this.$basil.isNil(args.user_id) && delete args.search;
      this.$router.replace({ query: {} })
        .catch(() => {})

      this.$emit('init', { filters: this.filters, actions: this.actions, reset: this.onRefresh });

      return this.$basil.sequence([
        window.$dl.loyalty.collection.bind(window.$dl.loyalty, {  }),
        window.$dl.loyaltyCard.collection.bind(window.$dl.loyaltyCard, { args }),
        window.$dl.loyaltyCard.getKpis.bind(window.$dl.loyaltyCard, {})
      ])
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      let query = vm.$basil.get(to, 'query', {});
      let keys = Object.keys(query);
      
      if(keys.length > 0) {
        vm.onRefresh()
      }
    })
  }
}
</script>

