<template>
  <footer class="n-footer">
    <div class="container">
      <div class="n-footer__copy">
        <!-- Sayl Logo -->
        <img 
          class="n-footer__logo"
          :src="$getImageSrc('/statics/images/logos/sayl/color.svg')" 
          alt="Sayl - Color">

        <!-- Sayl Label -->
        <div class="n-footer__label">{{ $t('sayl.copyright') }}</div>
      </div>

      <!-- Languages -->
      <forms-select 
        :appearance="$pepper.Appearance.SUBTLE"
        class="n-footer__languages"
        :key="key"
        :options="langs"
        :size="$pepper.Size.S"
        :value="locale"
        @change="changeLang" 
        />
    </div>
  </footer>
</template>

<script>
export default {
  name: 'NavigationsFooter',

  data() {
    return {
      key: 1,
      locale: null,
      locales: null
    }
  },

  computed: {
    langs() { 
      return (this.locales || []).map(l => {
        return {
          label: this.$t(`sayl.lang_${l.lang.toString()}`),
          value: l.toString()
        }
      })
    },
  },

  methods: {
    changeLang(l) {
      $dl.i18n.setLocale(l)
    },
  },

  mounted() {
    this.locales = this.$basil.i18n.locales
    this.locale = this.$basil.i18n.locale.toString()

    this.$bus.$on('change-lang', () => {
      this.locale = this.$basil.i18n.locale.toString()
      this.key++
    })
    this.key++
  },

  beforeDestroy() {
    this.$bus.$off('change-lang');
  }
}
</script>
