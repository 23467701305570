<template>
  <layout-modal-step-item
    v-bind="attrs">

    <!-- Loading -->
    <div 
      class="layout-modal__loader"
      v-if="loading">
      <ui-loader />
    </div>

    <template v-if="!loading"> 
      <article class="layout-modal__article flow">
        <forms-choice
          :options="formats"
          required
          v-if="formats.length > 1"
          v-model="format"
        />
      </article>

      <article class="layout-modal__article flow">
        <forms-input
          required
          v-model="name"
        >File name<template v-slot:suffix>.{{ format.value }}</template></forms-input>
      </article>
    </template>

    <template v-slot:footer>
      <actions-button
        :appearance="$pepper.Appearance.SUBTLE"
        :size="$pepper.Size.S"
        @click="$attrs.close"
      >{{ $t('sayl.cancel') }}</actions-button>

      <actions-button
        appearance="primary"
        :disabled="!valid"
        :size="$pepper.Size.S"
        icon-post="arrow-right"
        @click="onConfirm"
      >{{ $t('sayl.next') }}</actions-button>
    </template>
  </layout-modal-step-item>
</template>

<script>
export default {
  name: 'ModalExportStep1',

  props: {
    filename: {

    },

    visible: {
      default: false,
      type: Boolean,
    }
  },

  data(){
    return {
      loading: true,
      format: null,
      name: null
    }
  },

  computed: {
    attrs() {
      return Object.assign({
        loading: this.loading
      }, this.$attrs)
    },

    formats() {
      return [
        {
          description: this.$t(window.$dl.formats.CSV.description),
          label: this.$t(window.$dl.formats.CSV.label),
          value: window.$dl.formats.CSV.value,
        },
        {
          description: this.$t(window.$dl.formats.EXCEL.description),
          label: this.$t(window.$dl.formats.EXCEL.label),
          value: window.$dl.formats.EXCEL.value,
        }
      ]
    },

    valid() {
      return !this.$basil.isNil(this.format) && !(this.$basil.isNil(this.name) || this.$basil.isEmpty(this.name))
    }
  },

  watch: {
    visible: {
      immediate: true,
      handler(newVal, oldVal) {
        if(newVal && !oldVal) {
          this.reset()
        }
      }
    }
  },

  methods: {
    back() {
      this.$emit('close')
    },

    onConfirm() {
      let args = this.$basil.get(this.$attrs, 'aggregate', {})
      delete args.isTrusted;
      args.format = this.format.value
      args.name = this.name
      this.$attrs.next(args)
    },

    reset() {
      this.loading = true

      let args = this.$basil.get(this.$attrs, 'aggregate', {})
      let f = this.$basil.get(this.$attrs, 'aggregate.format', null)
      this.format = !this.$basil.isNil(f) ? 
        this.formats.find(f => args.format === f.value) : 
        this.formats[0]

      this.name = this.$basil.get(this.$attrs, 'aggregate.name', this.filename)

      this.loading = false
    },
  },

  mounted() {
    this.reset()
  }
}
</script>
