<template>
  <layout-level2-sided 
    v-bind="attrs" 
    v-on="listeners">

    <template v-if="loading">
      <div class="layout-level2-sided__loader">
        <ui-loader />
      </div>
    </template>

    <template v-else>
      <div class="layout-level2-sided__container container view-loyalty-referral">
        <div class="ui-timeline flow">
          <ui-card 
            :appearance="$pepper.Appearance.SUBTLE" 
            article
            class="view-loyalty-referral__descr">
            <article class="ui-card__article">
              <forms-input
                :class="hasTranslations('description')"
                :errors="getErrors('description')"
                icon-post="l8n"
                :icon-post-interactive="true"
                :placeholder="$t('sayl.placeholder')"
                :read-only="readOnly"
                type="text"
                @iconClick="onOpenTranslations('description')"
                @input="onRemoveError('description')"
                v-model="edition.description.all"
              >{{ $t('sayl-loyalty.referral_reward_description') }}</forms-input>
            </article>
          </ui-card>

          <referral-rules
            :edition="edition"
            :errors="getErrors('rules')"
            :read-only="readOnly"
            @change="onRemoveError('rules')"
            v-if="hasRules"
          />

          <referral-reward
            :edition="edition"
            :errors="getErrors('reward')"
            :read-only="readOnly"
            :tag="hasRules ? $t('sayl-loyalty.referral_tag_what') : $t('sayl-loyalty.referral_referring_tag_what')"
            @change="onRemoveError('reward')"
          />
        </div>
      </div>
    </template>

    <modal-translate 
      @close="onCloseTranslations"
      :readOnly="readOnly"
      :field="translationsField"
      :type="translationsType"
      :visible="showTranslations" 
    />
  </layout-level2-sided>
</template>

<script>
import { mapState } from 'vuex'

import { 
  MixinEditionDiscard,
  MixinEditionSave,
  MixinError,
  MixinTranslation,
} from '@sayl/admin-common'

import ReferralReward from './reward.vue'
import ReferralRules from './rules.vue'

export default {
  name: 'ModalReferralReward',

  components: {
    ReferralReward,
    ReferralRules,
  },

  mixins: [
    MixinEditionDiscard,
    MixinEditionSave,
    MixinError,
    MixinTranslation,
  ],

  props: {
    isNew: {
      type: Boolean,
      default: true,
    },

    readOnly: {
      type: Boolean,
      default: false,
    },

    hasRules: {
      type: Boolean,
      default: false,
    },

    value: {
      type: Object,
    },

    visible: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      edition: null,
      loading: true,
      errors: {}
    }
  },

  computed: {
    ...mapState({
      referral: state => state.loyalty.referral.edition,
    }),

    attrs() {
      return {
        icon: 'cog',
        loading: this.loading,
        visible: this.visible,
        hasSaveButton: !this.readOnly,
        pristine: this.pristine,
        title: this.title,
      }
    },

    listeners() {
      return {
        back: this.onDiscard,
        discard: this.onDiscard,
        close: this.onDiscard,
        save: this.save,
      }
    },

    pristine() {
      return this.readOnly || JSON.stringify(this.edition) === JSON.stringify(this.value)
    },

    title() {
      return this.isNew ? 
        this.$t('sayl-loyalty.referral_create_reward') : 
        this.$t('sayl-loyalty.referral_edit_reward')
    }
  },

  watch: {
    visible: {
      immediate: true,
      handler() {
        if(this.visible) {
          this.reset()
        }
      }
    }
  },

  methods: {
    back() {
      this.$emit('close')
    },

    reset() {
      this.loading = true
      this.errors = {}
      
      this.edition = this.value.copy()
      this.loading = false
    },

    save() {
      if(this.validate()) {
        this.$emit('confirm', this.edition)
      }
    },

    validate() {
      this.errors = {}

      if(this.hasRules && (this.$basil.isNil(this.edition.rules) || this.edition.rules.length === 0)) {
        this.errors['rules'] = ['rule_must_have_one_element']
      }

      if(this.$basil.isNil(this.edition.type) || this.$basil.isEmpty(this.edition.type)) {
        this.errors['reward'] = ['reward_is_required']
      }

      return Object.keys(this.errors).length === 0
    }
  }
}
</script>
