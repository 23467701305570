<template>
  <div class="data-table-cell-service">
    <!-- Icon -->
    <ui-icon 
      class="data-table-cell-service__icon" 
      :glyph="glyph"
      :title="serviceName" 
      />

    <!-- Label -->
    <div 
      v-if="!iconOnly" 
      class="data-table-cell-service__label">{{ serviceName }}</div>
  </div>
</template>

<script>
export default {
  name: 'DataTableServiceCell',

  props: {
    content: {
      type: String
    },
    iconOnly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    glyph(){
      return this.service
    },

    service(){
      return this.content === 'base' ? 'pickup' : this.content
    },

    serviceName(){
      return this.$t(`sayl.service_${this.service}`)
    }
  }
}
</script>
