const base = [
  {
    clearable: true,
    component: 'forms-input',
    fqn: 'usage-style-bg',
    label: 'bg',
    property: '--slp-usage-bg',
    section: 'usage',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'usage-content-empty-title',
    label: 'empty_title',
    property: 'empty-title',
    section: 'usage',
    type: 'content',
  },
]

const title = [
  {
    component: 'forms-input',
    fqn: 'usage-content-title',
    label: 'title',
    property: 'title',
    section: 'usage',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'usage-style-title-fc',
    label: 'fc',
    property: '--slp-usage-title-fc',
    section: 'usage',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-select',
    fqn: 'usage-style-title-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-usage-title-fs',
    section: 'usage',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component: 'forms-select',
    fqn: 'usage-style-title-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-usage-title-fw',
    section: 'usage',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const description = [
  {
    component: 'forms-textarea',
    fqn: 'usage-content-descr',
    label: 'description',
    property: 'description',
    section: 'usage',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'usage-style-descr-fc',
    label: 'fc',
    property: '--slp-usage-descr-fc',
    section: 'usage',
    subtype: 'color',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'usage-style-descr-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-usage-descr-fs',
    section: 'usage',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'usage-style-descr-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-usage-descr-fw',
    section: 'usage',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const meta = [
  {
    component: 'forms-merge-tags',
    fqn: 'usage-content-meta',
    label: 'meta',
    logged: true,
    property: 'meta',
    section: 'usage',
    subtype: 'merge-tags',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'usage-style-meta-fc',
    label: 'fc',
    logged: true,
    property: '--slp-usage-meta-fc',
    section: 'usage',
    subtype: 'color',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'usage-style-meta-fs',
    label: 'fs',
    logged: true,
    options: 'fontSizes',
    property: '--slp-usage-meta-fs',
    section: 'usage',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'usage-style-meta-fw',
    label: 'fw',
    logged: true,
    options: 'fontWeights',
    property: '--slp-usage-meta-fw',
    section: 'usage',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const progress = [
  {
    component: 'forms-input',
    fqn: 'usage-content-voucher-left',
    label: 'voucher_left',
    property: 'voucher-left',
    section: 'usage',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'usage-style-item-progress-primary',
    label: 'bg_primary',
    property: '--slp-usage-progress-primary-bg',
    section: 'usage',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'usage-style-item-progress-secondary',
    label: 'bg_secondary',
    property: '--slp-usage-progress-secondary-bg',
    section: 'usage',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'usage-style-item-progress-tertiary',
    label: 'bg_tertiary',
    property: '--slp-usage-progress-tertiary-bg',
    section: 'usage',
    subtype: 'color',
    type: 'style',
  },

  {
    component: 'forms-input',
    fqn: 'usage-style-item-progress-back-bg',
    label: 'bg',
    property: '--slp-usage-progress-back-bg',
    section: 'usage',
    subtype: 'color',
    type: 'style',
  },
]

const itemBase = [
  {
    component: 'forms-input',
    fqn: 'usage-style-item-bg',
    label: 'bg',
    property: '--slp-voucher-bg',
    section: 'usage',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'usage-style-item-bc',
    label: 'bc',
    property: '--slp-voucher-bc',
    section: 'usage',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'usage-style-item-shadow',
    label: 'shadow',
    property: '--slp-voucher-shadow',
    section: 'usage',
    subtype: 'color',
    type: 'style',
  }
]

const itemIcons = [
  // Discount
  {
    component: 'forms-input',
    fqn: 'usage-style-item-icon-discount-bg',
    label: 'bg',
    property: '--slp-voucher-icon-discount-bg',
    section: 'usage',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'usage-style-item-icon-discount-fc',
    label: 'fc',
    property: '--slp-voucher-icon-discount-fc',
    section: 'usage',
    subtype: 'color',
    type: 'style',
  },

  // Perks
  {
    component: 'forms-input',
    fqn: 'usage-style-item-icon-perks-bg',
    label: 'bg',
    property: '--slp-voucher-icon-perks-bg',
    section: 'usage',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'usage-style-item-icon-perks-fc',
    label: 'fc',
    property: '--slp-voucher-icon-perks-fc',
    section: 'usage',
    subtype: 'color',
    type: 'style',
  },

  // Wallet
  {
    component: 'forms-input',
    fqn: 'usage-style-item-icon-wallet-bg',
    label: 'bg',
    property: '--slp-voucher-icon-wallet-bg',
    section: 'usage',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'usage-style-item-icon-wallet-fc',
    label: 'fc',
    property: '--slp-voucher-icon-wallet-fc',
    section: 'usage',
    subtype: 'color',
    type: 'style',
  },
]

const itemTitle = [
  {
    component: 'forms-input',
    fqn: 'usage-style-item-title-fc',
    label: 'fc',
    property: '--slp-voucher-title-fc',
    section: 'usage',
    subtype: 'color',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'usage-style-item-title-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-voucher-title-fs',
    section: 'usage',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'usage-style-item-title-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-voucher-title-fw',
    section: 'usage',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const itemDescr = [
  {
    component: 'forms-input',
    fqn: 'usage-style-item-descr-fc',
    label: 'fc',
    property: '--slp-voucher-descr-fc',
    section: 'usage',
    subtype: 'color',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'usage-style-item-descr-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-voucher-descr-fs',
    section: 'usage',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'usage-style-item-descr-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-voucher-descr-fw',
    section: 'usage',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const itemPeriod = [
  {
    component: 'forms-merge-tags',
    fqn: 'usage-voucher-validity-period',
    label: 'validity_period',
    logged: true,
    property: 'validity-period',
    section: 'usage',
    subtype: 'merge-tags',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'usage-style-item-meta-fc',
    label: 'fc',
    property: '--slp-voucher-meta-fc',
    section: 'usage',
    subtype: 'color',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'usage-style-item-meta-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-voucher-meta-fs',
    section: 'usage',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'usage-style-item-meta-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-voucher-meta-fw',
    section: 'usage',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const itemActions = [
  {
    component: 'forms-input',
    fqn: 'usage-content-btn-redeem',
    label: 'btn_redeem',
    logged: true,
    property: 'btn-redeem',
    section: 'usage',
    subtype: 'text',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'usage-content-btn-confirm',
    label: 'btn_confirm',
    logged: true,
    property: 'btn-confirm',
    section: 'usage',
    subtype: 'text',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'usage-content-btn-cancel',
    label: 'btn_cancel',
    logged: true,
    property: 'btn-cancel',
    section: 'usage',
    subtype: 'text',
    type: 'content',
  },
]

const itemCopy = [
  {
    component: 'forms-input',
    fqn: 'usage-content-btn-copy',
    label: 'btn_copy',
    logged: true,
    property: 'btn-copy',
    section: 'usage',
    subtype: 'text',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'usage-content-copied',
    label: 'copied',
    logged: true,
    property: 'copied',
    section: 'usage',
    subtype: 'text',
    type: 'content',
  },
]

const itemButtonDefault = [
  {
    component: 'forms-input',
    fqn: 'usage-style-item-btn-default-bg',
    label: 'bg',
    property: '--slp-btn-voucher-default-bg',
    section: 'usage',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'usage-style-item-btn-default-bc',
    label: 'bc',
    property: '--slp-btn-voucher-default-bc',
    section: 'usage',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'usage-style-item-btn-default-fc',
    label: 'fc',
    property: '--slp-btn-voucher-default-fc',
    section: 'usage',
    subtype: 'color',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'usage-style-item-btn-default-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-btn-voucher-default-fs',
    section: 'usage',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'usage-style-item-btn-default-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-btn-voucher-default-fw',
    section: 'usage',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const itemButtonPrimary = [
  {
    component: 'forms-input',
    fqn: 'usage-style-item-btn-primary-bg',
    label: 'bg',
    property: '--slp-btn-voucher-primary-bg',
    section: 'usage',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'usage-style-item-btn-primary-bc',
    label: 'bc',
    property: '--slp-btn-voucher-primary-bc',
    section: 'usage',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'usage-style-item-btn-primary-fc',
    label: 'fc',
    property: '--slp-btn-voucher-primary-fc',
    section: 'usage',
    subtype: 'color',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'usage-style-item-btn-primary-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-btn-voucher-primary-fs',
    section: 'usage',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'usage-style-item-btn-primary-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-btn-voucher-primary-fw',
    section: 'usage',
    subtype: 'fontWeight',
    type: 'style',
  },
]

export default [ 
  ...base, 
  ...title, 
  ...description,
  ...meta, 
  ...progress,

  ...itemBase,
  ...itemIcons,

  ...itemTitle,
  ...itemDescr,
  ...itemPeriod,

  ...itemActions,
  ...itemCopy,

  ...itemButtonDefault,
  ...itemButtonPrimary,
]
