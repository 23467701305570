<template>
  <layout-modal-step-item v-bind="attrs">
    <!-- Loading -->
    <div 
      class="layout-modal-steps__loader" 
      v-if="loading" >
      <ui-loader />
    </div>

    <!-- Based on type -->
    <article 
      class="layout-modal__article"
      v-if="!loading">
      <!-- Perk::Grant::fields -->
      <component
        :is="f.component"
        :edition="edition"
        :key="`grant_field_${i}`"
        v-bind="f"
        v-for="(f, i) in grantFields"
        v-on="f.listeners"
        v-show="f.hasOwnProperty('if') ? f.if : true">
        <template>{{ f.label }}</template>
        <template #suffix v-if="f.suffix">{{f.suffix}}</template>
      </component>
    </article>

    <!-- Temporal limits -->
    <!-- <article 
      class="layout-modal__article ui-card__article flow"
      v-if="false">
      <forms-checkbox
        :appearance="$pepper.Appearance.TOGGLE"
        :size="$pepper.Size.S"
        @change="onFromChange"
        v-model="hasTemporalLimit"
      >{{ $t('sayl.perk_has_temporal_limit') }}</forms-checkbox>
        
      <div 
        class="group"
        v-if="hasTemporalLimit">
        <forms-input 
          type="date"
          :placeholder="$t('sayl.placeholder')"
          v-model="edition.from"
        >{{ $t('sayl.perk_from') }}</forms-input>
        
        <forms-input 
          type="date"
          :placeholder="$t('sayl.placeholder')"
          v-model="edition.to"
        >{{ $t('sayl.perk_to') }}</forms-input>
      </div>
    </article>

    <article 
      class="layout-modal__article flow"
      v-if="false">
      <!-- Limit 
      <forms-checkbox
        :appearance="$pepper.Appearance.TOGGLE"
        :size="$pepper.Size.S"
        @change="onLimitsChange"
        v-model="hasLimits"
      >{{ $t('sayl.perk_has_usage_limits') }}</forms-checkbox>

      <forms-input 
        min="0"
        :placeholder="$t('sayl.placeholder')"
        step="1"
        type="number"
        v-model="edition.triggerLimit.triggerAmount"
        v-if="hasLimits">
        <template>{{ $t('sayl.perk_limit_amount') }}</template>
        <template v-slot:suffix>{{ $t('sayl.perk_time') }}</template>
      </forms-input>

      <!-- Recurrence 
      <forms-checkbox
        :appearance="$pepper.Appearance.TOGGLE"
        :hint="$t('sayl.perk_has_recurrence_limits_hint')"
        :placeholder="$t('sayl.placeholder')"
        :size="$pepper.Size.S"
        @change="onRecurrenceChange"
        v-model="hasRecurrenceLimits"
        v-if="hasLimits"
      >{{ $t('sayl.perk_has_recurrence_limits') }}</forms-checkbox>
      
      <div 
        class="group modal-create-perk__group"
        v-if="hasRecurrenceLimits">
        <forms-input 
          min="0"
          :placeholder="$t('sayl.placeholder')"
          step="1"
          type="number"
          v-model="edition.triggerLimit.frequencyCount"
        >{{ $t('sayl.perk_limit_frequency_amount') }}</forms-input>

        <forms-select 
          class="modal-create-perk__bottom frequency"
          :options="frequencies"
          :placeholder="$t('sayl.select_placeholder')"
          v-model="edition.triggerLimit.frequencyUnit"
        ></forms-select>
      </div>
    </article> -->

    <modal-translate 
      :field="translationsField"
      :read-only="false"
      :type="translationsType"
      :visible="showTranslations" 
      @close="onCloseTranslations"
    />

    <template v-slot:footer>
      <actions-button
        :appearance="$pepper.Appearance.SUBTLE"
        icon-pre="arrow-left"
        @click="$attrs.previous"
      >{{ $t('sayl.previous') }}</actions-button>

      <actions-button
        appearance="primary"
        :disabled="!isValid"
        icon-post="arrow-right"
        @click="onConfirm"
      >{{ $t('sayl.continue') }}</actions-button>
    </template>
  </layout-modal-step-item>
</template>

<script>
import { MixinError } from '@sayl/admin-common'
import MixinGrant from './grant.js'

export default {
  name: 'TierRewardPerk2',

  mixins: [ 
    MixinError,
    MixinGrant,
  ],

  data() {
    return { 
      errors: {},
      key: 1,
      hasLimits: false,
      hasRecurrenceLimits: false,
      hasTemporalLimit: false,
      loading: true,
    }
  },

  computed: {
    attrs() {
      return Object.assign({
        class: 'add-class-here',
        title: this.$t('sayl.perk_create_configure_title'),
      }, this.$attrs)
    },

    edition() { 
      return this.key && this.$attrs.edition
    },

    // frequencies() {
    //   return [
    //     {
    //       label: this.$t('sayl.frequency_none'),
    //       value: 'none'
    //     },
    //     {
    //       label: this.$t('sayl.frequency_hour'),
    //       value: 'hour'
    //     },
    //     {
    //       label: this.$t('sayl.frequency_day'),
    //       value: 'day'
    //     },
    //     {
    //       label: this.$t('sayl.frequency_week'),
    //       value: 'week'
    //     },
    //     {
    //       label: this.$t('sayl.frequency_month'),
    //       value: 'month'
    //     },
    //   ]
    // },

    isLoading() {
      return this.$basil.isNil(this.edition) || this.$basil.get(this.$attrs, 'loading', false)
    },

    isValid() {
      let ret = true
      
      this.grantFields.forEach((f) => {
        let v = this.$basil.get(this.edition, `perk.grant.${f.property}`)
        
        if(this.$basil.isNil(v) || (this.$basil.isString(v) && this.$basil.isEmpty(v)) || (this.$basil.isNumber(v) && v <= 0)) {
          ret = false
        }
      })
      
      return this.key && ret
    },
  },

  methods: { 
    onConfirm() {
      if(this.isValid) {
        let ret = Object.assign(this.$attrs.aggregate, { })
        this.$attrs.next(ret)
      }
    },

    // onFromChange() {
    //   this.edition.from = !this.hasTemporalLimit ? null : this.$basil.get(this.origin, 'from', null)
    //   this.edition.to = !this.hasTemporalLimit ? null : this.$basil.get(this.origin, 'to', null)
    // },

    // onLimitsChange() {
    //   this.edition.triggerLimit.triggerAmount = !this.hasLimits ? null : this.$basil.get(this.origin, 'triggerLimit.triggerAmount', null)

    //   if(!this.hasLimit) {
    //     this.hasRecurrenceLimits = false
    //     this.onRecurrenceChange()
    //   }
    // },

    // onRecurrenceChange() {
    //   this.edition.triggerLimit.frequencyCount = !this.hasRecurrenceLimits ? null : this.$basil.get(this.origin, 'triggerLimit.frequencyCount', null)
    //   this.edition.triggerLimit.frequencyUnit = !this.hasRecurrenceLimits ? (this.hasLimit ? 'none' : null) : this.$basil.get(this.origin, 'triggerLimit.frequencyUnit', null)
    // },

    reset() {
      this.loading = true
      this.errors = {}

      window.$dl.tier.getEmbeds()
        // .then(() => {
        //   this.hasTemporalLimit = !this.$basil.isNil(this.edition.from) || !this.$basil.isNil(this.edition.to)

        //   let limit = this.$basil.get(this.edition, 'triggerLimit.triggerAmount')
        //   this.hasLimits = !this.$basil.isNil(limit)

        //   let recurrence = this.$basil.get(this.edition, 'triggerLimit.frequencyCount')
        //   this.hasRecurrenceLimits = this.hasLimits && !this.$basil.isNil(recurrence)
        // })
        .catch((e) => $console.error(e))
        .finally(() => this.loading = false)
    }
  },

  mounted() {
    this.reset()
  }
}
</script>
