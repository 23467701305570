import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import { config, isDevelopment } from '@/env'

/**
 * Register bugsnags
 * 
 * @param {Object} options
 * @param {GingerCapabilities} options.capabilities
 * @returns {Promise.resolve}
 */
export default function ({ capabilities }) {
  $console.group('utils-bugsnag')
  
  let Vue = capabilities.vue
  
  if(!isDevelopment){
    Bugsnag.start({
      apiKey: '97278ba38e38cb1fe37d30d046f5cd34',
      plugins: [
        new BugsnagPluginVue()
      ],
      appType: 'client',
      appVersion: config.version.client,
      releaseStage: config.env
    })
    Bugsnag.getPlugin('vue').installVueErrorHandler(Vue)
    Vue.prototype.$bugsnag = Bugsnag
  }
  
  $console.groupEnd('utils-bugsnag')
  return Promise.resolve()
}
