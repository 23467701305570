<template>
  <!-- Loading -->
  <div 
    class="view-loyalty__loader"
    v-if="iLoading">
    <ui-loader />
  </div>

  <div 
    style="display: contents;"
    v-else>
    <div class="layout-level2__container container -cols">
      <div class="col">
        <view-pretty-list-tiers
          :read-only="readOnly"
          :settings="edition.tiersSettings"
          :values="tiers"
          @create="onCreate"
          @edit="onEdit"
          @remove="onRemove"
        />
      </div>

      <div class="col">
        <loyalty-tier-settings 
          :read-only="readOnly"
        />
      </div>
    </div>

    <router-view 
      :read-only="readOnly" 
      :value="selectedTier" 
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { 
  MixinTable,
} from '@sayl/admin-common'

import LoyaltyTierSettings from './settings.vue'

export default {
  name: 'LoyaltyProgramTiers',

  components: {
    LoyaltyTierSettings
  },

  mixins: [ 
    MixinTable 
  ],

  props: {
    readOnly: {
      type: Boolean, 
      default: false,
    }
  },

  data() {
    return {
      iLoading: true,
      selectedTier: null
    }
  },

  computed: {
    ...mapState({
      state: state => state.tier.tier,
      tiers: state => state.tier.tier.all,
      edition: state => state.loyalty.loyalty.edition,
    }),

    actions() {
      let ret = []

      if(!this.readOnly) {
        ret.push(
          {
            label: this.$t('sayl.add'),
            action: this.onCreate,
            mode: this.$pepper.Action.GLOBAL,
          },
          {
            label: this.$t('sayl.delete'),
            action: this.onRemove,
            mode: this.$pepper.Action.SINGLE + this.$pepper.Action.SOME + this.$pepper.Action.PAGE + this.$pepper.Action.PAGES,
          },
        )
      }

      return ret
    },

    events() {
      return {
        click: this.onEdit
      }
    }
  },

  methods: {
    getTierType(tier) {
      if(tier.semgentId != null) {
        return this.$t('sayl-loyalty.tier_type_segment')
      }

      if(tier.amount != null) {
        return this.$t('sayl-loyalty.tier_type_amount')
      }

      if(tier.type != null) {
        return this.$t(`sayl-loyalty.tier_type_${tier.type}`)
      }
    },

    onCreate() {
      // this.selectedTier = this.edition.createTier({})
      this.$router.push({ name: 'sayl-customer-loyalty_program-tier', params: { tier: 'create' } }).catch(() => {})
    },

    onEdit(item) {
      this.selectedTier = item
      this.$router.push({ name: 'sayl-customer-loyalty_program-tier', params: { tier: item.id } }).catch(() => {})
    },

    remove(row) {
      return new Promise((resolve, reject) => {
        window.$dl.tier.remove({ id: row.id })
          .then(() => {
            this.reset()
            resolve()
          })
          .catch((e) => reject(e))
      })
    },

    reset() {
      if(window.$dl.elements.isHidden('sayl_resto_url')) {
        this.$router.replace({ name: 'sayl-customer-loyalty_program', params: { program: this.$route.params.program } })
        return new Promise((resolve, reject) => resolve())
      }

      return new Promise((resolve, reject) => {
        this.iLoading = true

        window.$dl.tier.all({ args: { limit: 9999, page: 1, relatedEntity: 'loyalty_program', relatedId: this.$route.params.program }})
          .then((r) => resolve(r))
          .catch((e) => reject(e))
          .finally(() => this.iLoading = false)
      })
    }
  },
}
</script>