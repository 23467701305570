// SAYL Connect

const LIST = [
  // NFT
  {
    description: 'sayl-nft.nft_module_description',
    fqn: 'sayl-conn3ct-nft-module',
    icon: 'diamond',
    icons: ['bitcoin', 'diamond', 'dollar'],
    label: 'sayl-nft.nft_module_title',
    subs: [],
    children: []
  },
  {
    description: 'sayl-nft.nft_description',
    fqn: 'sayl-conn3ct-nft',
    icon: 'diamond',
    href: { name: 'sayl-nft_library' },
    icons: ['bitcoin', 'diamond', 'dollar'],
    label: 'sayl-nft.nft_title',
    subs: [
      'sayl-nft_item',
      'sayl-nft_item-global',
      'sayl-nft_item-minted',
      'sayl-nft_item-minted-item',
      'sayl-nft_item-claimed',
      'sayl-nft_item-distribution',
      'sayl-nft_item-utility',
      'sayl-nft_item-messages',
      'sayl-nft_item-marketplace',
      'sayl-nft_item-marketplace-global',
      'sayl-nft_item-marketplace-overview',
      'sayl-nft_item-raw',
      'sayl-nft_library',
      'sayl-nft_item-claim',
    ],
  },
  {
    fqn: 'sayl-conn3ct-nft-collections',
    href: { name: 'sayl-nft_collections' },
    icon: 'bookmarks',
    description: 'sayl-nft.collections_description',
    label: 'sayl-nft.collections_title',
    subs: ['sayl-nft_collection-entry'],
  },
  {
    fqn: 'sayl-conn3ct-nft-analytics',
    href: { name: 'sayl-nft_overview' },
    icon: 'bookmarks',
    description: 'sayl-nft.nft_analytics_description',
    label: 'sayl-nft.nft_analytics_title',
    subs: ['sayl-nft_overview'],
  },
  {
    description: 'sayl.web3_triggers_description',
    fqn: 'sayl-conn3ct-web3-triggers',
    icon: 'bolt',
    href: { name: 'sayl-web3-triggers_library' },
    icons: ['bitcoin', 'bolt', 'ether'],
    label: 'sayl.web3_triggers_title',
    subs: ['sayl-web3-triggers_item'],
  },
  {
    description: 'sayl.web3_vouchers_description',
    fqn: 'sayl-conn3ct-web3-vouchers',
    icon: 'bolt',
    href: { name: 'sayl-web3-vouchers_library' },
    icons: ['bitcoin', 'bolt', 'ether'],
    label: 'sayl.web3_vouchers_title',
    subs: ['sayl-web3-voucher_item'],
  },
  // POAPs
  {
    description: 'sayl.poaps_menu_description',
    fqn: 'sayl-conn3ct-settings-poaps',
    icon: 'target',
    label: 'sayl.poaps_menu_title',
    subs: [],
  },
  {
    description: 'sayl-conn3ct-settings.poaps_library_description',
    fqn: 'sayl-conn3ct-settings-poaps-library',
    icon: 'target',
    href: { name: 'sayl-conn3ct-settings_poaps-library' },
    label: 'sayl-conn3ct-settings.poaps_library_title',
    subs: [
      'sayl-conn3ct-settings_poap',
      'sayl-conn3ct-settings_poap-global',
      'sayl-conn3ct-settings_poap-triggers',
      'sayl-conn3ct-settings_poap-trigger',
    ],
  },

  // Messaging
  {
    fqn: 'sayl-nft-messaging',
    icon: 'message-dots',
    description: 'sayl-nft.messaging_description',
    label: 'sayl-nft.messaging_title',
    subs: [],
    children: []
  },
  {
    fqn: 'sayl-nft-messaging-audiences',
    href: { name: 'sayl-nft-messaging_audiences' },
    icon: 'message-dots',
    description: 'sayl-nft.messaging_audiences_description',
    label: 'sayl-nft.messaging_audiences_title',
    subs: [
      'sayl-nft-messaging_audience-entry',
      'sayl-nft-messaging_audience-message-entry',
    ],
  },
  {
    fqn: 'sayl-nft-messaging-campaigns',
    href: { name: 'sayl-nft-messaging_campaigns' },
    icon: 'message-dots',
    description: 'sayl-nft.messaging_campaigns_description',
    label: 'sayl-nft.messaging_campaigns_title',
    subs: [
      'sayl-nft-messaging_campaign-entry',
      'sayl-nft-messaging_campaign-entry-global',
      'sayl-nft-messaging_campaign-message-entry',
      'sayl-nft-messaging_campaign-entry-kpis'
    ],
  },
  {
    fqn: 'sayl-nft-messaging-messages',
    href: { name: 'sayl-nft-messaging_messages' },
    icon: 'message-dots',
    description: 'sayl-nft.messaging_messages_description',
    label: 'sayl-nft.messaging_messages_title',
    subs: [
      'sayl-nft-messaging_message-entry',
    ],
  },
  {
    description: 'sayl-conn3ct-settings.poaps_overview_description',
    fqn: 'sayl-conn3ct-settings-poaps-overview',
    icon: 'target',
    href: { name: 'sayl-conn3ct-settings_poaps-overview' },
    label: 'sayl-conn3ct-settings.poaps_overview_title',
    subs: [
      'sayl-conn3ct-settings_poaps-overview-trigger',
    ],
  },

  // Shop
  {
    description: 'sayl.conn3ct_settings_enablers_description',
    fqn: 'sayl-conn3ct-settings-enablers',
    icon: 'login',
    href: { name: 'sayl-conn3ct-settings-enablers_home' },
    icons: ['bitcoin', 'login', 'ether'],
    label: 'sayl.conn3ct_settings_enablers_title',
    subs: ['sayl-conn3ct-settings-enablers_entry'],
  },
  {
    description: 'sayl.conn3ct_settings_shop_description',
    fqn: 'sayl-conn3ct-settings-shop',
    icon: 'building-store',
    icons: ['bag', 'building-store', 'delivery'],
    href: { name: 'sayl-conn3ct-settings-shop_home' },
    label: 'sayl.conn3ct_settings_shop_title',
    subs: [
      'sayl-conn3ct-settings-shop_shop',
      'sayl-conn3ct-settings-shop_catalog',
      'sayl-conn3ct-settings-shop_embed',
      'sayl-conn3ct-settings-shop_store_settings'
    ],
  },
  {
    fqn: 'sayl-conn3ct-orders',
    href: { name: 'sayl-sales_conn3ct-home' },
    icon: 'bag',
    description: 'sayl.conn3ct_store_orders_description',
    label: 'sayl.conn3ct_store_orders',
    subs: ['sayl-sales_conn3ct-entry'],
  },
  {
    description: 'sayl.conn3ct_shop_description',
    fqn: 'sayl-conn3ct-shop',
    icon: 'building-store',
    icons: ['login', 'building-store', 'adjustments'],
    label: 'sayl.conn3ct_shop_title',
    subs: [
      'sayl-conn3ct-settings-enablers_home',
      'sayl-conn3ct-settings-enablers_entry',
      'sayl-conn3ct-settings-shop_shop',
      'sayl-conn3ct-settings-shop_catalog',
      'sayl-conn3ct-settings-shop_embed',
      'sayl-conn3ct-settings-shop_store_settings',
    ],
  },

   // Settings
  {
    description: 'sayl.conn3ct_settings_description',
    fqn: 'sayl-conn3ct-settings',
    icon: 'adjustments',
    icons: ['login', 'adjustments', 'building-store'],
    label: 'sayl.conn3ct_settings_title',
    subs: [],
  },
  {
    description: 'sayl-conn3ct-settings.wallet_settings_description',
    fqn: 'sayl-conn3ct-web3-wallet',
    icon: 'adjustments',
    icons: ['login', 'adjustments', 'building-store'],
    href: { name: 'sayl-conn3ct-settings-merchant-wallet' },
    icons: ['bitcoin', 'bolt', 'ether'],
    label: 'sayl-conn3ct-settings.wallet_settings_title',
    subs: [],
  },
  {
    description: 'sayl-conn3ct-settings.brand_settings_description',
    fqn: 'sayl-conn3ct-web3-brand',
    icon: 'bolt',
    href: { name: 'sayl-conn3ct-settings-merchant-brand' },
    icons: ['bitcoin', 'bolt', 'ether'],
    label: 'sayl-conn3ct-settings.brand_settings_title',
    subs: [],
  },

  // Claims
  {
    description: 'sayl-conn3ct_settings.claim_description',
    fqn: 'sayl-conn3ct-settings-claim',
    icon: 'login',
    href: { name: 'sayl-conn3ct-settings-claim_home' },
    icons: ['bitcoin', 'login', 'ether'],
    label: 'sayl-conn3ct_settings.claim_title',
    subs: ['sayl-conn3ct-settings-claim_entry'],
  },
]

export default LIST
