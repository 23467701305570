<template>
  <layout-modal
    v-bind="attrs"
    v-on="listeners">

    <template>
      <div class="flow">
        <forms-choice
          :options="formats"
          required
          v-if="formats.length > 1"
          v-model="format"
        />
      
        <forms-input
          :hint="$t('sayl-ecommerce.loyalty_cards_create_filename_hint')"
          v-model="fileName"
        >{{ $t('sayl-ecommerce.loyalty_cards_create_filename_label') }}</forms-input>

        <forms-input
          :hint="$t('sayl-ecommerce.loyalty_cards_create_count_hint')"
          :min="1"
          :max="100"
          type="number"
          v-model="count"
        >{{ $t('sayl-ecommerce.loyalty_cards_create_count_label') }}</forms-input>
      </div>
    </template>

    <template v-slot:footer>
      <actions-button
        :appearance="$pepper.Appearance.SUBTLE"
        @click="back"
      >{{ $t('sayl.close') }}</actions-button>

      <actions-button
        appearance="primary"
        :disabled="!isValid || loading"
        :loading="loading"
        icon-post="arrow-right"
        @click="onConfirm"
      >{{ $t('sayl.continue') }}</actions-button>
    </template>
  </layout-modal>
</template>

<script>
export default {
  name: 'ModalLoyaltyCreateCards',

  props: {
    visible: {
      default: false,
      type: Boolean,
    }
  },

  data() {
    return {
      count: 5,
      fileName: 'loyalty-cards',
      format: null,
      loading: false,
      fields: 'token',
    }
  },

  computed: {
    attrs() {
      return {
        closable: true,
        title: this.$t('sayl-ecommerce.loyalty_cards_create_title'),
        description: this.$t('sayl-ecommerce.loyalty_cards_create_description'),
        visible: this.visible,
      }
    },

    formats() {
      return [
        {
          description: this.$t(window.$dl.formats.CSV.description),
          label: this.$t(window.$dl.formats.CSV.label),
          value: window.$dl.formats.CSV.value,
        },
        {
          description: this.$t(window.$dl.formats.EXCEL.description),
          label: this.$t(window.$dl.formats.EXCEL.label),
          value: window.$dl.formats.EXCEL.value,
        }
      ]
    },

    listeners() {
      return {
        close: this.back
      }
    },

    isValid() {
      return this.count > 0 && this.count <= 100
    }
  },

  methods: {
    back() {
      this.$emit('close')
    },

    onConfirm() {
      let payload = {
        exportType: 'export',
        item: { amount: this.count },
        type: this.format.value,
        fields: this.fields
      };

      this.loading = true;

      window.$dl.loyaltyCard.export({ args: payload }) 
        .then((res) => {
          let url = window.URL.createObjectURL(new Blob([res.data]));
          let link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${this.fileName}.${this.format.value}`);
          document.body.appendChild(link);
          link.click();
          this.back();
        })
        .catch((e) => $console.error(e))
        .finally(() => this.loading = false)
    },
  },

  mounted() {
    this.format = this.formats[0]
  }
}
</script>
