import Vue from 'vue'
import Vuex from 'vuex'

import host from './modules/host'
import images from './modules/images'
import loyalty from './modules/loyalty'
import tier from './modules/tier'
import sayl from './modules/sayl'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    host,
    images,
    loyalty,
    sayl,
    tier,
  },
  strict: false
})
