<template>
  <pre class="ui-json-format">
    <code 
      class="ui-json-format__code"
      v-html="prettyPrint(value)"
    ></code>
  </pre>
</template>

<script>
export default {
  name: 'UiJsonFormat',

  props: {
    value: {}
  },

  methods: {
    replacer(match, pIndent, pKey, pVal, pEnd) {
      var key = `<span class=json-key>`;
      var val = '<span class=json-value>';
      var str = '<span class=json-string>';
      var r = pIndent || '';
      if (pKey)
        r = r + key + pKey.replace(/[": ]/g, '') + '</span>: ';
      if (pVal)
        r = r + (pVal[0] == '"' ? str : val) + pVal + '</span>';
      return r + (pEnd || '');
    },

    prettyPrint(obj) {
      var jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*|\[\])?([,[{])?$/mg;
      
      let ret = `<span class=json-string>${obj}</span>`
      
      if(this.$basil.isObject(obj) || this.$basil.isArray(obj)) {
        ret = JSON.stringify(obj, null, 3)
          .replace(/&/g, '&amp;').replace(/\\"/g, '&quot;')
          .replace(/</g, '&lt;').replace(/>/g, '&gt;')
          .replace(jsonLine, this.replacer);
      }
      return ret
    },
  }
}
</script>
