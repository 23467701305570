<template>
  <layout-modal-step-item v-bind="attrs">
    
    <!-- Loading -->
    <div 
      class="layout-modal-steps__loader" 
      v-if="loading">
      <ui-loader />
    </div>

    <component
      :is="rewardIs"
      :value="value"
      :errors="errors"
      v-if="!loading"
    />

    <template v-slot:footer>
      <actions-button
        icon-pre="arrow-left"
        @click="$attrs.previous"
      >{{ $t('sayl.previous') }}</actions-button>

      <actions-button
        :appearance="$pepper.Appearance.PRIMARY"
        @click="onConfirm"
      >{{ $t('sayl.confirm') }}</actions-button>
    </template>
  </layout-modal-step-item>
</template>

<script>
import { mapState } from 'vuex'

import { MixinError } from '@sayl/admin-common'
import NFTAttributes from './components/nft-attributes'

export default {
  name: 'ModalChallengeRewardStep1',

  components: {
    NFTAttributes,
  },

  mixins: [ MixinError,],

  data() {
    return {
      errors: {},
      loading: true,
    }
  },

  computed: {
    ...mapState({
      edition: state => state.audiences.challenge.edition,
      nfts: state => state.audiences.challenge.nfts.all
    }),

    attrs() {
      return Object.assign({
        classes: { '-body-p0': true },
        title: this.$t('sayl-audiences.challenge_reward_step_3')
      }, this.$attrs)
    },

    rewardIs() {
      switch(this.value.type) {
        case 'nft': 
          return NFTAttributes
      }
    },

    nft() {
      return this.nfts ? this.nfts.find(nft => nft.id === this.value.settings.nft_id) : null
    },

    value() {
      return this.$attrs.aggregate
    }
  },

  methods: { 
    onConfirm() {
      if(this.validate()) {
        this.nft.attributes.forEach((a, i) => {
          if(a.type === 'timestamp' && !this.$basil.isNil(this.value.settings.nft_attributes[i].value)) {
            this.value.settings.nft_attributes[i].value = new Date(this.value.settings.nft_attributes[i].value).getTime()
          } else if(a.type === 'number' && (this.$basil.isString(this.value.settings.nft_attributes[i].value) && !this.$basil.isNil(this.value.settings.nft_attributes[i].value))) {
            this.value.settings.nft_attributes[i].value = parseFloat(this.value.settings.nft_attributes[i].value, 10)
          }
        })

        this.$emit('confirm', this.value)
      }
    },

    _formatDate(date) {
      let d = date != null ? new Date(date).getTime() : null
      let offset = new Date().getTimezoneOffset()
      let ret = d != null ? new Date((d - (offset * 60 * 1000))).toISOString() : null
      if(ret) {
        ret = ret.replace(/:[0-9]{2}.[0-9]{3}Z/g, '')
      }
      return ret
    },

    reset() {
      this.errors = {}

      this.nft.attributes.forEach((a, i) => {
        if(!this.$basil.isNil(this.$basil.get(this.value, `settings.nft_attributes[${i}].value`))) {
          if(a.type === 'timestamp') {
            this.value.settings.nft_attributes[i].value = this._formatDate(this.value.settings.nft_attributes[i].value)
          }
        } else {
          this.value.settings.nft_attributes.push({
            label: a.label,
            type: a.type,
            value: null
          })
        }
      })

      this.loading = false
    },

    validate() {
      this.errors = {}
      let tmp = []

      this.nft.attributes.forEach((a, i) => {
        if(a.type === 'number' && (this.$basil.isString(this.value.settings.nft_attributes[i].value) && !this.$basil.isNil(this.value.settings.nft_attributes[i].value))) {
          tmp[i] = parseFloat(this.value.settings.nft_attributes[i].value, 10)
        } else {
          tmp[i] = this.value.settings.nft_attributes[i].value
        }
      })

      this.nft.attributes.forEach((a, i) => {
        if((a.type === 'string' || a.type === 'timestamp') && (this.$basil.isNil(tmp[i]) || this.$basil.isEmpty(tmp[i]))) {
          this.errors[`attributes.${this.$basil.get(a, 'position', 0) - 1}`] = ['field_is_required']
        } else if((a.type === 'number') && (this.$basil.isNil(tmp[i]) || Number.isNaN(tmp[i]))) {
          this.errors[`attributes.${this.$basil.get(a, 'position', 0) - 1}`] = ['field_is_required']
        } else if(a.type === 'media' && this.$basil.isNil(tmp[i])) {
          this.errors[`attributes.${this.$basil.get(a, 'position', 0) - 1}`] = ['field_is_required']
        }
      })

      return Object.keys(this.errors).length === 0
    },
  },

  mounted() {
    this.reset()
  }
}
</script>
