<template>
  <div class="forms-merge-tags forms-control">
    <component
      :disabled="disabled"
      :errors="errors"
      :is="component"
      :hint="hint"
      :required="required"
      :read-only="readOnly"
      :placeholder="placeholder"
      ref="subject"
      @blur="onBlur"
      @change="onChange"
      @input="onChange"
      v-model="iValue">
      <slot></slot>
      <template #suffix>
        <div class="group">
          <div 
            class="forms-merge-tags__figure"
            :class="{ '-is-disabled': disabled, '-is-read-only': readOnly }"
            @click="onIconClick">
            <ui-icon 
              class="forms-merge-tags__icon"
              glyph="braces"  
            />
          </div>

          <div 
            class="forms-merge-tags__figure -translation"
            :class="{ '-is-disabled': disabled, '-is-read-only': readOnly }"
            @click="onInteractiveIconClick"
            v-if="iconPost">
            <ui-icon 
              class="forms-merge-tags__icon"
              :glyph="iconPost"  
            />
          </div>
        </div>
      </template>
    </component>

    <popins-dropdown
      class="forms-merge-tags__mergetags"
      :position="$pepper.Position.BOTTOM_CENTER"
      :target="$refs.subject"
      :visible="showOptions"
      :width="width"
      v-click-outside="onClose"
      v-if="showOptions">
      <div class="forms-merge-tags__options">
        <div
          class="forms-merge-tags__group"
          :key="group.label"
          v-for="group in options">
          <h3 class="forms-merge-tags__title">{{ group.label }}</h3>

          <ul 
            class="forms-merge-tags__values"
            :key="`${group.label}_${option.value}`"
            v-for="option in group.options">
            <li 
              class="forms-merge-tags__value"
              @click="onOptionClick(option)">{{ option.name }}</li>
          </ul>
        </div>
      </div>
    </popins-dropdown>
  </div>
</template>

<script>
import { ClickOutside } from '@pepper/pepper'

export default {
  name: 'FormsMergeTags',
  
  directives: {
    ClickOutside
  },

  model: {
    event: 'change',
    prop: 'value'
  },

  props: {
    component: {
      default: 'forms-input'
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    errors: {
      type: Array
    },

    hint: {
      type: String,
    },

    iconPost: {
      type: String,
      default: null,
    },

    iconPostInteractive: {
      type: Boolean,
      default: false
    },

    mergeTags: {
      type: Object,
    },

    placeholder: {
      type: String
    },

    readOnly: {
      type: Boolean,
      default: false,
    },

    required: {
      type: Boolean,
      default: false,
    },

    value: {
      required: true
    }
  },

  data() {
    return {
      currentIndex: 0,
      key: 1,
      showOptions: false,
      iValue: '',
    }
  },

  computed: {
    options() {
      let ret = []

      try {
        let roots = Object.keys(this.mergeTags)

        const parse = (level) => {
          let subRet = []
          if(level.hasOwnProperty('rules')) {
            return subRet
          }

          Object.keys(level).forEach(l => {
            if(l === 'rules' || (l === 'name' && this.$basil.isString(level[l]))) {
              return
            }

            if(l === 'mergeTags') {
              subRet.push(...parse(level[l]))
            } else {
              subRet.push(level[l])
              if(level[l].hasOwnProperty('mergeTags')) {
                subRet.push(...parse(level[l].mergeTags))
              }
            }
          })

          return subRet
        }

        roots.forEach((root) => {
          let group = {
            label: this.mergeTags[root].name,
            options: []
          }
          group.options = [...parse(this.mergeTags[root])]
          ret.push(group)
        })
      } catch(e) {
        $console.error(e)
        return []
      }

      return ret.filter(r => r.options.length > 0)
    },

    width() {
      let ref = this.$refs.subject
      return this.key && `${ref.$el.getBoundingClientRect().width}px`
    },
  },

  methods: {
    onBlur(e) {
      this.currentIndex = e.currentTarget.selectionStart
    },

    onChange() {
      this.$emit('change', this.iValue)
    },

    inInput() {
      this.$emit('input', this.iValue)
      this.$emit('change', this.iValue)
    },

    onClose() {
      this.showOptions = false
    },

    onIconClick() {
      this.showOptions = true
    },

    onInteractiveIconClick() {
      this.$emit('iconClick')
    },

    onOptionClick(option) {
      if(this.readOnly || this.disabled) {
        return
      }

      if(this.$basil.isNil(this.iValue)) {
        this.iValue = ''
      }

      this.iValue = this.iValue.slice(0, this.currentIndex) + option.value + this.iValue.slice(this.currentIndex)
      this.currentIndex += option.value.length

      this.onChange()
    },

    updateScreen() {
      this.key++
    }
  },

  mounted() {
    window.addEventListener('resize', this.updateScreen)
    this.iValue = this.value

    if(this.$basil.isNil(this.iValue)) {
      this.iValue = ''
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.updateScreen)
  }
}
</script>
