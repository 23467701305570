<template>
  <layout-level1  
    v-bind="attrs">

    <div class="container">
      <!-- Views -->
      <div class="layout-level1-toc__grid">
        <router-link 
          v-for="(view, i) in views"
          :key="i"
          class="toc-item"
          :to="view.route">

          <div class="toc-item__body">
            <div class="toc-item__illustration">
              <ui-icon
                class="toc-item__icon" 
                :glyph="view.icon" />
            </div>

            <div class="toc-item__label">{{ view.label }}</div>
            <div class="toc-item__description">{{ view.description }}</div>
          </div>

          <footer class="toc-item__footer">
            <actions-button
              :appearance="$pepper.Appearance.SUBTLE"
              class="toc-item__cta"
              icon-post="arrow-right"
              :size="$pepper.Size.S"
              >{{ view.cta || $t('sayl.view') }}</actions-button>
          </footer>
        </router-link>
      </div>
    </div>

    <!-- Body -->
    <router-view :key="$route.path" />
  </layout-level1>
</template>

<script>
import { MixinACL, MixinHeader, MixinInheritance } from '@sayl/admin-common'

export default {
  name: 'LayoutLevel1TOC',

  mixins: [
    MixinACL,
    MixinHeader,
    MixinInheritance
  ],

  props: {
    views: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  computed:{
    allowed(){
      let ret = this.views;

      // Allowed route?
      ret.forEach(e => {
        let route = this.$router.resolve(e.route);
        if (route && route.route){
          route = route.route;
          let privilege = route.meta.privilege || this.$basil.sayl.VIEW;
          let resource = route.meta.resource;
          e.isAllowed = this.isAllowed(resource, privilege);
        }
      })

      ret = ret.filter(r => r.isAllowed === true);

      return ret;
    },

    attrs(){
      return Object.assign({}, this.$props, {
        class: 'layout-level1-toc'
      })
    }
  },
}
</script>