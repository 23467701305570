<template>
  <layout-modal 
    v-if="field !== null"
    v-bind="attrs"
    @close="onClose">
    <div class="flow">
      <component
        :disabled="disabled"
        :is="type"
        :key="lang"
        :placeholder="$t('sayl.placeholder')"
        :read-only="readOnly"
        v-bind="bind"
        v-on="listeners ? listeners : {}"
        v-for="lang in languages" 
        v-model="field[lang]"
      >{{ $t(`sayl.lang_${lang}`)}}</component>
    </div>

    <template v-slot:footer>
      <actions-button 
        @click="onClose"
      >{{ $t('sayl.close') }}</actions-button>
      
      <actions-button 
        appearance="primary" 
        @click="onConfirm"
      >{{ $t('sayl.confirm') }}</actions-button>
    </template>
  </layout-modal>
</template>

<script>
import { mapState } from 'vuex'

import { LayoutModal } from '../../layouts'

export default {
  name: 'ModalTranslate',

  model: {
    prop: 'field',
    event: 'change'
  },

  props: {
    bind: {
      type: Object,
      default: () => {
        return {}
      }
    },

    disabled: {
      type: Boolean,
      default: false
    },
    
    field: {
      type: Object,
      default: null
    },

    listeners: {
      default: null
    },

    readOnly: {
      type: Boolean,
      default: false
    },

    type: {
      type: String,
      default: 'forms-input'
    },

    visible: {
      type: Boolean,
      default: false
    },
  },

  components: {
    LayoutModal
  },

  computed: {
    ...mapState({
      edition: state => state['host'].edition
    }),

    attrs() {
      return {
        classes: {},
        visible: this.visible,
        title: this.$t('sayl.translation_modal_title'),
        closable: false,
      }
    },

    languages() {
      return this.edition.languages
    }
  },

  methods: {
    onConfirm() {
      this.$emit('close');
    },

    onClose() {
      this.$emit('close');
    }
  },
}
</script>
