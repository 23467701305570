<template>
  <layout-level1 v-bind="attrs">
    <template #filters>
      <ui-filters
        :actions="actions"
        :has-reset="false"
      />
    </template>

    <div class="layout-level1__container">
      <!-- <div class=""
        :key="`missingtranslation_group_${i}`"
        v-for="(category, i) in categories">
        {{ category.category }} -->

        <data-table
          :columns="columns"
          :rows="rows"
        />
      <!-- </div> -->

    </div>
  </layout-level1>
</template>

<script>
import DataTableCellInput from './data-table-cell-input'

export default {
  data() {
    return {
      values: []
    }
  },

  computed: {
    actions() {
      return [
        {
          label: 'Export to JSON',
          events: { click: () => this.onExport('json') }
        },
        // {
        //   label: 'Export to CSV',
        //   events: { click: () => this.onExport('csv') }
        // }
      ]
    },

    attrs() {
      return {
        title: 'Missing translations',
        description: 'Here is a list of all the missing translations found while your where navigating in the admin',
        icon: 'l8n'
      }
    },

    categories() {
      let ret = []

      this.values.forEach(v => {
        if(v.group === 'sayl' || v.group.includes('sayl-')){
          let f = ret.find(r => r.group === v.group)
          if(this.$basil.isNil(f)) {
            f = {
              group: v.group,
              keys: []
            }
            ret.push(f)
          }
          f.keys.push(v.key)
        }
      })

      return ret.sort((a, b) => a.keys.length - b.keys.length)
    },

    rows() {
      let ret = []
      this.categories.forEach((categ) => {
        categ.keys.forEach(key => {
          ret.push({
            fqn: `${categ.group }.${key}`,
            data: [
              { content: categ.group, },
              {
                component: DataTableCellInput,
                content: key,
              }
            ]
          })
        })
      })
      return ret
    },

    columns() {
      return [
        {
          fqn: '12345',
          label: 'Group'
        },
        {
          fqn: '123454',
          label: 'Key'
        }
      ]
    }
  },

  methods: {
    onExport(format) {
      let ret = {}
      switch(format) {
        case 'json': 
          this.categories.forEach((categ) => {
            ret[categ.group] = {}
            categ.keys.forEach(key => {
              ret[categ.group][key] = ""
            })
          })
          break;
      }

      let url = window.URL.createObjectURL(new Blob([JSON.stringify(ret)]))
      let link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `missing-translation.${format}`)
      document.body.appendChild(link)
      link.click()
      sessionStorage.removeItem('missing_translations')
      this.reset()
    },

    reset() {
      let missingTranslations = sessionStorage.getItem('missing_translations')
      this.values = this.$basil.isNil(missingTranslations) ? 
        [] : missingTranslations = JSON.parse(missingTranslations)
    }
  },

  mounted() {
    this.reset()
  },
}
</script>
