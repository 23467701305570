import { CurryHostDatalayer } from '@curry/host'
import {basil} from '@spices/basil'

/**
 * Association controller
 * for @sayl/host
 * @class
 */
export default class HostController {
  /**
   * @constructor
   * @param {Object} options
   * @param {Object} options.curry - Curry Controller 
   * @param {Object} options.fqn  
   * @param {Object} options.module 
   * @param {Object} options.store
   */
  constructor({ store, transports, i18n }) {
    this._curry = new CurryHostDatalayer({ transports }).controllers[0]
    this._store = store
    this._i18n = i18n
  }

  findById() {
    return new Promise((resolve, reject) => {
      this._curry.findById({})
        .then((host) => {
          this._store.commit('host/setEdition', host)
          this._store.commit('host/setOrigin', host)
          resolve()
        })
        .catch(e => reject(e))
    })
  }

  clone(payload) {
    return this._curry.clone(payload)
  }

  sync(payload) {
    return this._curry.sync(payload)
  }

  findChildren(payload) {
    return new Promise((resolve, reject) => {
      this._curry.findChildren(payload)
        .then((c) => {
          this._store.commit('host/setChildren', c)
          resolve(this.children = c)
        })
        .catch((e) => reject(e))
    })
  }

  startImpersonation(payload){
    return this._curry.startImpersonation(payload)
  }

  stopImpersonation(payload){
    return this._curry.stopImpersonation(payload)
  }

  updateLanguages(payload) {
    return this._curry.updateLanguages(payload)
  }

  addCurrency(payload) {
    return new Promise((resolve, reject) => {
      this._curry.addCurrency(payload)
        .then((c) => this.findById())
        .then((c) => resolve())
        .catch((e) => reject(e))
    })
  }

  removeCurrency(payload) {
    return new Promise((resolve, reject) => {
      this._curry.removeCurrency(payload)
        .then((c) => this.findById())
        .then((c) => resolve())
        .catch((e) => reject(e))
    })
  }

  setDefaultCurrency(payload) {
    return new Promise((resolve, reject) => {
      this._curry.setDefaultCurrency(payload)
        .then((c) => {
          basil.i18n.defaultCurrency = payload.code
          let number = []
          Object.keys(this._i18n.i18n.numberFormats).forEach((c) => {
            number.push({ iso: c, currency: { style: 'currency', currency: payload.code }})
          })
          this._i18n.setConfig({ number })
          this.findById()
        })
        .then((c) => resolve())
        .catch((e) => reject(e))
    })
  }
}
