<template>
  <div>
    <forms-file
      :key="key"
      :accept="accept"
      :multiple="multiple"
      :read-only="readOnly"
      size="s"
      :loading="loading"
      :sortable="sortable"
      @clear="onClear"
      @input="onChange"
      @sort="onSort"
      v-model="model"
    >{{ title }}</forms-file> 

    <forms-label 
      intent="danger"
      v-if="hasErrors"
    >{{ errors[0] }}</forms-label>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { MixinList } from '@sayl/admin-common'

export default {
  name: 'FormsImageInput',

  inject: ['$image'],
  
  mixins: [MixinList],

  props: {
    accept: {
      type: String,
      default: 'image/*'
    },

    entityId: {
      type: String,
    },

    entityType: {
      required: true,
      type: String,
    },

    errors: {
      type: Array || null,
      default: null
    },

    fieldName: {
      required: true,
      type: String
    },

    imageId: {
      default: null,
      type: String
    },

    loading: {
      default: false,
      type: Boolean
    },

    module: {
      required: true,
      type: String,
    },

    multiple: {
      default: false,
      type: Boolean,
    },

    preview: {
      default: null,
      type: String,
    },

    readOnly: {
      default: false,
      type: Boolean,
    },

    sortable: {
      default: true,
      type: Boolean,
    },

    title: {
      default: null,
      type: String,
    },
  },

  data() {
    return {
      innerLoading: false,
      key: 1,
    }
  },

  watch: {
    loading() {
      this.key++
    }
  },

  computed: {
    ...mapState({
      baseState: state => state
    }),

    actions() {
      return !this.readOnly && this.multiple ? [
        {
          action: this.onRemove,
          label: this.$t('sayl.delete'),
          mode: this.$pepper.Action.SOME + this.$pepper.Action.PAGE,
        },
      ] : []
    },

    data() {
      return { 
        entityId: this.entityId,
        entityType: this.entityType,
        fieldName: this.fieldName,
        module: this.module,
      }
    },

    hasErrors() {
      return !this.$basil.isNil(this.errors) && this.errors.length > 0
    },

    model: {
      get() {
        let ret = !this.loading ? this.edition.map(i => this.$basil.get(i, 'image.all', {})) : []
        let ed = this.multiple ? ret : this.$basil.get(ret, '0')
        
        if(!this.isMultiple && !this.$basil.isNil(this.imageId)) { 
          ed = (!this.loading ? this.edition : []).find(img => img.id === this.imageId)

          if(ed) {
            ed = ed.image.all
          }
        }

        return ed
      },
      set(values) {
        if(this.edition[0] == null) {
          this.$image.create({ data: this.data })
            .then((l) => {
              l.image.all = values;
              l.image.all.transferred = true;
              this.edition.push(l)
            })  
            .catch((e) => $console.error(e))
        }
      }
    },
    
    edition() {
      return this.$basil.get(this.state, `${this.entityType}.${this.fieldName}.all`, []) || []
    },

    pristine() {
      return JSON.stringify(this.editionImages) === JSON.stringify(this.originImages)
    },

    select() {
      return this.actions.length > 0 ? 
        this.$pepper.Selection.PAGE: null
    },

    state() {
      return this.$basil.get(this.baseState, this.module, null)
    },
  },

  methods: {
    remove(values) {
      let payload = {
        data: this.data,
        images: this.edition,
        values
      }

      this.$emit('remove', payload)
    },

    onChange(values) {
      values = !this.$basil.isArray(values) ? [values] : values
      values = values.filter(v => !this.$basil.isNil(v))

      if(values.length >= this.edition.length) {
        let newImgs = values.filter(i => typeof i.src !== 'string')
        let payload = {
          data: this.data,
          images: this.edition,
          multiple: this.multiple,
          values: newImgs,
        }
        this.$emit('change', payload)
      }
    },

    onClear(value) {
      let payload = {
        data: this.data,
        images: this.edition,
        values: this.$basil.isArray(value) ? value : [value]
      }

      this.$confirm({
        primaryAction: this.$t('sayl.confirm'), 
        secondaryAction: this.$t('sayl.cancel'), 
        title: this.$t('sayl.delete_modal_title'),
        description: this.$t('sayl.delete_modal_message'),
        onPrimary: () => this.$emit('clear', payload)
      })
    },

    onSort(values) {
      let payload = {
        data: this.data,
        images: this.edition,
        values,
      }

      this.$emit('sort', payload)
    },
  },
}
</script>
