import { basil } from '@spices/basil'

export default class Tier {
  constructor({ created = null, events_retention = 0, excluded_resources = [], id = null, modified = null, name = null, next_tier = null, priority = 0, is_private = false, }) {
    try {
      this._created = created ? new Date(created) : null
    } catch(e) {
      console.error('Tier:contructor:Created:', e.message)
    }
    this._eventsRetention = events_retention
    this._excludedResources = excluded_resources
    this._id = id
    try {
      this._modified = modified ? new Date(modified) : null
    } catch(e) {
      console.error('Tier:contructor:Modified:', e.message)
    }
    this._name = name
    this._nextTier = next_tier
    this._priority = priority
    this._isPrivate = is_private
  } 

  /////////////////////////////////////////
  /// Getters
  get created() {
    return this._created
  }

  get eventsRetention() {
    return this._eventsRetention
  }

  get excludedResources() {
    return this._excludedResources
  }

  get id() {
    return this._id
  }

  get modified() {
    return this._modified
  }

  get name() {
    return this._name
  }

  get nextTier() {
    return this._nextTier
  }

  get priority() {
    return this._priority
  }

  get isPrivate() {
    return this._isPrivate
  }

  /////////////////////////////////////////
  /// Methods
  isResourceExcluded(resource) {
    let r = this.excludedResources.find(er => er.name === resource)
    
    if(!basil.isNil(r) && (basil.isNil(r.features) || basil.isEmpty(r.features))) {
      return true
    } else if(!basil.isNil(r) && !basil.isNil(r.features) && !basil.isEmpty(r.features)) {
      return false
    }

    return false
  }

  isFeatureExcluded(resource, feature) {
    let r = this.excludedResources.find(er => er.name === resource)
    
    if(!basil.isNil(r) && (basil.isNil(r.features) || basil.isEmpty(r.features))) {
      return true
    } else if(!basil.isNil(r) && !basil.isNil(r.features) && !basil.isEmpty(r.features)) {
      let f = r.features.find(f => f.name === feature)
      return !basil.isNil(f)
    }

    return false
  }
}