import cards from './cards'
import general from './general'
import header from './header'
import how from './how'
import ways from './ways'
import usage from './usage'
import history from './history'
import tiers from './tiers'
import referral from './referral'

export default {
  cards,
  general,
  header,
  how,
  ways,
  usage,
  tiers,
  history,
  referral,

  all: [
    ...cards,
    ...general,
    ...header,
    ...how,
    ...ways,
    ...tiers,
    ...usage,
    ...history,
    ...referral,
  ]
}
