const base = [
  {
    component: 'forms-input',
    fqn: 'ways-style-primary-bg',
    label: 'bg_primary',
    property: '--slp-ways-primary-bg',
    section: 'ways',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'ways-style-secondary-bg',
    label: 'bg_secondary',
    property: '--slp-ways-secondary-bg',
    section: 'ways',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'ways-style-filigrane',
    label: 'filigrane_stroke',
    property: '--slp-ways-filigrane-bg',
    section: 'ways',
    subtype: 'color',
    type: 'style',
  },
]

const title = [
  {
    component: 'forms-input',
    fqn: 'ways-content-title',
    label: 'title',
    property: 'title',
    section: 'ways',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'ways-style-title-fc',
    label: 'fc',
    property: '--slp-ways-title-fc',
    section: 'ways',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-select',
    fqn: 'ways-style-title-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-ways-title-fs',
    section: 'ways',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component: 'forms-select',
    fqn: 'ways-style-title-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-ways-title-fw',
    section: 'ways',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const description = [
  {
    component: 'forms-textarea',
    fqn: 'ways-content-descr',
    label: 'description',
    property: 'description',
    section: 'ways',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'ways-style-descr-fc',
    label: 'fc',
    property: '--slp-ways-descr-fc',
    section: 'ways',
    subtype: 'color',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'ways-style-descr-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-ways-descr-fs',
    section: 'ways',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'ways-style-descr-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-ways-descr-fw',
    section: 'ways',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const item = [
  {
    addable: false,
    fqn: 'ways-content-items',
    label: 'item',
    logged: false,
    property: 'content',
    removable: false,
    section: 'ways',
    type: 'array',
    children: [
      {
        component: 'forms-checkbox',
        fqn: 'ways-content-item-active',
        label: 'active',
        property: 'content',
        section: 'ways',
        subproperty: 'active',
        subtype: 'boolean',
        type: 'content',
      },
      {
        component: 'forms-input',
        fqn: 'ways-content-item-title',
        label: 'title',
        property: 'content',
        section: 'ways',
        subproperty: 'title',
        subtype: 'text',
        type: 'content',
      },
      {
        component: 'forms-textarea',
        fqn: 'ways-content-item-description',
        label: 'description',
        property: 'content',
        section: 'ways',
        subproperty: 'description',
        subtype: 'text',
        type: 'content',
      },
      {
        component: 'forms-textarea',
        fqn: 'ways-content-item-reward',
        label: 'reward',
        property: 'content',
        section: 'ways',
        subproperty: 'reward',
        subtype: 'text',
        type: 'content',
      },
      {
        component: 'forms-image-input',
        fqn: 'ways-content-item-image',
        label: 'image',
        subproperty: 'image',
        section: 'ways',
        property: 'content',
        subtype: 'image',
        type: 'content',
      },
    ]
  },
]

const itemBase = [
  {
    component: 'forms-input',
    fqn: 'ways-style-item-bg',
    label: 'bg',
    property: '--slp-ways-item-bg',
    section: 'ways',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'ways-style-item-bc',
    label: 'bc',
    property: '--slp-ways-item-bc',
    section: 'ways',
    subtype: 'color',
    type: 'style',
  },
]

const itemTitle = [
  {
    component: 'forms-input',
    fqn: 'ways-style-item-title-fc',
    label: 'fc',
    property: '--slp-ways-item-title-fc',
    section: 'ways',
    subtype: 'color',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'ways-style-item-title-fs',
    options: 'fontSizes',
    label: 'fs',
    property: '--slp-ways-item-title-fs',
    section: 'ways',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'ways-style-item-title-fw',
    options: 'fontWeights',
    label: 'fw',
    property: '--slp-ways-item-title-fw',
    section: 'ways',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const itemDescr = [
  {
    component: 'forms-input',
    fqn: 'ways-style-item-descr-fc',
    label: 'fc',
    property: '--slp-ways-item-descr-fc',
    section: 'ways',
    subtype: 'color',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'ways-style-item-descr-fs',
    options: 'fontSizes',
    label: 'fs',
    property: '--slp-ways-item-descr-fs',
    section: 'ways',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'ways-style-item-descr-fw',
    options: 'fontWeights',
    label: 'fw',
    property: '--slp-ways-item-descr-fw',
    section: 'ways',
    subtype: 'fontWeight',
    type: 'style',
  },
]

export default [
  ...base,
  ...title,
  ...description,
  ...item,
  ...itemBase,
  ...itemTitle,
  ...itemDescr,
]
