<template>
  <ui-card 
    :appearance="$pepper.Appearance.SUBTLE"
    article
    class="ui-vendor-references"
    v-if="value.length > 0">
    <template #header>
      <h3 class="ui-card__title">{{ $t('sayl.vendor_references_title') }}</h3>
    </template>

    <article 
      class="ui-card__article"
      :key="`vendor_${i}`" 
      v-for="(v, i) in value">

      <forms-input
        icon-post="copy"
        icon-post-interactive
        read-only
        :value="v.vendor_reference"
        @iconClick="() => onIconClick(v.vendor_reference)"
      >{{ $t(`sayl.vendor_reference_${v.vendor_slug}`) }}</forms-input>

    </article>
  </ui-card>
</template>

<script>
export default {
  name: 'UiVendorReferences',

  props: {
    value: {
      type: Array,
      required: true,
    }
  },

  methods: {
    onIconClick(value){
      if(navigator.clipboard) {
        navigator.clipboard.writeText(value)
          .then(() => {
            this.$notification({ 
              message: this.$t('sayl.copy_to_clipboard_message'), 
              title: this.$t('sayl.copy_to_clipboard'), 
              type: 'success' 
            })
          })
          .catch((e) => $console.error('failed to copy', e))
      } else {
        let tempInput = document.createElement("input")
        tempInput.setAttribute("style", "opacity: 0; position: fixed; top: 0; z-index: -1")
        tempInput.value = value || null
        document.body.appendChild(tempInput)
        tempInput.select()
        document.execCommand("copy")
        this.$notification({ title: this.$t('sayl.copy_confirm_title'), type: 'success' })
        document.body.removeChild(tempInput)
      }
    }
  },
}
</script>
