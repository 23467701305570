<template>
  <div 
    style="display: contents;" 
    v-if="!isLockedByInvoice">
    <slot />
  </div>

  <div 
    class="ui-feature" 
    v-else>
    <div class="ui-feature__body">
      <div class="ui-feature__body">
        <div class="ui-feature__figure">
          <img 
            alt="feature-locked"
            :src="$getImageSrc('/statics/images/figures/feature-locked.png')" 
          />
        </div>

        <div class="ui-feature__heading">
          <h3 class="ui-feature__title">{{ $t(`sayl-invoice.tier_locked_title`) }}</h3>
          <div class="ui-feature__description">{{ $t(`sayl-invoice.tier_locked_description`) }}</div>
        </div>

        <div class="ui-feature__actions">
          <!-- <actions-button
            class="ui-feature__action"
            icon-post="open"
            @click="onLearnMore"
          >{{ $t('sayl-invoice.learn_more') }}</actions-button> -->

          <!-- <actions-button
            class="ui-feature__action"
            icon-post="open"
            @click="onContactUs"
          >{{ $t('sayl-invoice.contact_us') }}</actions-button> -->

          <actions-button
            :appearance="$pepper.Appearance.PRIMARY"
            class="ui-feature__action"
            @click="onLearnMore"
          >{{ $t('sayl-invoice.upgrade') }}</actions-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MixinInvoicing } from '@sayl/admin-common'

export default {
  mixins: [
    MixinInvoicing,
  ],
}
</script>
