<template>
  <layout-modal-step-item v-bind="attrs">
    
    <!-- Loading -->
    <div 
      class="layout-modal-steps__loader" 
      v-if="loading">
      <ui-loader />
    </div>

    <div 
      class="layout-modal__article" 
      v-if="!loading">
      
      <div class="modal-reward__types">
        <div 
          :class="{
            'modal-reward__type': true,
            '-is-selected': type.value === value.type
          }"
          :key="`reward_type_${type.value}`"
          v-for="type in $audiences.RewardTypes.ALL"
          @click="() => onTypeSelect(type)">

          <h3 class="modal-reward__subtitle">{{ $t(type.label) }}</h3>
          <p class="modal-reward__description">{{ $t(type.description) }}</p>

          <div class="modal-reward__selected"> 
            <ui-icon glyph="check" />
          </div>
        </div>
      </div>
    </div>

    <template v-slot:footer>
      <actions-button
        @click="$attrs.close"
      >{{ $t('sayl.close') }}</actions-button>

      <actions-button
        appearance="primary"
        icon-post="arrow-right"
        :disabled="!isValid"
        @click="onConfirm"
      >{{ $t('sayl.next') }}</actions-button>
    </template>
  </layout-modal-step-item>
</template>

<script>
import { MixinError } from '@sayl/admin-common'

export default {
  name: 'ModalChallengeRewardStep1',

  mixins: [ MixinError,],

  data() {
    return {
      errors: {},
      loading: true,
    }
  },

  computed: {
    attrs() {
      return Object.assign({
        title: this.$t('sayl-audiences.challenge_reward_step_1')
      }, this.$attrs)
    },

    value() {
      return this.$attrs.aggregate
    },

    isValid() {
      return [
        !(this.$basil.isNil(this.value.type) || this.$basil.isEmpty(this.value.type))
      ].filter(r => r === false).length === 0
    },
  },

  methods: { 
    onConfirm() {
      this.isValid && this.$attrs.next(this.value)
    },

    onTypeSelect(type) {
      if(this.value.type !== type.value) {
        this.value.type = type.value
  
        switch(this.value.type) {
          case 'loyalty': 
            this.value.settings = { credits_amount: null }
            break
  
          case 'nft': 
            this.value.settings = { 
              nft_id: null, 
              nft_image: null, 
              nft_attributes: [] 
            }
            break
  
          case 'voucher': 
            this.value.settings = {
              voucher_type: null,
              voucher_template_id: null,
              voucher_validity_period: null,
              voucher_amount: null,
            }
            break
        }
      }
    },

    reset() {
      this.errors = {}
      this.loading = false
    },
  },

  mounted() {
    this.reset()
  }
}
</script>
