import { Store, StoresStates } from '@spices/basil-sayl'

export default Store('tier', {
  fqn: 'tier',
  
  getters: {
    pristine: [
      'tier',
      'reward',
    ]
  },

  state: [
    {
      fqn: 'tier',
      options: { sort: true },
      builder: StoresStates.DEFAULT_STATE,
      items: [
        {
          fqn: 'embeds',
          builder: StoresStates.ALL_STATE,
        },
        {
          fqn: 'loyalty',
          builder: StoresStates.DEFAULT_STATE,
        },
        {
          fqn: 'perkTypes',
          builder: StoresStates.ALL_STATE,
        },
        {
          fqn: 'segments',
          builder: StoresStates.ALL_STATE,
        },
        {
          fqn: 'vouchers',
          builder: StoresStates.ALL_STATE,
        },
      ]
    },
    {
      fqn: 'reward',
      options: {},
      builder: StoresStates.DEFAULT_STATE,
    }
  ]
})
