const base = [
  {
    component: 'forms-input',
    fqn: 'tiers-style-bg',
    label: 'bg_primary',
    property: '--slp-tiers-bg',
    section: 'tiers',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'tiers-style-items-bg',
    label: 'bg_secondary',
    property: '--slp-tiers-items-bg',
    section: 'tiers',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'tiers-style-filigrane',
    label: 'filigrane_stroke',
    property: '--slp-tiers-filigrane-bg',
    section: 'tiers',
    subtype: 'color',
    type: 'style',
  },
]

const title = [
  {
    component: 'forms-input',
    fqn: 'tiers-content-title',
    label: 'title',
    property: 'title',
    section: 'tiers',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'tiers-style-title-fc',
    label: 'fc',
    property: '--slp-tiers-title-fc',
    section: 'tiers',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-select',
    fqn: 'tiers-style-title-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-tiers-title-fs',
    section: 'tiers',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component: 'forms-select',
    fqn: 'tiers-style-title-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-tiers-title-fw',
    section: 'tiers',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const descr = [
  {
    component: 'forms-textarea',
    fqn: 'tiers-content-descr',
    label: 'description',
    property: 'description',
    section: 'tiers',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'tiers-style-descr-fc',
    label: 'fc',
    property: '--slp-tiers-descr-fc',
    section: 'tiers',
    subtype: 'color',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'tiers-style-descr-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-tiers-descr-fs',
    section: 'tiers',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'tiers-style-descr-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-tiers-descr-fw',
    section: 'tiers',
    subtype: 'fontWeight',
    type: 'style',
  },
]

// Item
const item = [
  {
    component: 'forms-input',
    fqn: 'tiers-style-item-bg',
    label: 'bg',
    property: '--slp-tiers-item-bg',
    section: 'tiers',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'tiers-style-item-bc',
    label: 'bc',
    property: '--slp-tiers-item-bc',
    section: 'tiers',
    subtype: 'color',
    type: 'style',
  },
]

const itemTitle = [
  {
    component: 'forms-input',
    fqn: 'tiers-style-item-title-fc',
    label: 'fc',
    property: '--slp-tiers-item-title-fc',
    section: 'tiers',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-select',
    fqn: 'tiers-style-item-title-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-tiers-item-title-fs',
    section: 'tiers',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component: 'forms-select',
    fqn: 'tiers-style-item-title-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-tiers-item-title-fw',
    section: 'tiers',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const itemDescr = [
  {
    component: 'forms-input',
    fqn: 'tiers-style-item-descr-fc',
    label: 'fc',
    property: '--slp-tiers-item-descr-fc',
    section: 'tiers',
    subtype: 'color',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'tiers-style-item-descr-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-tiers-item-descr-fs',
    section: 'tiers',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'tiers-style-item-descr-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-tiers-item-descr-fw',
    section: 'tiers',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const rewarded = [
  {
    component: 'forms-input',
    fqn: 'tiers-style-selected-c',
    label: 'rewarded_color',
    subtype: 'color',
    logged: true,
    type: 'style',
    section: 'tiers',
    property: '--slp-tiers-item-selected-c',
  },
  {
    component: 'forms-input',
    fqn: 'tiers-style-icon-award-bg',
    label: 'bg',
    subtype: 'color',
    logged: true,
    type: 'style',
    section: 'tiers',
    property: '--slp-icon-award-bg',
  },
  
  {
    component: 'forms-input',
    fqn: 'tiers-style-icon-award-stroke',
    label: 'stroke',
    subtype: 'color',
    logged: true,
    type: 'style',
    section: 'tiers',
    property: '--slp-icon-award-stroke',
  },
  {
    component: 'forms-input',
    fqn: 'tiers-style-icon-award-shadow-primary-bg',
    label: 'shadow_primary',
    subtype: 'color',
    logged: true,
    type: 'style',
    section: 'tiers',
    property: '--slp-icon-award-shadow-primary-bg',
  },
  {
    component: 'forms-input',
    fqn: 'tiers-style-icon-award-shadow-secondary-bg',
    label: 'shadow_secondary',
    subtype: 'color',
    logged: true,
    type: 'style',
    section: 'tiers',
    property: '--slp-icon-award-shadow-secondary-bg',
  },
]

export default [
  ...base,
  ...title,
  ...descr,
  
  // Item
  ...item,
  ...itemTitle,
  ...itemDescr,
  ...rewarded,
]
