const getState = (state, name) => {
  let s = name.split('.')
  let ret = null

  s.forEach((l) => {
    if(ret == null) { 
      ret = state[l]
    } else {
      ret = ret[l]
    }
  })
  return ret
}

export default {
  namespaced: true,

  state: {
    errors: {},
    all: null,
    loading: true,
    collection: null,
    invalid: true,
    saving: false,
    pagination: null,
    edition: null,
    origin: null,
  },

  mutations: {
    all: (state, { name, values }) => {
      let s = getState(state, name)
      if(s){
        s.all = values
      }
    },

    collection: (state, { name, values, meta = null }) => {
      let s = getState(state, name)
      if(s) {
        s.collection = values

        if(!basil.isNil(meta) && !basil.isNil(meta.pagination)) {
          let p = basil.get(meta, 'pagination', {})

          s.pagination = {
            currentIndex:  p.page ? p.page - 1 : parseInt(p.current_page) - 1,
            currentPage: p.page ? p.page : parseInt(p.current_page),
            total: p.total,
            pageSize: p.pageSize ? p.pageSize : (p.per_page ? p.per_page : p.pageSize) || 25,
            pageSizes: [10,25,35,50,100],
          }
        }
      }
    },

    invalid: (state, { name, value }) => {
      let s = getState(state, name)
      if(s) {
        s.invalid = value
      }
    },

    edition: (state, { name, value }) => {
      let s = getState(state, name)
      if(s){
        if(value == null || value.copy == null) {
          s.edition = value
        } else {
          s.edition = value.copy()
        }
      }
    },

    origin: (state, { name, value }) => {
      let s = getState(state, name)
      if(s){
        if(value == null || value.copy == null) {
          s.origin = value
        } else {
          s.origin = value.copy()
        }
      } 
    },

    errors: (state, { name, status = 422, errors }) => {
      let s = getState(state, name)
      if(s) {
        s.errors = { status: status, items: errors }
      }
    },

    loading: (state, { name, value }) => {
      let s = getState(state, name)
      if(s) {
        s.loading = value
      }
    }
  },
}
