<template>
  <article 
    :class="classes"
    @click="onClick">
    <!-- Illustration -->
    <div class="a-preview__illustration">
      <ui-icon :glyph="icon" />
    </div>

    <!-- Body -->
    <div class="a-preview__body">
      <div 
        v-if="legend"
        class="a-preview__description">{{ legend }}</div>
      <div class="a-preview__phrase">
        <component
          v-for="(block, i) in blocks"
          :key="i"
          :is="block.type"
          v-bind="block.bind">{{ block.value }}</component>
      </div>
    </div>

    <!-- Actions -->
    <div 
      class="a-preview__actions"
      v-if="!readOnly">
      <actions-button
        class="a-preview__edit"
        :size="$pepper.Size.S"
        @click="$emit('edit', values)">{{ $t('sayl.edit') }}</actions-button>
        
      <actions-button
        class="a-preview__more"
        v-if="hasActions"
        ref="condition"
        :size="$pepper.Size.S"
        icon-pre="dot-dot-dot"
        @click.stop.prevent="showPopin"/>
    </div>

    <!-- Extra actions -->
    <popins-list-dropdown
      :target="currentGroup"
      position="bottom-center"
      :visible="showActions"
      :layout="['label']"
      :options="actions"
    />
  </article>
</template>

<script>
import Mustache from 'mustache'

export default {
  name: 'AudienceConditionPreview',

  props: {
    actions: {
      type: Array,
      default: () => []
    },

    entity: {
      type: String,
      values: ['action', 'branch', 'condition', 'event', 'email'],
      default: 'action'
    },

    icon: {
      type: String
    },

    legend: {
      type: String
    },

    readOnly: {
      type: Boolean,
      default: false,
    },

    template: {
      type: String
    },

    values: {
      type: Object
    }
  },

  data() {
    return {
      showActions: false,
      currentGroup: null,
    }
  },

  computed: {
    blocks(){
      let render = Mustache.parse(this.template, ['{{', '}}'])
      return render.map(([type, name, start, stop]) => {
        let r = {}

        // Dynamic value
        if (type === 'name'){
          r.bind = {
            class: 'a-preview__tag',
            hue: this.hue
          }
          r.type = 'data-tag'
          r.value = this.$basil.get(this.values, name, '')
        }

        // Static value
        else{
          r.bind = {
            class: 'a-preview__label'
          }
          r.type = 'span'
          r.value = this.template.substring(start, stop)
        }

        // The value is an array
        if (basil.isArray(r.value)){
          r = r.value.map(e => {
            let i = Object.assign({}, r)
            i.value = e

            return i
          })
        }

        return r
      }).flat()
    },

    classes(){
      return {
        'a-preview': true,
        [`-hue-${this.hue}`]: true
      }
    },

    hasActions() {
      return this.actions && this.actions.length > 0
    },

    hue(){
      let ret = this.$pepper.Hue.BLUE;

      switch(this.entity){
        case 'action': 
          ret = this.$pepper.Hue.TEAL;
          break;
        case 'branch':
          ret = this.$pepper.Hue.ORANGE;
          break;
        case 'condition':
          ret = this.$pepper.Hue.YELLOW;
          break;
        case 'event':
          ret = this.$pepper.Hue.BLUE;
          break;
        case 'email':
          ret = this.$pepper.Hue.GREEN;
          break;
      }

      return ret;
    }
  },

  methods: {
    onClick(){
      this.$emit('click')
    },

    showPopin() {
      let target = this.$refs['condition'];
      let same = this.currentGroup === target;
      
      this.showActions = !same;
      this.currentGroup = same ? null : target;

      this.$emit('select', !same)
    },

    hidePopin() {
      this.showActions = false;
      this.currentGroup = null;
    }
  }
}
</script>
