<template>
  <!-- Search -->
  <div 
    class="n-primary-search"
    v-click-outside="onClickOutside">
    <forms-search
      :autofocus="false"
      :appearance="$pepper.Appearance.SUBTLE"
      :autocomplete="false"
      class="n-primary-search__form"
      :placeholder="$t('sayl.search_placeholder')"
      ref="search"
      :size="$pepper.Size.M"
      :value="search"
      @blur="() => focused = false"
      @focus="onFocus"
      @mouseenter="focused === true ? onFocus : () => {}"
      @search="onInput"
      v-if="opened"
    />

    <div 
      class="n-primary-search__icon" 
      v-else>
      <ui-icon glyph="search" />
    </div>

    <div
      class="n-primary-search__popin"
      v-if="!$basil.isNil(target)">
      <!-- :visible="" -->
      <ul class="n-primary-search__list" v-if="searchList.length > 0">
        <li
          class="n-primary-search__item" 
          :key="item.fqn"
          v-for="item in searchList"
          @click="onClick(item)">
          <span class="n-primary-search__figure" v-if="item.logo">
            <img
              :alt="item.label"
              class="n-primary-search__logo" 
              :src="$getImageSrc(item.logo)"
            />
          </span>

          <span class="n-primary-search__figure" v-if="item.translatableLogo">
            <img
              :alt="item.label"
              class="n-primary-search__logo" 
              :src="$getImageSrc($t(item.translatableLogo))"
            />
          </span>

          <span class="n-primary-search__figure" v-if="!item.logo && item.icon">
            <ui-icon 
              class="n-primary-search__item-icon"
              :glyph="item.icon" 
            />
          </span>

          <p class="n-primary-search__label">{{ item.label }}</p>
        </li>
      </ul>

      <div class="n-primary-search__empty" v-if="searchList.length === 0">
        <h3 class="n-primary-search__empty -title">{{ $t('sayl.nav_search_empty') }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Navigation from './navigation/index'
import { ClickOutside } from '@spices/pepper'

export default {
  name: 'PrimarySearch',

  directives: {
    ClickOutside
  },

  mixins: [ Navigation ],

  data(){
    return {
      search: null,
      target: null,
      immutable: false,
      debounce: null,
      focused: false,
      key: 1,
    }
  },

  props: {
    /**
     * @property {Boolean} opened
     *  Whether or not the whole navigation is opened (vs closed)
     */
    opened: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    ...mapGetters({
      config: 'sayl/config',
      user: 'sayl/user',
    }),
    
    searchList() {
      let stored = localStorage.getItem('navigation')
      if(stored) {
        stored = JSON.parse(stored)
      } else {
        stored = []
      }

      let ret = null

      let storedRet = stored.map(item => {
        let r = this.fullNavigation.find(n => n.fqn === item)
        return r ? this.getSearchEntry(r) : null
      }).filter(i => !this.$basil.isNil(i))

      if(this.search) {
        ret = this.fullNavigation
          .filter(n => this.getSearchEntry(n).label.toLowerCase().includes(this.search.toLowerCase()))
          .filter(n => !this.$basil.isNil(n.href))
          .map(n => this.getSearchEntry(n))
      }

      return this.key && (ret ? ret : storedRet)
    },
  },

  watch: {
    opened: {
      immediate: true,
      handler() {
        if(this.opened === false) {
          this.onBlur()
        }
      }
    },

    '$route.name': {
      immediate: true,
      handler() {
        this.key++
      }
    }
  },

  methods: {
    getSearchEntry(item) {
      const getParent = (item) => {
        let parent = item.parent
        if(!this.$basil.isNil(parent)) {
          if(!this.$basil.isNil(parent.parent)) {
            parent = getParent(parent)
          }
        }

        return parent
      }

      let p = getParent(item)

      return {
        ...item,
        logo: p ? p.logo : item.logo,
        label: this.$t(item.label)
      }
    },

    onClickOutside() {
      if(!this.immutable) {
        this.target = null
      }
    },
    
    /**
     * A click on an entry.
     * @method
     * @param {Object} entry
     */
    onClick(entry){
      if (entry.href){
        this.$router.push(entry.href).catch(() => {});
      }
      
      this.$emit('selection', entry)
    },

    onBlur() {
      this.target = null
    },

    onInput(value) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.search = value
        this.onFocus()
      }, 250)
    },

    onFocus() {
      clearTimeout(this.debounce)
      this.immutable = true
      this.debounce = setTimeout(() => {
        this.immutable = false
      }, 250)

      this.focused = true
      this.target = this.$refs.search
      this.$emit('focus')
    },

    onKeyUp(event) {
      let k = event.keyCode
      
      switch(k) {
        case 27:
          this.onClickOutside()
          this.onBlur()
          document.activeElement.blur()
          break;
      }
    },

    reset() {
      this.search = null
      this.target = null

      window.addEventListener('keyup', this.onKeyUp)
    },
  },

  mounted(){
    this.reset()
  },

  onBeforeUnmount() {
    window.removeEventListener('keyup', this.onKeyUp)
  }
}
</script>

