<template>
  <nav :class="classes">

    <!-- Ghost -->
    <div class="nav-categories__ghost">
      <!-- Back -->
      <actions-button
        v-if="back" 
        :appearance="$pepper.Appearance.SUBTLE"
        class="nav-categories__back" 
        icon-pre="arrow-left">{{ back }}</actions-button>

      <!-- Categories -->
      <ul 
        ref="categories"
        class="nav-categories__categories">
        <li 
          v-for="(c, i) in categories"
          :key="i"
          class="nav-categories__category"
          :class="{ '-is-active': isCurrentCategory(c) }">
          <button class="nav-categories__item">{{ c.label }}</button>
        </li>
      </ul>

      <!-- More -->
      <div class="nav-categories__more">
        <actions-button 
          class="nav-categories__more-cta"
          :appearance="$pepper.Appearance.SUBTLE"
          icon-post="angle-down">+99</actions-button>
      </div>
    </div>


    <!-- Inner -->
    <div class="nav-categories__inner">
      <!-- Back -->
      <actions-button
        v-if="back" 
        :appearance="$pepper.Appearance.SUBTLE"
        class="nav-categories__back" 
        icon-pre="arrow-left"
        @click="$emit('back')">{{ back }}</actions-button>

      <!-- Categories -->
      <ul class="nav-categories__categories">
        <li 
          v-for="c in primary"
          :key="c.id"
          class="nav-categories__category"
          :class="{ '-is-active': isCurrentCategory(c) }">
          <button 
            class="nav-categories__item"
            @click="go(c)">{{ c.label }}</button>
        </li>
      </ul>

      <!-- more -->
      <div
        v-if="nSecondary > 0" 
        class="nav-categories__more"
        :class="{ '-is-active': showSecondary }">
        <actions-button 
          class="nav-categories__more-cta"
          :class="{'-is-active': isSecondaryActive }"
          :appearance="$pepper.Appearance.SUBTLE"
          icon-post="angle-down"
          @click="showSecondary = !showSecondary">+{{ nSecondary }}</actions-button>

        <ul class="nav-categories__list">
          <li
            v-for="(c, i) in secondary"
            :key="i" 
            class="nav-categories__category"
            :class="{ '-is-active': isCurrentCategory(c) }">
            <button 
              class="nav-categories__item"
              @click="go(c)">{{ c.label }}</button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'NavCategories',

  props: {
    back: {
      type: String
    },

    category: {
      type: Object
    },

    categories: {
      type: Array,
      default: () => []
    }
  },

  data(){
    return {
      observer: null,
      indexes: [],
      nVisible: 0,
      showSecondary: false
    }
  },

  computed: {
    children(){
      return Array.from(this.$refs.categories.children) || [];
    },

    classes(){
      return {
        'nav-categories': true,
      }
    },

    isSecondaryActive(){
      return this.secondary.filter(i => this.category && this.categories.id === i.id).length > 0
    },

    nSecondary(){
      return this.secondary.length
    },

    primary(){
      let ret = [...this.categories];
      ret.length = this.nVisible;
      return ret;
    },

    secondary(){
      let ret = [...this.categories];
      ret.reverse();
      ret.length = ret.length - this.nVisible;
      ret.reverse();

      return ret;
    }
  },

  methods: {

    go(category){
      this.$emit('category', category);
      this.showSecondary = false;
    },

    isCurrentCategory(c){
      return !!this.category && this.category.id === c.id;
    },

    reset(){
      if (!this.$refs.categories){
        return;
      }

      this.indexes = Array(this.categories.length).fill(false);
      this.observer = new IntersectionObserver(this.update, {root: this.$refs.categories});

      this.children.forEach( c => this.observer.observe(c) );
    },

    update(entries){
      entries.forEach( e => {
        let i = -1;
        if(this.children && this.$basil.isArray(this.children)) {
          this.children.forEach((c, j) => {
            if (c === e.target){
              i = j
            }
          })
        }

        if (i > -1){
          this.indexes[i] = e.intersectionRatio >= .98
        }
      });

      this.nVisible = this.indexes.filter( i => i === true ).length;
    },

    onResize(){
      this.reset();
    }
  },

  mounted(){
    window.addEventListener('resize', this.$basil.debounce(this.onResize.bind(this), 500));
    this.reset();
  },

  destroy(){
    window.removeEventListener('resize', this.onResize);
  }
}
</script>
