<template>
  <div :class="classes">
    <div class="ui-progress__content">
      <header class="ui-progress__header">
        <slot name="header">
          <h3 class="ui-progress__title">{{ headerLabel }}</h3>  
          <ui-loader 
            appearance="dots"
            size="s" 
            v-if="!ended"
          />
        </slot>
      </header>

      <div 
        class="ui-progress__body" 
        v-if="!ended && hasValue && hasProgress">
        <slot>
          <i18n-percent 
            class="ui-progress__value"
            :value="value" 
            v-if="hasValue"  
          />
        </slot>
      </div>

      <div 
        class="ui-progress__results"
        v-if="ended && hasValue && hasResults">
        <slot 
          name="results"
        >Done</slot>
      </div>
    </div>
  </div>
</template>

<script>
import { Appearance } from '@spices/pepper'

export default {
  name: 'UiProgress',

  props: {
    appearance: {
      default: Appearance.DEFAULT,
      type: String,
      validator: value => [Appearance.DEFAULT, Appearance.SUBTLE].includes(value),
      values: [Appearance.DEFAULT, Appearance.SUBTLE],
    },

    current: {
      total: Number | null,
      required: true,
    },

    hasResults: {
      type: Boolean, 
      default: true,
    },

    hasProgress: {
      type: Boolean, 
      default: true,
    },

    total: {
      type: Number | null,
      required: true
    },

    visible: {
      type: Boolean,
      default: true
    },
  },

  computed: {
    classes() {
      return {
        'ui-progress': true,
        '-subtle': this.appearance === Appearance.SUBTLE
      }
    },

    ended() {
      return this.hasValue && this.current === this.total
    },

    hasValue() {
      return !this.$basil.isNil(this.total) && !this.$basil.isNil(this.current)
    },

    headerLabel() {
      if(!this.hasValue && !this.ended) {
        return this.$t('sayl.progress_starting')
      } else if(this.hasValue && !this.ended) {
        return this.$t('sayl.progress_working')
      } else {
        return this.$t('sayl.progress_ended')
      }
    },

    value() {
      return ((100 / this.total) * this.current) / 100
    },
  }
}
</script>
