

export default class Feature {
  constructor({ created = null, endpoints = [], id = null, modified = null, name = null, pricing = [], priority = 0, teamleader = {} }) {
    try {
      this._created = created ? new Date(created) : null
    } catch(e) {
      console.error('Tier:contructor:Created:', e.message)
    }
    this._endpoints = endpoints
    this._id = id
    try {
      this._modified = modified ? new Date(modified) : null
    } catch(e) {
      console.error('Tier:contructor:Modified:', e.message)
    }
    this._name = name
    this._pricing = pricing
    this._priority = priority
    this._teamleader = teamleader
  }

  /////////////////////////////////////////
  /// Getters
  get created() {
    return this._created
  }

  get endpoints() {
    return this._endpoints
  }

  get id() {
    return this._id
  }

  get modified() {
    return this._modified
  }

  get name() {
    return this._name
  }

  get pricing() {
    return this._pricing
  }

  get priority() {
    return this._priority
  }

  get teamleader() {
    return this._teamleader
  }
}