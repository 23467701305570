<template>
  <ui-card 
    :appearance="$pepper.Appearance.SUBTLE"
    article
    class="view-loyalty-config__row">
    <template v-slot:header>
      <h3 class="ui-card__title">{{ $t('sayl-ecommerce.loyalty_config_pass_colors_title') }}</h3>
    </template>

    <article 
      class="ui-card__article flow" 
      v-if="!loading">
      <!-- Pass background color -->
      <forms-input
        :errors="getErrors('pass_background_color')"
        :hint=" $t('sayl-ecommerce.loyalty_config_pass_colors_background_hint')"
        :placeholder="$t('sayl.placeholder')"
        :disabled="readOnly"
        type="color"
        @input="onRemoveError('pass_background_color')"
        v-model="edition.passBackgroundColor"
      >{{ $t('sayl-ecommerce.loyalty_config_pass_colors_background_label') }}</forms-input>

      <!-- Pass strip color -->
      <forms-input
        :errors="getErrors('pass_strip_color')"
        :hint=" $t('sayl-ecommerce.loyalty_config_pass_colors_strip_hint')"
        :placeholder="$t('sayl.placeholder')"
        :disabled="readOnly"
        type="color"
        @input="onRemoveError('pass_strip_color')"
        v-model="edition.passStripColor"
      >{{ $t('sayl-ecommerce.loyalty_config_pass_colors_strip_label') }}</forms-input>

      <!-- Pass text color -->
      <forms-input
        :errors="getErrors('pass_text_color')"
        :hint=" $t('sayl-ecommerce.loyalty_config_pass_colors_text_hint')"
        :placeholder="$t('sayl.placeholder')"
        :disabled="readOnly"
        type="color"
        @input="onRemoveError('pass_text_color')"
        v-model="edition.passTextColor"
      >{{ $t('sayl-ecommerce.loyalty_config_pass_colors_text_label') }}</forms-input>
    </article>
  </ui-card>
</template>

<script>
import { mapState } from 'vuex'
import { MixinError } from '@sayl/admin-common'

export default {
  name: 'LoyaltyPassConfigColors',

  mixins: [ 
    MixinError,
  ],

  props: {
    errors: {
      type: Object,
    },

    readOnly: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    ...mapState({
      edition: state => state.loyalty.loyalty.edition,
    }),
  },

  data() {
    return {
      loading: true,
    }
  },

  methods: {
    reset() {
      
    }
  },

  mounted() {
    this.loading = false;
  }
}
</script>

