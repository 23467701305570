<template>
  <ui-details 
    :auto-padding="false"
    class="view-loyalty-page__details -target"
    :key="key"
    :open="!$basil.isNil(target)"
    v-if="!$basil.isNil(target) && targeted.length > 0">
    <template #summary>
      <div class="view-loyalty-page__target row">
        <span>{{ $t(`sayl-loyalty.page_target_edition`)}}</span>

        <actions-button
          :appearance="$pepper.Appearance.SUBTLE"
          icon-post="cross"
          :size="$pepper.Size.S"
          :title="$t('sayl-loyalty.page_clear_target')"
          @click="$emit('clear')"
        />
      </div>
    </template>

    
    <ui-details
      class="view-loyalty-page__subdetails"
      :auto-padding="false"
      :key="section.name"
      :open="isSectionOpened(section.name)"
      @summary="openSection(section.name)"
      v-for="section in targeted">
      <template #summary>{{ $t(`sayl-loyalty.loyalty_page_${section.label}`)}}</template>
      
      <div class="view-loyalty-page__article">
        <div class="flow">
          <div 
            style="display: content;"
            :key="comp.fqn"
            v-for="comp in section.values">
            <component
              :is="comp.component"
              :read-only="readOnly"
              v-bind="comp.attrs"
              v-on="comp.listeners"
              v-if="!comp.child"
            >{{ comp.label }}</component>

            <component
              :is="comp.component"
              :read-only="readOnly"
              v-bind="comp.attrs"
              v-on="comp.listeners"
              v-model="comp.attrs.value.all"
              v-if="comp.child && !comp.image"
            >{{ comp.label }}</component>

            <component
              :is="comp.component"
              :read-only="readOnly"
              v-bind="comp.attrs"
              v-on="comp.listeners"
              v-if="comp.child && comp.image"
            >{{ comp.label }}</component>
          </div>
        </div>
      </div>
    </ui-details>

    <modal-translate 
      :bind="translationsType.includes('merge-tags') ? { mergeTags: tags } : {}"
      @close="translastionClosed"
      :field="translationsField"
      :read-only="readOnly"
      :type="translationsType"
      :visible="showTranslations" 
    />
  </ui-details>
</template>

<script>
import MixinBuilder from './builder.js'
import { MixinImage } from '@sayl/admin-common'

export default {
  name: 'LoyaltyPagePropertyTarget',

  inject: [ '$image' ],

  mixins: [ 
    MixinBuilder, 
    MixinImage 
  ],

  props: {
    isLogged: {
      type: Boolean,
      default: false,
    },

    pageContent: {
      type: Object
    },

    pageStyle: {
      type: Object,
    },

    target: {}
  },

  data() {
    return {
      key: 1,
      openedSection: null,
    }
  },

  watch: {
    target: {
      immediate: true,
      handler() {
        if(this.target) {
          this.openSection(null)
          setTimeout(() => {
            this.openSection(this.targeted[0].name)
          }, 10)
        }
        this.key++
      }
    }
  },

  methods: {
    isSectionOpened(section) {
      return this.key && !this.$basil.isNil(this.openedSection) && this.openedSection === section
    },

    openSection(section) {
      if(this.openedSection === section) {
        this.openedSection = null
      } else {
        this.openedSection = section
      }
      this.key++
    }
  }
}
</script>
