<template>
  <div 
    class="ui-map" 
    :id="id"
    ref="map"></div>
</template>

<script>
import { mapState } from "vuex"

export default {
  data() {
    return {
      id: "google-maps-sdk",
      map: null,
      marker: null,
      latitude: null,
      longitude: null,
      pStyles: null,
      circle: null
    };
  },

  props: {
    address: {
      type: String
    },
    lat: {
      type: Number,
      default: 41.40338
    },
    lng: {
      type: Number,
      default: 2.17403
    },

    radius: {
      type: Number|String|null,
      default: 0
    },

    zoom: {
      type: Number,
      default: 15
    },
    markerShown: {
      type: Boolean,
      default: true
    },
    markerIcon: {
      type: String,
      default: "/statics/images/icons/position.svg"
    },
    styles: {
      type: String,
      default: "/statics/json/lightgrey.json"
    }
  },

  watch: {
    lat(val) {
      this.latitude = val;
      this.update();
    },
    lng(val) {
      this.longitude = val;
      this.update();
    },
    address(val) {
      this.resolveAddress(val).then(this.update);
    },
    radius: {
      handler() {
        let radius = this.radius
        if(this.$basil.isString(radius)) {
          radius = parseInt(radius)
        }
        
        if(!this.$basil.isNil(this.circle)) {
          this.circle.setMap(null)
        }

        if(this.radius != null && this.radius > 0) {
          this.circle = new google.maps.Circle({
            strokeColor: "#2c9ccb",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#80cdf0",
            fillOpacity: 0.35,
            map: this.map,
            center: { lat: this.lat, lng: this.lng },
            radius: radius,
          });
        }
      }
    }
  },

  computed: {
    ...mapState({
      gmapLoaded: state => state.sayl.gmapLoaded
    }),

    options() {
      return {
        center: {
          lat: this.latitude,
          lng: this.longitude
        },
        zoom: this.zoom,
        disableDefaultUI: true,
        styles: this.pStyles
      };
    },

    markerOptions() {
      return {
        position: {
          lat: this.latitude,
          lng: this.longitude
        },
        map: this.map,
        icon: this.$getImageSrc(this.markerIcon)
      };
    }
  },

  methods: {
    init() {
      this.initStyles()
        .then(this.initLocation.bind(this))
        .then(this.initMap.bind(this))
        .catch(err => {
          console.warn("issue map", err);
        });
    },

    initStyles() {
      return (this.styles.includes('.json')) ?
        $dl.$http.get(this.$getImageSrc(this.styles))
          .then(response => {
            this.pStyles = response.data;
          })
          .catch(error => {
            console.warn('Sorry an error occured during the style retrieving');
          }) :
        Promise.resolve( this.styles );
    },

    initLocation() {
      return this.address ? this.resolveAddress(this.address) : Promise.resolve();
    },

    initMap() {
      return new Promise((resolve, reject) => {
        try {
          let el = document.getElementById(this.id);
          this.map = new google.maps.Map(
            el,
            this.options
          );
          if (this.markerShown) {
            this.marker = new google.maps.Marker(this.markerOptions);
            this.marker.addListener("click", this.openDirection);
          }

          let radius = this.radius
          if(this.$basil.isString(radius)) {
            radius = parseInt(radius)
          }
          
          if(!this.$basil.isNil(this.circle)) {
            this.circle.setMap(null)
          }

          if(this.radius != null && this.radius > 0) {
            this.circle = new google.maps.Circle({
              strokeColor: "#2c9ccb",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#80cdf0",
              fillOpacity: 0.35,
              map: this.map,
              center: { lat: this.lat, lng: this.lng },
              radius: radius,
            });
          }

          resolve();
        } catch (e) {
          this.map = null;
          console.warn("initMap issue", e);
          reject();
        }
      });
    },

    update() {
      if (!this.map) {
        return;
      }

      this.map.setCenter(new google.maps.LatLng(this.latitude, this.longitude));
      this.markerShown &&
        this.marker.setPosition(
          new google.maps.LatLng(this.latitude, this.longitude)
        );

      this.$emit('change', { lat: this.latitude, lon: this.longitude });
    },

    onGmapLoaded(){
      this.$bus.$off('gmap.loaded', this.onGmapLoaded.bind(this))
      this.init()
    },

    openDirection() {
      const destination = `${this.lat},${this.lng}`;
      const origin = this.position
        ? `${this.position.coords.latitude},${this.position.coords.longitude}`
        : null;
      let url = `https://www.google.com/maps/dir/?api=1&destination=${destination}`;
      if (origin) {
        url += `&origin=${origin}`;
      }
      window.open(url);
    },

    resolveAddress(address) {
      return new Promise((resolve, reject) => {
        let g = new google.maps.Geocoder();
        g.geocode({ address: address }, (results, status) => {
          if (status == google.maps.GeocoderStatus.OK) {
            let r = results[0].geometry.location;
            this.latitude = r.lat();
            this.longitude = r.lng();
            this.$emit("address", this.latitude, this.longitude, address);
            resolve(this.latitude, this.longitude);
          }
        });
      });
    },
  },

  created(){
    this.id = this.$basil.uniqId('map')
    this.latitude = this.lat;
    this.longitude = this.lng;

    if (this.gmapLoaded === true){
      this.init()
    }
    else{
      this.$bus.$on("gmap.loaded", this.onGmapLoaded.bind(this))
      this.$bus.$emit("gmap.load")
    }
  },

  beforeDestroy(){
    this.$bus.$off('gmap.loaded', this.onGmapLoaded.bind(this))
  }
};
</script>

<style scoped></style>
