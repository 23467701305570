<template>
  <ui-card 
    :appearance="$pepper.Appearance.SUBTLE" 
    article>

    <article class="ui-card__article">
      <forms-checkbox 
        :appearance="$pepper.Appearance.TOGGLE"
        :size="$pepper.Size.S"
        v-model="edition.active"
      >{{ $t('sayl-loyalty.referral_active') }}</forms-checkbox>
    </article>

    <article class="ui-card__article flow">
      <forms-input
        :errors="getErrors('link_utm.source')"
        :placeholder="$t('sayl.placeholder')"
        required
        :read-only="readOnly"
        type="text"
        @input="onRemoveError('link_utm.source')"
        v-model="edition.linkUtm.source"
      >{{ $t('sayl-loyalty.referral_utm_source') }}</forms-input>

      <forms-input
        :errors="getErrors('link_utm.medium')"
        :placeholder="$t('sayl.placeholder')"
        required
        :read-only="readOnly"
        type="text"
        @input="onRemoveError('link_utm.medium')"
        v-model="edition.linkUtm.medium"
      >{{ $t('sayl-loyalty.referral_utm_medium') }}</forms-input>
    </article>

    <template #footer>
    <!-- <article class="ui-card__article flow"> -->
      <actions-button
        :appearance="$pepper.Appearance.LINK"
        class="break"
        icon-post="open"
        href="https://shorturl.at/puI23"
        target="_blank"
      >{{ $t('sayl-loyalty.referral_utm_hint') }}</actions-button>
    <!-- </article> -->
    </template>
  </ui-card>
</template>

<script>
import { mapState } from 'vuex'
import { 
  MixinEditionRemove,
  MixinError,
} from '@sayl/admin-common'

export default {
  name: 'ReferralInfoCard',

  mixins: [ 
    MixinEditionRemove,
    MixinError 
  ],

  props: {
    errors: {
      type: Object,
    },

    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState({
      edition: state => state.loyalty.referral.edition,
    }),
  },
}
</script>
