<template>
  <div 
    class="view-loyalty-config__fields view-loyalty-modal__fields view-loyalty-config__vouchers loyalty-vouchers flow"
    v-if="show">
    <!-- Rules::Points Redemption::Activation-->
    <forms-checkbox
      :appearance="$pepper.Appearance.TOGGLE"
      :read-only="readOnly"
      :size="$pepper.Size.S"
      v-model="edition.allowVouchersRedemption"
      v-if="showAllowVouchers"
    >{{ $t('sayl-ecommerce.loyalty_config_vouchers_redemption_activation_label') }}</forms-checkbox>

    <forms-label>{{ $t('sayl-ecommerce.loyalty_config_vouchers_redemption_activation_hint') }}</forms-label>

    <notice-banner 
      :intent="$pepper.Intent.WARNING"
      v-if="dirty.length > 0">
      <template #title>{{ $t('sayl-ecommerce.loyalty_config_vouchers_redemption_ditry_title') }}</template>
      <template>{{ $t('sayl-ecommerce.loyalty_config_vouchers_redemption_ditry_description') }}</template>
    </notice-banner>

    <div 
      class="loyalty-vouchers__items" 
      v-if="!loading && showRedeemableVouchers">
      <card-voucher
        :key="v.id"
        :index="i"
        :read-only="readOnly"
        :value="v"
        @delete="onVoucherDelete"
        @edit="onVoucherEdit"
        @view="onVoucherView"
        v-for="(v, i) in list"
      />

      <div 
        class="loyalty-vouchers__add" 
        v-if="!readOnly">
        <div class="loyalty-vouchers__figures">
          <img 
            alt="Add a voucher"
            class="loyalty-vouchers__illustration"
            :src="$getImageSrc('/statics/images/figures/loyalty-empty.svg')" 
          />
        </div>

        <actions-button
          :appearance="$pepper.Appearance.PRIMARY"
          class="loyalty-vouchers__actions"
          icon-post="plus"
          :size="$pepper.Size.S"
          @click="onVoucherCreate"
        >{{ $t('sayl-ecommerce.loyalty_config_vouchers_add_label') }}</actions-button>
      </div>
    </div>

    <modal-voucher 
      :index="index"
      :is-new="isNew"
      :value="selected"
      :visible="showVoucher"
      @close="onVoucherClose"
      @confirm="onVoucherConfirm"
      v-if="showVoucher && !readOnly && showRedeemableVouchers"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { MixinError } from '@sayl/admin-common'

import CardVoucher from './voucher'
import ModalVoucher from './modal-voucher'

export default { 
  name: 'LoyaltyProgramConfigRedemptionVouchers',

  components: {
    CardVoucher,
    ModalVoucher,
  },

  mixins: [ MixinError, ],

  props: {
    dirty: {
      type: Array,
      default: () => [],
    },

    errors: {
      type: Object,
    },

    readOnly: {
      required: false,
      type: Boolean
    },
  },

  data() {
    return {
      index: -1,
      isNew: false,
      loading: true,
      selected: null,
      showVoucher: false,
    }
  },

  computed: {
    ...mapState({
      edition: state => state.loyalty.loyalty.edition,
      vouchers: state => state.ecommerce.voucher.templates.all
    }),

    list() {
      let ret = []
      this.dirties = []

      this.edition.redeemableVouchers.forEach((rv) => {
        let f = this.vouchers.find(v => v.id === rv.voucher_id)
        if(!this.$basil.isNil(f)) {
          ret.push({
            ...rv,
            ...f,
            voucher: f,
            redeemableVoucher: rv
          })
        } else {
          if(!this.dirty.includes(rv.voucher_id)){
            this.dirty.push(rv.voucher_id)
          }
        }
      })

      return ret
    },

    show() {
      return this.showAllowVouchers ||
        this.showRedeemableVouchers
    },

    showAllowVouchers() {
      return !window.$dl.fields.isPropHidden('loyalty_program', 'allow_vouchers_redemption')
    },

    showRedeemableVouchers() {
      return !window.$dl.fields.isPropHidden('loyalty_program', 'redeemable_vouchers') && this.edition.allowVouchersRedemption === true
    }
  },

  methods: {
    onVoucherClose() {
      this.showVoucher = false
      this.selected = null
      this.index = -1
    },

    onVoucherConfirm(value, index) {
      if(this.isNew) {
        this.edition.redeemableVouchers.push(value)
      } else {
        Object.keys(value).forEach(k => {
          this.edition.redeemableVouchers[index][k] = value[k]
        })
      }
      this.onVoucherClose()
    },

    onVoucherCreate() {
      this.selected = { 
        voucher_id: null,
        required_points: null,
        validity_period: 365,
      }

      this.index = -1
      this.isNew =  true
      this.showVoucher = true
    },

    onVoucherDelete(v, index) {
      this.edition.redeemableVouchers.splice(index, 1)
    },

    onVoucherEdit(v, index) {
      this.index = index
      this.selected = v.redeemableVoucher
      this.isNew =  false
      this.showVoucher = true
    },

    onVoucherView(v, index) {
      let route = this.$router.resolve({ name: 'sayl-ecommerce_voucher-template', params: { id: v.id }})
      window.open(route.href, '_blank')
    },

    reset() {
      this.loading = true

      this.$ecommerce.voucherTemplates.all({ args: { isTemplate: 1 }})
        .catch((e) => $console.error(e))
        .finally(() => this.loading = false)
    }
  },

  mounted() {
    this.reset()
  }
}
</script>
