<template>
  <ui-card 
    class="view-loyalty-card"
    :appearance="$pepper.Appearance.SUBTLE"
    article>

    <!-- Base information -->
    <article class="ui-card__article" v-if="edition">
      <data-tag
        :appearance="isActive ? $pepper.Appearance.PRIMARY : $pepper.Appearance.DEFAULT"
      >{{ isActive ? $t('sayl-loyalty.referral_is_active') : $t('sayl-loyalty.referral_is_not_active') }}</data-tag>
    
      <div class="view-loyalty-card__row">
        <div class="view-loyalty-card__rowitem">
          <div class="value">{{ referrerRewardsCount }}</div>
        </div>
      </div>

      <div class="view-loyalty-card__row">
        <div class="view-loyalty-card__rowitem">
          <div class="value">{{ referringRewardsCount }}</div>
        </div>
      </div>
    </article>

    <template v-slot:footer v-if="edition">
      <article class="view-loyalty-card__footer">
        <!-- Edit -->
        <actions-button
          :appearance="$pepper.Appearance.SUBTLE"
          class="view-loyalty-card__button"
          icon-post="arrow-right"
          :size="$pepper.Size.S"
          @click="onEdit($basil.get(edition, 'id'))"
        >{{ editLabel }}</actions-button>
      </article>
    </template>
  </ui-card>
</template>

<script>
export default {
  name: 'ReferralCardInformation',

  props: {
    edition:{
      type: Object,
      required: true,
    },

    loading: {
      type: Boolean,
      default: true,
    },

    readOnly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    editLabel() {
      return !this.readOnly ? 
        this.$t('sayl-loyalty.referral_edit_config') : 
        this.$t('sayl-loyalty.referral_view_config')
    },

    isActive() {
      return this.$basil.get(this.edition, 'active')
    },

    referrerRewardsCount() {
      let count = this.$basil.get(this.edition, 'referrerRewards.length')
      return count && count > 0 ? this.$t('sayl-loyalty.referral_referrer_rewards_count', { count }) : this.$t('sayl-loyalty.referral_referrer_rewards_empty')
    },

    referringRewardsCount() {
      let count = this.$basil.get(this.edition, 'referringRewards.length')
      return count && count > 0 ? this.$t('sayl-loyalty.referral_referring_rewards_count', { count }) : this.$t('sayl-loyalty.referral_referring_rewards_empty')
    }
  },

  methods: {
    onEdit() {
      this.$router.push({ name: 'sayl-customer-loyalty_referral-edition' })
    },
  },

  mounted() {
    this.$bus.$on('edit-referral', () => this.onEdit())
  },

  beforeDestroy() {
    this.$bus.$off('edit-referral')
  }
}
</script>
