export default [
  {
    label: 'header_layout_left',
    value: 'sayl-loyalty.start',
  },
  {
    label: 'header_layout_center',
    value: 'sayl-loyalty.center',
  },
  {
    label: 'header_layout_right',
    value: 'sayl-loyalty.end',
  },
]
