<template>
  <layout-level2
    class="view-referral-config"  
    v-bind="attrs"
    v-on="listeners">
    <div class="layout-level2__container container view-loyalty-referral -cols">
      <!-- Loading -->
      <div 
        class="view-referral-config__loader"
        v-if="loading">
        <ui-loader />
      </div>

      <div class="col" v-if="!loading">
        <referral-rewards 
          :description="$t('sayl-loyalty.referral_referrer_rewards_description')"
          :errors="errors"
          field="referrer_rewards"
          :rewards="edition.referrerRewards"
          :read-only="readOnly"
          :title="$t('sayl-loyalty.referral_referrer_rewards_title')"
          @add="onAddReward('referrerRewards', true)"
          @edit="onEditReward($event, 'referrerRewards', true)"
        />

        <referral-rewards 
          :description="$t('sayl-loyalty.referral_referring_rewards_description')"
          :errors="errors"
          field="referring_rewards"
          :rewards="edition.referringRewards"
          :read-only="readOnly"
          :title="$t('sayl-loyalty.referral_referring_rewards_title')"
          @add="onAddReward('referringRewards', false)"
          @edit="onEditReward($event, 'referringRewards', false)"
        />

        <modal-reward 
          :is-new="isNew"
          :has-rules="hasRules"
          :read-only="readOnly"
          :value="reward"
          :visible="showModal" 
          @close="onCloseReward"
          @confirm="onConfirmReward"
          v-if="showModal"
        />
      </div>

      <div class="col" v-if="!loading">
        <referral-info
          :errors="errors"
          :read-only="readOnly"
        />
      </div>
    </div>
  </layout-level2>
</template>

<script>
import { 
  mapGetters, 
  mapState,
} from "vuex"

import { 
  MixinACL, 
  MixinEditionRemove, 
  MixinEditionDiscard, 
  MixinEditionSave, 
  MixinError, 
  MixinImage,
  MixinImpersonation,
} from '@sayl/admin-common'

import ReferralInfo from './info.vue'
import ReferralRewards from './rewards.vue'

import ModalReward  from './modals/index.vue'

export default {
  name: 'ReferralEditModal',

  components: {
    ReferralInfo,
    ReferralRewards,

    ModalReward,
  },

  mixins: [ 
    MixinACL, 
    MixinEditionRemove, 
    MixinEditionDiscard, 
    MixinEditionSave, 
    MixinError,
    MixinImage,
    MixinImpersonation,
  ],

  data() {
    return {
      loading: true,
      errors: {},

      // Reward edition
      isNew: false,
      hasRules: false,
      reward: null,
      rewardIndex: null,
      rewardField: null,
      showModal: false,
    } 
  },

  computed: {
    ...mapState({
      edition: state => state.loyalty.referral.edition,
      origin: state => state.loyalty.referral.origin,
      referrals: state => state.loyalty.referral.all,

      module: state => state.ginger.module,
      programs: state => state.loyalty.loyalty.collection,

      nfts: state => state.loyalty.referral.nfts.all,
    }),

    ...mapGetters({
      pristine: 'loyalty/referral.pristine',
    }),

    attrs() {
      return {
        breadcrumb: this.breadcrumb,
        icon: 'users',
        hasSaveButton: this.isEditable && !this.isReadOnly,
        inheritedFromChild: false,
        inheritedFromParent: !this.isMaster || this.isImpersonating,
        pristine: this.pristine,
        loading: this.loading,
        tertiary: this.popinsOptions,
        saving: this.saving,
        title: this.title,
      }
    }, 

    breadcrumb() {
      return [
        { label: this.$t('sayl-loyalty.loyalty'), icon: this.icon, href: { name:'sayl-customer-loyalty_home'} },
        { label: this.$t('sayl-loyalty.loyalty_referral'), href: { name:'sayl-customer-loyalty_referral'} },
        { label: this.title }
      ]
    },

    customerId() {
      return this.$basil.get(this.edition, 'customerId')
    },

    icon() {
      return 'award'
    },

    isEditable() {
      let ret = this.canEdit;

      return ret
    },

    isInherited() {
      return !this.isCurrentCustomer(this.customerId)
    },

    hasPrograms() {
      return !this.$basil.isNil(this.programs) && this.programs.length > 0
    },

    hasReferrals() {
      return !this.$basil.isNil(this.referrals) && this.referrals.length > 0
    },

    readOnly() {
      return !this.isMaster || this.isImpersonating
    },

    listeners() {
      return {
        back: this.onDiscard,
        discard: this.onDiscard,
        close: this.onDiscard,
        save: this.onConfirm,
      }
    },

    popinsOptions() {
      let ret = []

      if(this.canEdit && !this.isReadOnly && this.hasReferrals) { 
        ret.push({ 
          label: this.$t('sayl.delete'), 
          event: { click: this.onRemove } 
        })
      }

      return ret
    },

    title() {
      let ret = this.$t('sayl.loading')

      if(!this.loading) {
        ret = this.hasReferrals ? this.$t('sayl-loyalty.referral_edition_title') : this.$t('sayl-loyalty.referral_creation_title')
      }

      return ret
    }
  },

  methods: {
    back() {
      this.$router.push({ name: 'sayl-customer-loyalty_referral' }).catch((e) => {})
    },

    createAction() {
      return this.canCreate && !this.readOnly ?
        this.onCreate() : () => {}
    },

    onAddReward(field, hasRules) {
      this.hasRules = hasRules
      this.reward = this.edition.createReward()
      this.rewardIndex = -1
      this.rewardField = field
      this.isNew = true
      this.showModal = true
    },

    onCloseReward() {
      this.showModal = false
      this.hasRules = false
      this.reward = null
      this.rewardIndex = null
      this.isNew = false
    },

    onConfirm() {
      this.loading = true
      this.onSave({})
        .then(() => this.reset())
        .catch((e) => {
          $console.error(e)
          this.loading = false
        })
    },

    onConfirmReward(reward) {
      if(this.rewardIndex === -1) {
        this.edition[this.rewardField].push(reward)
      } else {
        this.edition[this.rewardField][this.rewardIndex].settings = reward.settings
        this.edition[this.rewardField][this.rewardIndex].type = reward.type
        this.edition[this.rewardField][this.rewardIndex].description = reward.description
        
        if(this.hasRules) {
          this.edition[this.rewardField][this.rewardIndex].rules = reward.rules
        }
      }
      this.onCloseReward()
    },

    onEditReward({ value, index }, field, hasRules) {
      this.hasRules = hasRules
      this.reward = value
      this.rewardIndex = index
      this.rewardField = field
      this.isNew = false
      this.showModal = true
    },

    remove() {
      return window.$dl.referral.remove({ id: this.edition.id })
    },

    reset() {
      this.loading = true
      
      window.$dl.loyalty.collection({})
        .then(() => window.$dl.referral.all({}))
        .then((c) => c.length > 0 ? window.$dl.referral.findById({ id: c[0].id }) : window.$dl.referral.create({ args: { loyalty_program_id: this.$basil.get(this.programs, '[0].id') } }))
        .then(() => this.$ecommerce.voucherTemplates.all({ args: { isTemplate: 1 }}))
        .then(() => window.$dl.referral.getNFTs({}))
        .then(() => {
          if(!this.hasPrograms) {
            this.$router.replace({ name: `sayl-customer-loyalty_overview` })
          }
        })
        .catch((e) => $console.error(e))
        .finally(() => this.loading = false)
    },

    save() {
      return window.$dl.referral.save({ item: this.edition.toAPI(), id: this.edition.id, hasUpdate: false, isNew: this.$basil.isNil(this.edition.id) })
    }
  },

  mounted() {
    this.reset()
  },
}
</script>
