<template>
  <!-- Breakage -->
  <ui-details 
    class="view-loyalty-config__details flow" 
    open>
    <template #summary>{{ $t('sayl-ecommerce.loyalty_config_communication_title') }}</template>
    <!-- <summary class="ui-card__summary layout-modal__summary">{{ $t('sayl-ecommerce.loyalty_config_communication_title') }}</summary> -->

    <div class="view-loyalty-config__fields view-loyalty-modal__fields flow">
      <forms-image-input
        :entity-id="$route.params.program"
        entity-type="loyalty_program"
        field-name="mail_logo"
        module="loyalty"
        :read-only="readOnly"
        required
        :title="$t('sayl-ecommerce.loyalty_config_mail_logo_title')"
        @clear="clearImages"
        @change="saveImages"
      />
    </div>
  </ui-details>
</template>

<script>
import { mapState } from 'vuex'

import { 
  MixinError,
  MixinImage,
} from '@sayl/admin-common'

export default { 
  name: 'LoyaltyProgramConfigThreshold',

  mixins: [
    MixinError,
    MixinImage,
  ],

  props: {
    errors: {
      type: Object,
    },

    readOnly: {
      required: false,
      type: Boolean
    },
  },

  computed: {
    ...mapState({
      iconImg: state => state.loyalty.loyalty_program.pass_icon.all,
    }),
  },
}
</script>
