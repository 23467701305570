<template>
  <layout-modal 
    v-bind="attrs"
    @close="onClose">

    <!-- Warning -->
    <article
      v-if="warning" 
      class="modal-danger__article modal-danger__warning">
      <notice-banner :intent="$pepper.Intent.WARNING">
        <template v-slot:title>{{ warning }}</template>
      </notice-banner>
    </article>

    <!-- Slot -->
    <article 
      v-if="hasDefaultSlot"
      class="modal-danger__article">
      <slot></slot>
    </article>

    <!-- Confirm -->
    <article class="modal-danger__article flow">
      <forms-input
        autofocus
        v-model="input"
        :placeholder="phrase">{{ label }}</forms-input>

      <actions-button
        :disabled="!enabled"
        :intent="$pepper.Intent.DANGER"
        :size="$pepper.Size.S"
        @click="$emit('confirm')">{{ cta }}</actions-button>
    </article>
  </layout-modal>
</template>


<script>
export default {
  name: 'ModalDanger',

  props: {
    cta: {
      type: String,
      default: function(){
        return this.$t('sayl.danger_cta')
      }
    },

    /**
     * @property {String} phrase
     *  The phrase the user has to type to confirm the operation
     */
    phrase: {
      type: String,
      required: true
    },

    /**
     * @property {String} title
     *  The title of the modal
     */
    title: {
      type: String,
      default: function(){
        return this.$t('sayl.danger_title')
      }
    },

    /**
     * @property {Boolean} visible
     *  Whether or not the modal is visible
     */
    visible: {
      type: Boolean,
      default: true
    },

    warning: {
      type: String,
      default: function(){
        return this.$t('sayl.danger_warning')
      }
    }
  },

  data(){
    return {
      input: '',
      fieldEnabled: true
    }
  },

  computed: {
    attrs(){
      return {
        classes: { 'modal-danger': true },
        closable: true,
        size: 's',
        title: this.title,
        visible: this.visible
      }
    },

    enabled(){
      return this.input === this.phrase
    },

    hasDefaultSlot(){
      return !!this.$slots.default
    },

    label(){
      let ret = this.$t('sayl.danger_label', { phrase: this.phrase })

      return ret
    }
  },

  methods: {
    onClose(){
      this.$emit('close')
    },
  }
}
</script>