<template>
  <layout-modal
    v-bind="attrs"
    v-on="listeners">

    <div
      class="layout-modal__loader"
      v-if="loading">
      <ui-loader/>
    </div>
  
    <article 
      class="layout-modal__article" 
      v-if="!loading" >
      <forms-file
        accept="image/*"
        icon-post="plus"
        :placeholder="$t('sayl.image_explorer_add')"
        @input="onUploadImage"
        v-model="uploadedImage"
      />
    </article>
      
    <article 
      class="layout-modal__article" 
      v-if="!loading && !hasImages">
      <data-empty 
        :description="$t('sayl.image_explorer_empty_description')"
        :title="$t('sayl.image_explorer_empty_title')"
      />
    </article>

    <article 
      class="layout-modal__article modal-image-explorer__grid" 
      v-if="!loading && hasImages">
      <article 
        :class="imageClasses(item)"
        v-for="(item) in images" 
        :key="item.id"
        @click="()=>onImageSelect(item)">

        <div 
          class="modal-image-explorer__selected"  
          v-if="isSelected(item)">
            <ui-icon glyph="check" />
        </div>

        <article class="modal-image-explorer__item">
          <div class="modal-image-explorer__image-container">
            <img 
              class="modal-image-explorer__image"
              :id="$basil.get(item,'id')"
              :src="$basil.get(item,'image.all.src')" 
              :alt="$basil.get(item,'alt.all')">
          </div>

          <div 
            :key="item._id"
            class="modal-image-explorer__action-container">
            <actions-button
              :appearance="$pepper.Appearance.SUBTLE"
              class="modal-image-explorer__more"
              :ref="item.id"
              icon-pre="dot-dot-dot"
              :size="$pepper.Size.S"
              @click.prevent.stop="onTrigger(item)"
              />

            <popins-list-dropdown
              position="bottom-center"
              :visible="showAction"
              :layout="['label']"
              :options="actions"
              :target="currentTarget"
              />
          </div> 
        </article>
      </article>
    </article>

    <template 
      v-if="!loading"
      v-slot:footer>
      <actions-button 
        @click="onClose"
      >{{ $t('sayl.close') }}</actions-button>

      <data-pagination
        class="modal-image-explorer__pagination"
        :current-page="pagination.current_page"
        :layout="['prev', 'pager', 'next']"
        :page-size="pagination.pageSize"
        :total="pagination.total"
        :size="$pepper.Size.S"
        @change="onPage"
        v-if="hasPagination"
      />

      <actions-button
        appearance="primary"
        :disabled="loading || selected.length === 0"
        @click="onConfirm"
      >{{ $t('sayl.confirm') }}</actions-button>
    </template>
  </layout-modal>
</template>

<script>
import { 
  MixinACL,
  MixinError,
  MixinImage,
  MixinEditionSave, 
  MixinEditionDiscard,
} from '@sayl/admin-common'

export default {
  name:'ModalImageExplorer',

  mixins: [
    MixinACL,
    MixinError,
    MixinImage,
    MixinEditionDiscard,
    MixinEditionSave,
  ],

  props: {    
    classes: { type: Object },
    customerId: { type: String },
    entityId: { type: String },
    entityType: { type: String },
    fieldName: { type: String },
    hasSelection: {
      type: Boolean,
      default: true,
    },
    hasBorder: {
      type: Boolean,
      default: true
    },
    icon: { type: String },
    isMultiple: {
      type: Boolean,
      default: false
    },
    module: { type: String },
    title: { type: String },
    visible:{
      type:Boolean,
      required:true,
    },
  },

  data() {
    return {
      loading: false,
      images: null,
      page: 1,
      currentPage: 1,
      limit: 40,  
      selected: [],
      key: 1,
      pagination: null,
      uploadedImage: null,
      currentTarget: null,
      showAction: false,
      target: null,
    }
  },

  computed:{
    actions() {
      return [
        { 
          label:this.$t('sayl.delete'),
          event: { 
            click:this.onDelete,
          }
        }
      ]
    },

    attrs() {
      return {
        classes: {
          '-body-p0': true,
          'modal-image-explorer': true,

          '-card': this.appearance === this.$pepper.Appearance.CARD,
          '-full': this.fillImage,
          '-has-selection': this.hasSelection,
          '-no-border': !this.hasBorder,
          ...this.classes
        },
        closable: true,
        icon: this.icon,
        title: this.title,
        // description:this.description,
        pristine: this.pristine,
        visible: this.visible,
        loading: this.loading,
      }
    },

    description() {
      return this.entityType
    },

    hasImages() {
      return this.$basil.get(this.images, 'length', 0) > 0
    },

    hasPagination() {
      return !this.$basil.isNil(this.pagination)
    },

    listeners() {
      return {
        back: this.onDiscard,
        close: this.onClose,
        discard: this.onDiscard,
        confirm: this.onConfirm,
      }
    },

    pristine() {
      return JSON.stringify(this.selected) === JSON.stringify(this.value)
    },
  },

  watch: {
    visible: {
      immediate: true,
      handler() {
        this.reset()
      }
    },
  },

  methods: {
    imageClasses(image) {
      return {
        'modal-image-explorer__item-container':true,
        '-is-selected':this.isSelected(image)
      }
    },

    isSelected(image) {
      return this.isMultiple ? this.selected.includes(image) : this.selected === image
    },

    onTrigger(image) {
      let target = this.$refs[image.id][0];
      let same = this.currentTarget === target
      this.target = same ? null : image 
      this.showAction= !same;
      this.currentTarget = same ? null : target
    },

    onImageSelect(image) {
      if(this.isMultiple){
        let index = this.images.indexOf(image)
        this.selected.includes(image)?
          this.selected.splice(this.selected.indexOf(image),1):
          this.selected.splice(index, 0,image)
      }
      else{
        this.selected = image
      }
      this.key++
      return this.selected
    },
    
    onUploadImage(imageData) {
      this.uploadedImage = imageData

      this.saveImage({ data: { module: this.module, entityType: this.entityType, fieldName: this.fieldName }, images: [], value: this.uploadedImage })
        .then((i) => this.reset(i))
        .catch((e) => $console.error(e))
    },

    reset(image = null) {
      this.loading = true
      this.uploadedImage = null
      this.currentTarget = null
      this.showAction = false

      let args = { 
        module: this.module, 
        entityType: this.entityType, 
        entityId: this.entityId, 
        fieldName: this.fieldName,
        limit: this.limit,
        page: this.page,
      }

      this.$image.collection({ data: args })
        .then((res) => {
          this.images = res.collection
          this.pagination = res.meta.pagination
          if(!this.$basil.isNil(image)) {
            let r = this.images.find(i => i.id === image.id)
            if(!this.$basil.isNil(r)) {
              this.onImageSelect(r)
            }
          }
        }) 
        .catch((e) => $console.error(e))
        .finally(() => this.loading = false)
    },
  
    onClose() {
      this.$emit('close')
    },
    
    onConfirm() {
      this.$emit('confirm',this.selected)
    },

    onDelete() {
      const callBack = () =>{
        this.loading = true
        this.isSelected(this.target) ? 
          this.onImageSelect(this.target) : null

        this.$image.remove({ data: { module: this.module, entityType: this.entityType, fieldName: this.fieldName }, id: this.target.id })
          .catch((e) => $console.error(e))
          .finally(() => this.reset()) 
      }

      this.$confirm({
        primaryAction: this.$t('sayl.confirm'), 
        secondaryAction: this.$t('sayl.cancel'), 
        title: this.$t('sayl.delete_modal_title'),
        description: this.$t('sayl.delete_modal_message'),
        onPrimary: callBack
      })
    },

    onPage(page) {
      this.page = page
      this.reset()
    },
  },

  mounted() {
    this.reset()
  }
}
</script>
