import Config from './config'
import Definitions from './definitions'
import Options from './options'

import MixinProperties from './properties.js'
import { MixinTranslation } from '@sayl/admin-common'

export default {
  mixins: [
    MixinProperties,
    MixinTranslation,
  ],

  data() {
    return {
      debounceStyle: null
    }
  },

  computed: {
    page() {
      let ret = []
      
      Config.forEach((item) => {
        let itemName = item.section ? item.section : item.name
        let c = {
          name: item.name,
          sections: []
        }

        if(item.sections.length > 0 && (item.logged == null || item.logged === this.isLogged )) {
          item.sections.forEach((section) => {
            let s = {
              name: section.name,
              label: section.label ?? null,
              type: section.type ?? null,
              values: [],
              models: [],
            }

            section.values.forEach(fqn => {
              let found = Definitions[itemName].find(i => i.fqn === fqn) 
              if(s.type === 'array' && found) {
                s.section = found
                s.models = this.getContentValue(itemName, found.property) || []
                if(s.models) {
                  s.models = s.models.value
                }

                found.children.forEach((child) => {
                  let built = this.build(itemName, child)
                  if(built) {
                    delete built.attrs.value
                    delete built.attrs.class
                    s.values.push(built)
                  }
                })
              } else if(found) {
                let built = this.build(itemName, found)
                if(built) {
                  s.values.push(built)
                }
              }
            })

            if(s.values.length > 0) {
              c.sections.push(s)
            }
          })
        }

        if(c.sections.length > 0) {
          ret.push(c)
        }
      })

      return ret
    },

    definitions() {
      return Definitions
    },

    options() {
      let ret = {}

      Object.keys(Options).forEach(opt => {
        if(opt === 'mergeTags') {
          const translate = (tag) => {
            if(tag.hasOwnProperty('mergeTags')) {
              let ret = {}
              Object.keys(tag.mergeTags).forEach((mt) => {
                ret[mt] = translate(tag.mergeTags[mt])
              })
              tag.mergeTags = ret
            }

            if(tag.hasOwnProperty('specialLinks')) {
              let ret = {}
              Object.keys(tag.specialLinks).forEach((mt) => {
                ret[mt] = translate(tag.specialLinks[mt])
              })
              tag.specialLinks = ret
            }

            if(tag.hasOwnProperty('name') && tag.name.includes('sayl-loyalty')) {
              tag.name = this.$t(tag.name)
            }

            return tag
          }

          let mergeTags = {}
          Object.keys(Options.mergeTags).forEach(mt => {
            mergeTags[mt] = translate(Options.mergeTags[mt])
          })
          ret.mergeTags = mergeTags
        } else {
          ret[opt] = Options[opt].map((o) => {
            return {
              label: o.label.includes('sayl-loyalty.') ? this.$t(`${o.label}`) : o.label,
              value: o.value
            }
          })
        }
      })
      
      return ret
    },

    targeted() {
      let ret = []
      let tar = this.target
      let index = null
      let match = tar.match(/-[0-9]+/g)

      if(match) {
        index = parseInt(match[0].replace('-', ''), 10)
        tar = tar.replace(match[0], '')
      }

      let values = []
      
      Config
        .forEach(c => {
          let sections = c.sections.filter(s => s.context != null && s.context.includes(tar))
          if(sections.length > 0) {
            values.push(...sections)
          } 
        })

      values.forEach(sect => {
        let r = {
          name: sect.name,
          label: sect.label ?? null,
          type: sect.type ?? null,
          values: [],
          models: [],
        }
        
        sect.values.forEach(fqn => {
          let found = Definitions.all.find(i => i.fqn === fqn) 
          
          if(sect.type === 'array' && found) {
            let section = found.section
            r.models = this.getContentValue(section, found.property).value[index]
            found.children.forEach(child => {
              let built = this.build(section, child)
              let value = this.getContentValue(section, child.property).value[index]
              if(built && child.subtype !== 'image') {
                built.child = true
                built.attrs.value = value[child.subproperty]
                built.attrs.class = this.hasTranslations(child.subproperty, value)
                built.listeners.iconClick = () => this.onOpenTranslations(child.subproperty, child.component, value)
                built.listeners.input = (value) => this.$emit('change')
                r.values.push(built)
              } else if(built && child.subtype === 'image') {
                built.child = true
                built.image = true
                built.attrs.fieldName = built.attrs.fieldName + '_' + index
                built.listeners.change = ({ data, images, multiple, values }) => this.onImageChange({ data, images, multiple, values }, found.section, child.subproperty, value)
                built.listeners.clear = ({ data, images, multiple, values }) => this.onImageClear({ data, images, multiple, values }, found.section, child.subproperty, value)
                r.values.push(built)
              }
            })
          } else if(found) {
            let section = found.section
            let built = this.build(section, found)
            if(built) {
              r.values.push(built)
            }
          }
        })
        
        if(r.values.length > 0) {
          ret.push(r)
        }
      })

      return ret
    }
  },

  methods: {
    attrs(sectionName, item) {
      let ret = {
        placeholder: this.$t('sayl.placeholder')
      }

      if(item.component === 'forms-select') {
        ret.placeholder = this.$t('sayl.select_placeholder')
      }
      
      if(item.options) {
        ret.options = this.options[item.options]
      }

      if((item.type === 'content') && item.subtype !== 'image') {
        let value = this.getContentValue(sectionName, item.property) || []
        ret.type = 'text'

        if(this.$basil.isNil(item.translatable) || this.item.translatable === true) {
          ret.class = !Array.isArray(value) ? this.hasTranslations('value', value) : ''
          ret.iconPost = 'l8n'
          ret.iconPostInteractive = true
          ret.value = !Array.isArray(value) ? value.value.all : value
          ret.readOnly = this.readOnly || false
        }

        if(item.subtype === 'merge-tags') {
          ret.mergeTags = this.options.mergeTags
        }

        if(item.subtype  === 'boolean') {
          ret.appearance = this.$pepper.Appearance.TOGGLE
          ret.size = this.$pepper.Size.S
          ret.layout = this.$pepper.Layout.BLOCK
        }

        if(!this.$basil.isNil(item.max)) {
          ret.max = item.max
        }

        if(!this.$basil.isNil(item.min)) {
          ret.min = item.min
        }
      } else if(item.type === 'content' && item.subtype === 'image') {
        delete ret.placeholder
        ret.accept = 'image/*'
        ret.entityId = this.$route.params.program
        ret.entityType = 'loyalty_program'
        ret.fieldName = `page_${sectionName}`
        ret.module = 'loyalty'
        ret.title = this.$t(`sayl-loyalty.${item.label}`)
      }

      if(item.type === 'style') {
        ret.type = item.subtype
        ret.value = this.getStyleValue(item.property)
        ret.readOnly = this.readOnly || false
        ret.disabled = this.readOnly || false
        if(item.clearable) {
          ret.iconPost = 'cross'
          ret.iconPostInteractive = true
        }
      }
      
      return ret
    },

    build(sectionName, item) {
      let ret = null

      if(item && (item.logged == null || item.logged === this.isLogged)) {
        ret = {
          label: this.$t(`sayl-loyalty.${item.label}`),
          component: item.component,
          attrs: this.attrs(sectionName, item),
          listeners: this.listeners(sectionName, item),
          item: item
        }
      }

      return ret
    },
    
    listeners(sectionName, item) {
      let ret = {}

      if(item.type === 'content' && item.subtype !== 'image') {
        ret.iconClick = () => this.onOpenTranslations('value', item.component, this.getContentValue(sectionName, item.property))
        ret.input = (value) => this.onContentChange(sectionName, item.property, value)
        if(item.component === 'forms-merge-tags') {
          ret.change = (value) => this.onContentChange(sectionName, item.property, value)
        }
      } else if(item.type === 'content' && item.subtype === 'image') {
        ret.change = ({ data, images, multiple, values }) => this.onImageChange({ data, images, multiple, values }, sectionName, item.property, item.subproperty)
        ret.clear = ({ data, images, multiple, values }) => this.onImageClear({ data, images, multiple, values }, sectionName, item.property)
      } 

      if(item.type === 'style') {
        ret.input = (value) => {
          clearTimeout(this.debounceStyle)
          this.debounceStyle = setTimeout(() => {
            this.onStyleChange(value, item.property)
          }, 25)
        }
        
        if(item.clearable) {
          ret.iconClick = (value) => {
            clearTimeout(this.debounceStyle)
            this.debounceStyle = setTimeout(() => {
              this.onStyleChange(null, item.property)
            }, 25)
          }
        }
      }

      return ret
    }
  }
}
