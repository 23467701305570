<template>
  <div 
    class="group view-web3-trigger__embed modal-create-trigger__embed" 
    v-if="!hasEnablers">
    <forms-input
      class="label"
      :errors="iErrors"
      :placeholder="$t('sayl.create_enabler_placeholder')"
      required
      type="text"
      @input="onNameInput"
      v-if="!loading"
      v-model="enabler.name"
    >{{ label }}</forms-input>

    <actions-button
      class="action bottom"
      @click="onConfirm"
    >{{ $t('sayl.create_enablers_action') }}</actions-button>
  </div>

  <div 
    class="view-web3-trigger__embed modal-create-trigger__embed" 
    v-else>
    <div class="group">
      <forms-autocomplete
        allow-add
        :errors="eErrors['grant.embed_id'] || []"
        :options="embeds"
        :disabled="loading"
        :loading="loading"
        :placeholder="$t('sayl.select_placeholder')"
        required
        :value="selected"
        @change="onChange"
        @add="onAdd"
        v-on="$listeners"
      >{{ label }}</forms-autocomplete>

      <actions-button
        class="bottom"
        icon-post="redo"
        style="align-self: end;"
        @click="reset"
      />
    </div>

    <forms-label 
      :size="$pepper.Size.S" 
      v-html="embedHint" 
    />
  </div>
</template>

<script>
import {mapState} from 'vuex'
import { 
  MixinEditionSave, 
  MixinError
} from '@sayl/admin-common'

export default {
  name: 'TriggerFormsEmbeds',

  mixins: [
    MixinEditionSave,
    MixinError
  ],

  props: {
    eErrors: {},
  },

  data() {
    return {
      addNew: false,
      errors: {},
      loading: true,

      selected: null,
      key: 1,
    }
  },

  computed: {
    ...mapState({
      edition: state => state.tier.reward.edition,
      enabler: state => state.nft.enabler.edition,
      embeds: state => state.nft.web3.trigger.embeds.all
    }),

    embedHint() {
      let route = this.$router.resolve({ name: 'sayl-conn3ct-settings-enablers_home' })
      return `<a href="${route.href}" target="_blank">${this.$t('sayl.tier_perk_go_to_enablers')}</a>`
    },

    embedId() {
      let ret = this.embeds || []
      ret = ret.find(e => e.value === this.$basil.get(this.edition, 'grant.embed_id', null))

      return this.key && !this.$basil.isNil(ret) ? ret.value : null
    },

    iErrors() {
      if(this.getErrors('name').length > 0) {
        return this.getErrors('name')
      } else if(!this.$basil.isNil(this.eErrors['grant.embed_id'])) {
        return this.eErrors['grant.embed_id']
      }
      return []
    },

    hasEnablers() {
      return this.$basil.get(this.embeds, 'length', 0) > 0
    },

    label() {
      return this.hasEnablers ? 
        this.$t('sayl.tier_perk_grant_embeds') :
        this.$t('sayl.create_enabler_label')
    }
  },

  methods: {
    isValid() {
      this.errors = {}
      
      if(this.$basil.isNil(this.enabler.name) || this.$basil.isEmpty(this.enabler.name)) {
        this.errors['name'] = [this.$t('validation.field_is_required')]
      }

      return this.key && Object.keys(this.errors).length === 0
    },

    onAdd(value) {
      this.$nft.enabler.create({ args: { active: true, product: 'conn3ct', embed_type: 'enabler', name: value.label }})
        .then(() => this.$nft.enabler.save({ item: this.enabler, isNew: true }))
        .then(() => this.$nft.web3Trigger.getEmbeds())
        .then(() => {
          this.onChange(this.embeds.find(e => e.value === this.$basil.get(this.enabler, 'id')))
          this.$basil.get(this.$listeners, 'change', () => {})(this.edition.perk.grant.embed_id)  
        })
        .catch((e) => $console.info(e))
    },

    onChange(value) {
      this.selected = value
      this.edition.perk.grant.embed_id = value.value
      
      if(this.eErrors.hasOwnProperty('grant.embed_id')) {
        delete this.eErrors['grant.embed_id']
      }
      this.key++
      this.$bus.$emit('refresh-card')
    },

    onConfirm() {
      if(this.isValid()) {
        this.onSave({})
          .then(() => this.$nft.web3Trigger.getEmbeds())
          .then((e) => {
            this.onChange(this.embeds.find(em => em.value === this.$basil.get(this.enabler, 'id')))
            this.$basil.get(this.$listeners, 'change', () => {})(e.id)
            this.reset()
          })
          .catch((e) => $console.error(e))
      }
    },

    onNameInput() {
      if(this.eErrors.hasOwnProperty('grant.embed_id')) {
        delete this.eErrors['grant.embed_id']
      }

      delete this.errors['name']
    },  

    reset() {
      this.loading = true
      this.$nft.web3Trigger.getEmbeds()
        .then(() => {
          if(!this.hasEnablers) {
            return this.$nft.enabler.create({ args: { active: true, product: 'conn3ct', embed_type: 'enabler' }})
          }

          if(this.hasEnablers && !this.$basil.isNil(this.$basil.get(this.edition, 'perk.grant.embed_id'))) {
            this.selected = this.embeds.find(e => e.value === this.$basil.get(this.edition, 'perk.grant.embed_id'))
          }

          return Promise.resolve()
        })
        .catch((e) => $console.error(e))
        .finally(() => this.loading = false)

    },

    save() {
      return this.$nft.enabler.save({ item: this.enabler, isNew: true })
    }
  },

  mounted() {
    this.reset()
  }
}
</script>
