<template>
  <div class="forms-email-editor forms-control">    
    <div 
      class="forms-control__header" 
      v-if="hasDefault">
      <forms-label class="forms-control__label">
        <slot></slot>
      </forms-label>
    </div>

    <div :class="classes">
      <div
        class="forms-email-editor__unlayer" 
        v-bind:id="id"
      ></div>

    </div>

    <div class="forms-control__footer">
      <div 
        class="forms-control__hint" 
        v-html="hint"
        v-if="hint"
      ></div>

      <div 
        class="forms-email-editor__errors" 
        v-if="hasErrors">
        <forms-label
          :key="`errors_${i}`"
          v-for="(e, i) in errors"
          :intent="$pepper.Intent.DANGER"
        >{{ e }}</forms-label>
      </div>
    </div>

    <modal-image-explorer
      entity-type="mail_template"
      field-name="picture"
      :isMultiple="false"
      module="images"
      :title="$t('sayl.wysiwyg_image_explorer_title')"
      :visible="showModalImage"
      @close="() => showModalImage = false"
      @confirm="onConfirmImage"
      v-if="showModalImage"
    />
  </div>
</template>

<script>
import { loadScript } from './loadscript'
let lastEditorId = 0

export default {
  name: 'EmailEditor',

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    errors: {
      type: Array,
      default: () => []
    },

    hint: {
      type: String,
    },

    options: Object,

    readOnly: {
      type: Boolean, 
      default: false
    },

    required: {
      type: Boolean,
      default:false,
    },

    mergeTags: {},

    sample: {},

    specialLinks: {},

    value: {}
  },

  data() {
    return {
      showModalImage: false,
      editor: null,
    }
  },
  
  computed: {
    classes() {
      return {
        'forms-email-editor__body': true,

        '-is-disabled': this.disabled,
        '-is-errored': this.hasErrors, 
        '-is-readonly': this.readOnly,
        '-is-required': this.required,
      }
    },

    hasDefault() {
      return !this.$refs.default
    },

    hasErrors() {
      return this.$basil.get(this.errors, 'length', 0) > 0
    },

    id() {
      return `editor-${this.$basil.uniqId(++lastEditorId)}`;
    },

    locale() {
      return this.$basil.i18n.locale.lang
    }
  },

  methods: {
    loadEditor() {      
      const options = this.options || {}

      if (this.locale) {
        options.locale = this.locale;
      }

      /* global unlayer */
      this.editor = unlayer.createEditor({
        ...options,
        id: this.id,
        displayMode: 'email',
        source: {
          name: 'test',
          version: '0.0.0',
        },
      });

      this.editor.loadDesign(this.value)
      this.editor.setMergeTags(this.mergeTags)
      this.editor.setSpecialLinks(this.specialLinks)
      
      this.editor.registerCallback('selectImage', (file, done) => {
        this.showModalImage = true
        this.$on('image', (url) => done({ progress: 100, url }))
      })

      this.editor.registerCallback('previewHtml', (params, done) => {
        const template = this.$handlebars.compile(params.html)
        let result = template(this.sample)
        done({ html: result })
      })

      this.$emit('load')
      this.editor.addEventListener('editor:ready', () => {
        this.$emit('ready')
      });

      this.editor.addEventListener('design:updated', (data) => {
        let json = null
        let html = null

        this.editor.saveDesign((d) => {
          json = d
          this.editor.exportHtml((v) => {
            html = v.html
            this.$emit('change', { json, html })
          })
        })
      })
    },

    onConfirmImage(image) {
      this.$emit('image', this.$basil.get(image, 'url.all'))
      this.showModalImage = false
    },
  },

  mounted() {
    loadScript(this.loadEditor.bind(this))
    this.$bus.$on('i18n.locale.updated', () => {
      this.editor.destroy()
      this.loadEditor()
    })
  },

  beforeDestroy() {
    this.$bus.$off('i18n.locale.updated')
  }
};
</script>
