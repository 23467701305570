<template>
  <!-- Points -->
  <ui-details 
    class="view-loyalty-config__details flow" 
    open>
    <template #summary>{{ $t('sayl-ecommerce.loyalty_config_points_title') }}</template>
    <!-- <summary class="ui-card__summary layout-modal__summary">{{ $t('sayl-ecommerce.loyalty_config_points_title') }}</summary> -->
    
    <div class="view-loyalty-config__fields view-loyalty-modal__fields flow">
      <div class="group">
        <!-- Points::Name -->
        <forms-input
          :class="hasTranslations('points.name')"
          @iconClick="onOpenTranslations('points.name')"
          icon-post="l8n"
          :icon-post-interactive="true"
          @input="onRemoveError('points.name.all')"
          :errors="getErrors('points.name.all')"
          :hint="$t('sayl-ecommerce.loyalty_config_points_name_hint')"
          :placeholder="$t('sayl-ecommerce.loyalty_config_points_name_placeholder')"
          :read-only="readOnly"
          required
          type="text"
          v-model="edition.points.name.all"
        >{{ $t('sayl-ecommerce.loyalty_config_points_name_label') }}</forms-input>

        <!-- Points::Symbol -->
        <forms-input
          @input="onRemoveError('points.symbol')"
          :errors="getErrors('points.symbol')"
          :hint="$t('sayl-ecommerce.loyalty_config_points_slug_hint')"
          :placeholder="$t('sayl-ecommerce.loyalty_config_points_slug_placeholder')"
          :read-only="readOnly"
          required
          type="text"
          v-model="edition.points.symbol"
        >{{ $t('sayl-ecommerce.loyalty_config_points_slug_label') }}</forms-input>
      </div>
    </div>

    <modal-translate 
      @close="onCloseTranslations"
      :readOnly="readOnly"
      :field="translationsField"
      :type="translationsType"
      :visible="showTranslations" 
    />
  </ui-details>
</template>

<script>
import { mapState } from 'vuex'

import { 
  MixinError,
  MixinTranslation,
} from '@sayl/admin-common'

export default { 
  name: 'LoyaltyProgramConfigPoints',

  mixins: [
    MixinError,
    MixinTranslation,
  ],

  props: {
    errors: {
      type: Object,
    },

    readOnly: {
      required: false,
      type: Boolean
    },
  },

  computed: {
    ...mapState({
      edition: state => state.loyalty.loyalty.edition,
    }),
  },
}
</script>
