export default [
  {
    key: '111',
    title: 'Redeem a voucher',
    type: 'voucher_redemption', // ['voucher_redemption', 'challenge_redemption', 'nft_redemption'],
    amount: 123,
    date: '2022-12-21T12:00:00.000Z', // ISO Date never null
    metadata: [
      { 
        key: 'code',
        value: 'abcd-1234'
      },
      {
        key: 'challenge',
        value: '1234' // Key of the challenge
      }
    ],
  },
  {
    key: '222',
    title: 'Create an account',
    type: 'challenge_redemption', // ['voucher_redemption', 'challenge_redemption', 'nft_redemption'],
    amount: 10,
    date: '2022-12-20T12:00:00.000Z', // ISO Date never null
    metadata: [
      {
        key: 'challenge',
        value: '11111' // Key of the challenge
      }
    ],
  },
  {
    key: '333',
    title: '10Eur = 1 point',
    type: 'challenge_redemption', // ['voucher_redemption', 'challenge_redemption', 'nft_redemption'],
    amount: 5,
    date: '2022-12-21T12:00:00.000Z', // ISO Date never null
    metadata: [
      {
        key: 'challenge',
        value: '22222' // Key of the challenge
      }
    ],
  }
]
