<template>
  <ui-card 
    class="view-loyalty-card"
    :appearance="$pepper.Appearance.SUBTLE"
    article>

    <!-- Base information -->
    <article class="ui-card__article">
      <data-tag
        v-if="isActive"
        :appearance="$pepper.Appearance.PRIMARY"
        :intent="isActive ? $pepper.Intent.DEFAULT : $pepper.Intent.WARN"
        :size="$pepper.Size.S"
        >{{ $t('sayl-ecommerce.loyalty_is_active') }}</data-tag>

      <div 
        class="view-loyalty-card__row"
        v-if="hasRange">
        <div 
          class="view-loyalty-card__rowitem" 
          v-if="!$basil.isNil(from)">
          <div class="title">{{ $t('sayl.from') }}</div>
          <div class="value">{{ $basil.i18n.datetime(from) }}</div>
        </div>
        
        <div 
          class="view-loyalty-card__rowitem" 
          v-if="!$basil.isNil(to)">
          <div class="title">{{ $t('sayl.to') }}</div>
          <div class="value">{{ $basil.i18n.datetime(to) }}</div>
        </div>
      </div>

      <div class="view-loyalty-card__row">
        <div class="view-loyalty-card__rowitem" >
          <div class="title">{{ $t('sayl-ecommerce.loyalty_reward_label')}}</div>
          <div class="value">{{ moneyToPoints }}</div>
        </div>
        
        <div class="view-loyalty-card__rowitem">
          <div class="title">{{ $t('sayl-ecommerce.loyalty_redemption_label')}}</div>
          <div class="value">{{ pointsToMoney }}</div>
        </div>
      </div>
    </article>

    <template v-slot:footer>
      <article class="view-loyalty-card__footer">
        <!-- Edit -->
        <actions-button
          :appearance="$pepper.Appearance.SUBTLE"
          class="view-loyalty-card__button"
          icon-post="arrow-right"
          :size="$pepper.Size.S"
          @click="onEdit($basil.get(edition, 'id'))"
        >{{ editLabel }}</actions-button>
      </article>
    </template>
  </ui-card>
</template>

<script>
export default {
  name: 'LoyaltyCardInformation',

  props: {
    edition:{
      type: Object,
      required: true,
    },

    loading: {
      type: Boolean,
      default: true,
    },

    readOnly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    editLabel() {
      return !this.readOnly ? 
        this.$t('sayl-ecommerce.loyalty_edit_config') : 
        this.$t('sayl-ecommerce.loyalty_view_config')
    },

    hasRange() {
      return !this.$basil.isNil(this.from) || !this.$basil.isNil(this.to);
    },

    from() {
      let fromDate = this.$basil.get(this.edition, 'from', null);
      let fromTime = this.$basil.get(this.edition, 'fromTime', null);
      
      let from = null;

      if(!this.$basil.isNil(fromDate)) {
        from = new Date(fromDate + ' ' + fromTime);
      }
      
      return from
    },

    isActive() {
      let ret = true;
      let now = new Date();

      let from = this.from;
      let to = this.to; 

      if(!this.$basil.isNil(from) && !this.$basil.isNil(to)) {
        ret = now.getTime() > from.getTime() && now.getTime() < to.getTime();
      } else if(!this.$basil.isNil(from) && this.$basil.isNil(to)) {
        ret = now.getTime() > from.getTime();
      } else if(this.$basil.isNil(from) && !this.$basil.isNil(to)) {
        ret = now .getTime() < to.getTime();
      }
      
      return ret;
    },

    to() {
      let toDate = this.$basil.get(this.edition, 'to', null);
      let toTime = this.$basil.get(this.edition, 'toTime', null);

      let to = null;
      if(!this.$basil.isNil(toDate)) {
        to = new Date(toDate + ' ' + toTime);
      }

      return to
    },

    moneyToPoints() {
      let pointSymbol = this.$basil.get(this.edition, 'points.symbol');
      let moneyBase = this.$basil.i18n.currency(1/this.$basil.get(this.edition, 'rules.moneyToPoints'), {fraction: 2});

      return `${moneyBase} = ${1}${pointSymbol}`
    },

    pointsToMoney() {
      let pointSymbol = this.$basil.get(this.edition, 'points.symbol');
      let value = this.$basil.get(this.edition, 'rules.pointsToMoney');
      value = !this.$basil.isNil(value) ? this.$basil.i18n.currency(value) : null;
      return `1${pointSymbol} = ${value}`
    }
  },

  methods: {
    onEdit(id) {
      this.$router.push({ name: 'sayl-customer-loyalty_program', params: { program: id }})
    },
  },

  mounted() {
    this.$bus.$on('edit-loyalty', () => {
      this.onEdit(this.$basil.get(this.edition, 'id'))
    })
  },

  beforeDestroy() {
    this.$bus.$off('edit-loyalty')
  }
}
</script>
