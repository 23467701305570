const btnDefault = [
  {
    component: 'forms-input',
    fqn: 'general-style-btn-default-bg',
    label: 'bg',
    property: '--slp-btn-default-bg',
    section: 'general',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'general-style-btn-default-bc',
    label: 'bc',
    property: '--slp-btn-default-bc',
    section: 'general',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'general-style-btn-default-fc',
    label: 'fc',
    property: '--slp-btn-default-fc',
    section: 'general',
    subtype: 'color',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'general-style-btn-default-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-btn-default-fs',
    section: 'general',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'general-style-btn-default-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-btn-default-fw',
    section: 'header',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const btnPrimary = [
  {
    component: 'forms-input',
    fqn: 'general-style-btn-primary-bg',
    label: 'bg',
    property: '--slp-btn-primary-bg',
    section: 'general',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'general-style-btn-primary-fc',
    label: 'fc',
    property: '--slp-btn-primary-fc',
    section: 'general',
    subtype: 'color',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'general-style-btn-primary-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-btn-primary-fs',
    section: 'general',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'general-style-btn-primary-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-btn-primary-fw',
    section: 'header',
    subtype: 'fontWeight',
    type: 'style',
  },
]

export default [ 
  ...btnDefault, 
  ...btnPrimary 
]
