<template>
  <!-- Loading -->
  <div 
    class="layout-modal-steps__loader" 
    v-if="loading" >
    <ui-loader />
  </div>

  <article 
    class="layout-modal__article" 
    v-else>
    <div class="modal-reward__nfts modal-choices">
      <nft-card 
        :class="{ '-is-selected': isSelected(nft) }"
        :value="nft" 
        :is-selected="isSelected(nft)"
        v-for="nft in list"
        :key="nft.id"
        @click="onSelect"
      />
    </div>
  </article>
</template>

<script>
import { mapState } from 'vuex'
import NftCard from './nft-card'

export default {
  name: 'ChallengeTypeNFT',
  
  components: {
    NftCard,
  },

  props: {
    search: String,
    value: {
      type: Object,
      required: true
    }
  },

  data() {
    return { 
      errors: {},
      key: 1,
      loading: true,
    }
  },

  computed: {
    ...mapState({
      nfts: state => state.audiences.challenge.nfts.all
    }),

    list() {
      let ret = this.nfts || []

      if(this.$basil.isNil(this.search)) {
        return ret
      }

      return ret.filter((nft) => {
        return this.$basil.get(nft, 'name.all').toLowerCase().includes(this.search.toLowerCase())
      })
    },
  },

  methods: { 
    getNfts() {
      this.loading = true
      this.$audiences.challenge.getNFTs({ images: true }) 
        .catch((e) => $console.error(e))
        .finally(() => {
          this.key++
          this.loading = false
        })
    },

    isSelected(nft) {
      return this.key && this.value.settings.nft_id === nft.id
    },

    onSelect(nft) {
      this.value.settings.nft_id = nft.id
      this.key++
    },

    reset() {
      this.loading = true
      if(this.$basil.isNil(this.nfts)) {
        this.getNfts()
      } else {
        this.loading = false
      }
    }
  },

  mounted() {
    this.reset()
  }
}
</script>
