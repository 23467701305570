import { OAuthApi } from './api'

/**
 * OAuth Controller
 * 
 * @class
 */
export default class OAuthController{
  /**
   * Start the authentication process
   * 
   * @returns {Promise}
   */
  authenticate({ hash = null }){
    return OAuthApi.authenticate({ hash })
  }

  /**
   * Log the user out of the application
   * 
   * @returns {Promise}
   */
  logout(){
    return OAuthApi.logout()
  }

  /**
   * Log the user out of the application
   * 
   * @returns {Promise}
   */
  logoutWithoutRedirect(){
    return OAuthApi.logoutWithoutRedirect()
  }
}
