/* IMPORT CURRYs HERE */
import { CurryLoyaltyDatalayer } from '@curry/loyalty'

/* IMPORT CONTROLLERs HERE */
import { 
  LoyaltyController,
  LoyaltyCardController,
  ReferralController,
} from './controllers'

/* IMPORT STOREs HERE */
import LoyaltyStore from '../../store/modules/loyalty'

/** 
 * @class
 */
class DataLayer {
  /**
   * @constructor
   */
  constructor({ store, transports }) {
    this._fqn = 'loyalty'
    this._store = store
    
    this._innerStore = LoyaltyStore
    
    /* Set the currys here for the object */
    this._loyaltyCurry = new CurryLoyaltyDatalayer({ transports })
    
    /* Set the controller here */
    this._loyalty = null
    this._loyaltyCard = null
    this._referral = null
  }

  /////////////////////////////////////////////////////////////////////
  // Properties
  /////////////////////////////////////////////////////////////////////
  /**
   * @property {String} fqn
   * @readonly
   * @return {String}
   */
  get fqn(){ 
    return this._fqn 
  }

  /**
   * @property {Object} store
   * @readonly
   * @return {Object}
   */
  get store() {
    return this._innerStore
  }

  /////////////////////////////////////////////////////////////////////
  // Controllers
  /////////////////////////////////////////////////////////////////////
  /**
   * @property {Object} loyalty
   * @readonly
   * @return {LoyaltyController} loyalty controller
   */
  get loyalty() {
    if(this._loyalty == null) {
      let curry = this._loyaltyCurry.controllers.find(c => c.fqn === 'ifm.loyalty.program')
      this._loyalty = new LoyaltyController({ curry, store: this._store })
    }
    return this._loyalty
  }

  /**
   * @property {Object} loyaltyCard
   * @readonly
   * @return {LoyaltyController} loyaltyCard controller
   */
  get loyaltyCard() {
    if(this._loyaltyCard == null) {
      let curry = this._loyaltyCurry.controllers.find(c => c.fqn === 'ifm.loyalty.cards')
      this._loyaltyCard = new LoyaltyCardController({ curry, store: this._store })
    }
    return this._loyaltyCard
  }

  /**
   * @property {Object} referral
   * @readonly
   * @return {ReferralController} referral controller
   */
  get referral() {
    if(this._referral == null) {
      let curry = this._loyaltyCurry.controllers.find(c => c.fqn === 'ifm.loyalty.referral')
      this._referral = new ReferralController({ curry, store: this._store })
    }
    return this._referral
  }
}

export default DataLayer
