<template>
  <div class="layout-level2__container container -cols-1-2 view-loyalty-page">
    <div class="col">
      <loyalty-page-config 
        :page-content="content"
        :is-logged="isLogged"
        :page-style="style"
        :read-only="readOnly"
        :target="loyalty.target"
        :state="state"
        @change="onChange"
        @clear="onClearTarget"
        @scroll="onSectionFocus"
        @logged="onLogged"
        @state="onState"
        v-if="!iLoading"
      />
    </div>

    <div class="col">
      <div class="view-loyalty-page__wrapper">
        <div class="view-loyalty-page__header">
          <div class="view-loyalty-page__left">
            <div class="view-loyalty-page__tick -red"></div>
            <div class="view-loyalty-page__tick -yellow"></div>
            <div class="view-loyalty-page__tick -green"></div>
          </div>

          <div class="view-loyalty-page__center">
            <div 
              :class="{ 'view-loyalty-page__figure': true, '-selected': desktop }" 
              @click="onDesktop">
              <ui-icon 
                class="view-loyalty-page__icon" 
                glyph="desktop" 
              />
            </div>

            <div 
              :class="{ 'view-loyalty-page__figure': true, '-selected': widget }" 
              @click="onWidget">
              <ui-icon 
                class="view-loyalty-page__icon" 
                glyph="mobile-h" 
              />
            </div>

            <div 
              class="view-loyalty-page__figure" 
              :class="{ 'view-loyalty-page__figure': true, '-selected': mobile }"
              @click="onMobile">
              <ui-icon 
                class="view-loyalty-page__icon" 
                glyph="mobile-v"
              />
            </div>
          </div>

          <div class="view-loyalty-page__right">
            <forms-select
              :appearance="$pepper.Appearance.SUBTLE"
              :options="languages"
              :size="$pepper.Size.S"
              @change="onChange"
              v-model="lang"
            />
          </div>
        </div>

        <div 
          class="view-loyalty-page__page"
          id="sayl-loyalty-page" 
          :style="`width: ${width}`"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { 
  mapGetters,
  mapState, 
} from 'vuex'

import { loadScript } from './loadscript.js'

import CardsMockup from './mockups/cards'
import LoyaltyHistoryMockup from './mockups/loyalty-history'
import ReferralMockup from './mockups/referral'
import ReferralHistoryMockup from './mockups/referral-history'
import TiersMockup from './mockups/tiers'
import UserMockup from './mockups/user'
import VouchersMockup from './mockups/vouchers'

import LoyaltyPageConfig from './config'

export default {
  name: 'LoyaltyPage',

  components: {
    LoyaltyPageConfig,
  },

  inject: ['$image'],

  props: {
    loading: {
      type: Boolean,
      default: true,
    },

    readOnly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      iLoading: true,
      isLogged: false,
      loyalty: null,

      state: {
        historyEmpty: false,
        vouchersEmpty: false,
        referralCode: false
      },

      desktop: true,
      widget: false,
      mobile: false,

      width: '100%',
      key: 1,

      lang: 'all',
      debounce: null,
    }
  },

  computed: {
    ...mapState({
      host: state => state.host.edition,
      edition: state => state.loyalty.loyalty.edition,
      vouchersTemplates: state => state.ecommerce.voucher.templates.all
    }),

    ...mapGetters({
      config: 'sayl/config',
    }),

    cards() {
      return CardsMockup
    },

    content() {
      return this.edition ? this.edition.pageContent : null
    },

    dataset() {
      let points = this.$basil.get(this.edition, 'points')

      let transPoints = this.$basil.get(points, `name[${this.lang}]`)
      if(this.$basil.isNil(transPoints)) {
        transPoints = this.$basil.get(points, 'name.all')
      }

      let pointsRet = {
        symbol: points.symbol,
        name: transPoints
      }

      let content = !this.$basil.isNil(this.edition ? this.edition.pageContent : null) ? this.edition.pageContent.toDataset(this.lang) : {}
      let style = !this.$basil.isNil(this.edition ? this.edition.pageStyle : null) ? this.edition.pageStyle.toDataset() : []

      return this.key && {
        cards: this.cards,
        content,
        display: {
          digitalCards: true,
          physicalCards: true,
          points: true,
          vouchers: true,
          referral: true,
        },
        points: pointsRet,
        rules: this.$basil.get(this.edition, 'rules'),
        referral: this.referral,
        style,
        tiers: this.tiers,
        user: null,
        vouchers: this.vouchers,
      }
    },

    languages() {
      let ret = [
        {
          label: this.$t('sayl.lang_all'),
          value: 'all'
        },
      ]

      this.host.languages.forEach((l) => {
        ret.push({
          label: this.$t('sayl.lang_' + l),
          value: l
        })
      })

      return ret
    },

    loyaltyHistory() {
      return LoyaltyHistoryMockup
    },

    referral() {
      return ReferralMockup
    },

    referralHistory() {
      return ReferralHistoryMockup
    },

    style() {
      return this.edition ? this.edition.pageStyle : null
    },

    tiers() {
      return TiersMockup
    },

    user() {
      return UserMockup
    },

    vouchers() {
      return VouchersMockup
    },

    scriptUrl() {
      return this.$basil.get(this.config, 'loyalty.page.url', 'https://cdn-apps.sayl.cloud/loyalty-page/') + 'index.js'
    }
  },
  
  methods: {
    onClearTarget() {
      this.loyalty.clearTarget()
      document.documentElement.scrollIntoView({ top: 0, behavior: 'smooth' })
    },
    
    onSectionFocus(id) {
      if(id === 'history' || id === 'history_items') {
        this.onLogged(true)
      }

      if(id === 'referral_history' || id === 'referral_history_items') {
        this.onLogged(true)
        this.state.referralCode = true
        this.onState()
      }
      
      setTimeout(() => this.loyalty.focus(id), 100)
    },

    onDesktop() {
      this.width = '100%'
      this.desktop = true
      this.mobile = false
      this.widget = false
      setTimeout(() => this.loyalty.update(), 10)
    },

    onWidget() {
      this.width = '750px'
      this.widget = true
      this.desktop = false
      this.mobile = false
      setTimeout(() => this.loyalty.update(), 10)
    },

    onMobile() {
      this.width = '425px'
      this.mobile = true
      this.desktop = false
      this.widget = false
      setTimeout(() => this.loyalty.update(), 10)
    },

    onChange() {
      clearTimeout(this.debounce)

      this.debounce = setTimeout(() => {
        this.loyalty.set('content', this.dataset.content)
        this.loyalty.set('style', this.dataset.style)
      }, 100)
    },

    onLogged(value) {
      this.isLogged = value
      value ? this.loyalty.set('user', this.user) : this.loyalty.set('user', null)
    },

    onState() {
      this.loyalty.dataset.set('history', this.state.historyEmpty ? [] : this.loyaltyHistory)
      this.loyalty.dataset.set('vouchers', this.state.vouchersEmpty ? [] : this.vouchers)

      this.referral.user = this.state.referralCode ? { code: 'abcd1234' } : null
      this.loyalty.dataset.set('referral', this.referral)
    },
    
    reset() {
      loadScript(this.scriptUrl, this.scriptLoaded)
      
      this.$image.all({ data: { entityType: 'loyalty_program', entityId: this.$route.params.program, fieldName: 'page_header', module: 'loyalty' }})
        .catch((e) => $console.error(e))

      this.edition.pageContent.get('ways').getTranslatable('content').value
        .forEach((content, i) => {
          this.$image.all({ data: { entityType: 'loyalty_program', entityId: this.$route.params.program, fieldName: 'page_ways' + '_' + i, module: 'loyalty' }})
            .then((i) => {
              if(i[0]) {
                content.image.all = i[0].url.all
                this.onChange()
              }
            })
            .catch((e) => $console.error(e))
        })
    },
    
    scriptLoaded() {
      this.iLoading = true

      this.loyalty = window.SaylLoyaltyPage.init(
        this.dataset, 
        {
          context: document.getElementById('sayl-loyalty-page'), 
          shadow: true,
          hasHeight: true,
          edition: !this.readOnly,
          debug: false,
        }
      )
      
      if(this.loyalty) {
        this.loyalty.registerCallback('redeem', (voucher, done) => {
          setTimeout(() => { 
            voucher.code = 'ABCD-1234'
            voucher.redeemed = true

            done()
          }, 1000)
        })

        this.loyalty.registerCallback('login', (done) => {
          setTimeout(() => { 
            this.loyalty.set('user', this.user)
            this.isLogged = true
            done() 
          }, 1000)
        })

        this.loyalty.registerCallback('way', (fqn, done, opts) => {
          if(fqn === 'birthday') {
            if(opts.birthdate) {
              setTimeout(() => done(), 1000)
            } else {
              setTimeout(() => done(this.$t('sayl-loyalty.error_birthdate_required')))
            }
          }
        })

        this.loyalty.registerCallback('subscribe', (done) => {
          setTimeout(() => { 
            this.loyalty.set('user', this.user)
            this.isLogged = true
            done() 
          }, 1000)
        })

        this.loyalty.registerCallback('history-more', (done) => {
          setTimeout(() => {
            let history = this.user.history
            history.push(...history)
            this.loyalty.set('history', history)
            done(false)
          }, 1000)
        })

        this.loyalty.registerCallback('add-card', (token, done) => {
          setTimeout(() => done(null), 1000)
        })

        this.loyalty.registerCallback('referral-history-more', (done) => {
          setTimeout(() => {
            let history = this.referralHistory
            history.push(...history)
            this.loyalty.set('referral-history', history)
            done(false)
          }, 1000)
        })

        this.loyalty.registerCallback('referral-activation', (done) => {
          setTimeout(() => {
            this.referral.user = { code: 'abcd1234' }
            this.loyalty.set('referral', this.referral)
            done()
          }, 1000)
        })
      }

      this.iLoading = false
    },
  },

  mounted() {
    this.$bus.$on('loyalty-page-reset', this.onChange)
    this.iLoading = true
    this.reset()
  },

  beforeDestroy() {
    this.$bus.$off('loyalty-page-reset')

    let page = document.getElementById('sayl-loyalty-page')
    page && page.remove()

    this.loyalty.unmount()
  }
}
</script>
