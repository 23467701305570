<template>
  <div class="loyalty-voucher">
    <header class="loyalty-voucher__heading">
      <div class="loyalty-voucher__header">
        <h3 class="loyalty-voucher__title">{{ name }}</h3>
        
        <data-tag 
          :appearance="$pepper.Appearance.PRIMARY"
          :size="$pepper.Size.S"
        >{{ type }}</data-tag>
      </div>

      <div class="loyalty-voucher__values">
        <p class="loyalty-voucher__value">{{ amount }}</p>
        
        <data-badge 
          :appearance="$pepper.Appearance.PRIMARY"
          class="loyalty-voucher__cost"
          :size="$pepper.Size.S"
          :value="cost + symbol"
        />
      </div>
    </header>

    <div class="loyalty-voucher__body" v-if="false"></div>

    <footer class="loyalty-voucher__footer">
      <div class="loyalty-voucher__action">
        <actions-button
          :appearance="$pepper.Appearance.SUBTLE"
          class="loyalty-voucher__actionwe"
          icon-post="visible"
          @click="$emit('view', value, index)"
          :size="$pepper.Size.S"
        >{{ $t('sayl-ecommerce.loyalty_voucher_view') }}</actions-button>
      </div>

      <div class="loyalty-voucher__action">
        <actions-button
          :appearance="$pepper.Appearance.SUBTLE"
          class="loyalty-voucher__actionwe"
          icon-post="trash"
          :size="$pepper.Size.S"
          @click="$emit('delete', value, index)"
          v-if="!readOnly"
        >{{ $t('sayl-ecommerce.loyalty_voucher_delete') }}</actions-button>
      </div>

      <div class="loyalty-voucher__action">
        <actions-button
          :appearance="$pepper.Appearance.SUBTLE"
          class="loyalty-voucher__actionwe"
          icon-post="arrow-right"
          :size="$pepper.Size.S"
          @click="$emit('edit', value, index)"
          v-if="!readOnly"
        >{{ $t('sayl-ecommerce.loyalty_voucher_edit') }}</actions-button>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LoyaltyVoucherCard',

  props: {
    index: {
      type: Number,
      default: -1
    },

    readOnly: {
      type: Boolean,
      default: false,
    },

    value: {
      type: Object,
      required: true,
    }
  },

  computed: {
    ...mapState({
      edition: state => state.loyalty.loyalty.edition,
    }),

    amount() {
      let ret = this.$basil.get(this.value, 'amount')
      let type = this.$basil.get(this.value, 'amountType', 'number')

      if(type === 'perc' && !this.$basil.isNil(ret)) {
        return `${ret}%`
      }

      return !this.$basil.isNil(ret) ? this.$basil.i18n.currency(parseInt(ret)) : null
    },

    cost() {
      return this.$basil.get(this.value, 'required_points', 0)
    },

    name() {
      return this.$basil.get(this.value, 'name.all')
    },

    symbol() {
      return this.$basil.get(this.edition, 'points.symbol')
    },

    type() {
      return this.$t(`sayl-ecommerce.voucher_type_${this.$basil.get(this.value, 'type')}`)
    },
  }
}
</script>
