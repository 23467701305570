<template>
  <ui-details 
    :auto-padding="false"
    class="view-loyalty-page__details"
    :key="key"
    :ref="detail.name"
    :open="isOpened"
    @summary="$emit('summary')">
    <template #summary>{{ $t(`sayl-loyalty.page_${detail.name}`)}}</template>

    <ui-details
      class="view-loyalty-page__subdetails"
      :auto-padding="false"
      :key="`${section.name}_${key}`"
      :open="isSectionOpened(section.name)"
      @summary="openSection(section.name)"
      v-for="section in detail.sections">
      <template #summary>{{ $t(`sayl-loyalty.loyalty_page_${section.label}`)}}</template>
      
      <!-- Handling array -->
      <div
        class="view-loyalty-page__article"
        :key="`model_${j}`"
        v-for="(model, j) in section.models">
        <div class="flow">
          <div
            :key="comp.fqn"
            v-for="(comp) in section.values">
            <component
              :class="hasTranslations(comp.item.subproperty, model)"
              :is="comp.component"
              @iconClick="onOpenTranslations(comp.item.subproperty, comp.item.component, model)"
              @input="$emit('change')"
              v-if="comp.item.subtype !== 'image'"
              v-bind="comp.attrs"
              v-model="model[comp.item.subproperty].all"
            >{{ comp.label }}</component>

            <component
              :is="comp.component"
              :accept="comp.attrs.accept"
              :module="comp.attrs.module"
              :entity-id="comp.attrs.entityId"
              :entity-type="comp.attrs.entityType"
              :fieldName="comp.attrs.fieldName + '_' + j"
              :title="comp.attrs.title"
              @change="({ data, images, multiple, values }) => onImageChange({ data, images, multiple, values }, section.name, comp.item.subproperty, model)"
              @clear="({ data, images, multiple, values }) => onImageClear({ data, images, multiple, values }, section.name, comp.item.subproperty, model)"
              v-if="comp.item.subtype === 'image'"
            >{{ comp.label }}</component>
          </div>
          
          <actions-button
            :appearance="$pepper.Appearance.DEFAULT"
            :intent="$pepper.Intent.DANGER"
            class="view-loyalty-page__actions"
            icon-post="trash"
            :size="$pepper.Size.S"
            @click="() => onContentRemove(section, j)"
            v-if="((section.section.removable || false) && !readOnly)"
          >{{ $t('sayl-loyalty.loyalty_page_remove_element') }}</actions-button>
        </div>
      </div>

      <!-- Array ADD -->
      <div 
        class="view-loyalty-page__article" 
        :key="`add_${section.section.section}_${addKey}`"
        v-if="section.section && section.section.addable && !readOnly">
        <div class="flow">
          <div 
            :key="comp.fqn"
            v-for="comp in section.values">
            <component
              :class="hasTranslations(comp.item.subproperty, add[section.section.section])"
              :errors="addErrors[comp.item.subproperty]"
              :is="comp.component"
              :icon-post="comp.attrs.iconPost"
              :placeholder="$t('sayl.placeholder')"
              :read-only="readOnly"
              icon-post-interactive
              :type="comp.attrs.type"
              @iconClick="onOpenTranslations(comp.item.subproperty, comp.item.component, add[section.section.section])"
              v-model="add[section.section.section][comp.item.subproperty].all"
            >{{ comp.label }}</component>
          </div>
          
          <actions-button
            :appearance="$pepper.Appearance.PRIMARY"
            class="view-loyalty-page__actions"
            icon-post="plus"
            :size="$pepper.Size.S"
            @click="onContentAdd(section)"
          >{{ $t('sayl-loyalty.loyalty_page_add_element') }}</actions-button>
        </div>
      </div>

      <div 
        class="view-loyalty-page__article"
        v-if="section.type !== 'array'">
        <div class="flow">
          <div 
            :key="comp.fqn"
            v-for="comp in section.values">
            <component
              :is="comp.component"
              v-bind="comp.attrs"
              v-on="comp.listeners"
            >{{ comp.label }}</component>
          </div>
        </div>
      </div>
    </ui-details>

    <modal-translate 
      :bind="translationsType.includes('merge-tags') ? { mergeTags: tags } : {}"
      @close="translastionClosed"
      :field="translationsField"
      :read-only="readOnly"
      :type="translationsType"
      :visible="showTranslations" 
    />
  </ui-details>
</template>

<script>
import MixinBuilder from './builder.js'
import { MixinImage } from '@sayl/admin-common'

export default {
  name: 'LoyaltyPageProperty',

  inject: ['$image'],

  mixins: [ MixinBuilder, MixinImage ],

  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },

    isLogged: {
      type: Boolean,
      default: false,
    },

    opened: {
      type: Boolean,
      default: false
    },

    pageContent: {
      type: Object
    },

    pageStyle: {
      type: Object,
    },

    target: {}
  },

  data() {
    return {
      key: 1,
      openedSection: null,
    }
  },

  watch: {
    opened: {
      immediate: true,
      handler() {
        this.openSection(null)
        setTimeout(() => {
          this.openSection(this.detail.sections[0].name)
        }, 10)
        this.key++
      }
    }
  },

  computed: {
    isOpened() {
      return this.key && this.opened
    }
  },

  methods: {
    isSectionOpened(section) {
      return !this.$basil.isNil(this.openedSection) && this.openedSection === section
    },

    openSection(section) {
      if(this.openedSection === section) {
        this.openedSection = null
      } else {
        this.openedSection = section
      }
      this.key++
    }
  }
}
</script>
