<template>
  <div class="c-stats">
    <div
      v-for="(child, index) in children"
      :key="index"
      class="c-stats__item">
      <div class="c-stats__label">{{ child.label }}</div>
      <div class="c-stats__value">{{ child.value }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UiStat",

  props: {
    children: {
      type: Array
    }
  },
}
</script>
