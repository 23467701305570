<template>
  <section
    class="loyalty-preview"
    :style="`background-color: ${backgroundColor};`">
    <div class="loyalty-preview__logo">
      <img :src="logo" />
    </div>

    <div class="loyalty-preview__body">
      <img :src="strip" class="loyalty-preview__strip"/>
      <div 
        class="loyalty-preview__label"
        :style="`color: ${stripColor}`">
        <div class="value">123456</div>
        <div class="label">{{ pointsName }}</div>
      </div>
    </div>

    <div 
      class="loyalty-preview__text"
      :style="`color: ${textColor}`">
      <div class="label">Token</div>
      <div class="value">ABC123</div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'LoyaltyDigitalPassPreview',

  props: {
    icon: { type: String | File },
    logo: { type: String | File }, 
    strip: { type: String | File },
    backgroundColor: String,
    stripColor: String,
    textColor: String,
    pointsName: {
      type: String,
      default: 'points'
    }
  },
}
</script>
