const callbacks = [];
let loaded = false;

const isScriptInjected = (scriptUrl) => {
  const scripts = document.querySelectorAll('script');
  let injected = false;

  scripts.forEach((script) => {
    if (script.src.includes(scriptUrl)) {
      injected = true;
    }
  });

  return injected;
};

const addCallback = (callback) => {
  callbacks.push(callback);
};

const runCallbacks = () => {
  if (loaded) {
    let callback;

    while ((callback = callbacks.shift())) {
      callback();
    }
  }
};

export const loadScript = (scriptUrl, callback) => {
  addCallback(callback);

  if (!isScriptInjected(scriptUrl)) {
    const script = document.createElement('script');
    script.setAttribute('src', scriptUrl);
    script.onload = () => {
      loaded = true;
      runCallbacks();
    };
    document.head.appendChild(script);
  } else {
    loaded = true;
    runCallbacks();
  }
};
