<template>
  <ui-card
    :appearance="$pepper.Appearance.SUBTLE"
    article>
    <template v-slot:header>
      <div>
        <h3 class="ui-card__title">{{ $t('sayl-ecommerce.loyalty_config_ssm_remdemption_title') }}</h3>
        <p class="ui-card__description">{{ $t('sayl-ecommerce.loyalty_config_ssm_redemption_description') }}</p>
      </div>
    </template>

    <!-- Intro -->
    <div 
      class="ui-card__details layout-modal__details flow" 
      open>
      <div class="ui-card__details-body layout-modal__details-body">
        <forms-checkbox
          :appearance="$pepper.Appearance.TOGGLE"
          :hint="$t('sayl-ecommerce.loyalty_ssm_allow_custom_redemption_hint')"
          :size="$pepper.Size.S"
          v-model="edition.allowCustomRedemption"
        >{{ $t('sayl-ecommerce.loyalty_ssm_allow_custom_redemption') }}</forms-checkbox>

        <forms-iterable
          auto-add
          :options="options"
          :i18n="{ filled: $t('sayl.iterable_hint_add') }"
          v-model="edition.redemptionLevels"
        >{{ $t('sayl-ecommerce.loyalty_ssm_redemption_levels',  {currency: this.currency})}}</forms-iterable>
      </div>
    </div>
  </ui-card>
</template>

<script>
import { mapState } from 'vuex'

import { MixinError } from '@sayl/admin-common'

export default {
  name: 'LoyaltySSMRedemption',

  mixins: [ MixinError ],

  props: {
    errors: {
      type: Object
    },

    readOnly: {
      type: Boolean,
      default: false
    }
  },
  
  computed: {
    ...mapState({
      edition: state => state.loyalty.loyalty.edition,
      host: state => state['host'].edition
    }),

    currency() {
      let ret = { code: 'EUR' }
      if(this.host && this.host.currencies && this.host.currencies.length > 0) {
        ret = this.host.currencies.find((c) => c.is_default === true)
      }

      return ret && ret.code ? ret.code : 'EUR'
    },

    options() {
      return [
        {
          label: this.$t('sayl-ecommerce.loyalty_ssm_redemption_level'),
          property: 'value',
          placeholder: this.$t('sayl.placeholder'),
          type: 'number',
          min: '0',
          step: '1',
          required: true,
        }
      ]
    },
  },
}
</script>
