<template>
  <layout-base>
    <div class="notfound">
      <div class="notfound__inner">
        <header class="notfound__header">
          <img 
            :src="$getImageSrc('/statics/images/logos/sayl/glyph-white.svg')" 
            alt="Sayl Logo"
          />
        </header>

        <section class="notfound__body">
          <h1 class="notfound__title">Not Allowed!</h1>
          <h2 class="notfound__subtitle">Sorry, but your are not allowed to access the resource.</h2>
        </section>

        <div class="footer notfound__footer">
          <div class="notfound__description">You can return to our <router-link to="/">front page</router-link>, or <a href="mailto:info@infinity-mobile.io">drop us a line</a> if you can't find what you're looking for.</div>
        </div>
      </div>
    </div>
  </layout-base>
</template>

<script>
import LayoutBase from '../layouts/base'

export default {
  name: 'NotAllowed',

  components: {
    LayoutBase
  },
}
</script>
