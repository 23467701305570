<template>
  <div style="display: contents;">
    <article class="layout-modal__article row group">
      <div class="flow" style="flex:1">
        <forms-file
          accept="*"
          :errors="getErrors('image')"
          :hint="$t('sayl-audiences.challenge_reward_ntf_custom_image_hint')"
          :key="key"
          :placeholder="$t('sayl-audiences.challenge_reward_ntf_custom_image_placeholder')"
          @input="onImageChange"
          v-model="image">
          <div>{{ $t('sayl-audiences.challenge_reward_ntf_custom_image_placeholder') }}</div>
          <div 
            class="modal-reward__preview"
            v-if="preview != null" >
            <img 
              class="preview"
              :src="preview" 
              v-if="imageType === 'image'"
            />

            <video
              autoplay
              class="preview"
              loop
              muted
              :src="preview"
              v-if="preview && imageType === 'video'"
            />
          </div>
        </forms-file>
      </div>

      <actions-button
        class="modal-reward__clear"
        icon-post="cross"
        :disabled="value.settings.nft_image == null"
        @click="onRemoveNftImage"
      />
    </article>

    <!-- Attributes -->
    <article 
      class="layout-modal__article flow"
      v-if="hasAttributes">
      <h3 class="layout-modal__subtitle">{{ $t('sayl-audiences.referral_reward_nft_attributes_title') }}</h3>

      <div
        class="group"
        :key="`attribute_${i}`"
        v-for="(attribute, i) in nft.attributes">

        <div 
          class="modal-reward__img" 
          v-if="attribute.type === 'media' && value.settings.nft_attributes[i].value != null && typeof value.settings.nft_attributes[i].value == 'string'">
          <img class="preview" :src="getIpfs(value.settings.nft_attributes[i].value)" />
        </div>

        <div class="modal-reward__cmp">
          <component
            :is="is(attribute)"
            v-bind="cmpAttrs(attribute)"
            v-on="cmpListeners(attribute)"
            v-model="value.settings.nft_attributes[i].value"
          >{{ $basil.get(attribute, 'label') }}</component>

          <div 
            class="forms-control"
            :key="key"
            v-if="$basil.get(cmpAttrs(attribute), 'errors.length', 0) > 0 && attribute.type === 'media'">
            <div class="forms-control__footer">
              <div class="forms-control__errors">
                <forms-label
                  :intent="$pepper.Intent.DANGER"
                  class="forms-control__label">
                  <span 
                    class="forms-label__content"
                    :key="err"
                    v-for="err in cmpAttrs(attribute).errors"
                  >{{ err }}</span>
                </forms-label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { MixinError } from '@sayl/admin-common'

export default {
  name: 'ChallengeTypeNFTAttributes',
  
  mixins: [
    MixinError
  ],

  props: {
    errors: {},
    value: {
      type: Object,
      required: true
    }
  },

  data() {
    return { 
      attributes: [],
      // errors: {},
      image: null,
      loading: true,
      key: 1,
      preview: null,
      imgType: null,
    }
  },

  computed: {
    ...mapState({
      nfts: state => state.audiences.challenge.nfts.all
    }),

    hasAttributes() {
      return this.$basil.get(this.nft, 'attributes.length', 0) > 0
    },

    imageType() {
      let type = this.$basil.get(this.value, 'settings.nft_image.type.category')

      if(!type && this.imgType) {
        type = this.imgType
      }

      if(type && type.includes('video')) {
        return 'video'
      }

      if(type && type.includes('image')) {
        return 'image'
      }

      if(type && type.includes('document')) {
        return 'document'
      }

      return null
    },

    nft() {
      return this.nfts ? this.nfts.find(nft => nft.id === this.value.settings.nft_id) : null
    }
  },

  methods: { 
    cmpAttrs(attribute) {
      let ret = {
        placeholder: this.$t('sayl.placeholder'),
        required: true,
        type: 'text',
        errors: this.getErrors(`attributes.${this.$basil.get(attribute, 'position', 0) - 1}`)
      }

      switch(this.$basil.get(attribute, 'type', 'string')) {
        case 'number': 
          ret.type = 'number'
          ret.min = 0
          break

        case 'timestamp': 
          ret.type = 'datetime-local'
          break

        case 'media':
          delete ret.placeholder
          break
      }

      return this.key && ret
    },

    cmpListeners(attribute) {
      let ret = {
        change: () => {
          this.onRemoveError(`attributes.${this.$basil.get(attribute, 'position', 0) - 1}`)
          this.key++
        }
      }

      switch(this.$basil.get(attribute, 'type', 'string')) {

        case 'media':
          ret.input = (c) => {
            this.onRemoveError(`attributes.${this.$basil.get(attribute, 'position', 0) - 1}`)
            this.key++
          }
          break
      }

      return ret
    },

    getIpfs(value) {
      return value.replace('ipfs://', 'https://ipfs.sayl.cloud/ipfs/')
    },

    is(attribute) {
      let ret = 'forms-input'
      
      switch(this.$basil.get(attribute, 'type', 'string')) {
        case 'media':
          ret = 'forms-file'
          break

        default: 
          ret = 'forms-input'
          break
      }

      return ret
    },

    onRemoveNftImage() {
      this.value.settings.nft_image = null
      this.preview = null
    },

    onImageChange(i) {
      var reader = new FileReader()
      let ctx = this
      this.imgType = null

      if(this.$basil.isString(i)) {
        this.preview = i
        fetch(i, { method: 'head' })
          .then(({ headers }) => this.imgType = headers.get('content-type'))
          .catch((e) => $console.error(e))
          .finally(() => this.key++)
      } else {
        this.value.settings.nft_image = this.image

        reader.readAsDataURL(i.src)
        reader.onload = function() {
          ctx.preview = reader.result
          ctx.key++
        }

        reader.onerror = function (error) {
          ctx.preview = null
          ctx.image = null
          ctx.settings.nft_image = null
          $console.error('Error parsing images')
        }
      }
    },

    reset() {
      if(!this.$basil.isNil(this.nft.attributes) && this.$basil.get(this.nft.attributes, 'length', 0) > 0) {
        this.nft.attributes.forEach((a, i) => a.value = null)
      }

      if(!this.$basil.isNil(this.value.settings.nft_image)) {
        let i = null
        
        if(this.value.settings.nft_image.constructor.name === 'PepperFile' ) {
          this.image = this.value.settings.nft_image
        } else {
          i = this.value.settings.nft_image.replace('ipfs://', 'https://ipfs.sayl.cloud/ipfs/')
        }

        this.onImageChange(this.$basil.isString(this.value.settings.nft_image) ? i : this.value.settings.nft_image)
      }
      this.loading = false
    }
  },

  mounted() {
    this.reset()
  }
}
</script>
