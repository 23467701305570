const base = [
  {
    component: 'forms-input',
    fqn: 'header-style-bg',
    label: 'bg',
    property: '--slp-header-bg',
    section: 'header',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-image-input',
    fqn: 'header-banner',
    label: 'banner',
    property: 'banner',
    section: 'header',
    subtype: 'image',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'header-style-filigrane',
    label: 'filigrane_stroke',
    property: '--slp-header-filigrane-bg',
    section: 'header',
    subtype: 'color',
    type: 'style',
  },
]

const description = [
  {
    component: 'forms-textarea',
    fqn: 'header-content-descr',
    label: 'description',
    property: 'description',
    section: 'header',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'header-style-descr-bg',
    label: 'bg',
    property: '--slp-header-descr-bg',
    section: 'header',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'header-style-descr-fc',
    label: 'fc',
    property: '--slp-header-descr-fc',
    section: 'header',
    subtype: 'color',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'header-style-descr-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-header-descr-fs',
    section: 'header',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'header-style-descr-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-header-descr-fw',
    section: 'header',
    subtype: 'fontWeight',
    type: 'style',
  },
]

const footer = [
  {
    component: 'forms-input',
    fqn: 'header-content-btn-register',
    label: 'btn_register',
    logged: false,
    property: 'btn-register',
    section: 'header',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'header-content-btn-login',
    label: 'btn_login',
    logged: false,
    property: 'btn-login',
    section: 'header',
    type: 'content',
  },
]

const title = [
  {
    component: 'forms-textarea',
    fqn: 'header-content-title',
    label: 'title',
    property: 'title',
    section: 'header',
    type: 'content',
  },
  {
    component: 'forms-input',
    fqn: 'header-style-title-bg',
    label: 'bg',
    property: '--slp-header-title-bg',
    section: 'header',
    subtype: 'color',
    type: 'style',
  },
  {
    component: 'forms-input',
    fqn: 'header-style-title-fc',
    label: 'fc',
    property: '--slp-header-title-fc',
    section: 'header',
    subtype: 'color',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'header-style-title-fs',
    label: 'fs',
    options: 'fontSizes',
    property: '--slp-header-title-fs-default',
    section: 'header',
    subtype: 'fontSize',
    type: 'style',
  },
  {
    component:'forms-select',
    fqn: 'header-style-title-fw',
    label: 'fw',
    options: 'fontWeights',
    property: '--slp-header-title-fw',
    type: 'style',
    section: 'header',
    subtype: 'fontWeight',
  },
]

export default [ 
  ...base, 
  ...description, 
  ...footer,
  ...title, 
] 
