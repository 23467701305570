export default [
  {
    label: '400',
    value: '400',
  },
  {
    label: '500',
    value: '500',
  },
  {
    label: '600',
    value: '600',
  },
  {
    label: '700',
    value: '700',
  },
  {
    label: '800',
    value: '800',
  },
  {
    label: '900',
    value: '900',
  },
]
