let general = {
  name: 'general',
  sections: [
    {
      label: 'general_base',
      name: 'general.base',
      values: [
        'general-style-bg',
      ]
    },
    {
      label: 'general_merge_tags',
      name: 'general.merge-tags',
      values: [
        'general-style-merge-tags-fc',
        'general-style-merge-tags-fw',
      ]
    },
    {
      label: 'general_btn_default',
      name: 'general.btn.default',
      values: [
        'general-style-btn-default-bg',
        'general-style-btn-default-bc',
        'general-style-btn-default-fc',
        'general-style-btn-default-fs',
        'general-style-btn-default-fw',
      ]
    },
    {
      label: 'general_btn_primary',
      name: 'general.btn.primary',
      values: [
        'general-style-btn-primary-bg',
        'general-style-btn-primary-fc',
        'general-style-btn-primary-fs',
        'general-style-btn-primary-fw',
      ]
    },
    {
      label: 'general_forms_input_base',
      name: 'general.forms.input.base',
      values: [
        'general-style-input-bg',
        'general-style-input-bc',
        'general-style-input-bc-hover',
        'general-style-input-fs',
        'general-style-input-fc',
        'general-style-input-fw',
      ]
    },
    {
      label: 'general_forms_input_icon',
      name: 'general.forms.input.icon',
      values: [
        'general-style-input-icon-bg',
        'general-style-input-icon-fc',
        'general-style-input-icon-fc-hover',
      ]
    },
  ]
}

let header = {
  name: 'header', 
  sections: [
    {
      context: ['header'],
      label: 'header_base',
      name: 'header.base',
      values: [
        'header-banner',
        'header-style-bg',
        'header-style-filigrane',
      ]
    },
    {
      context: ['header', 'header.title'],
      label: 'header_title',
      name: 'header.title',
      values: [
        'header-content-title',
        'header-style-title-bg',
        'header-style-title-fc',
        'header-style-title-fs',
        'header-style-title-fw',
      ]
    },
    {
      context: ['header', 'header.descr'],
      label: 'header_description',
      name: 'header.description',
      values: [
        'header-content-descr',
        'header-style-descr-bg',
        'header-style-descr-fc',
        'header-style-descr-fs',
        'header-style-descr-fw',
      ]
    },
    {
      context: ['header', 'header.footer'],
      label: 'header_footer',
      name: 'header.footer',
      values: [
        'header-content-btn-register',
        'header-content-btn-login',
      ]
    },
  ]
}

let how = {
  name: 'how', 
  sections: [
    {
      context: ['how'],
      label: 'how_base',
      name: 'how.base',
      values: [
        'how-style-bg',
      ]
    },
    {
      context: ['how', 'how.title'],
      label: 'how_title',
      name: 'how.title',
      values: [
        'how-content-title',
        'how-style-title-fc',
        'how-style-title-fs',
        'how-style-title-fw',
      ]
    },
    {
      context: ['how', 'how.descr'],
      label: 'how_description',
      name: 'how.description',
      values: [
        'how-content-descr',
        'how-style-descr-fc',
        'how-style-descr-fs',
        'how-style-descr-fw',
      ]
    },
    {
      context: ['how', 'how.card'],
      label: 'how_card',
      name: 'how.card',
      values: [
        'how-content-meta',
        'how-style-card-bg',
        'how-style-card-fc',
        'how-style-card-stroke-bg',
        'how-style-card-secondary-bg',
        'how-style-card-tertiary-bg',
        'how-style-card-quaternary-bg',
      ]
    },
    {
      context: ['how', 'how.actions'],
      label: 'how_actions',
      name: 'how.actions',
      values: [
        'how-content-btn-redeem',
        'how-content-btn-history',
      ]
    },
  ]
}

let howItem = {
  name: 'how_items', 
  section: 'how',
  logged: false,
  sections: [
    {
      context: ['how.item'],
      label: 'how_items_content',
      name: 'how.items',
      type: 'array',
      values: [
        'how-content-items',
      ]
    },
    {
      context: ['how.item'],
      label: 'how_items_index_style',
      name: 'how.item.index',
      values: [
        'how-style-item-index-fc',
      ]
    },
    {
      context: ['how.item'],
      label: 'how_items_title_style',
      name: 'how.item.title',
      values: [
        'how-style-item-title-fc',
        'how-style-item-title-fs',
        'how-style-item-title-fw',
      ]
    },
    {
      context: ['how.item'],
      label: 'how_items_description_style',
      name: 'how.item.descr',
      values: [
        'how-style-item-descr-fc',
        'how-style-item-descr-fs',
        'how-style-item-descr-fw',
      ]
    },
  ]
}

let walletPass = {
  name: 'wallet', 
  section: 'cards',
  sections: [
    {
      context: ['cards', 'cards.wallet'],
      label: 'wallet_pass_base',
      name: 'wallet',
      values: [
        'cards-style-wallet-pass-bg',
        'cards-style-wallet-pass-bc',
        'cards-style-wallet-pass-filigrane-bg',
      ]
    },
    {
      context: ['cards', 'cards.wallet'],
      label: 'wallet_pass_title',
      name: 'wallet.title',
      values: [
        'cards-content-wallet-pass-title',
        'cards-style-wallet-pass-title-fc',
        'cards-style-wallet-pass-title-fs',
        'cards-style-wallet-pass-title-fw',
      ]
    },
  ]
}

let cards = {
  name: 'cards', 
  section: 'cards',
  sections: [
    {
      context: ['cards', 'cards.cards'],
      label: 'cards_base',
      name: 'cards',
      values: [
        'cards-card-bg',
        'cards-card-bc',
      ]
    },
    {
      context: ['cards', 'cards.cards'],
      label: 'cards_header',
      name: 'cards.header',
      values: [
        'cards-card-header-primary-bg',
        'cards-card-header-secondary-bg',
      ]
    },
    {
      context: ['cards', 'cards.cards'],
      label: 'cards_title',
      name: 'cards.title',
      values: [
        'cards-content-digital-label',
        'cards-content-physical-label',
        'cards-style-card-title-fc',
        'cards-style-card-title-fs',
        'cards-style-card-title-fw',
      ]
    },
    {
      context: ['cards', 'cards.cards'],
      label: 'cards_descr',
      name: 'cards.descr',
      values: [
        'cards-style-card-descr-fc',
        'cards-style-card-descr-fs',
        'cards-style-card-descr-fw',
      ]
    },
  ]
}

let cardsAdd = {
  name: 'cards_add', 
  section: 'cards',
  sections: [
    {
      context: ['cards', 'cards.cards'],
      label: 'cards_add_base',
      name: 'cards.add.base',
      values: [
        'cards-style-card-add-bg',
        'cards-style-card-add-bc',
        'cards-style-card-add-bg-opacity',
      ]
    },
    {
      context: ['cards', 'cards.cards'],
      label: 'cards_add_icon',
      name: 'cards.add.icon',
      values: [
        'cards-style-card-add-icon-bg',
        'cards-style-card-add-icon-fc',
      ]
    },
    {
      context: ['cards', 'cards.cards'],
      label: 'cards_add_title',
      name: 'cards.add.title',
      values: [
        'cards-content-card-add-title',
        'cards-style-card-add-title-fc',
        'cards-style-card-add-title-fs',
        'cards-style-card-add-title-fw',
      ]
    },
    {
      context: ['cards', 'cards.cards'],
      label: 'cards_add_form',
      name: 'cards.add.form',
      values: [
        'cards-content-card-add-placeholder',
        'cards-content-card-add-btn-confirm',
        'cards-content-card-add-btn-cancel',
      ]
    },
  ]
}

let ways = {
  name: 'ways', 
  sections: [
    {
      context: ['ways'],
      label: 'ways_base',
      name: 'ways.base',
      values: [
        'ways-style-primary-bg',
        'ways-style-secondary-bg',
        'ways-style-filigrane',
      ]
    },
    {
      context: ['ways', 'ways.title'],
      label: 'ways_title',
      name: 'ways.title',
      values: [
        'ways-content-title',
        'ways-style-title-fc',
        'ways-style-title-fs',
        'ways-style-title-fw',
      ]
    },
    {
      context: ['ways', 'ways.descr'],
      label: 'ways_description',
      name: 'ways.description',
      values: [
        'ways-content-descr',
        'ways-style-descr-fc',
        'ways-style-descr-fs',
        'ways-style-descr-fw',
      ]
    },
  ]
}

let waysItem = {
  name: 'ways_items', 
  section: 'ways',
  sections: [
    {
      context: ['ways.item'],
      label: 'ways_items_content',
      name: 'ways.items',
      type: 'array',
      values: [
        'ways-content-items',
      ]
    },
    {
      context: ['ways.item'],
      label: 'ways_items_base',
      name: 'ways.items.base',
      values: [
        'ways-style-item-bg',
        'ways-style-item-bc',
      ]
    },
    {
      context: ['ways.item'],
      label: 'ways_items_title',
      name: 'ways.item.title',
      values: [
        'ways-style-item-title-fc',
        'ways-style-item-title-fs',
        'ways-style-item-title-fw',
      ]
    },
    {
      context: ['ways.item'],
      label: 'ways_items_description',
      name: 'ways.item.descr',
      values: [
        'ways-style-item-descr-fc',
        'ways-style-item-descr-fs',
        'ways-style-item-descr-fw',
      ]
    },
  ]
}

let usage = {
  name: 'usage', 
  sections: [
    {
      context: ['usage'],
      label: 'usage_base',
      name: 'usage.base',
      values: [
        'usage-style-bg',
        'usage-content-empty-title',
      ]
    },
    {
      context: ['usage', 'usage.title'],
      label: 'usage_title',
      name: 'usage.title',
      values: [
        'usage-content-title',
        'usage-style-title-fc',
        'usage-style-title-fs',
        'usage-style-title-fw',
      ]
    },
    {
      context: ['usage', 'usage.descr'],
      label: 'usage_description',
      name: 'usage.description',
      values: [
        'usage-content-descr',
        'usage-style-descr-fc',
        'usage-style-descr-fs',
        'usage-style-descr-fw',
      ]
    },
    {
      context: ['usage', 'usage.meta'],
      label: 'usage_meta',
      name: 'usage.meta',
      values: [
        'usage-content-meta',
        'usage-style-meta-fc',
        'usage-style-meta-fs',
        'usage-style-meta-fw',
      ]
    },
    {
      context: ['usage'],
      label: 'usage_progress_bar_front',
      name: 'usage.progress.front',
      values: [
        'usage-style-item-progress-primary',
        'usage-style-item-progress-secondary',
        'usage-style-item-progress-tertiary',
      ]
    },
    {
      context: ['usage'],
      label: 'usage_progress_bar_back',
      name: 'usage.progress.back',
      values: [
        'usage-style-item-progress-back-bg'
      ]
    },
  ]
}

let usageItem = {
  name: 'usage_items', 
  section: 'usage',
  sections: [
    {
      context: ['usage.item.discount', 'usage.item.perks', 'usage.item.wallet'],
      label: 'usage_item_base',
      name: 'usage.item.base',
      values: [
        'usage-style-item-bg',
        'usage-style-item-bc',
        'usage-style-item-shadow',
      ]
    },
    {
      context: ['usage.item.discount', 'usage.item.perks', 'usage.item.wallet'],
      label: 'usage_item_title',
      name: 'usage.item.title',
      values: [
        'usage-style-item-title-fc',
        'usage-style-item-title-fs',
        'usage-style-item-title-fw',
      ]
    },
    {
      context: ['usage.item.discount', 'usage.item.perks', 'usage.item.wallet'],
      label: 'usage_item_description',
      name: 'usage.item.descr',
      values: [
        'usage-style-item-descr-fc',
        'usage-style-item-descr-fs',
        'usage-style-item-descr-fw',
      ]
    },
    {
      context: ['usage.item.discount', 'usage.item.perks', 'usage.item.wallet'],
      label: 'usage_item_validity_period',
      name: 'usage.item.meta',
      values: [
        'usage-voucher-validity-period',
        'usage-style-item-meta-fc',
        'usage-style-item-meta-fs',
        'usage-style-item-meta-fw',
      ]
    },
    {
      context: ['usage.item.discount'],
      label: 'usage_item_discount',
      name: 'usage.item.discount',
      values: [
        'usage-style-item-icon-discount-bg',
        'usage-style-item-icon-discount-fc',
      ]
    },
    {
      context: ['usage.item.perks'],
      label: 'usage_item_perks',
      name: 'usage.item.perks',
      values: [
        'usage-style-item-icon-perks-bg',
        'usage-style-item-icon-perks-fc',
      ]
    },
    {
      context: ['usage.item.wallet'],
      label: 'usage_item_wallet',
      name: 'usage.item.wallet',
      values: [
        'usage-style-item-icon-wallet-bg',
        'usage-style-item-icon-wallet-fc',
      ]
    },
    {
      context: ['usage.item.discount', 'usage.item.perks', 'usage.item.wallet'],
      label: 'usage_item_actions_label',
      name: 'usage.item.actions',
      values: [
        'usage-content-btn-redeem',
        'usage-content-btn-confirm',
        'usage-content-btn-cancel',
        'usage-content-voucher-left',
      ]
    },
    {
      context: ['usage.item.discount', 'usage.item.perks', 'usage.item.wallet'],
      label: 'usage_item_style_default',
      name: 'usage.item.style.default.actions',
      values: [
        'usage-style-item-btn-default-bg',
        'usage-style-item-btn-default-bc',
        'usage-style-item-btn-default-fc',
        'usage-style-item-btn-default-fs',
        'usage-style-item-btn-default-fw',
      ]
    },
    {
      context: ['usage.item.discount', 'usage.item.perks', 'usage.item.wallet'],
      label: 'usage_item_style_primary',
      name: 'usage.item.style.primary.actions',
      values: [
        'usage-style-item-btn-primary-bg',
        'usage-style-item-btn-primary-bc',
        'usage-style-item-btn-primary-fc',
        'usage-style-item-btn-primary-fs',
        'usage-style-item-btn-primary-fw',
      ]
    },
    {
      context: ['usage.item.discount', 'usage.item.perks', 'usage.item.wallet'],
      label: 'usage_item_copy',
      name: 'usage.item.copy',
      values: [
        'usage-content-btn-copy',
        'usage-content-copied',
      ]
    },
  ]
}

let history = {
  name: 'history', 
  sections: [
    {
      context: ['history'],
      label: 'history_base',
      name: 'history.base',
      values: [
        'history-style-bg',
        'history-content-empty-title',
        'history-content-btn-more',
      ]
    },
    {
      context: ['history', 'history.title'],
      label: 'history_title',
      name: 'history.title',
      values: [
        'history-content-title',
        'history-style-title-fc',
        'history-style-title-fs',
        'history-style-title-fw',
      ]
    },
    {
      context: ['history', 'history.descr'],
      label: 'history_description',
      name: 'history.description',
      values: [
        'history-content-descr',
        'history-style-descr-fc',
        'history-style-descr-fs',
        'history-style-descr-fw',
      ]
    },
    {
      context: ['history', 'history.meta'],
      label: 'history_meta',
      name: 'history.meta',
      values: [
        'history-content-meta',
        'history-style-meta-fc',
        'history-style-meta-fs',
        'history-style-meta-fw',
      ]
    },
  ]
}

let historyItem = {
  name: 'history_items',
  section: 'history', 
  sections: [
    {
      context: ['history', 'history.item'],
      label: 'history_item_base',
      name: 'history.item.base',
      values: [
        'history-style-item-bg',
        'history-style-item-bc',
      ]
    },
    {
      context: ['history', 'history.item'],
      label: 'history_item_label',
      name: 'history.item.label',
      values: [
        'history-style-item-fc',
        'history-style-item-fs',
        'history-style-item-fw',
      ]
    },
    {
      context: ['history', 'history.item'],
      label: 'history_item_date',
      name: 'history.item.date',
      values: [
        'history-style-item-date-fc',
        'history-style-item-date-fw',
      ]
    },
  ]
}

let tiers = {
  name: 'tiers', 
  section: 'tiers',
  sections: [
    {
      context: ['tiers'],
      label: 'tiers_base',
      name: 'tiers.base',
      values: [
        'tiers-style-bg',
        'tiers-style-items-bg', 
        'tiers-style-filigrane',
      ]
    },
    {
      context: ['tiers', 'tiers.title'],
      label: 'tiers_title',
      name: 'tiers.title',
      values: [
        'tiers-content-title',
        'tiers-style-title-fc',
        'tiers-style-title-fs',
        'tiers-style-title-fw',
      ]
    },
    {
      context: ['tiers', 'tiers.descr'],
      label: 'tiers_description',
      name: 'tiers.description',
      values: [
        'tiers-content-descr',
        'tiers-style-descr-fc',
        'tiers-style-descr-fs',
        'tiers-style-descr-fw',
      ]
    },
  ]
}

let tiersItems = {
  name: 'tiers_items', 
  section: 'tiers',
  sections: [
    {
      context: ['tiers.items'],
      label: 'tiers_base',
      name: 'tiers.base',
      values: [
        'tiers-style-item-bg',
        'tiers-style-item-bc',
      ]
    },
    {
      context: ['tiers.items'],
      label: 'tiers_item_title',
      name: 'tiers.item.title',
      values: [
        'tiers-style-item-title-fc',
        'tiers-style-item-title-fs',
        'tiers-style-item-title-fw',
      ]
    },
    {
      context: ['tiers.items'],
      label: 'tiers_item_description',
      name: 'tiers.item.description',
      values: [
        'tiers-style-item-descr-fc',
        'tiers-style-item-descr-fs',
        'tiers-style-item-descr-fw',
      ]
    },
    {
      context: ['tiers.items'],
      label: 'tiers_item_rewarded',
      name: 'tiers.item.rewarded',
      values: [
        'tiers-style-selected-c',
        'tiers-style-icon-award-bg',
        'tiers-style-icon-award-stroke',
        'tiers-style-icon-award-shadow-primary-bg',
        'tiers-style-icon-award-shadow-secondary-bg',
      ]
    },
  ]
}

let referral = {
  name: 'referral', 
  section: 'referral',
  sections: [
    {
      context: ['referral'],
      label: 'referral_base',
      name: 'referral.base',
      values: [
        'referral-style-primary-bg',
        'referral-style-secondary-bg',
        'referral-style-filigrane',
      ]
    },
    {
      context: ['referral', 'referral.title'],
      label: 'referral_title',
      name: 'referral.title',
      values: [
        'referral-content-title',
        'referral-style-title-fc',
        'referral-style-title-fs',
        'referral-style-title-fw',
      ]
    },
    {
      context: ['referral', 'referral.descr'],
      label: 'referral_description',
      name: 'referral.description',
      values: [
        'referral-content-descr',
        'referral-style-descr-fc',
        'referral-style-descr-fs',
        'referral-style-descr-fw',
      ]
    },
    {
      context: ['referral', 'referral.actions'],
      label: 'referral_actions',
      name: 'referral.actions',
      values: [
        'referral-content-btn-register',
        'referral-content-btn-login',
        'referral-content-btn-activate',
      ]
    },
    {
      context: ['referral', 'referral.sharing'],
      label: 'referral_sharing',
      name: 'referral.sharing',
      values: [
        'referral-content-link',
        'referral-content-copied',
        'referral-content-btn-share',
      ]
    },
  ]
}

let referralRewards = {
  name: 'referral_rewards', 
  section: 'referral',
  sections: [
    {
      context: ['referral.rewards'],
      label: 'referral_rewards_base',
      name: 'referral.rewards.base',
      values: [
        'referral-content-referrer-title',
        'referral-content-referring-title',
        'referral-style-subtitle-fc',
        'referral-style-subtitle-fs',
        'referral-style-subtitle-fw',
      ]
    },
  ]
}

let referralReward = {
  name: 'referral_reward', 
  section: 'referral',
  sections: [
    {
      context: ['referral.rewards'],
      label: 'referral_reward_base',
      name: 'referral.reward.base',
      values: [
        'referral-style-reward-bg',
        'referral-style-reward-bc',
      ]
    },
    {
      context: ['referral.rewards'],
      label: 'referral_reward_title',
      name: 'referral.reward.title',
      values: [
        'referral-content-referrer-type-loyalty',
        'referral-content-referrer-type-nft',
        'referral-content-referrer-type-voucher',
        'referral-style-reward-title-fc',
        'referral-style-reward-title-fs',
        'referral-style-reward-title-fw',
      ]
    },
    {
      context: ['referral.rewards'],
      label: 'referral_reward_description',
      name: 'referral.reward.description',
      values: [
        'referral-style-reward-descr-fc',
        'referral-style-reward-descr-fs',
        'referral-style-reward-descr-fw',
      ]
    },
    {
      context: ['referral.rewards'],
      label: 'referral_reward_icon_loyalty',
      name: 'referral.reward.icon.loyalty',
      values: [
        'referral-style-reward-icon-loyalty-bg',
        'referral-style-reward-icon-loyalty-fc',
      ]
    },
    {
      context: ['referral.rewards'],
      label: 'referral_reward_icon_nft',
      name: 'referral.reward.icon.nft',
      values: [
        'referral-style-reward-icon-nft-bg',
        'referral-style-reward-icon-nft-fc',
      ]
    },
    {
      context: ['referral.rewards'],
      label: 'referral_reward_icon_voucher',
      name: 'referral.reward.icon.voucher',
      values: [
        'referral-style-reward-icon-voucher-bg',
        'referral-style-reward-icon-voucher-fc',
      ]
    },
  ]
}

let referralHistory = {
  name: 'referral_history', 
  section: 'referral',
  sections: [
    {
      context: ['referral-history'],
      label: 'referral_history_base',
      name: 'referral.history.base',
      values: [
        'referral-history-style-bg',
        'referral-history-content-empty-title',
      ]
    },
    {
      context: ['referral-history', 'referral-history.title'],
      label: 'referral_history_title',
      name: 'referral.history.title',
      values: [
        'referral-history-content-title',
        'referral-history-style-title-fc',
        'referral-history-style-title-fs',
        'referral-history-style-title-fw',
      ]
    },
    {
      context: ['referral-history', 'referral-history.descr'],
      label: 'referral_history_description',
      name: 'referral.history.description',
      values: [
        'referral-history-content-descr',
        'referral-history-style-descr-fc',
        'referral-history-style-descr-fs',
        'referral-history-style-descr-fw',
      ]
    },
  ]
}

let referralHistoryItem = {
  name: 'referral_history_items',
  section: 'referral', 
  sections: [
    {
      context: ['referral-history', 'referral-history.item'],
      label: 'referral_history_item_base',
      name: 'referral.history.item.base',
      values: [
        'referral-history-style-item-bg',
        'referral-history-style-item-bc',
      ]
    },
    {
      context: ['referral-history', 'referral-history.item'],
      label: 'referral_history_item_label',
      name: 'referral.history.item.label',
      values: [
        'referral-history-style-item-fc',
        'referral-history-style-item-fs',
        'referral-history-style-item-fw',
      ]
    },
    {
      context: ['referral-history', 'referral-history.item'],
      label: 'referral_history_item_date',
      name: 'referral.history.item.date',
      values: [
        'referral-history-style-item-date-fc',
        'referral-history-style-item-date-fw',
      ]
    },
    {
      context: ['referral-history', 'referral-history.item'],
      label: 'referral_history_item_actions',
      name: 'referral.history.item.actions',
      values: [
        'referral-history-content-btn-more',
      ]
    },
  ]
}

export default [
  general,
  header,
  how,
  howItem,
  walletPass,
  cards,
  cardsAdd,
  ways,
  waysItem,
  usage,
  usageItem,
  history,
  historyItem,
  tiers,
  tiersItems,
  referral,
  referralRewards,
  referralReward,
  referralHistory,
  referralHistoryItem,
]
