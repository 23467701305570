import { CurryImageDatalayer } from '@curry/image'
import SaylImageModel from '../models/image'

/**
 * Generic image handler for @Sayl
 * 
 * @class
 */
export default class ImageController {
  constructor({ transports, store }) {
    let dl = new CurryImageDatalayer({ transports })
    this._curry = dl.controllers.find(c => c.fqn === 'ifm.image')
    this._store = store
  }

  /**
   * Fetches all the images for an entity
   * 
   * @param {Object} options 
   * @param {Object} options.data 
   * @param {Object} options.data.entityId 
   * @param {Object} options.data.entityType
   * @param {Object} options.data.fieldName
   * @param {Object} options.entity
   * @param {Object} options.module
   * 
   * @return {Promise}
   */
  
  all({ data = { entityId, entityType, fieldName, module}, }) {
    return new Promise((resolve, reject) => {
      let payload = { 
        limit:-1,
        entity_id: data.entityId, 
        entity_type: data.entityType, 
        field_name: data.fieldName,
        limit: -1
      }

      this._store.commit(`${data.module}/loading`, { name: `${data.entityType}.${data.fieldName}`, values: true })
      this._curry.all(payload)
        .then((collection) => {
          collection = collection
            .map(c => new SaylImageModel(c.toAPI()))
            .sort((a, b) => a.position - b.position)
          
          this._store.commit(`${data.module}/all`, { name: `${data.entityType}.${data.fieldName}`, values: collection })
          resolve(collection)
        })
        .catch((e) => reject(e))
        .finally(() => this._store.commit(`${data.module}/loading`, { name: `${data.entityType}.${data.fieldName}`, values: true }))
    })
  }

  /**
   * Fetches all the images for an entity
   * 
   * @param {Object} options 
   * @param {Object} options.data 
   * @param {Object} options.data.entityId 
   * @param {Object} options.data.entityType
   * @param {Object} options.data.fieldName
   * @param {Object} options.entity
   * @param {Object} options.module
   * 
   * @return {Promise}
   */
  
  collection({ data = { entityId, entityType, fieldName, module,limit,page }, }) {
    return new Promise((resolve, reject) => {
      let payload = { 
        entity_id: data.entityId, 
        entity_type: data.entityType, 
        field_name: data.fieldName,
        limit:data.limit,
        page:data.page 
      }

      this._store.commit(`${data.module}/loading`, { name: `${data.entityType}.${data.fieldName}`, values: true })
      this._curry.find(payload)
        .then(({collection,meta}) => {
          collection = collection
            .map(c => new SaylImageModel(c.toAPI()))
            .sort((a, b) => a.position - b.position)
          
          this._store.commit(`${data.module}/collection`, { name: `${data.entityType}.${data.fieldName}`, values: collection,meta })
          resolve({collection,meta})

        })
        .catch((e) => reject(e))
        .finally(() => this._store.commit(`${data.module}/loading`, { name: `${data.entityType}.${data.fieldName}`, values: true }))
    })
  }

  /**
   * Creates a new image
   * 
   * @param {Object} options
   * @param {Object} options.data
   * @param {Object} options.data.entityId
   * @param {Object} options.data.entityType
   * @param {Object} options.data.fieldName
   * @param {Object} options.entity
   * @param {Object} options.module
   * 
   * @return {Promise}
   */
  create({ data = { entityId: null, entityType: null, fieldName: null }}) {
    return new Promise((resolve, reject) => {
      let payload = { 
        entity_id: data.entityId, 
        entity_type: data.entityType, 
        field_name: data.fieldName 
      }

      this._curry.create(payload) 
        .then((value) => {
          let image = new SaylImageModel(value.toAPI ? value.toAPI() : value)
          resolve(image)
        })
        .catch((e) => reject(e))
    })
  }

  /**
   * Links an image to an entity based on the entity's id
   * 
   * @param {Object} options
   * @param {Object} options.entityId
   * @param {Object} options.id
   * 
   * @return {Promise}
   */
  link({ data, entityId = null, id }) {
    return new Promise((resolve, reject) => {
      let payload = {
        id,
        item: { entity_id: entityId }
      };
      
      this._store.commit(`${data.module}/loading`, { name: `${data.entityType}.${data.fieldName}`, values: true })
      this._curry.link(payload)
        .then((e) => resolve(e))
        .catch((e) => reject(e))
        .finally(() => this._store.commit(`${data.module}/loading`, { name: `${data.entityType}.${data.fieldName}`, values: true }))
    })
  }

  positions({ positions }) {
    return new Promise((resolve, reject) => {
      let payload = {
        item: positions
      }

      this._curry.positions(payload)
        .then((e) => resolve(e))
        .catch((e) => reject(e))
    })
  }

  /**
   * Removes an image 
   * 
   * @return {Promise}
   */
  remove({ data, id }) {
    return new Promise((resolve, reject) => {
      let payload = { id }
      
      this._store.commit(`${data.module}/loading`, { name: `${data.entityType}.${data.fieldName}`, values: true })
      this._curry.remove(payload)
        .then((e) => resolve(e))
        .catch((e) => reject(e))
        .finally(() => this._store.commit(`${data.module}/loading`, { name: `${data.entityType}.${data.fieldName}`, values: true }))
    })
  }

  /**
   * Save a new image and resolve it
   * 
   * @return {Promise}
   */
  save({ data, image }) {
    return new Promise((resolve, reject) => {
      let payload = {
        image_id: basil.get(image, 'id', null),
        item: image.toAPI ? image.toAPI() : image
      }
      
      this._store.commit(`${data.module}/loading`, { name: `${data.entityType}.${data.fieldName}`, values: true })
      this._curry.save(payload)
        .then((value) => {
          let image = new SaylImageModel(value.toAPI())
          resolve(image)
        })
        .catch((e) => reject(e))
        .finally(() => this._store.commit(`${data.module}/loading`, { name: `${data.entityType}.${data.fieldName}`, values: true }))
    })
  }

  /**
   * Sort some images and return the result
   * 
   * @return {Promise}
   */
  sort({ images, values }) {
    return new Promise((resolve, reject) => {
      let positions = [];

      values.forEach((img, i) => {
        let r = images.find(b => b.image.all.$id === img.$id)
        positions.push({ id: r.id, position: i + 1 })
      })

      this._curry.positions({ item: { positions }})
        .then((e) => resolve(e))
        .catch((e) => reject(e))
    })
  }

  /**
   * Unlinks an image from an entity
   * 
   * @param {Object} options
   * @param {Object} options.entityId
   * @param {Object} options.id
   * 
   * @return {Promise}
   */
  unlink({ data, entityId, id }) {
    return new Promise((resolve, reject) => {
      let payload = {
        entity_id: entityId,
        id
      };
      this._store.commit(`${data.module}/loading`, { name: `${data.entityType}.${data.fieldName}`, values: true })
      this._curry.unlink(payload)
        .then((e) => resolve(e))
        .catch((e) => reject(e))
        .finally(() => this._store.commit(`${data.module}/loading`, { name: `${data.entityType}.${data.fieldName}`, values: true }))
    })
  }
}
