<template>
  <data-status v-bind="attrs">{{ label }}</data-status>
</template>

<script>
export default {
  name: 'DataTableCellVisibility',

  props: {
    content: {
      type: Boolean
    }
  },

  computed: {
    active(){
      return this.content === true
    },

    attrs(){
      return {
        active: this.active === true,
        class: "data-table-cell-visibility",
        icon: this.active === true ? 'visible' : 'hidden',
        intent: this.active === true ? this.$pepper.Intent.SUCCESS : null
      }
    },

    label(){
      return this.active === true ? 
             this.$t('sayl.visible') :
             this.$t('sayl.invisible')
    }
  }

}
</script>